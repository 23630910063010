import React from 'react';
import Box from '@mui/material/Box';

import Chip from '@mui/material/Chip';
import { getCompleteListOfDateOptions, generateArrayOfDates } from 'utils/utility';
import {
  setCheckedFilters,
  selectCheckedFilters,
  setCheckedFiltersToggle,
  selectCalendarFromAndTo,
  selectDateListNoOfYears,
  setFormattedCalendarIntervalDates
} from 'redux/slices/filters';
import { useDispatch, useSelector } from 'react-redux';
import { fDate } from 'utils/formatTime';

export default function ActiveDataRangeLabels({ selectedTimeframe, selectedDates, activeTab }) {
  const calendarFromAndTo = useSelector(selectCalendarFromAndTo);
  const dateListNoOfYears = useSelector(selectDateListNoOfYears);
  const dispatch = useDispatch();
  const getArrayOfLabels = (selectedDates) => {
    const fullOptions = getCompleteListOfDateOptions(selectedTimeframe, dateListNoOfYears);
    const checkedOptions = selectedDates.filter((option) => option.checked === true);
    const uncheckedOptions = selectedDates.filter((option) => !option.checked);
    if (!calendarFromAndTo[0] && calendarFromAndTo[1] && activeTab !== 0) {
      dispatch(setFormattedCalendarIntervalDates(generateArrayOfDates(null, calendarFromAndTo[1])));
      return [
        {
          id: 'calendar-selection-to',
          name: `${fDate(calendarFromAndTo[1])}`
        }
      ];
    }
    if (calendarFromAndTo[0] && !calendarFromAndTo[1] && activeTab !== 0) {
      dispatch(setFormattedCalendarIntervalDates(generateArrayOfDates(calendarFromAndTo[0], null)));
      return [
        {
          id: 'calendar-selection-from',
          name: `${fDate(calendarFromAndTo[0])}`
        }
      ];
    }
    if (calendarFromAndTo[0] && calendarFromAndTo[1] && activeTab !== 0) {
      dispatch(
        setFormattedCalendarIntervalDates(
          generateArrayOfDates(calendarFromAndTo[0], calendarFromAndTo[1])
        )
      );
      return [
        {
          id: 'calendar-selection',
          name: `From ${fDate(calendarFromAndTo[0])} - To ${fDate(calendarFromAndTo[1])}`
        }
      ];
    }
    if (fullOptions.length === checkedOptions.length || activeTab || !checkedOptions.length) {
      return [
        {
          id: 'include-all',
          name: 'Include all'
        }
      ];
    }
    if (uncheckedOptions.length >= fullOptions.length / 2 && !activeTab) {
      return checkedOptions;
    }
    if (checkedOptions.length >= fullOptions.length / 2 && !activeTab) {
      return uncheckedOptions.map((option) => {
        const excludedOption = {
          ...option,
          excluded: true
        };
        return excludedOption;
      });
    }
    return [];
  };

  return (
    <Box
      sx={{
        maxWidth: '350px',
        overflow: 'hidden'
      }}
    >
      {getArrayOfLabels(selectedDates)
        .map((option) => (
          <Chip
            key={option.name}
            label={option.name}
            color={option.excluded ? 'error' : 'primary'}
            variant="filled"
            size="small"
            sx={{ marginRight: '3px', borderRadius: '3px', marginTop: '7px' }}
          />
        ))
        .slice(0, 3)}
    </Box>
  );
}
