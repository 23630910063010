import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import UITable from 'components/Table';
import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import {
  impressionDevicesColumns,
  impressionDevicesRows,
  ageColumns,
  ageRows,
  genderRows,
  genderColumns,
  demographicsGrandTotal
} from '_mocks_/table';
import { useTranslation } from 'react-i18next';

MissingData.propTypes = {};

export default function MissingData() {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
      <Grid xs={12} item sx={{ fontSize: 14 }}>
        {t('noDataToShow')}
      </Grid>
    </Grid>
  );
}
