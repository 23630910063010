import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import BiaxialComposedChartsGroup from 'components/_dashboard/BiaxialComposedChartsGroup';
import { selectVolumeEfficiencyChartData } from 'redux/slices/dashboards';
import { getVolumeEfficiencyChartData } from 'redux/thunk/dashboards';
import { useDashboard } from 'utils/hooks/useDashboard';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { detectEmptyData } from 'utils/utility';

// ----------------------------------------------------------------------

VolumeEfficiency.propTypes = {
  isModal: PropTypes.bool
};

export default function VolumeEfficiency({ isModal }) {
  const { dashboardData, isLoading } = useDashboard(
    getVolumeEfficiencyChartData,
    selectVolumeEfficiencyChartData,
    isModal
  );

  return (
    <DashboardLayout
      hideFilters={isModal}
      isLoading={isLoading}
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12}>
        <BiaxialComposedChartsGroup volumeEfficiencyChartData={dashboardData} isModal={isModal} />
      </Grid>
    </DashboardLayout>
  );
}
