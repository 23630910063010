import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { selectIsLogged } from 'redux/slices/auth';
import { useSelector } from 'react-redux';
import DashboardLayout from 'layouts/dashboard';
import CopyrightLayout from 'layouts/CopyrightLayout';
//
import ChangePassword from 'pages/ChangePassword';
import Login from 'pages/Login';
import Register from 'pages/Register';
import Dashboard from 'pages/Dashboard';
import Products from 'pages/Products';
import Blog from 'pages/Blog';
import User from 'pages/User';
import NotFound from 'pages/Page404';
import ForgotPassword from 'pages/ForgotPassword';

// ----------------------------------------------------------------------

export default function Router() {
  const isLogged = useSelector(selectIsLogged);
  return useRoutes([
    {
      path: '/dashboard',
      element: isLogged ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/85bc44aa744e11ec90d60242ac120003" replace /> },
        { path: '', element: <Navigate to="/dashboard/:dashboardId" /> },
        { path: ':dashboardId', element: <Dashboard /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: !isLogged ? <CopyrightLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'change-password/:verificationCode', element: <ChangePassword /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
