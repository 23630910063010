import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import UITable from 'components/Table';
import PieChartsGroup from 'components/_dashboard/productDataPieCharts';
import { useDashboard } from 'utils/hooks/useDashboard';
import { getProductDataData } from 'redux/thunk/dashboards';
import { selectProductDataData } from 'redux/slices/dashboards';
import { useTranslation } from 'react-i18next';
import { detectEmptyData } from 'utils/utility';

// ----------------------------------------------------------------------

ProductData.propTypes = {
  isModal: PropTypes.bool
};

export default function ProductData({ isModal }) {
  const { t } = useTranslation();
  const { dashboardData, isLoading } = useDashboard(
    getProductDataData,
    selectProductDataData,
    isModal
  );

  return (
    <DashboardLayout
      hideFilters={isModal}
      hideTimeSelector
      isLoading={isLoading}
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12}>
        <PieChartsGroup pieChartsData={dashboardData.pieCharts} isModal={isModal} />
      </Grid>
      {dashboardData && dashboardData.table && (
        <Grid item xs={12} mt={5} sx={{ marginRight: isModal ? 2 : 0 }}>
          <UITable
            grandTotal={dashboardData.table?.grandTotal}
            list={dashboardData.table?.rows}
            columns={dashboardData.table?.columns}
            title={t('product')}
            filters={dashboardData.table?.filters}
            exportLink={dashboardData.table?.exportLink}
          />
        </Grid>
      )}
    </DashboardLayout>
  );
}
