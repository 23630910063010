import React from 'react';
import PropTypes from 'prop-types';
import { RadarChart, PolarGrid, PolarAngleAxis, Radar, PolarRadiusAxis, Legend } from 'recharts';
import { useTooltipContext } from 'components/charts/CustomTooltip';
import palette from 'theme/palette';
import typography from 'theme/typography';
import { polarToCartesian, formatTick } from 'utils/utility';

RocketerRadarChart.propTypes = {
  chartData: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  outerRadius: PropTypes.number,
  maxDomain: PropTypes.number
};

const tooltipContent = (props, radarColor, title, index) => {
  const point = props.points[index];
  return (
    <div>
      <div style={{ color: palette.common.black }}>{`${title}`}</div>
      <div style={{ color: radarColor, fontWeight: 'bold' }}>{`${formatTick(
        point.value
      )} / 100%`}</div>
      <hr style={{ marginTop: 10, marginBottom: 10 }} />
      <span style={{ color: palette.common.black }}>{point.name}</span>
    </div>
  );
};

const tooltipStyle = {
  backgroundColor: palette.common.white,
  borderColor: palette.grey[500],
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  fontFamily: typography.fontFamily,
  fontSize: '14px',
  padding: '.5%',
  marginTop: '-35px'
};

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
    'L',
    x,
    y,
    'L',
    start.x,
    start.y
  ].join(' ');

  return d;
}

const computePathD = (props, index, chartData) => {
  const { points } = props;
  const halfAngleValue = 360 / chartData.data.length / 4; // to display a part of the slice before the guiding line of the grid
  const offset = index * halfAngleValue * 4;
  const additionalSpace = chartData.data.length < 4 ? 10 : 0;
  return describeArc(
    points[index].cx,
    points[index].cy,
    points[index].radius,
    offset - halfAngleValue + additionalSpace,
    offset + halfAngleValue - additionalSpace
  );
};

export default function RocketerRadarChart({ chartData, width, height, outerRadius, maxDomain }) {
  const color = palette.radarChart[0];
  const colorHover = palette.radarChart[1];
  const { openTooltip, closeTooltip } = useTooltipContext();

  return (
    <RadarChart
      id="41"
      cx="50%"
      cy="50%"
      outerRadius={outerRadius || 120}
      data={chartData.data}
      width={width || 480}
      height={height || 420}
      isAnimationActive={false}
      margin={{ top: 20 }}
      style={{ margin: 'auto' }}
    >
      <PolarGrid gridType="circle" strokeDasharray="3 3" />
      <PolarAngleAxis dataKey="name" style={{ fontSize: 13 }} />
      <PolarRadiusAxis
        domain={[0, maxDomain]}
        tickFormatter={(tick) => formatTick(tick)}
        style={{ fontSize: 13, color: palette.grey[800] }}
      />
      <Legend />
      <Radar
        name={chartData.title}
        dataKey="value"
        stroke={color}
        fill={color}
        fillOpacity={0.8}
        shape={(props) => (
          <svg
            version="1.1"
            baseProfile="full"
            width="500"
            height="500"
            xlmns="http://www/w3/org/2000/svg"
          >
            {chartData.data.map((elem, index) => (
              <g
                key={`${elem.name}${index}`}
                onMouseEnter={(e) => {
                  e.target.style.fill = colorHover;
                  return openTooltip({
                    content: tooltipContent(props, color, chartData.title, index),
                    style: tooltipStyle
                  });
                }}
                onMouseLeave={(e) => {
                  e.target.style.fill = color;
                  return closeTooltip();
                }}
              >
                <path d={computePathD(props, index, chartData)} fill={color} />
              </g>
            ))}
          </svg>
        )}
      />
    </RadarChart>
  );
}
