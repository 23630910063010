import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import DashboardOptionsPicker from 'components/DashboardOptionsPicker';
import CompanyDropdown from 'components/dashboard-dropdowns/CompanyDropdown';
import ProjectDropdown from 'components/dashboard-dropdowns/ProjectDropdown';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { TIME_FRAMES } from 'uiConstants';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import MissingData from 'layouts/dashboard/MissingData';

// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  color: theme.palette.text.secondary
}));

const timeFrames = Object.keys(TIME_FRAMES);

export default function DashboardLayout({
  hideFilters,
  children,
  hideTimeSelector,
  isLoading,
  emptyData
}) {
  const [width] = useWindowSize();
  const xlWidth = 1580;

  return (
    <Grid container justifyContent="space-between" mt={5} spacing={2}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!hideFilters && (
            <Grid container mb={3} spacing={3} alignItems="center" justifyContent="space-evenly">
              {!hideTimeSelector && (
                <Grid item xs={12} md={width < xlWidth ? 12 : 6}>
                  <Item>
                    <DashboardOptionsPicker filterType="time" options={timeFrames} />
                  </Item>
                </Grid>
              )}
              <Grid item xs={12} md={width < xlWidth ? 6 : (!hideTimeSelector && 3) || 6}>
                <Item sx={(width < xlWidth || hideTimeSelector) && { paddingLeft: '15px' }}>
                  <CompanyDropdown width={width} />
                </Item>
              </Grid>
              <Grid item xs={12} md={width < xlWidth ? 6 : (!hideTimeSelector && 3) || 6}>
                <Item>
                  <ProjectDropdown width={width} />
                </Item>
              </Grid>
            </Grid>
          )}
          {emptyData ? (
            <Grid item xs={12} sx={{ ml: 1 }}>
              <Item>
                <MissingData />{' '}
              </Item>
            </Grid>
          ) : (
            children
          )}{' '}
        </>
      )}
    </Grid>
  );
}

DashboardLayout.propTypes = {
  hideFilters: PropTypes.bool,
  hideTimeSelector: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node
};
