import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDashboardFilters,
  setCheckedFilters,
  selectCheckedFilters,
  selectCompaniesFromFilter,
  selectProjectsFromFilter
} from 'redux/slices/filters';
import AutocompleteDropdown from 'components/dropdown/AutocompleteDropdown';
import { setDataForFilter } from 'utils/utility';
import { setDataLength } from 'redux/slices/dashboards';

export default function ProjectDropdown() {
  const { t } = useTranslation();
  const companies = useSelector(selectCompaniesFromFilter);
  const projects = useSelector(selectProjectsFromFilter);
  const companyProjFilter = useSelector(selectDashboardFilters);
  const dispatch = useDispatch();
  const { project, filteredProjects } = companyProjFilter;
  const checkedFilters = useSelector(selectCheckedFilters);
  let checkedProjectsForCurrentCompany = [];

  const handleCompanySelection = (projectSelected, checked) => {
    const companyWasSelected = !!checkedFilters['Company Name']?.data.find(
      (company) => company === companies.find((c) => c.id === projectSelected.companyId)?.name
    );
    const companyToBeSelected = companies.find(
      (company) => company.id === projectSelected.companyId
    );
    checkedProjectsForCurrentCompany = checkedFilters['Project Name']?.data.filter(
      (project) => projects.find((p) => p.name === project).companyId === projectSelected.companyId
    );
    if (checked && !companyWasSelected) {
      dispatch(
        setCheckedFilters({
          filterName: 'Company Name',
          data: setDataForFilter(
            companyToBeSelected.name,
            'Company Name',
            true,
            companies,
            checkedFilters
          )
        })
      );
    } else if (!checked && companyWasSelected && checkedProjectsForCurrentCompany.length === 1) {
      dispatch(
        setCheckedFilters({
          filterName: 'Company Name',
          data: setDataForFilter(
            companyToBeSelected.name,
            'Company Name',
            false,
            companies,
            checkedFilters
          )
        })
      );
    }
    dispatch(setDataLength(''));
  };

  const handleProjectChange = (event, arrayOfValues, reason) => {
    const projectTouched = projects.find((p) => {
      if (event.target.textContent !== '') {
        return p.name === event.target.textContent;
      }
      if (arrayOfValues[0]?.name) {
        return p.name === arrayOfValues[0].name;
      }
      return false;
    });
    if (reason === 'removeOption' || reason === 'clear') {
      if (checkedFilters['Project Name']?.data.length === 1 || !arrayOfValues.length) {
        dispatch(
          setCheckedFilters({
            filterName: 'Project Name',
            data: setDataForFilter('all', 'Project Name', false, projects, checkedFilters)
          })
        );
        dispatch(
          setCheckedFilters({
            filterName: 'Company Name',
            data: setDataForFilter('all', 'Company Name', false, companies, checkedFilters)
          })
        );
      } else {
        const removedOption = checkedFilters['Project Name'].data.find(
          (o) => !arrayOfValues.map((value) => value.name).includes(o)
        );
        dispatch(
          setCheckedFilters({
            filterName: 'Project Name',
            data: setDataForFilter(removedOption, 'Project Name', false, projects, checkedFilters)
          })
        );
        handleCompanySelection(projectTouched, false);
      }
    } else {
      dispatch(
        setCheckedFilters({
          filterName: 'Project Name',
          data: setDataForFilter(
            event.target.textContent || arrayOfValues[0].name,
            'Project Name',
            !!arrayOfValues.find(
              (value) => value.name === event.target.textContent || arrayOfValues[0].name
            ),
            projects,
            checkedFilters
          )
        })
      );
      handleCompanySelection(projectTouched, true);
    }
    dispatch(setDataLength(''));
  };

  return (
    <AutocompleteDropdown
      options={filteredProjects.length ? filteredProjects : projects}
      placeholder={project.length ? '' : t('projectName')}
      handleChange={handleProjectChange}
      filterType="project"
      disabled={checkedFilters['Project Name']?.disabled || false}
      selectedOptions={projects.filter(
        (project) =>
          checkedFilters['Project Name']?.data?.find(
            (checkedFilter) => checkedFilter === project.name
          ) || false
      )}
    />
  );
}
