export const USER_ROLES = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  CLIENT_ADMIN: 'client_admin',
  CLIENT: 'client'
};

export const TIME_FRAMES = {
  YEARLY: 'YEARLY',
  QUARTERLY: 'QUARTERLY',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
  DAYOFWEEK: 'DAYOFWEEK'
};

export const UNIT_OF_TIME = {
  YEARS: 'years',
  QUARTERS: 'quarters',
  MONTHS: 'months',
  WEEKS: 'weeks',
  DAYS: 'days'
};

export const HERO_METRICS_LABELS = {
  HERO_METRICS1: 'HERO METRIC 1',
  HERO_METRICS_CVR: 'HERO METRIC 1 CR',
  HERO_METRICS_CPQ: 'HERO METRIC 1 CPA'
};

export const DATE_LIST_OPTIONS_LENGTH = {
  YEARS: 2
};

export const DRAWER_WIDTH = 220;

export const COPY_IMAGE_PAGE_TITLES = {
  MEDIA: 'Media by',
  COPY: 'Copy by',
  BY: 'by',
  AND_BY: '& by'
};

export const RADAR_CHARTS_TITLES = [
  'Top 20 Media File Ids by Hero Metric 1',
  'Top 20 Media File Ids by Spend'
];

export const CHART_DEFAULT_MEASUREMENTS = {
  HORIZONTAL_CHART_BAR_HEIGHT: 12,
  LARGE_DATA_HORIZONTAL_CHART_BAR_HEIGHT: 8
};

export const API_ERROR_MESSAGES = {
  invalidEmailOrPassword: 'Invalid email or password'
};

export const DASHBOARDS_WITH_LARGE_DATA = ['Copy & Image', 'Top Cities'];
export const DASHBOARDS_FREE_OF_CHART_LENGTH_SELECTOR = [
  'Volume Vs Efficiency',
  'Product Analysis',
  'Demographics',
  'Time of Day',
  'City Data',
  'Region'
];
export const DASHBOARDS_WITH_SINGLE_TYPE_OF_DATA = ['Performance Overview'];

export const CHART_LENGTH_SELECTOR_OPTIONS = [
  { id: 0, label: '25', value: 25 },
  { id: 1, label: '50', value: 50 },
  { id: 2, label: '75', value: 75 },
  { id: 3, label: '100', value: 100 },
  { id: 4, label: 'All', value: '' }
];
