import { Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectNotification, setNotification } from 'redux/slices/app';

export default function Notification() {
  const { t } = useTranslation();
  const notification = useSelector(selectNotification);
  const dispatch = useDispatch();

  return (
    <Snackbar
      open
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => dispatch(setNotification(null))}
    >
      <Alert
        severity={notification?.type}
        sx={{ width: '100%', whiteSpace: 'pre-line', textAlign: 'justify' }}
        onClose={() => dispatch(setNotification(null))}
      >
        {t(notification?.messageId)}
      </Alert>
    </Snackbar>
  );
}
