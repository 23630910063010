import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';

const CustomSwitchWrapper = styled(Switch)(({ theme }) => ({
  width: 18,
  height: 10,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(8px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.dark
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 8,
    height: 8,

    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));

export default function CustomSwitch({ isEnabled, onChange, size, extraCustomisation }) {
  return (
    <CustomSwitchWrapper
      size={size}
      sx={extraCustomisation}
      checked={isEnabled}
      onChange={(e) => onChange(e.target.checked)}
    />
  );
}
CustomSwitch.propTypes = {
  isEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  extraCustomisation: PropTypes.object
};
