import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { fShortenNumber, fPercent } from 'utils/formatNumber';
import palette from 'theme/palette';
import typography from 'theme/typography';
import { useTooltipContext } from 'components/charts/CustomTooltip';
// ----------------------------------------------------------------------

const xAxisStyle = {
  fontSize: 12
};
const yAxisStyle = {
  fontSize: 12,
  marginRight: '15px'
};

// ----------------------------------------------------------------------

HourlyBiaxialComposedChart.propTypes = {
  chartData: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  ticksInterval: PropTypes.number,
  lineColor: PropTypes.string,
  barColor: PropTypes.string,
  hoverBarColor: PropTypes.string,
  percentageFormatRight: PropTypes.bool,
  percentageFormatLeft: PropTypes.bool,
  CustomTooltip: PropTypes.func
};

const tooltipContent = (props, color, title) => {
  const { payload } = props;
  const { name, dataKey } = props.tooltipPayload[0];
  if (!name || !dataKey || !payload) return null;
  return (
    <div>
      <div style={{ color: palette.common.black }}>{`${name}`}</div>
      <div style={{ color, fontWeight: 'bold' }}>{`${fShortenNumber(
        props.value
      ).toUpperCase()}`}</div>
      <hr style={{ marginTop: 10, marginBottom: 10, minWidth: 50 }} />
      <span style={{ color: palette.common.black }}>{formatHour(payload.name)}</span>
    </div>
  );
};

const tooltipStyle = {
  backgroundColor: palette.common.white,
  borderColor: palette.grey[500],
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  fontFamily: typography.fontFamily,
  fontSize: '13px',
  padding: '.5%',
  marginTop: '-190px'
};

export const formatHour = (tick) => (tick && tick.length === 1 ? `0${tick}` : tick);

export default function HourlyBiaxialComposedChart({
  chartData,
  height,
  width,
  ticksInterval,
  lineColor,
  barColor,
  hoverBarColor,
  percentageFormatRight,
  percentageFormatLeft,
  CustomTooltip
}) {
  const { openTooltip, closeTooltip } = useTooltipContext();

  const onMouseOver = (data, x, e) => {
    e.target.style.fill = hoverBarColor;
  };

  const onMouseOut = (data, x, e) => {
    e.target.style.fill = barColor;
  };

  const numberFormatter = (tick, percentage) => {
    if (percentage) {
      return fPercent(tick);
    }
    return fShortenNumber(tick).toUpperCase();
  };

  return (
    <ComposedChart data={chartData?.data} width={width || 500} height={height || 400}>
      <XAxis
        dataKey="name"
        allowDataOverflow
        minTickGap={1}
        tickSize={10}
        tickFormatter={(tick) => formatHour(tick)}
        interval={ticksInterval}
        style={xAxisStyle}
        tickLine={false}
      />
      <YAxis
        yAxisId="left"
        tickSize={10}
        allowDataOverflow
        style={yAxisStyle}
        padding={{ top: 20, bottom: 1 }}
        tickFormatter={(tick) => numberFormatter(tick, percentageFormatLeft)}
        tickLine={false}
      />
      <YAxis
        yAxisId="right"
        orientation="right"
        tickSize={10}
        allowDataOverflow
        style={yAxisStyle}
        tickFormatter={(tick) => numberFormatter(tick, percentageFormatRight)}
        padding={{ top: 20 }}
        tickLine={false}
      />
      <CartesianGrid horizontal={false} strokeDasharray="3 3" />
      <Bar
        yAxisId="left"
        name={chartData?.barName}
        dataKey="barValue"
        maxBarSize={80}
        fill={barColor}
        isAnimationActive={false}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onMouseEnter={(e) =>
          openTooltip({
            content: tooltipContent(e, barColor, chartData?.barName),
            style: tooltipStyle
          })
        }
        onMouseLeave={() => closeTooltip()}
      />
      <Line
        yAxisId="right"
        name={chartData?.lineName}
        dataKey="lineValue"
        type="linear"
        stroke={lineColor}
        strokeWidth={2}
        isAnimationActive={false}
        dot={
          CustomTooltip ? (
            <CustomTooltip color={lineColor} />
          ) : (
            <div />
          )
        }
      />
    </ComposedChart>
  );
}
