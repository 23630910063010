import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { productAnalysisBarChartData, productAnalysisMultiSeriesChartsData } from 'mockData';
import ProductAnalysisHorizontalChartsGroup from 'components/_dashboard/productAnalysisHorizontalChartsGroup';
import MultiLinesStackedChartsGroupProductAnalysis from 'components/_dashboard/multiLinesStackedChartsGroupProductAnalysis';

// ----------------------------------------------------------------------

ProductAnalysis.propTypes = {
  isModal: PropTypes.bool
};

export default function ProductAnalysis({ isModal }) {
  // to be replaced with the commented code when the connection with the api is ready
  // const { dashboardData, isLoading } = useDashboard(
  //   getProductAnalysisData,
  //   selectProductAnalysisData,
  //   isModal
  // );
  return (
    <DashboardLayout
      hideFilters={isModal}
      isLoading={false}
      // TO DO: add emptyData={detectEmptyData(dashboardData)} when api connected
    >
      <Grid item xs={12} mt={5}>
        <ProductAnalysisHorizontalChartsGroup
          barChartData={productAnalysisBarChartData}
          isModal={isModal}
        />
      </Grid>

      <Grid item xs={12} mt={-3}>
        <MultiLinesStackedChartsGroupProductAnalysis
          multiSeriesChartsData={productAnalysisMultiSeriesChartsData}
        />
      </Grid>
    </DashboardLayout>
  );
}
