import React from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { AbilityContext } from './components/user-privileges/Can';
import ability from './components/user-privileges/ability';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <AbilityContext.Provider value={ability}>
      <ThemeConfig>
        <React.Suspense fallback={null}>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <Router />
        </React.Suspense>
      </ThemeConfig>
    </AbilityContext.Provider>
  );
}
