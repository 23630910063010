import React, { useState, useEffect } from 'react';
import { Checkbox, Box, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { getCompleteListOfDateOptions } from 'utils/utility';
import NativeSelect from '@mui/material/NativeSelect';
import { selectDateListNoOfYears } from 'redux/slices/filters';
import { useSelector } from 'react-redux';

export default function DateList({
  selectedDates,
  setSelectedDates,
  selectedTimeframe,
  setSelectedTimeframe
}) {
  const [localOptions, setLocalOptions] = useState([]);
  useEffect(() => {
    // DateList gets remounted everytime an option is selected
    // on every mount, the options are being populated taking into account the timeframe
    // it needs to get initialized with all the values selected, therefore once it will populate
    // all the options in the external selectedDates array

    populateOptions(selectedTimeframe, !!selectedDates.length);
  }, []);

  const dateListNoOfYears = useSelector(selectDateListNoOfYears);
  const populateOptions = (selectedOption, hasBeenInitialized) => {
    if (hasBeenInitialized) {
      setLocalOptions(selectedDates);
    } else {
      const options = getCompleteListOfDateOptions(selectedOption, dateListNoOfYears);
      setLocalOptions(options);
      setSelectedDates(options);
    }
  };

  // timeframe selection
  const _renderSelectDropdown = () => (
    <FormControl fullWidth>
      <NativeSelect
        defaultValue="Years"
        inputProps={{
          name: 'age',
          id: 'uncontrolled-native'
        }}
        onChange={(e) => {
          populateOptions(e.target.value);
          setSelectedTimeframe(e.target.value);
        }}
        value={selectedTimeframe}
        MenuProps={{
          style: {
            marginTop: '200px',
            position: 'absolute'
          }
        }}
        sx={{ fontSize: '12px', marginTop: '4px', width: '150px' }}
      >
        <option value="Years">Years</option>
        <option value="Quarters">Quarters</option>
        <option value="Months">Months</option>
        <option value="Days">Days</option>
      </NativeSelect>
    </FormControl>
  );

  return (
    <>
      <Box sx={{ position: 'relative', display: 'flex' }}>
        <Checkbox
          onChange={(e) => {
            setSelectedDates(
              localOptions.map((option) => {
                const updatedOption = {
                  ...option,
                  checked: e.target.checked
                };
                return updatedOption;
              })
            );
          }}
          checked={
            !!(localOptions.length && localOptions.every((option) => option.checked === true))
          }
          name="all"
          size="small"
          disableRipple
          sx={{
            '& .MuiSvgIcon-root': { fontSize: '14px' },
            width: '17px',
            height: '17px',
            padding: '17px 17px',
            marginLeft: '-12px'
          }}
        />
        {_renderSelectDropdown()}
      </Box>

      <Box
        sx={{
          display: 'grid',
          overflow: 'auto',
          height: '281px',
          width: '313px'
        }}
      >
        {localOptions.length ? (
          <FormGroup
            onChange={(e) => {
              setSelectedDates(
                localOptions.map((option) => {
                  let newOption = option;
                  if (option.name === e.target.name) {
                    newOption = { ...newOption, checked: e.target.checked };
                  }
                  return newOption;
                })
              );
            }}
            name="radio-buttons-group"
          >
            {localOptions.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.name}
                control={
                  <Checkbox
                    checked={localOptions.find((date) => date.name === option.name)?.checked}
                    name={option.name}
                    size="small"
                    disableRipple
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: '14px' }
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: 12 }}>{option.name}</Typography>}
                sx={{ fontSize: 10, height: '25px' }}
              />
            ))}
          </FormGroup>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

DateList.propTypes = {};
