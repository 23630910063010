import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  LabelList
} from 'recharts';
import palette from 'theme/palette';
import { dateFormatter } from 'utils/utility';

RocketerMultiLineChart.propTypes = {
  chartData: PropTypes.array,
  height: PropTypes.number,
  angle: PropTypes.number,
  tickInterval: PropTypes.number,
  showTooltip: PropTypes.bool,
  leftRightPadding: PropTypes.number,
  CustomTooltip: PropTypes.func
};

function RocketerMultiLineChart({
  height,
  chartData,
  angle,
  tickInterval,
  showTooltip,
  leftRightPadding,
  CustomTooltip
}) {
  
  return (
    <ResponsiveContainer width="100%" height={height || 500}>
      <LineChart
        margin={{
          right: 0,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid horizontal={false} />
        <XAxis
          dataKey="name"
          type="category"
          allowDuplicatedCategory={false}
          angle={angle}
          minTickGap={1}
          interval={tickInterval || 0}
          tickFormatter={(tick) => dateFormatter(tick)}
          style={{ fontSize: 13 }}
          dy={angle < 0 ? 20 : 10}
          dx={angle < 0 ? -20 : 0}
          height={60}
          padding={{ left: leftRightPadding, right: leftRightPadding }}
        />
        <YAxis
          dataKey="value"
          tickFormatter={(tick) => tick.toLocaleString()}
          domain={[0, 'dataMax + 300']}
          scale="sequential"
          tickCount={20}
          style={{ fontSize: 13 }}
        />
        <Legend wrapperStyle={{ fontSize: 13 }} />

        {chartData.map((s, i) => {
          const color = palette.chart.multiLineChartColors[i % chartData.length];
          return (
            <Line
              dataKey="value"
              data={s.data}
              name={s.category}
              key={s.category}
              type="linear"
              stroke={color}
              strokeWidth={3}
              legendType="square"
              isAnimationActive={false}
              connectNulls={false}
              dot={
                showTooltip && CustomTooltip ? (
                  <CustomTooltip color={color} />
                ) : (
                  { r: 4, fill: color }
                )
              }
            >
              <LabelList
                dataKey="value"
                position="top"
                offset={14}
                style={{ fontSize: 13, zIndex: 1000 }}
                formatter={(value) => value.toLocaleString()}
              />
            </Line>
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default RocketerMultiLineChart;
