import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  LabelList
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { fShortenNumber, fPercent } from 'utils/formatNumber';
import { fDate } from 'utils/formatTime';
import { useTooltipContext } from 'components/charts/CustomTooltip';
import palette from 'theme/palette';
import { dateFormatter } from 'utils/utility';

RocketerLineChart.propTypes = {
  chartData: PropTypes.object,
  height: PropTypes.number,
  axisTicks: PropTypes.array,
  leftRightPadding: PropTypes.number,
  angle: PropTypes.number,
  lineColor: PropTypes.string,
  xDataKey: PropTypes.string,
  tickInterval: PropTypes.number,
  CustomTooltip: PropTypes.func
};

function RocketerLineChart({
  height,
  chartData,
  axisTicks,
  leftRightPadding,
  angle,
  lineColor,
  CustomTooltip,
  tickInterval,
  xDataKey
}) {
  const theme = useTheme();
  const color = lineColor || theme.palette.success.main;
  const dataLength = chartData?.data.length;
  const maxNoScrollDataLength = 90;

  const numberFormatter = (tick) => {
    if (tick === '') return tick;
    if (chartData?.hasPercentage) {
      return fPercent(tick);
    }
    return fShortenNumber(tick).toUpperCase();
  };

  return (
    <ResponsiveContainer
      width={dataLength > maxNoScrollDataLength ? dataLength * 70 : '100%'}
      height={height || 450}
    >
      <LineChart data={chartData?.data}>
        <CartesianGrid strokeDasharray="3 3" horizontalPoints={[]} verticalPoints={[]} />
        <XAxis
          dataKey={xDataKey || 'tooltipValue'}
          angle={angle}
          padding={
            dataLength > maxNoScrollDataLength
              ? { left: 70, right: 70 }
              : { left: leftRightPadding, right: leftRightPadding }
          }
          allowDataOverflow
          minTickGap={1}
          tickLine={false}
          interval={dataLength > maxNoScrollDataLength ? 0 : tickInterval || 0}
          tickFormatter={(tick) => dateFormatter(tick)}
          style={{ fontSize: 18 }}
          dy={angle < 0 ? 29 : 10}
          dx={angle < 0 ? -30 : 0}
          height={60}
        />
        <YAxis
          style={{ fontSize: 18 }}
          tickFormatter={(tick) => numberFormatter(tick)}
          tickLine={false}
          ticks={axisTicks}
          padding={{ bottom: 50, top: 50 }}
          width={100}
        />
        <Legend
          wrapperStyle={{ position: 'relative', marginTop: angle < 0 ? 15 : 0, fontSize: 22 }}
        />
        <Line
          name={chartData?.title}
          type="linear"
          dataKey="value"
          stroke={color}
          strokeWidth={4.5}
          legendType="square"
          isAnimationActive={false}
          dot={CustomTooltip ? <CustomTooltip color={lineColor} /> : <div />}
        >
          <LabelList
            dataKey={dataLength > maxNoScrollDataLength ? 'value' : 'valueToShow'}
            position="top"
            offset={14}
            style={{ fontSize: 19 }}
            formatter={(value) => numberFormatter(value)}
          />
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
}

export default RocketerLineChart;
