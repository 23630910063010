import { configureStore } from '@reduxjs/toolkit';
import apiService from 'services/http/apiService';
import authSlice from 'redux/slices/auth';
import dashboardFiltersSlice from 'redux/slices/filters';
import companiesSlice from 'redux/slices/companies';
import appSlice from '../slices/app';
import dashboardsSlice from '../slices/dashboards';

export default configureStore({
  reducer: {
    app: appSlice,
    auth: authSlice,
    dashboardFilters: dashboardFiltersSlice,
    dashboards: dashboardsSlice,
    companies: companiesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { apiService }
      },
      serializableCheck: false
    })
});
