import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

// ------------------ Validation schemas ------------------

export const ForgotPasswordSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    email: Yup.string().email(t('emailMustBeValid')).required(t('emailIsRequired'))
  });
};

export const LoginSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    email: Yup.string().email(t('emailMustBeValid')).required(t('emailIsRequired')),
    password: Yup.string().required(t('passwordIsRequired'))
  });
};

export const ChangePasswordSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    email: Yup.string().email(t('emailMustBeValid')).required(t('emailIsRequired')),
    newPassword: Yup.string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/ , t('passwordValidationError'))
      .required(t('passwordIsRequired')),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('passwordDoesntMatch'))
      .required(t('passwordIsRequired'))
  });
};

// -------------------- Formik --------------------

export const ForgotPasswordFormik = () =>
  useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordSchema(),
    validateOnMount: true
  });

export const LoginFormik = () =>
  useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema(),
    validateOnMount: true
  });

export const ChangePasswordFormik = () =>
  useFormik({
    initialValues: {
      email: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: ChangePasswordSchema(),
    validateOnMount: true
  });
