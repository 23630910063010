import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useDispatch, useSelector } from 'react-redux';

// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { ChangePasswordFormik } from 'utils/formik';
import { changePassword } from 'redux/thunk/auth';
import { selectLoading } from 'redux/slices/auth';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const { verificationCode } = useParams();

  const formik = ChangePasswordFormik();

  const { errors, touched, values, getFieldProps, isValid } = formik;

  const onSubmit = () => {
    dispatch(changePassword(
      verificationCode,
      values.email,
      values.newPassword,
      values.confirmNewPassword,
      () => navigate('/login', { replace: true })));
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { t } = useTranslation();

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={(e) => {
          onSubmit();
          e.preventDefault();
        }}
      >
        <Stack spacing={3} mb={3}>
          <TextField
            fullWidth
            type="email"
            label={t('email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label={t('newPassword')}
            {...getFieldProps('newPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />

          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label={t('confirmNewPassword')}
            {...getFieldProps('confirmNewPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
            helperText={touched.confirmNewPassword && errors.confirmNewPassword}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!isValid}
          loading={isLoading}
        >
          {t('changePassword')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
