import React, { useState, useEffect } from 'react';
import { Checkbox, Box, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import Search from 'components/Search';
import { useTranslation } from 'react-i18next';
import { filterListByName } from 'utils/utility';

// ----------------------------------------------------------------------

export default function MultipleSelectCardContent({
  options,
  setFilter,
  withSelectAll,
  withSearch,
  checkedValues
}) {
  const [localOptions, setLocalOptions] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (options) {
      setLocalOptions(options);
    }
  }, [options]);

  const filterOptions = (word) => {
    const newOptions = filterListByName(options, word);
    setLocalOptions(newOptions);
  };
  return (
    <FormControl
      component="fieldset"
      sx={{ overflowY: localOptions?.length > 8 ? 'scroll' : 'hidden', maxHeight: 280, width: 175 }}
    >
      <Box sx={{ position: 'relative', display: 'flex' }}>
        {withSelectAll && (
          <Checkbox
            onChange={(e) => setFilter('all', e.target.checked)}
            checked={
              // eslint-disable-next-line no-nested-ternary
              checkedValues[0]?.id
                ? checkedValues.every((option) => option.checked === true)
                : searchActive && withSearch
                ? false
                : localOptions.length === checkedValues.length
            }
            name="all"
            size="small"
            disabled={searchActive && withSearch}
            disableRipple
            sx={{
              '& .MuiSvgIcon-root': { fontSize: '14px' },
              width: '17px',
              height: '17px',
              padding: '17px 17px',
              marginLeft: '-12px'
            }}
          />
        )}
        {withSearch && (
          <Search
            placeholderId={t('startTypingToSearch')}
            onChange={(word) => {
              filterOptions(word);
              if (word !== '' && !searchActive) {
                setSearchActive(true);
              }
              if (word === '') {
                setSearchActive(false);
              }
            }}
          />
        )}
      </Box>

      {localOptions.length ? (
        <FormGroup
          onChange={(e) => setFilter(e.target.name, e.target.checked)}
          name="radio-buttons-group"
        >
          {localOptions.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.name}
              control={
                <Checkbox
                  checked={checkedValues.includes(option.name)}
                  name={option.name}
                  disableRipple
                  size="small"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: '14px' } }}
                />
              }
              label={
                <Typography
                  sx={{
                    maxWidth: 120,
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  {option.name}
                </Typography>
              }
              sx={{ fontSize: 10, height: '25px' }}
            />
          ))}
        </FormGroup>
      ) : (
        <></>
      )}
    </FormControl>
  );
}

MultipleSelectCardContent.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setFilter: PropTypes.func,
  withSearch: PropTypes.bool,
  withSelectAll: PropTypes.bool,
  checkedValues: PropTypes.array
};
