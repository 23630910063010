import { useState } from 'react';
import UITable from 'components/Table';
import TimeSeriesLineChart from 'components/_dashboard/timeSeriesLineChart/index';
import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import { selectTimeSeriesChartData } from 'redux/slices/dashboards';
import { getTimeSeriesChartData } from 'redux/thunk/dashboards';
import { useDashboard } from 'utils/hooks/useDashboard';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectActiveLabel } from 'redux/slices/filters';
import { TIME_FRAMES } from 'uiConstants';
import { Grid, Box } from '@mui/material';
import HeroMetricsSelector from 'components/hero-metrics/HeroMetricsSelector';
import { isEmpty } from 'lodash';
import HorizontalChartsGroupTimeSeriesData from 'components/_dashboard/horizontalChartsGroupTimeSeriesData';
import { detectEmptyData } from 'utils/utility';

// ----------------------------------------------------------------------

TimeSeriesData.propTypes = {
  isModal: PropTypes.bool
};

export default function TimeSeriesData({ isModal }) {
  const activeTimeFrame = useSelector(selectActiveLabel('time'));
  //
  const prioritizeHerometricsFetch = true;
  const [initialize, setInitialize] = useState(false);
  // When sorting by the first column, we have to define if the sort has to be by date
  // or by using .sort from immutable List
  const isSortByDate = [TIME_FRAMES.DAILY, TIME_FRAMES.WEEKLY, TIME_FRAMES.MONTHLY].includes(
    activeTimeFrame
  );
  const { dashboardData, isLoading } = useDashboard(
    getTimeSeriesChartData,
    selectTimeSeriesChartData,
    isModal,
    prioritizeHerometricsFetch
  );

  return (
    <DashboardLayout
      hideFilters={isModal}
      isLoading={isLoading}
      emptyData={detectEmptyData(dashboardData)}
    >
      {!isEmpty(dashboardData) && (
        <>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <HeroMetricsSelector />
            </Box>
          </Grid>
          <Grid item xs={12} mb={-10}>
            <TimeSeriesLineChart lineChartData={dashboardData.lineChart} isModal={isModal} />
          </Grid>
          <Grid item xs={12}>
            <HorizontalChartsGroupTimeSeriesData
              isModal={isModal}
              barChartData={dashboardData.barCharts}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginRight: isModal ? 2 : 0 }}>
            <UITable
              grandTotal={dashboardData.table?.grandTotal}
              sortBy="primaryReportingConversionCpa"
              sortDirection="ASC"
              list={dashboardData.table?.rows}
              columns={dashboardData.table?.columns}
              title="All metrics"
              filters={dashboardData.table?.filters}
              exportLink={dashboardData.table?.exportLink}
              isSortByDate={isSortByDate}
            />
          </Grid>
        </>
      )}
    </DashboardLayout>
  );
}
