import {
  getDashboardsError,
  getDashboardsRequest,
  getDashboardsSuccess,
  getPerformanceOverviewDataRequest,
  getPerformanceOverviewDataError,
  getPerformanceOverviewDataSuccess,
  getTimeSeriesChartDataRequest,
  getTimeSeriesChartDataError,
  getTimeSeriesChartDataSuccess,
  getRegionDataRequest,
  getRegionDataSuccess,
  getRegionDataError,
  getProjectsChartDataRequest,
  getProjectsChartDataSuccess,
  getProjectsChartDataError,
  getProductDataRequest,
  getProductDataSuccess,
  getProductDataError,
  getVolumeEfficiencyChartDataRequest,
  getVolumeEfficiencyDataSuccess,
  getVolumeEfficiencyChartDataError,
  getTopCitiesDataRequest,
  getTopCitiesDataSuccess,
  getTopCitiesDataError,
  getCopyImageDataRequest,
  getCopyImageDataSuccess,
  getCopyImageDataError,
  getTimeOfDayDataRequest,
  getTimeOfDayDataSuccess,
  getTimeOfDayDataError,
  getProductAnalysisDataRequest,
  getProductAnalysisDataError,
  getProductAnalysisDataSuccess,
  getCityDataRequest,
  getCityDataError,
  getCityDataSuccess,
  getDemographicsDataRequest,
  getDemographicsDataSuccess,
  getDemographicsDataError
} from 'redux/slices/dashboards';
import { setNotification } from 'redux/slices/app';

export const getDashboards = (callback) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getDashboardsRequest());
  try {
    const dashboards = await apiService.getDashboards();
    dispatch(getDashboardsSuccess(dashboards));
    // open first dashboard in list if no dashboard is selected on mount
    if (callback) {
      callback(dashboards[0].id);
    }
  } catch (error) {
    dispatch(getDashboardsError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const getPerformanceOverviewData =
  (filterObj) => async (dispatch, getState, extraArgument) => {
    const { apiService } = extraArgument;
    dispatch(getPerformanceOverviewDataRequest());
    try {
      const response = await apiService.getPerformanceOverviewData(filterObj);
      dispatch(getPerformanceOverviewDataSuccess(response));
    } catch (error) {
      dispatch(getPerformanceOverviewDataError(error.errorMessage));
      dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
    }
  };

export const getTimeSeriesChartData = (filterObj) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getTimeSeriesChartDataRequest());
  try {
    const response = await apiService.getTimeSeriesData({
      ...getState().dashboardFilters.extraFilters,
      ...filterObj
    });
    dispatch(getTimeSeriesChartDataSuccess(response));
  } catch (error) {
    dispatch(getTimeSeriesChartDataError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const getRegionData = (filterObj) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getRegionDataRequest());
  try {
    const response = await apiService.getRegionData(filterObj);
    dispatch(getRegionDataSuccess(response));
  } catch (error) {
    dispatch(getRegionDataError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const getProjectsChartData = (filterObj) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getProjectsChartDataRequest());
  try {
    const response = await apiService.getProjectsData(filterObj);
    dispatch(getProjectsChartDataSuccess(response));
  } catch (error) {
    dispatch(getProjectsChartDataError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const getTopCitiesData =
  (filterObj, initialFetch) => async (dispatch, getState, extraArgument) => {
    const { apiService } = extraArgument;
    dispatch(getTopCitiesDataRequest());
    try {
      const response = await apiService.getTopCitiesData(filterObj);
      dispatch(getTopCitiesDataSuccess({ initialFetch, data: response }));
    } catch (error) {
      dispatch(getTopCitiesDataError(error.errorMessage));
      dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
    }
  };

export const getVolumeEfficiencyChartData =
  (filterObj) => async (dispatch, getState, extraArgument) => {
    const { apiService } = extraArgument;
    dispatch(getVolumeEfficiencyChartDataRequest());
    try {
      const response = await apiService.getVolumeVsEfficiencyData(filterObj);
      dispatch(getVolumeEfficiencyDataSuccess(response));
    } catch (error) {
      dispatch(getVolumeEfficiencyChartDataError(error.errorMessage));
      dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
    }
  };

export const getCopyImageChartData =
  (filterObj, initialFetch) => async (dispatch, getState, extraArgument) => {
    const { apiService } = extraArgument;
    dispatch(getCopyImageDataRequest());
    try {
      const response = await apiService.getCopyImageData(filterObj);
      dispatch(getCopyImageDataSuccess({ initialFetch, data: response }));
    } catch (error) {
      dispatch(getCopyImageDataError(error.errorMessage));
      dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
    }
  };

export const getTimeOfDayData = (filterObj) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getTimeOfDayDataRequest());
  try {
    const response = await apiService.getTimeOfDayData(filterObj);
    dispatch(getTimeOfDayDataSuccess(response));
  } catch (error) {
    dispatch(getTimeOfDayDataError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const getProductDataData = (filterObj) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getProductDataRequest());
  try {
    const response = await apiService.getProductDataData(filterObj);
    dispatch(getProductDataSuccess(response));
  } catch (error) {
    dispatch(getProductDataError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const getProductAnalysisData = (filterObj) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getProductAnalysisDataRequest());
  try {
    const response = await apiService.getProductAnalysisData(filterObj);
    dispatch(getProductAnalysisDataSuccess(response));
  } catch (error) {
    dispatch(getProductAnalysisDataError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const getCityData = (filterObj) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getCityDataRequest());
  try {
    const response = await apiService.getCityData(filterObj);
    dispatch(getCityDataSuccess(response));
  } catch (error) {
    dispatch(getCityDataError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const getDemographicsData = (filterObj) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getDemographicsDataRequest());
  try {
    const response = await apiService.getDemographicsData(filterObj);
    dispatch(getDemographicsDataSuccess(response));
  } catch (error) {
    dispatch(getDemographicsDataError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};
