import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useDispatch, useSelector } from 'react-redux';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { LoginFormik } from 'utils/formik';
import { login } from 'redux/thunk/auth';
import { selectLoading } from 'redux/slices/auth';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);

  const formik = LoginFormik();

  const { errors, touched, values, getFieldProps, isValid } = formik;

  const onSubmit = () => {
    dispatch(login(values.email, values.password,
      () => navigate('/dashboard/85bc44aa744e11ec90d60242ac120003', { replace: true })));
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { t } = useTranslation();

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={(e) => {
          onSubmit();
          e.preventDefault();
        }}
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ mb: 4 }}>
          <Typography variant="body2"> {t('forgotYourPassword')}</Typography>
          <Link component={RouterLink} variant="subtitle2" to="/forgot-password" sx={{ ml: 0.5 }}>
            {t('here')}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!isValid}
          loading={isLoading}
        >
          {t('login')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
