import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography } from '@mui/material';
// layouts
// components
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const LogoStyle = styled('div')(() => ({
  margin: '0px auto 50px'
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation();

  return (
    <RootStyle title="Login">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <LogoStyle>
              <Logo />
            </LogoStyle>
            <Typography variant="h4" gutterBottom>
              {t('welcomeToRocketerReporting')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{t('signInAndStartExploring')}</Typography>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
