import { styled, useTheme } from '@mui/material/styles';
import { DRAWER_WIDTH } from 'uiConstants';
import HourlyBiaxialComposedChart from 'components/charts/HourlyBiaxialComposedChart';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { selectIsSidebarOpen } from 'redux/slices/app';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import CustomTooltip from 'components/charts/CustomTooltip';
import TimeOfDayLineTooltip from 'components/charts/HourlyBiaxialComposedChart/TimeOfDayLineTooltip';
// ----------------------------------------------------------------------

const ChartTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  fontWeight: 'bold',
  opacity: 0.5,
  paddingBottom: 10,
  marginLeft: 30
}));

TimeOfDayBiaxialComposedChartsGroup.propTypes = {
  timeOfDayChartData: PropTypes.array,
  isModal: PropTypes.bool
};

export default function TimeOfDayBiaxialComposedChartsGroup({ timeOfDayChartData, isModal }) {
  const [width] = useWindowSize();
  const theme = useTheme();
  const defaultHeight = 120;
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const { timeOfDayBarColors, timeOfDayBarHoverColors, timeOfDayLineColors } = theme.palette.chart;

  const _width =
    isModal || (!isDashboardSidebarFiltersOpen && !isSidebarOpen)
      ? (width - 200) / 2
      : (width - 60 - (isDashboardSidebarFiltersOpen ? 450 : 300)) / 2;

  const _renderChartTitle = (data, index) => <ChartTitle>{data.title}</ChartTitle>;

  return (
    <Grid container>
      {timeOfDayChartData &&
        timeOfDayChartData.map((data, index) => (
          <Grid item xs={6} key={index}>
            {_renderChartTitle(data, index)}
            <CustomTooltip zoom={-0.05}>
              <HourlyBiaxialComposedChart
                chartData={data}
                height={defaultHeight}
                width={_width}
                ticksInterval={0}
                barColor={timeOfDayBarColors[index]}
                hoverBarColor={timeOfDayBarHoverColors[index]}
                lineColor={timeOfDayLineColors[index]}
                percentageFormatLeft={!!timeOfDayChartData[index].barName.includes('%')}
                percentageFormatRight={!!timeOfDayChartData[index].lineName.includes('%')}
                CustomTooltip={TimeOfDayLineTooltip}
              />
            </CustomTooltip>
          </Grid>
        ))}
    </Grid>
  );
}
