import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import UITable from 'components/Table';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useDashboard } from 'utils/hooks/useDashboard';
import { getCityData } from 'redux/thunk/dashboards';
import { selectCityData } from 'redux/slices/dashboards';
import { detectEmptyData } from 'utils/utility';

CityData.propTypes = {
  isModal: PropTypes.bool
};

export default function CityData({ isModal }) {
  const { dashboardData, isLoading } = useDashboard(getCityData, selectCityData, isModal);
  return (
    <DashboardLayout
      hideFilters={isModal}
      isLoading={isLoading}
      hideTimeSelector
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12} sx={{ marginRight: isModal ? 4 : 0 }}>
        <UITable
          grandTotal={dashboardData.table?.grandTotal}
          sortDirection="ASC"
          list={dashboardData.table?.rows}
          columns={dashboardData.table?.columns}
          filters={dashboardData.table?.filters}
          exportLink={dashboardData.table?.exportLink}
          colWidth={120}
        />
      </Grid>
    </DashboardLayout>
  );
}
