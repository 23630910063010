import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import UITable from 'components/Table';
import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import { useDashboard } from 'utils/hooks/useDashboard';
import { selectCopyImageChartData, setCurrentDashboardData } from 'redux/slices/dashboards';
import { getCopyImageChartData } from 'redux/thunk/dashboards';
import CopyImageChartsGroup from 'components/_dashboard/copyImageBarChartsGroup';
import RadarChartsGroup from 'components/_dashboard/radarChartsGroup';
import { COPY_IMAGE_PAGE_TITLES } from 'uiConstants';
import { useDispatch } from 'react-redux';
import { sortChartDataByTabelData, detectEmptyData } from 'utils/utility';

CopyAndImage.propTypes = {
  isModal: PropTypes.bool
};

export default function CopyAndImage({ isModal }) {
  // the commented code will replace the current code when the connection with the api is ready
  // for the table component - sorting props to be defined, fields for rows, cols, grandTotal verified
  // for the charts - dashboard data field naming to be verified

  const { dashboardData, isLoading } = useDashboard(
    getCopyImageChartData,
    selectCopyImageChartData,
    isModal
  );
  const dispatch = useDispatch();

  const mediaTitlesAddon = [
    COPY_IMAGE_PAGE_TITLES.MEDIA,
    COPY_IMAGE_PAGE_TITLES.AND_BY,
    COPY_IMAGE_PAGE_TITLES.BY,
    COPY_IMAGE_PAGE_TITLES.AND_BY
  ];
  const copyTitlesAddon = [
    COPY_IMAGE_PAGE_TITLES.COPY,
    COPY_IMAGE_PAGE_TITLES.AND_BY,
    COPY_IMAGE_PAGE_TITLES.BY,
    COPY_IMAGE_PAGE_TITLES.AND_BY
  ];

  return (
    <DashboardLayout
      hideFilters={isModal}
      isLoading={isLoading}
      hideTimeSelector
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12} mt={2} mr={isModal && 3}>
        <CopyImageChartsGroup
          barChartData={dashboardData.mediaBarCharts}
          isModal={isModal}
          titles={mediaTitlesAddon}
        />
      </Grid>

      <Grid item xs={12} sx={{ marginRight: isModal ? 2 : 0 }}>
        <UITable
          list={dashboardData.mediaFileTable?.rows}
          columns={dashboardData.mediaFileTable?.columns}
          grandTotal={dashboardData.mediaFileTable?.grandTotal}
          colWidth={120}
          exportLink={dashboardData.mediaFileTable?.exportLink}
          filters={dashboardData.mediaFileTable?.filters}
          title="Media File"
          sortDirection="ASC"
          globalSort={(list) =>
            sortChartDataByTabelData(
              'mediaBarCharts',
              'mediaName',
              list,
              dashboardData,
              (newDashboardData) =>
                dispatch(
                  setCurrentDashboardData({
                    dataType: 'mediaBarCharts',
                    dashboardData: newDashboardData
                  })
                )
            )
          }
        />
      </Grid>

      <Grid item xs={12} mt={5}>
        <CopyImageChartsGroup
          barChartData={dashboardData.copyBarCharts}
          isModal={isModal}
          titles={copyTitlesAddon}
        />
      </Grid>

      <Grid item xs={12} mt={5} sx={{ marginRight: isModal ? 2 : 0 }}>
        <UITable
          list={dashboardData.copyTemplateTable?.rows}
          columns={dashboardData.copyTemplateTable?.columns}
          grandTotal={dashboardData.copyTemplateTable?.grandTotal}
          exportLink={dashboardData.copyTemplateTable?.exportLink}
          filters={dashboardData.copyTemplateTable?.filters}
          colWidth={120}
          title="Copy Template"
          globalSort={(list) =>
            sortChartDataByTabelData(
              'copyBarCharts',
              'copyName',
              list,
              dashboardData,
              (newDashboardData) =>
                dispatch(
                  setCurrentDashboardData({
                    dataType: 'copyBarCharts',
                    dashboardData: newDashboardData
                  })
                )
            )
          }
        />
      </Grid>

      <Grid
        item
        xs={12}
        mt={7}
        sx={{
          zoom: '80%'
        }}
      >
        {' '}
        <RadarChartsGroup radarChartData={dashboardData.radarChart} isModal={isModal} />
      </Grid>

      <Grid item xs={12} mt={5} sx={{ marginRight: isModal ? 2 : 0 }}>
        <UITable
          list={dashboardData.mediaCopyTable?.rows}
          columns={dashboardData.mediaCopyTable?.columns}
          grandTotal={dashboardData.mediaCopyTable?.grandTotal}
          exportLink={dashboardData.mediaCopyTable?.exportLink}
          filters={dashboardData.mediaCopyTable?.filters}
          colWidth={150}
          title="Media & Copy"
        />
      </Grid>
    </DashboardLayout>
  );
}
