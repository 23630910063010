import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const IconWrapperStyle = styled('div')(() => ({
  width: 'fit-content',
  paddingTop: 5
}));

export default function Icon({ type }) {
  const getIcon = () => {
    let iconComponent = <></>;
    switch (type) {
      case 'arrowLeft':
        iconComponent = <ArrowBackIosNewIcon />;
        break;
      case 'search':
        iconComponent = <SearchIcon />;
        break;
      case 'dashboardSidebarItem':
        iconComponent = <AlignHorizontalLeftIcon />;
        break;
      case 'extend':
        iconComponent = <AspectRatioIcon />;
        break;
      case 'edit':
        iconComponent = <EditIcon />;
        break;
      case 'close':
        iconComponent = <CloseIcon />;
        break;
      case 'download':
        iconComponent = <FileDownloadIcon />;
        break;
      default:
        break;
    }
    return iconComponent;
  };
  return <IconWrapperStyle>{getIcon()}</IconWrapperStyle>;
}

Icon.propTypes = {
  type: PropTypes.string
};
