import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import { formatTick, dateFormatter } from 'utils/utility';
import palette from 'theme/palette';
import typography from 'theme/typography';
import { fShortenNumber } from 'utils/formatNumber';
import PropTypes from 'prop-types';
import { useTooltipContext } from 'components/charts/CustomTooltip';
//----------------------------------------------------------------------

StackedBarChart.propTypes = {
  chartData: PropTypes.object,
  height: PropTypes.number,
  angle: PropTypes.number,
  tickInterval: PropTypes.number,
  bars: PropTypes.array,
  showTooltip: PropTypes.bool
};

const tooltipContent = (props, barColor, bars, key) => (
  <div>
    <div style={{ color: palette.common.black }}>{`${bars[key]}`}</div>
    <div style={{ color: barColor, fontWeight: 'bold' }}>{`${fShortenNumber(
      props[key]
    ).toUpperCase()}`}</div>
    <hr style={{ marginTop: 10, marginBottom: 10 }} />
    <span style={{ color: palette.common.black }}>{dateFormatter(props.payload.name)}</span>
  </div>
);

const tooltipStyle = {
  backgroundColor: palette.common.white,
  borderColor: palette.grey[500],
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  fontFamily: typography.fontFamily,
  fontSize: '13px',
  padding: '.5%'
};

export default function StackedBarChart({
  height = 350,
  chartData = [],
  bars = [],
  angle,
  tickInterval,
  showTooltip,
  leftRightPadding
}) {
  const barsKeys = Object.keys(bars);
  const { openTooltip, closeTooltip } = useTooltipContext();
  const onMouseOverOut = (e, color) => {
    e.target.style.fill = color;
  };

  return (
    <ResponsiveContainer width="100%" height={height || 350}>
      <BarChart
        height={height}
        data={chartData}
        margin={{
          right: 20,
          left: 0,
          bottom: 5
        }}
      >
        <CartesianGrid horizontal={false} />
        <XAxis
          dataKey="name"
          type="category"
          allowDuplicatedCategory={false}
          angle={angle}
          minTickGap={1}
          interval={tickInterval || 0}
          tickFormatter={(tick) => dateFormatter(tick)}
          style={{ fontSize: 13 }}
          dy={angle < 0 ? 20 : 10}
          dx={angle < 0 ? -20 : 0}
          height={60}
          padding={{ left: leftRightPadding, right: leftRightPadding }}
        />
        <YAxis tickFormatter={(tick) => formatTick(tick)} style={{ fontSize: 13 }} />

        <Legend
          formatter={(value, entry, index) => <span>{`${bars[value]}`}</span>}
          wrapperStyle={{ fontSize: 13 }}
        />
        {barsKeys.map((key, i) => {
          const color = palette.chart.stackedBarChartColor.colors[i % barsKeys.length];
          const hoverColor = palette.chart.stackedBarChartColor.hoverColors[i % barsKeys.length];
          return (
            <Bar
              key={key}
              onMouseOver={(data, x, e) => onMouseOverOut(e, hoverColor)}
              onMouseOut={(data, x, e) => onMouseOverOut(e, color)}
              dataKey={key}
              stackId="a"
              fill={color}
              onMouseEnter={(e) =>
                showTooltip &&
                openTooltip({
                  content: tooltipContent(e, color, bars, key),
                  style: tooltipStyle
                })
              }
              onMouseLeave={() => showTooltip && closeTooltip()}
            >
              <LabelList
                dataKey={key}
                position="inside"
                style={{ fontSize: 13 }}
                formatter={(label) => formatTick(label)}
              />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}
