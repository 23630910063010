import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import CustomTooltip from 'components/charts/CustomTooltip';
import { DRAWER_WIDTH } from 'uiConstants';
import RocketerMultiLineChart from 'components/charts/MultiLineChart';
import StackedBarChart from 'components/charts/StackedBarChart';
import { selectIsSidebarOpen } from 'redux/slices/app';
import { getMaxOfArray2Keys } from 'utils/utility';
import ProductAnalysisTooltip from 'components/charts/MultiLineChart/ProductAnalysisTooltip';
// ----------------------------------------------------------------------

const ChartDataWrapper = styled('div')(() => ({
  marginLeft: 20,
  paddingBottom: 20,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'center'
}));

const ChartTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  fontWeight: 'bold',
  opacity: 0.7,
  marginLeft: 9
}));

MultiLinesStackedChartsGroupProductAnalysis.propTypes = {
  multiSeriesChartsData: PropTypes.array,
  isModal: PropTypes.bool
};

export default function MultiLinesStackedChartsGroupProductAnalysis({
  multiSeriesChartsData,
  isModal
}) {
  const [width] = useWindowSize();
  const defaultHeight = 500;
  const [aproxChartWidth, setAproxChartWidth] = useState(0);
  const isFilterSidebarOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  const _renderChartTitle = (data, index) => (
    <ChartDataWrapper index={index}>
      <ChartTitle>{data}</ChartTitle>
    </ChartDataWrapper>
  );

  useEffect(() => {
    let aproxChartWidth = isSidebarOpen ? width - DRAWER_WIDTH : width;
    aproxChartWidth = isFilterSidebarOpen ? aproxChartWidth - DRAWER_WIDTH : aproxChartWidth;
    setAproxChartWidth(aproxChartWidth / 2);
  }, [width, isSidebarOpen, isFilterSidebarOpen]);

  const getInterval = (data, totalEntries) => {
    if (totalEntries < 30) return 0;
    if (totalEntries >= 30 && totalEntries < 60) return 1;
    if (totalEntries >= 50 && totalEntries < 100) return 3;
    if (totalEntries >= 100 && totalEntries < 150) return 5;
    return 10;
  };

  return (
    <Grid
      container
      sx={{
        ml: '-20px'
      }}
    >
      {multiSeriesChartsData &&
        multiSeriesChartsData.map((data, index) => {
          const maxLengthOfDatesLines = getMaxOfArray2Keys('data', 'length', data.lineSeries);
          const maxLengthOfDatesBars = data.barSeries?.barsData?.length;
          return (
            <Grid item xs={6} mt={5} key={data.title}>
              {_renderChartTitle(data.title, index)}
              {!data.lineSeries.length ? (
                <CustomTooltip zoom={-0.07}>
                  <StackedBarChart
                    height={defaultHeight}
                    chartData={data.barSeries.barsData}
                    bars={data.barSeries.bars}
                    leftRightPadding={aproxChartWidth / (maxLengthOfDatesBars + 4)}
                    tickInterval={getInterval(data, maxLengthOfDatesBars)}
                    angle={maxLengthOfDatesBars > 10 ? -40 : 0}
                    showTooltip={false}
                  />
                </CustomTooltip>
              ) : (
                <CustomTooltip zoom={-0.05}>
                  <RocketerMultiLineChart
                    chartData={data.lineSeries}
                    tickInterval={getInterval(data, maxLengthOfDatesLines)}
                    leftRightPadding={aproxChartWidth / (maxLengthOfDatesLines + 4)}
                    angle={maxLengthOfDatesLines > 10 ? -40 : 0}
                    CustomTooltip={ProductAnalysisTooltip}
                    showTooltip={false}
                  />
                </CustomTooltip>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
}
