import { createSlice } from '@reduxjs/toolkit';
import { clearToken, getAccessToken, setLocalStorageAccessToken } from 'utils/utility';

const initialState = {
  loading: false,
  user: {}
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginError: (state) => {
      state.loading = false;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      setLocalStorageAccessToken(action.payload.accessToken);
      state.user = action.payload;
    },
    changePasswordRequest: (state) => {
      state.loading = true;
    },
    changePasswordError: (state) => {
      state.loading = false;
    },
    changePasswordSuccess: (state, action) => {
      state.loading = false;
    },
    forgotPasswordRequest: (state) => {
      state.loading = true;
    },
    forgotPasswordError: (state) => {
      state.loading = false;
    },
    forgotPasswordSuccess: (state) => {
      state.loading = false;
    },
    getUserRequest: (state) => {
      state.loading = true;
    },
    getUserError: (state) => {
      state.loading = false;
    },
    getUserSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    logout: (state, action) => {
      // navigate to login page
      action.payload();
      clearToken();
      state.loading = false;
      state.user = {};
    }
  }
});

export const {
  loginRequest,
  loginError,
  loginSuccess,
  logout,
  getUserRequest,
  getUserError,
  getUserSuccess,
  forgotPasswordSuccess,
  forgotPasswordError,
  forgotPasswordRequest,
  changePasswordSuccess,
  changePasswordError,
  changePasswordRequest
} = authSlice.actions;

export const selectIsLogged = (state) => getAccessToken() ?? state.auth.user?.accessToken;
export const selectUser = (state) => state.auth.user;
export const selectLoading = (state) => state.auth.loading;

export default authSlice.reducer;
