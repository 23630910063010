import { Grid, Paper, Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import RocketerBarChart from 'components/charts/BarChart';
import { selectActiveLabel, selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TIME_FRAMES } from 'uiConstants';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { selectIsSidebarOpen } from 'redux/slices/app';
import { useTranslation } from 'react-i18next';
import CustomTooltip from 'components/charts/CustomTooltip';

// ----------------------------------------------------------------------

const ChartStyled = styled(Paper)(() => ({
  zoom: '80%',
  MozTransform: 'scale(0.8)'
}));

const ChartTotalData = styled('div')(({ index }) => ({
  marginLeft: !index || index === 8 ? 30 : 43,
  marginBottom: 20,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTotalTitle = styled('div')(() => ({
  fontSize: 12,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTotalValue = styled('div')(({ theme }) => ({
  color: theme.palette.chart.barChartColor[0],
  fontSize: 14,
  fontWeight: 'bold'
}));

HorizontalChartsGroup.propTypes = {
  performanceOverviewChartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isModal: PropTypes.bool
};

const calcDefaultWidth = (width, isModal) => {
  if (width < 1300) return 170;
  if (width >= 1300 && width < 1600) return 220;
  if (width >= 1600 && width <= 1920) return isModal ? 270 : 250;
  return isModal ? 300 : 275;
};

const getInterval = (totalEntries) => {
  if (totalEntries < 50) return 0;
  if (totalEntries >= 50 && totalEntries < 100) return 3;
  if (totalEntries >= 100 && totalEntries < 200) return 4;
  if (totalEntries >= 200 && totalEntries < 250) return 6;
  if (totalEntries >= 700 && totalEntries < 1000) return 16;
  if (totalEntries >= 2000 && totalEntries < 3000) return 65;
  return 12;
};

export default function HorizontalChartsGroup({ performanceOverviewChartData, isModal }) {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const theme = useTheme();
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const isDashboardSidebarOpen = useSelector(selectIsSidebarOpen);
  const activeTimeFrame = useSelector(selectActiveLabel('time'));
  const labelMargin = ![TIME_FRAMES.YEARLY].includes(activeTimeFrame);

  const defaultWidth = isModal
    ? calcDefaultWidth(width, true)
    : calcDefaultWidth(
        width - (isDashboardSidebarFiltersOpen ? 200 : 0) - (isDashboardSidebarOpen ? 200 : 0)
      );

  const _renderChartTitle = (data, index) => (
    <Tooltip title={data.title || ''} placement="top">
      <ChartTotalData index={index}>
        <ChartTotalTitle>{data.title}</ChartTotalTitle>
        <ChartTotalValue>
          {(Math.round(data.total * 100) / 100).toLocaleString()}
          {data.hasPercentage ? '%' : ''}
        </ChartTotalValue>
      </ChartTotalData>
    </Tooltip>
  );

  const _renderNotConfiguredChartTitle = (data, t) => (
    <ChartTotalData index={0}>
      <ChartTotalTitle>{t(data.title)}</ChartTotalTitle>
      <br />
    </ChartTotalData>
  );

  return (
    <Grid container alignItems="stretch" justifyContent="space-between" mr={isModal ? 3 : 0}>
      {performanceOverviewChartData.length &&
        performanceOverviewChartData.map((data, index) => {
          const hideYAxisLegend = !(index === 0) && !(index === 8);
          return (
            <Grid item key={index} style={{ minHeight: 400, width: '12.5%' }}>
              {data.notConfigured && _renderNotConfiguredChartTitle(data, t)}
              {!data.notConfigured && _renderChartTitle(data, index)}

              <ChartStyled>
                <CustomTooltip>
                  <RocketerBarChart
                    barColor={theme.palette.chart.barChartColor[0]}
                    hoverBarColor={theme.palette.chart.barChartColor[1]}
                    chartData={data}
                    hideXAxisLegend
                    hideYAxisLegend={hideYAxisLegend}
                    showLabelList
                    hideZeroYAxisLabels
                    width={defaultWidth}
                    height={460}
                    margin={{ left: !hideYAxisLegend && labelMargin ? 50 : 0 }}
                    yPadding={{ top: 20, bottom: 20 }}
                    barLabelKey="label"
                    roundValue={!index || index === 8}
                    yDataKey="tooltipValue"
                    tickInterval={getInterval(data?.data.length)}
                  />
                </CustomTooltip>
              </ChartStyled>
            </Grid>
          );
        })}
    </Grid>
  );
}
