/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/thunk/auth';
import { getDashboards } from 'redux/thunk/dashboards';
import { selectNotification } from 'redux/slices/app';
import { selectDashboards, setCurrentDashboard } from 'redux/slices/dashboards';
import { setDefaultCheckedFilters, setFiltersLoading } from 'redux/slices/filters';
import { getCurrentDashboardFilters } from 'redux/thunk/filters';
import Notification from 'components/Notification';
import DashboardSidebar from './DashboardSidebar';
import DashboardNavbar from './DashboardNavbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: APP_BAR_DESKTOP - 12,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function AppLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notification = useSelector(selectNotification);
  const dashboards = useSelector(selectDashboards);
  const { pathname } = useLocation();
  const params = useParams();
  const [initialFiltersSet, setInitialFiltersSet] = useState(false);

  useEffect(() => {
    dispatch(getUser());
    setInitialFiltersSet(true);
  }, []);

  useEffect(() => {
    const noDashboardSelected = pathname.includes('dashboard') && !params.dashboardId;
    // redirect to the first dashboard if none is selected
    if (!dashboards.length) {
      dispatch(
        getDashboards(
          noDashboardSelected &&
            ((dashboardId) => navigate(`/dashboard/${dashboardId}`, { replace: true }))
        )
      );
    }
  }, [dashboards]);

  const setDefault = (filters) => {
    // set the redux checkedFilters with the default values, already selected after fetch
    dispatch(setDefaultCheckedFilters(filters));

    // dashboards.forEach((dashboard) => {
    //   dispatch(setDefaultCheckedFilters(dashboard));
    // if (filter.type === 'singleSelect') {
    //   dispatch(
    //     setCheckedFilters({
    //       filterName: filter.name,
    //       data: filter.options[0].name
    //     })
    //   );
    //   dispatch(setCheckedFiltersToggle({ filterName: filter.name, data: false }));
    // }
    // });
  };

  useEffect(() => {
    if (dashboards.length) {
      if (!dashboards.find((dashboard) => dashboard.id === params.dashboardId)) {
        navigate(`/dashboard/${dashboards[0].id}`, { replace: true });
      } else {
        dispatch(setCurrentDashboard(params.dashboardId));
        // fetch from API all the filters corresponding to the current dashboard
        // fetch filters only for the dashboards that we currently have the API for
        dispatch(
          getCurrentDashboardFilters(
            params.dashboardId,
            initialFiltersSet
              ? (filters) => setDefault(filters)
              : () => dispatch(setFiltersLoading(false))
          )
        );
        setInitialFiltersSet(false);
      }
    }
  }, [pathname, dashboards]);

  return (
    <RootStyle>
      {notification && <Notification />}

      <DashboardNavbar />
      <DashboardSidebar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
