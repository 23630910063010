import palette from 'theme/palette';
import typography from 'theme/typography';
import { useTooltipContext } from 'components/charts/CustomTooltip';
import React from 'react';
import { fShortenNumber } from 'utils/formatNumber';
import { formatHour } from 'components/charts/HourlyBiaxialComposedChart/index';

const tooltipStyle = {
  backgroundColor: palette.common.white,
  borderColor: palette.grey[500],
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  fontFamily: typography.fontFamily,
  fontSize: '13px',
  padding: '.5%',
  marginTop: '-180px'
};

const tooltipContent = (props) => {
  const { name, payload } = props;
  if (!payload || !name) return null;
  const percentage = typeof name === 'string' && name.includes('%') ? '%' : '';
  return (
    <div>
      <div style={{ color: palette.common.black }}>{`${name}`}</div>
      <div style={{ color: props.color, fontWeight: 'bold' }}>
        {`${fShortenNumber(payload.lineValue).toUpperCase()}${percentage}`}
      </div>
      <hr style={{ marginTop: 10, marginBottom: 10 }} />
      <span style={{ color: palette.common.black }}>{formatHour(payload.name)}</span>
    </div>
  );
};

export default (props) => {
  const { dataKey, ...rest } = props;
  const { openTooltip, closeTooltip } = useTooltipContext();

  if (props.value === null) return <div />;
  return (
    <svg t="100" width="160%" height="160%">
      <g
        onMouseEnter={(e) => {
          e.target.style.opacity = 0.3;
          e.target.style.r = 6;
          return openTooltip({
            content: tooltipContent(rest),
            style: tooltipStyle
          });
        }}
        onMouseLeave={(e) => {
          e.target.style.opacity = 0;
          e.target.style.r = 4;
          return closeTooltip();
        }}
      >
        <circle {...rest} r={4} fill={props.color} opacity={0} />
      </g>
    </svg>
  );
};
