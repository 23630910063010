import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsSidebarOpen, setIsSidebarOpen } from 'redux/slices/app';
import AccountPopover from './AccountPopover';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 70;

const RootStyle = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.common.white
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('md')]: {
    minHeight: APPBAR_DESKTOP,
    padding: '0 10px'
  }
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const dispatch = useDispatch();
  return (
    <RootStyle>
      <ToolbarStyle>
        {!isSidebarOpen ? (
          <IconButton
            onClick={() => dispatch(setIsSidebarOpen(true))}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        ) : (
          <></>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
