/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { List } from 'immutable';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  MultiGrid,
  SortDirection,
  SortIndicator
} from 'react-virtualized';
import { withStyles } from '@mui/styles';
import { createTheme, styled } from '@mui/material/styles';
import palette from 'theme/palette';
import { numericASC, numericDESC } from 'utils/comparators';
import PositionedMenu from 'components/PositionedMenu';
import ApiService from 'services/http/apiService';
import { dateComparator } from 'utils/formatTime';
import { formatDayOfWeekLabel } from 'utils/utility';
import { Grid, Paper } from '@mui/material';

const STYLE = {};

const STYLE_BOTTOM_RIGHT_GRID = {};

const STYLE_BOTTOM_LEFT_GRID_DOUBLED = {
  borderRight: `2px solid ${palette.table.cellBorderColor}`
};

const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: `1px solid ${palette.table.cellBorderColor}`
};

const STYLE_TOP_LEFT_GRID = {
  borderBottom: palette.table.cellBorder
};

const STYLE_TOP_RIGHT_GRID = {
  borderBottom: palette.table.cellBorder
};

const defaultTheme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginBottom: '20px',
  color: '#000',
  fontWeight: 'bold',
  boxShadow: 'none',
  opacity: '0.7'
}));

const styles = (t) => ({
  tableHeader: {
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 12,
    paddingTop: 10
  },
  tableHeaderFixed: {
    textAlign: 'left',
    paddingTop: 10,
    cursor: 'pointer',
    fontSize: 12,
    paddingLeft: 5,
    height: '60px !important',
    '&:first-child': {
      textTransform: 'lowercase',
      '&:first-letter': {
        textTransform: 'uppercase'
      }
    }
  },
  tableHeaderDayOfWeek: {
    textAlign: 'left',
    paddingTop: 10,
    cursor: 'pointer',
    fontSize: 12,
    paddingLeft: 5,
    height: '60px !important'
  },
  sortIndicator: {
    marginRight: 10,
    float: 'right'
  },
  tableTitle: {
    textAlign: 'center',
    marginBottom: 30,
    position: 'relative',
    fontSize: '16px'
  },
  contextButton: {
    position: 'absolute',
    right: 15
  },
  0: {
    backgroundColor: palette.table.cellBackground[0]
  },
  1: {
    backgroundColor: palette.table.cellBackground[1]
  },
  2: {
    backgroundColor: palette.table.cellBackground[2]
  },
  3: {
    backgroundColor: palette.table.cellBackground[3]
  },
  4: {
    backgroundColor: palette.table.cellBackground[4]
  },
  5: {
    backgroundColor: palette.table.cellBackground[5]
  },
  6: {
    backgroundColor: palette.table.cellBackground[6]
  }
});

class UITable extends React.Component {
  constructor(props, context) {
    super(props, context);
    let sortedList = List(props.list);
    if (props.sortBy && props.sortDirection) {
      sortedList = sortedList
        .sortBy((item) => item[props.sortBy])
        .update((list) => (props.sortDirection === SortDirection.DESC ? list.reverse() : list));
    }
    this.state = {
      grandTotal: props.grandTotal,
      isSortByDate: props.isSortByDate,
      sortedList,
      columns: props.columns,
      exportLink: props.exportLink,
      filters: props.filters,
      sortBy: props.sortBy,
      sortDirection: props.sortDirection ? SortDirection[props.sortDirection] : '',
      fixedColumnCount: props.columns?.filter((c) => c.isFixed).length,
      menuItems: [
        {
          name: 'CSV',
          onClick: () => this.csvDownload()
        }
      ]
    };

    this._cache = new CellMeasurerCache({
      minWidth: props.tableWidthFlexibleCols
        ? props.tableWidthFlexibleCols / props.columns.length
        : 120
    });

    this._cellRenderer = this._cellRenderer.bind(this);
    this.sort = this.sort.bind(this);
    this.sortList = this.sortList.bind(this);
    this.getColumnMeasure = this.getColumnMeasure.bind(this);
    this.csvDownload = this.csvDownload.bind(this);
  }

  getColumnMeasure(dataKey, indicator) {
    const { list } = this.props;
    const data = list
      .map((row) => row[dataKey])
      .sort(indicator === 'min' ? numericDESC : numericASC);
    const min = indicator === 'min' ? data.pop() : data.shift();
    const max = indicator === 'min' ? data.shift() : data.pop();
    const arrayPartsIndex = Math.ceil(data.length / 5);
    const split = [[min]];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 5; i++) {
      split.push(data.splice(-arrayPartsIndex));
    }
    split.push([max]);
    return split;
  }

  csvDownload() {
    const { exportLink, filters } = this.state;
    ApiService.csvDownload(exportLink, { filters }, 'table-report.csv');
  }

  sort({ sortBy, sortDirection }) {
    const sortedList = this.sortList({ sortBy, sortDirection });
    if (this.props.globalSort) {
      const fullSortedList = [
        ...(sortedList._root?.array
          ? sortedList._root.array.map((elem) => elem.array).flat(1)
          : []),

        ...sortedList._tail.array
      ];
      this.props.globalSort(fullSortedList);
    }

    this.setState({ sortBy, sortDirection, sortedList });
  }

  sortList({ sortBy, sortDirection }) {
    const { sortedList, isSortByDate } = this.state;
    if (sortBy === 'day' && isSortByDate) {
      return sortedList
        .sort(dateComparator)
        .update((list) => (sortDirection === SortDirection.DESC ? list.reverse() : list));
    }
    return sortedList
      .sortBy((item) => item[sortBy])
      .update((list) => (sortDirection === SortDirection.DESC ? list.reverse() : list));
  }

  // eslint-disable-next-line react/sort-comp
  render() {
    const { columns, classes, title, list, grandTotal, colWidth, tableWidthFlexibleCols } =
      this.props;
    const { sortedList, sortDirection, sortBy, fixedColumnCount, menuItems } = this.state;
    // checking for the grandTotal in calculating the multigrid height
    // in case there is grandTotal, we add table header height(60px) + grandTotal row height(20px) + scroll bar height(10px) + border(2px)
    // if not, we add table header height(60px)
    const height = (grandTotal ? list?.length * 20 + 92 : list?.length * 20 + 60) || 0;

    // tableWidthFlexibleCols set will enable auto-resizable columns depending on the width of the table
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Item>{title}</Item>
          </Grid>
          <Grid item xs={1}>
            <PositionedMenu items={menuItems} />
          </Grid>
        </Grid>
        <AutoSizer disableHeight>
          {({ width }) => (
            <MultiGrid
              deferredMeasurementCache={this._cache}
              fixedColumnCount={tableWidthFlexibleCols ? columns.length : fixedColumnCount}
              fixedRowCount={1}
              cellRenderer={this._cellRenderer}
              columnWidth={this._cache.columnWidth}
              columnCount={columns?.length}
              enableFixedColumnScroll
              enableFixedRowScroll
              height={height >= 500 ? 500 : height}
              rowHeight={this._cache.rowHeight}
              rowCount={sortedList.size ? sortedList.size + 1 + !!grandTotal : 0}
              style={STYLE}
              styleBottomLeftGrid={
                tableWidthFlexibleCols ? STYLE_BOTTOM_LEFT_GRID : STYLE_BOTTOM_LEFT_GRID_DOUBLED
              }
              styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
              styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
              styleBottomRightGrid={STYLE_BOTTOM_RIGHT_GRID}
              width={tableWidthFlexibleCols || width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
              sortDirection={sortDirection}
              sortBy={sortBy}
            />
          )}
        </AutoSizer>
      </div>
    );
  }

  _cellRenderer(data) {
    const { rowIndex } = data;
    return rowIndex === 0 ? this._renderHeader(data) : this._renderBody(data);
  }

  _renderHeader(data) {
    const { columns, sortBy, sortDirection, fixedColumnCount } = this.state;
    const { classes, tableWidthFlexibleCols } = this.props;
    const { key, style, columnIndex, parent, rowIndex } = data;
    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          key={key}
          role="button"
          tabIndex={columnIndex}
          style={{
            ...style,
            borderRight:
              !tableWidthFlexibleCols && columnIndex === fixedColumnCount - 1
                ? '2px solid #aaa'
                : palette.table.cellBorder
          }}
          className={
            columns[columnIndex].isFixed
              ? columns[columnIndex].label === 'DAYOFWEEK'
                ? classes.tableHeaderDayOfWeek
                : classes.tableHeaderFixed
              : classes.tableHeader
          }
          onClick={() =>
            this.sort({
              sortBy: columns[columnIndex].dataKey,
              sortDirection: SortDirection[sortDirection === 'ASC' ? 'DESC' : 'ASC']
            })
          }
        >
          {columns[columnIndex] ? formatDayOfWeekLabel(columns[columnIndex].label) : ''}
          {sortBy === columns[columnIndex].dataKey ? (
            <span className={classes.sortIndicator}>
              <SortIndicator sortDirection={sortDirection} />
            </span>
          ) : (
            <></>
          )}
        </div>
      </CellMeasurer>
    );
  }

  _renderBody(data) {
    const { classes, list, tableWidthFlexibleCols } = this.props;
    const { columns } = this.state;
    const { key, style, rowIndex, columnIndex, parent } = data;
    const { withColorIndicator, dataKey, label } = columns[columnIndex];
    const datum = this._getDatum(rowIndex);
    const cellData = datum[columns[columnIndex].dataKey] || '';
    const colMeasure = withColorIndicator
      ? this.getColumnMeasure(dataKey, withColorIndicator)
      : null;
    const flexStyle = columns[columnIndex].isFixed
      ? {
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: 5
        }
      : {
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        };
    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          key={key}
          style={{
            ...style,
            ...flexStyle,
            // textAlign: columns[columnIndex].isFixed ? 'left' : 'center',
            paddingRight: columns[columnIndex].isFixed ? 10 : 0,
            borderRight: palette.table.cellBorder,
            fontSize: 13,
            whiteSpace: 'nowrap',
            borderTop:
              rowIndex === list.length + 1
                ? `${tableWidthFlexibleCols ? 1 : 2}px solid ${palette.table.cellBorderColor}`
                : ''
          }}
          className={
            classes[
              rowIndex !== list.length + 1 && colMeasure && cellData
                ? colMeasure.findIndex((m) => m.includes(cellData))
                : ''
            ]
          }
        >
          {cellData !== null ? cellData.toLocaleString() : null}
          {cellData !== null && cellData !== '' && columns[columnIndex].hasPercentage ? '%' : ''}
        </div>
      </CellMeasurer>
    );
  }

  _getDatum(index) {
    const { sortedList, grandTotal } = this.state;
    if (grandTotal && index === sortedList.size + 1) return grandTotal;
    return sortedList.get((index % sortedList.size) - 1);
  }
}

export default withStyles(styles, { defaultTheme })(UITable);
