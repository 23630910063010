import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectNotification } from 'redux/slices/app';
import Notification from 'components/Notification';

// ----------------------------------------------------------------------

const FooterStyle = styled('footer')(({ theme }) => ({
  bottom: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function CopyrightLayout() {
  const notification = useSelector(selectNotification);
  const { t } = useTranslation();

  return (
    <>
      {notification && <Notification />}
      <FooterStyle>
        <Typography variant="body2" align="center" gutterBottom>
          {t('copyrightRocketer', [new Date().getFullYear()])}
        </Typography>
      </FooterStyle>
      <Outlet />
    </>
  );
}
