/* eslint-disable no-nested-ternary */
import { createSlice, current } from '@reduxjs/toolkit';
import { TIME_FRAMES, UNIT_OF_TIME, DATE_LIST_OPTIONS_LENGTH } from 'uiConstants';
import {
  findEarliestDateFromArray,
  findLatestDateFromArray,
  findExcludedDatesBetweenEarliestAndLatest,
  getBegginingOfYear,
  getEndOfYear,
  findOriginalTimeFormat,
  convertToDefaultTimeFormat,
  fDate,
  findDateType
} from 'utils/formatTime';
import { formatWithDash } from 'utils/utility';

const initialState = {
  company: [],
  project: [],
  filteredCompanies: [],
  filteredProjects: [],
  time: {
    activeLabel: TIME_FRAMES.DAILY
  },
  currentHeroMetrics: [], // the list of labels
  heroMetricsLoading: false,
  heroMetrics: {
    activeLabel: ''
  },
  isDashboardSidebarFiltersOpen: true,
  currentDashboardFilters: [],
  checkedFilters: {},
  filtersLoading: false,
  calendarFromAndTo: [null, null],
  filterObjForApiCall: {},
  extraFilters: {
    heroMetrics: {
      type: '',
      metric: 'primary'
    }
  },
  heroMetricsDropdownSelection: 0,
  dateListNoOfYears: DATE_LIST_OPTIONS_LENGTH.YEARS,
  dateListEarliest: null,
  dateListLatest: null,
  formattedCalendarIntervalDates: [],
  heroMetricOptions: [],

  // in order to use filterObjForApiCall in useEffect, some values within the filterObjForApiCall are arrays,
  // which means they cannot be compared in order to trigger an update based on changes
  // -> trackableFilterObjForApiCall stores, for those arrays, their length, therefore this new object
  // can be used for useEffect comparison reasons

  trackableFilterObjForApiCall: {}
};

const getClientIds = (state, clientGroupIds) =>
  clientGroupIds.map(
    (id) =>
      current(state)
        .currentDashboardFilters.find((filter) => filter.name === 'Project Name')
        .options.find((o) => o.id === id).clientId
  );

const appSlice = createSlice({
  name: 'dashboardFilters',
  initialState,
  reducers: {
    setDashboardFilters: (state, action) => {
      state[action.payload.type] = action.payload.data;
    },
    setFiltersAvailableOptions: (state, action) => {
      state[action.payload.optionType] = action.payload.data;
    },
    setActiveLabel: (state, action) => {
      state[action.payload.type].activeLabel = action.payload.data;
    },
    setIsDashboardSidebarFiltersOpen: (state, action) => {
      state.isDashboardSidebarFiltersOpen = action.payload;
    },
    getCurrentDashboardFiltersRequest: (state) => {
      state.filtersLoading = true;
    },
    getCurrentDashboardFiltersError: (state) => {
      state.filtersLoading = false;
    },
    getCurrentDashboardFiltersSuccess: (state, action) => {
      // setting loading false only after populating the default values in filter
      state.currentDashboardFilters = action.payload;
      state.dateListNoOfYears =
        (action.payload.find((filter) => filter.name === 'Date Range')?.options.years ||
          DATE_LIST_OPTIONS_LENGTH.YEARS) + 1;
      state.dateListEarliest = action.payload.find(
        (filter) => filter.name === 'Date Range'
      )?.options.earliest;
      state.dateListLatest = action.payload.find(
        (filter) => filter.name === 'Date Range'
      )?.options.latest;
    },
    // used for setting the selected value of each filter card
    setCheckedFilters: (state, action) => {
      const isCalendarData =
        (action.payload.data[0] &&
          action.payload.data[0]?.id &&
          action.payload.data[0].id &&
          action.payload.data[0]?.id?.includes('calendar')) ||
        false;
      state.filtersLoading = false;
      let newFiltersState = {
        data: isCalendarData ? current(state).formattedCalendarIntervalDates : action.payload.data,
        dataIds:
          // eslint-disable-next-line no-nested-ternary
          action.payload.filterName === 'Date Range'
            ? action.payload.data
            : typeof action.payload.data !== 'string' && typeof action.payload.data !== 'boolean'
            ? action.payload.data?.map(
                (data) =>
                  current(state)
                    .currentDashboardFilters?.find(
                      (filter) => filter.name === action.payload.filterName
                    )
                    ?.options?.find((option) => option.name === data)?.id || null
              )
            : action.payload.data,
        apiKey:
          (current(state).currentDashboardFilters &&
            current(state).currentDashboardFilters.find(
              (currentDFilter) => currentDFilter.name === action.payload.filterName
            )?.apiKey) ||
          null
      };
      if (action.payload.filterName === 'Project Name') {
        newFiltersState = {
          ...newFiltersState,
          clientIds: action.payload.data.map(
            (data) =>
              current(state)
                .currentDashboardFilters.find((filter) => filter.name === action.payload.filterName)
                ?.options.find((option) => option.name === data)?.clientId || null
          )
        };
      }
      if (
        action.payload.filterName === 'Date Range' &&
        action.payload.data.length &&
        (current(state).checkedFilters['Last 13 Months'].data ||
          current(state).checkedFilters['Last 13 Weeks'].data)
      ) {
        state.checkedFilters['Last 13 Weeks'] = {
          ...current(state).checkedFilters['Last 13 Weeks'],
          disabled: true,
          data: false,
          dataIds: false
        };
        state.checkedFilters['Last 13 Months'] = {
          ...current(state).checkedFilters['Last 13 Months'],
          disabled: true,
          data: false,
          dataIds: false
        };
        state.checkedFilters['Date Range'] = {
          ...current(state).checkedFilters['Date Range'],
          disabled: false,
          data: isCalendarData
            ? current(state).formattedCalendarIntervalDates
            : action.payload.data,
          dataIds: isCalendarData
            ? current(state).formattedCalendarIntervalDates
            : action.payload.data
        };
      } else {
        state.checkedFilters[action.payload.filterName] = newFiltersState;
      }
    },
    // used for enabling/disabling each filter card
    setCheckedFiltersToggle: (state, action) => {
      let newState = {
        ...current(state).checkedFilters[action.payload.filterName],
        disabled: !action.payload.data
      };
      if (typeof current(state).checkedFilters[action.payload.filterName]?.data === 'boolean') {
        newState = { ...newState, data: action.payload.data, dataIds: action.payload.data };
      }
      // handler for preventing both Last 13 months and Last 13 weeks filters being active simultaneously
      if (
        action.payload.filterName === 'Last 13 Months' &&
        action.payload.data &&
        (current(state).checkedFilters['Last 13 Weeks'].data ||
          current(state).checkedFilters['Date Range'].data.length)
      ) {
        state.checkedFilters['Last 13 Weeks'] = {
          ...current(state).checkedFilters['Last 13 Weeks'],
          disabled: true,
          data: false,
          dataIds: false
        };
        state.checkedFilters['Last 13 Months'] = {
          ...current(state).checkedFilters['Last 13 Months'],
          disabled: false,
          data: true,
          dataIds: true
        };
        state.checkedFilters['Date Range'] = {
          ...current(state).checkedFilters['Date Range'],
          disabled: true
        };
      } else if (
        action.payload.filterName === 'Last 13 Weeks' &&
        action.payload.data &&
        (current(state).checkedFilters['Last 13 Months'].data ||
          current(state).checkedFilters['Date Range'].data.length)
      ) {
        state.checkedFilters['Last 13 Weeks'] = {
          ...current(state).checkedFilters['Last 13 Weeks'],
          disabled: false,
          data: true,
          dataIds: true
        };
        state.checkedFilters['Last 13 Months'] = {
          ...current(state).checkedFilters['Last 13 Months'],
          disabled: true,
          data: false,
          dataIds: false
        };
        state.checkedFilters['Date Range'] = {
          ...current(state).checkedFilters['Date Range'],
          disabled: true
        };
      } else if (
        action.payload.filterName === 'Date Range' &&
        action.payload.data &&
        (current(state).checkedFilters['Last 13 Months'].data ||
          current(state).checkedFilters['Last 13 Weeks'].data)
      ) {
        state.checkedFilters['Last 13 Weeks'] = {
          ...current(state).checkedFilters['Last 13 Weeks'],
          disabled: true,
          data: false,
          dataIds: false
        };
        state.checkedFilters['Last 13 Months'] = {
          ...current(state).checkedFilters['Last 13 Months'],
          disabled: true,
          data: false,
          dataIds: false
        };
        state.checkedFilters['Date Range'] = {
          ...current(state).checkedFilters['Date Range'],
          disabled: false,
          data: action.payload.data[0]
            ? action.payload.data
            : current(state).checkedFilters['Date Range'].data,
          dataIds: action.payload.data[0]
            ? action.payload.data
            : current(state).checkedFilters['Date Range'].dataIds
        };
      } else {
        state.checkedFilters[action.payload.filterName] = newState;
      }
    },
    getHeroMetricsRequest: (state) => {
      state.heroMetricsLoading = true;
    },
    getHeroMetricsError: (state) => {
      state.heroMetricsLoading = false;
    },
    getHeroMetricsSuccess: (state, action) => {
      state.currentHeroMetrics = action.payload;
      state.heroMetricsLoading = false;
      state.heroMetrics = {
        activeLabel: action.payload[0].label
      };
    },
    getHeroMetricOptionsRequest: (state) => {
      state.heroMetricsLoading = true;
    },
    getHeroMetricOptionsError: (state) => {
      state.heroMetricsLoading = false;
    },
    getHeroMetricOptionsSuccess: (state, action) => {
      state.heroMetricOptions = action.payload;
      state.heroMetricsLoading = false;
    },
    setCalendarFromAndTo: (state, action) => {
      state.calendarFromAndTo = action.payload;
    },
    setCurrentDashboardFiltersRequest: (state) => {
      state.filtersLoading = true;
    },
    setCurrentDashboardFiltersError: (state) => {
      state.filtersLoading = false;
    },
    setCurrentDashboardFiltersSuccess: (state, action) => {
      // setting loading false only after populating the default values in filter
      state.currentDashboardFilters = action.payload;
    },
    composeFilterObj: (state) => {
      const filtersFromCheckedFilters = Object.entries(current(state).checkedFilters).reduce(
        (acc, item) => {
          if (
            item[1].apiKey === 'clientGroupReportingIds' ||
            item[1].apiKey === 'clientGroupIds' ||
            item[1].apiKey === 'clientIds'
          ) {
            acc[item[1].apiKey] = item[1].dataIds.length
              ? item[1].dataIds
              : current(state)
                  .currentDashboardFilters.find(
                    (dashboardFlter) => item[1].apiKey === dashboardFlter.apiKey
                  )
                  .options.map((option) => option.id);
            return acc;
          }
          if (item[1].disabled || Array.isArray(item[1].data && item[1].disabled)) {
            return acc;
          }
          if (item[1].apiKey) {
            acc[item[1].apiKey] = item[1].disabled ? false : item[1].dataIds;
          }
          if (item[0] === 'Date Range' && item[1].data.length) {
            const dateType = findDateType(item[1].data[0].name);

            if (dateType === UNIT_OF_TIME.DAYS) {
              const selectedDates = item[1].data
                .filter((date) => date.checked)
                .map((date) => date.name);
              const excludedDates = item[1].data
                .filter((date) => !date.checked)
                .map((date) => date.name);
              const originalFormat = findOriginalTimeFormat(selectedDates[0]);

              const formattedSelectedDates = selectedDates.map((date) =>
                convertToDefaultTimeFormat(date, originalFormat)
              );

              const formattedExcludedDates = excludedDates.map((date) =>
                convertToDefaultTimeFormat(date, originalFormat)
              );
              const earliest = findEarliestDateFromArray(formattedSelectedDates, originalFormat);
              const latest = findLatestDateFromArray(formattedSelectedDates, originalFormat);
              const exclude = findExcludedDatesBetweenEarliestAndLatest(
                convertToDefaultTimeFormat(earliest, 'dd/MM/yyyy'),
                convertToDefaultTimeFormat(latest, 'dd/MM/yyyy'),
                formattedExcludedDates
              );

              acc.dateRange = {
                from: originalFormat === 'yyyy' ? getBegginingOfYear(earliest) : earliest,
                to: originalFormat === 'yyyy' ? getEndOfYear(latest) : latest,
                exclude: exclude.length ? exclude.map((date) => fDate(date, 'dd/MM/yyyy')) : []
              };
              return acc;
            }

            acc.dateRange = {
              [dateType]: item[1].data
                .filter((date) => date.checked)
                .map((date) => formatWithDash(date.name))
            };
            return acc;
          }
          return acc;
        },
        {}
      );
      const completeFilterObj = {
        activeLabel: current(state).time.activeLabel,
        // eslint-disable-next-line no-nested-ternary
        clientIds: current(state).checkedFilters['Project Name'].dataIds?.length
          ? getClientIds(state, current(state).checkedFilters['Project Name'].dataIds)
          : current(state).checkedFilters['Project Name']?.clientIds.length
          ? current(state).checkedFilters['Project Name']?.clientIds
          : current(state)
              .currentDashboardFilters.find((d) => d.name === 'Project Name')
              ?.options.map((o) => o.clientId) || [],
        ...filtersFromCheckedFilters
      };
      const convertedTrackableFilterObj = Object.entries(completeFilterObj).reduce((acc, item) => {
        if (Array.isArray(item[1])) {
          acc[item[0]] = item[1].length;
        } else {
          // eslint-disable-next-line prefer-destructuring
          acc[item[0]] = item[1];
        }
        return acc;
      }, {});
      state.trackableFilterObjForApiCall = convertedTrackableFilterObj;
      state.filterObjForApiCall = completeFilterObj;
    },
    setDefaultCheckedFilters: (state, action) => {
      // setting loading false only after populating the default values in filter

      const companyNameOptions = action.payload.find((o) => o.name === 'Company Name')?.options;
      const projectNameOptions = action.payload.find((o) => o.name === 'Project Name')?.options;

      const defaultOptions = {
        Toggle: {
          apiKey: 'netGross',
          data: 'net',
          dataIds: 'net'
        },
        'Last 13 Months': {
          apiKey: 'last13Months',
          data: false,
          dataIds: false,
          disabled: true
        },
        'Last 13 Weeks': {
          apiKey: 'last13Weeks',
          data: true,
          dataIds: true
        },
        'Date Range': {
          apiKey: 'dateRange',
          data: [
            {
              id: '2022',
              name: '2022',
              checked: true
            },
            {
              id: '2021',
              name: '2021',
              checked: true
            },
            {
              id: '2020',
              name: '2020',
              checked: true
            }
          ],
          dataIds: true,
          disabled: true
        },
        'Company Name': {
          apiKey: 'clientGroupReportingIds',
          data: companyNameOptions.map((option) => option.name),
          dataIds: companyNameOptions.map((option) => option.id)
        },
        'Project Name': {
          apiKey: 'clientGroupIds',
          clientIds: projectNameOptions.map((option) => option.clientId),
          data: projectNameOptions.map((option) => option.name),
          dataIds: projectNameOptions.map((option) => option.id)
        }
      };
      state.checkedFilters = defaultOptions;
      state.filtersLoading = false;
    },
    setHeroMetricsFilter: (state, action) => {
      state.extraFilters.heroMetrics = action.payload;
    },
    setHeroMetricsDropdownSelection: (state, action) => {
      state.heroMetricsDropdownSelection = action.payload;
    },
    setFormattedCalendarIntervalDates: (state, action) => {
      state.formattedCalendarIntervalDates = action.payload;
    },
    setFiltersLoading: (state, action) => {
      state.filtersLoading = action.payload;
    },
    clearFilters: (state) => {
      state.company = [];
      state.project = [];
      state.filteredCompanies = [];
      state.filteredProjects = [];
      state.time = {
        activeLabel: TIME_FRAMES.DAILY
      };
      state.currentHeroMetrics = [];
      state.heroMetricsLoading = false;
      state.heroMetrics = {
        activeLabel: ''
      };
      state.isDashboardSidebarFiltersOpen = true;
      state.currentDashboardFilters = [];
      state.checkedFilters = {};
      state.filtersLoading = false;
      state.calendarFromAndTo = [null, null];
      state.filterObjForApiCall = {};
      state.extraFilters = {
        heroMetrics: {
          type: '',
          metric: 'primary'
        }
      };
      state.heroMetricsDropdownSelection = 0;
      state.dateListNoOfYears = DATE_LIST_OPTIONS_LENGTH.YEARS;
      state.dateListEarliest = null;
      state.dateListLatest = null;
      state.formattedCalendarIntervalDates = [];
      state.heroMetricOptions = [];
      state.trackableFilterObjForApiCall = {};
    }
  }
});

export const {
  setDashboardFilters,
  setActiveLabel,
  setFiltersAvailableOptions,
  setIsDashboardSidebarFiltersOpen,
  getCurrentDashboardFiltersRequest,
  getCurrentDashboardFiltersError,
  getCurrentDashboardFiltersSuccess,
  setCheckedFilters,
  setCheckedFiltersToggle,
  setCurrentDashboardFiltersRequest,
  setCurrentDashboardFiltersError,
  setCurrentDashboardFiltersSuccess,
  composeFilterObj,
  setDefaultCheckedFilters,
  getHeroMetricsRequest,
  getHeroMetricsSuccess,
  getHeroMetricsError,
  setHeroMetricsFilter,
  setHeroMetricsDropdownSelection,
  setCalendarFromAndTo,
  setFormattedCalendarIntervalDates,
  setFiltersLoading,
  getHeroMetricOptionsSuccess,
  getHeroMetricOptionsError,
  getHeroMetricOptionsRequest,
  clearFilters
} = appSlice.actions;

export const selectDashboardFilters = (state) => state.dashboardFilters;
export const selectActiveLabel = (filterType) => (state) =>
  state.dashboardFilters[filterType].activeLabel;
export const selectIsDashboardSidebarFiltersOpen = (state) =>
  state.dashboardFilters.isDashboardSidebarFiltersOpen;
export const selectCurrentDashboardFilters = (state) =>
  state.dashboardFilters.currentDashboardFilters;
export const selectCheckedFilters = (state) => state.dashboardFilters.checkedFilters;
export const selectFiltersLoading = (state) => state.dashboardFilters.filtersLoading;
export const selectCurrentHeroMetrics = (state) => state.dashboardFilters.currentHeroMetrics;
export const selectHeroMetricsLoading = (state) => state.dashboardFilters.heroMetricsLoading;
export const selectCalendarFromAndTo = (state) => state.dashboardFilters.calendarFromAndTo;
export const selectCompaniesFromFilter = (state) =>
  state.dashboardFilters.currentDashboardFilters.find((filter) => filter.name === 'Company Name')
    ?.options || [];
export const selectProjectsFromFilter = (state) =>
  state.dashboardFilters.currentDashboardFilters.find((filter) => filter.name === 'Project Name')
    ?.options || [];
export const selectFilterObjForApiCall = (state) => state.dashboardFilters.filterObjForApiCall;
export const selectHeroMetricsFilter = (state) => state.dashboardFilters.heroMetrics;
export const selectHeroMetricsDropdownSelection = (state) =>
  state.dashboardFilters.heroMetricsDropdownSelection;
export const selectHeroMetricFilter = (state) => state.dashboardFilters.extraFilters.heroMetrics;
export const selectDateListNoOfYears = (state) => state.dashboardFilters.dateListNoOfYears;
export const selectDateListEarliest = (state) => state.dashboardFilters.dateListEarliest;
export const selectDateListLatest = (state) => state.dashboardFilters.dateListLatest;
export const selectHeroMetricOptions = (state) => state.dashboardFilters.heroMetricOptions;
export const selectTrackableFilterObjForApiCall = (state) =>
  state.dashboardFilters.trackableFilterObjForApiCall;

export default appSlice.reducer;
