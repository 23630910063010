import { defineAbility } from '@casl/ability';
import { USER_ROLES } from 'uiConstants';

export default (user) =>
  defineAbility((can) => {
    if (user.isLoggedIn && user.role) {
      const { SUPER_ADMIN, ADMIN, CLIENT_ADMIN, CLIENT } = USER_ROLES;

      switch (user.role) {
        case SUPER_ADMIN:
          can('view', 'all');
          can('manage', 'all');
          break;
        case ADMIN:
          can('view', 'all');
          can('manage', 'all');
          break;
        case CLIENT_ADMIN:
          can('view', 'all');
          can('manage', 'all');
          break;
        case CLIENT:
          can('view', 'all');
          can('manage', 'all');
          break;
        default:
          can('view', 'all');
      }
    }
  });
