import {
  getCompaniesRequest,
  getCompaniesError,
  getCompaniesSuccess
} from 'redux/slices/companies';
import { setNotification } from 'redux/slices/app';
import { companyProjectsData } from 'mockData';

export const getCompanies = () => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getCompaniesRequest());
  try {
    // const response = await apiService.getCompanies();
    dispatch(getCompaniesSuccess(companyProjectsData));
  } catch (error) {
    dispatch(getCompaniesError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};
