import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Tooltip
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectUser } from 'redux/slices/auth';
import { USER_ROLES } from 'uiConstants';
import { clearFilters } from 'redux/slices/filters';
import { clearCompanies } from 'redux/slices/companies';
import { clearDashboards } from 'redux/slices/dashboards';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

const linkStyle = {
  textDecoration: 'none',
  color: 'inherit'
};

const adminCreateRoute = 'https://act.rocketer.com:5501/admin/create';
const adminUpdateRoute = 'https://act.rocketer.com:5501/admin/update';

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={user.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {`${user.name} ${user.familyName}`}
          </Typography>
          <Tooltip title={user.email || ''} placement="top">
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user.email}
            </Typography>
          </Tooltip>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* {MENU_OPTIONS.map((option) => ( */}
        {/*  <MenuItem */}
        {/*    key={option.label} */}
        {/*    to={option.linkTo} */}
        {/*    component={RouterLink} */}
        {/*    onClick={handleClose} */}
        {/*    sx={{ typography: 'body2', py: 1, px: 2.5 }} */}
        {/*  > */}
        {/*    <Box */}
        {/*      component={Icon} */}
        {/*      icon={option.icon} */}
        {/*      sx={{ */}
        {/*        mr: 2, */}
        {/*        width: 24, */}
        {/*        height: 24 */}
        {/*      }} */}
        {/*    /> */}

        {/*    {option.label} */}
        {/*  </MenuItem> */}
        {/* ))} */}
        {user.role === USER_ROLES.SUPER_ADMIN && (
          <>
            <Box sx={{ p: 2, pt: 1.5 }}>
              <Button fullWidth color="inherit" variant="outlined">
                <a href={adminCreateRoute} target="_blank" rel="noreferrer" style={linkStyle}>
                  Create User
                </a>
              </Button>
            </Box>

            <Box sx={{ p: 2, pt: 1.5 }}>
              <Button fullWidth color="inherit" variant="outlined">
                <a href={adminUpdateRoute} target="_blank" rel="noreferrer" style={linkStyle}>
                  Update User
                </a>
              </Button>
            </Box>
          </>
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => {
              dispatch(logout(() => navigate('/login', { replace: true })));
              dispatch(clearFilters());
              dispatch(clearCompanies());
              dispatch(clearDashboards());
            }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
