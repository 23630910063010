import http from 'services/http/http';
import FileDownload from 'js-file-download';
import { stringify } from 'qs';

const ApiService = {
  csvDownload: (url, body, fileName) =>
    http.post(url, body, { responseType: 'blob' }).then((res) => FileDownload(res, fileName)),
  login: (email, pwd) => http.post(`/users/login`, { email, pwd }),
  changePassword: (code, email, pwd, confirmPwd) =>
    http.post(`/users/reset-password`, { code, email, pwd, confirmPwd }),
  recoverPassword: ({ email }) => http.post(`/users/forgot-password`, { email }),
  getUser: () => http.get('/users/get-user'),
  forgotPassword: (email) => http.get('/users/forgot-password', { params: { email } }),
  getPerformanceOverviewData: (filters) => http.post('/graphs/performance-overview', { filters }),
  getDashboards: () => http.get('dashboards/get-dashboards'),
  getCurrentDashboardFilters: (id) => http.get(`/dashboards/get-filters/${id}`),
  getTimeSeriesData: (filters) => http.post('/graphs/time-series-data', { filters }),
  getRegionData: (filters) => http.post('/graphs/region', { filters }),
  getProjectsData: (filters) => http.post('/graphs/projects', { filters }),
  getVolumeVsEfficiencyData: (filters) => http.post('/graphs/volume-vs-efficiency', { filters }),
  getTimeOfDayData: (filters) => http.post('/graphs/time-of-day', { filters }),
  getProductDataData: (filters) => http.post('/graphs/product-data', { filters }),
  getCopyImageData: (filters) => http.post('/graphs/copy-and-image', { filters }),
  getCityData: (filters) => http.post('/graphs/city-data', { filters }),
  getTopCitiesData: (filters) => http.post('/graphs/top-cities', { filters }),
  getHeroMetricsFilters: (metric, clientGroupReportingIds) => {
    if (clientGroupReportingIds)
      return http.get(`/dashboards/get-hero-metrics-filters/${metric || 1}`, {
        params: { clientGroupReportingIds: [...clientGroupReportingIds] },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' })
      });
    return http.get(`/dashboards/get-hero-metrics-filters/${metric || 1}`);
  },
  getDemographicsData: (filters) => http.post('/graphs/demographics', { filters }),
  getHeroMetricsOptions: (clientGroupReportingIds) => {
    if (clientGroupReportingIds)
      return http.get(`/dashboards/get-hero-metrics-options`, {
        params: { clientGroupReportingIds: [...clientGroupReportingIds] },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' })
      });
    return http.get(`/dashboards/get-hero-metrics-options`);
  }
};

export default ApiService;
