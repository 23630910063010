import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup, Divider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import { selectDashboardFilters, setActiveLabel, setHeroMetricsFilter } from 'redux/slices/filters';
import { useTranslation } from 'react-i18next';

DashboardOptionsPicker.propTypes = {
  filterType: PropTypes.string,
  options: PropTypes.array,
  heroMetricsList: PropTypes.array
};
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderLeft: '0px'
  },
  '& .MuiToggleButton-root.Mui-selected': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    fontWeight: 700,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    }
  },
  '& .MuiToggleButton-root': {
    border: '0px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    }
  }
}));

export default function DashboardOptionsPicker({ filterType, options, heroMetricsList }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pageFilter = useSelector(selectDashboardFilters);

  const handleChange = (event, newOption) => {
    if (filterType === 'heroMetrics') {
      const filterObj = heroMetricsList.find((elem) => elem.label === newOption);
      dispatch(
        setHeroMetricsFilter({
          type: filterObj?.type,
          label: filterObj?.label,
          metric: filterObj?.metric
        })
      );
    }
    if (newOption) {
      dispatch(setActiveLabel({ type: filterType, data: newOption }));
    }
  };

  const selectors = options.map((elem, index) => {
    const divider = index !== options.length - 1 && (
      <Divider
        key={`divider${elem}`}
        variant="middle"
        orientation="vertical"
        sx={{
          bgcolor: (theme) => theme.palette.divider,
          margin: '20px -5px'
        }}
        flexItem
      />
    );

    const buttonWithDivider = [
      <ToggleButton key={index} value={elem}>
        {t(elem)}
      </ToggleButton>,
      divider
    ];
    return buttonWithDivider;
  });

  return (
    <StyledToggleButtonGroup
      color="primary"
      size="large"
      value={pageFilter[filterType].activeLabel}
      exclusive
      onChange={handleChange}
    >
      {selectors}
    </StyledToggleButtonGroup>
  );
}
