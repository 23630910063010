import Icon from 'components/Icon';
// ----------------------------------------------------------------------

const configSidebar = (dashboards, t) => {
  if (dashboards.length) {
    return dashboards.map((dashboard) => {
      const dashboardSidebarItem = {
        id: dashboard.id,
        title: t(dashboard.name),
        path: `/dashboard/${dashboard.id}`,
        icon: <Icon type="dashboardSidebarItem" />
      };
      return dashboardSidebarItem;
    });
  }
  return [];
};

export default configSidebar;
