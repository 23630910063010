import { useSelector, useDispatch } from 'react-redux';
import {
  selectCurrentHeroMetrics,
  selectFilterObjForApiCall,
  selectHeroMetricFilter,
  selectHeroMetricOptions,
  selectHeroMetricsDropdownSelection,
  setActiveLabel,
  setHeroMetricsDropdownSelection,
  setHeroMetricsFilter,
  selectTrackableFilterObjForApiCall,
  selectCheckedFilters
} from 'redux/slices/filters';
import { useEffect } from 'react';
import DashboardOptionsPicker from 'components/DashboardOptionsPicker';
import { Grid } from '@mui/material';
import Select from 'components/dropdown/Select';
import { getHeroMetricOptions } from 'redux/thunk/filters';

export default function HeroMetricsSelector({ initialize, setInitialize }) {
  const dispatch = useDispatch();
  const activeHeroMetricFilter = useSelector(selectHeroMetricFilter);
  const heroMetricsDropdownOptions = useSelector(selectHeroMetricOptions);
  const filterObjForApiCall = useSelector(selectFilterObjForApiCall);
  const dropdownSelection = useSelector(selectHeroMetricsDropdownSelection);
  const checkedFilters = useSelector(selectCheckedFilters);

  useEffect(() => {
    if (heroMetricsDropdownOptions.length && activeHeroMetricFilter) {
      const { metrics } = heroMetricsDropdownOptions[dropdownSelection];
      const { label } =
        metrics.find(
          (m) =>
            m.label === activeHeroMetricFilter.label && activeHeroMetricFilter.metric === m.metric
        ) || metrics[0];
      dispatch(setActiveLabel({ type: 'heroMetrics', data: label }));
    }
  }, [heroMetricsDropdownOptions, dropdownSelection]);

  const handleChange = (event) => {
    dispatch(setHeroMetricsDropdownSelection(event.target.value));

    // set also the first option of the selection
    dispatch(
      setHeroMetricsFilter({
        type: heroMetricsDropdownOptions[event.target.value].metrics[0].type,
        label: heroMetricsDropdownOptions[event.target.value].metrics[0].label,
        metric: heroMetricsDropdownOptions[event.target.value].metrics[0].metric
      })
    );
  };

  return heroMetricsDropdownOptions.length ? (
    <Grid container alignItems="center" mb={2}>
      <Grid item xs={4}>
        <Select
          options={heroMetricsDropdownOptions}
          onChange={handleChange}
          value={dropdownSelection}
        />
      </Grid>
      <Grid item xs={8}>
        <DashboardOptionsPicker
          filterType="heroMetrics"
          options={heroMetricsDropdownOptions[dropdownSelection].metrics.map((elem) => elem.label)}
          heroMetricsList={heroMetricsDropdownOptions[dropdownSelection].metrics}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}
