import { TIME_FRAMES, HERO_METRICS_LABELS } from 'uiConstants';

export const dashboardsData = [
  {
    id: '1231432442312',
    name: 'Performance Overview'
  },
  {
    id: '1231231r334432221',
    name: 'Time Series Data'
  },
  {
    id: '1231234344412312',
    name: 'Projects'
  },
  {
    id: '123w3312313212',
    name: 'Volume vs Efficiency'
  },
  {
    id: '123w33123132s2',
    name: 'Product Data'
  },
  {
    id: '1231432442312c3',
    name: 'City Data'
  }
];

export const performanceOverviewData = (timeframe) => {
  if (timeframe === TIME_FRAMES.YEARLY) {
    return [
      {
        total: 12312,
        title: 'Spend',
        data: [
          {
            name: '2021',
            value: 411.123
          }
        ]
      },
      {
        total: 432,
        title: 'Cost Per Click',
        data: [
          {
            name: '2021',
            value: 23444
          }
        ]
      },
      {
        total: 45333,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: '2021',
            value: 32.43
          }
        ]
      },
      {
        total: 424.2343,
        title: 'Unq CPL 1D Click',
        data: [
          {
            name: '2021',
            value: 444
          }
        ]
      },
      {
        total: 6575,
        title: 'Unique Quote CR% 1D Click',
        data: [
          {
            name: '2021',
            value: 65.23
          }
        ]
      },
      {
        total: 2344,
        title: 'Apps 1D Click',
        data: [
          {
            name: '2021',
            value: 665
          }
        ]
      },
      {
        total: 42342.43242,
        title: 'CPA 1D Click',
        data: [
          {
            name: '2021',
            value: 234
          }
        ]
      },
      {
        total: 12312,
        title: 'App CR% 1D Click',
        data: [
          {
            name: '2021',
            value: 4.4
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 234
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 4123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 34
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 432
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 1333
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 11.12
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 32
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 123
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.MONTHLY) {
    return [
      {
        total: 12312,
        title: 'Spend',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 432,
        title: 'Cost Per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 45333,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 424.2343,
        title: 'Unq CPL 1D Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 6575,
        title: 'Unique Quote CR% 1D Click',
        data: [
          {
            name: 'January',
            value: 41.123
          },
          {
            name: 'February',
            value: 51.123
          },
          {
            name: 'March',
            value: 61.123
          }
        ]
      },
      {
        total: 2344,
        title: 'Apps 1D Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 42342.43242,
        title: 'CPA 1D Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 12312,
        title: 'App CR% 1D Click',
        data: [
          {
            name: 'January',
            value: 31.12
          },
          {
            name: 'February',
            value: 51.12
          },
          {
            name: 'March',
            value: 21.12
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: 'January',
            value: 411.123
          },
          {
            name: 'February',
            value: 511.123
          },
          {
            name: 'March',
            value: 611.123
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.QUARTERLY) {
    return [
      {
        total: 12312,
        title: 'Spend',
        data: [
          {
            name: '2021 Q3',
            value: 1233.123
          },
          {
            name: '2021 Q4',
            value: 1233.123
          }
        ]
      },
      {
        total: 432,
        title: 'Cost Per Click',
        data: [
          {
            name: '2021 Q3',
            value: 4324.123
          },
          {
            name: '2021 Q4',
            value: 111.123
          }
        ]
      },
      {
        total: 45333,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: '2021 Q3',
            value: 543.123
          },
          {
            name: '2021 Q4',
            value: 1233.123
          }
        ]
      },
      {
        total: 424.2343,
        title: 'Unq CPL 1D Click',
        data: [
          {
            name: '2021 Q3',
            value: 442389
          },
          {
            name: '2021 Q4',
            value: 1233234
          }
        ]
      },
      {
        total: 6575,
        title: 'Unique Quote CR% 1D Click',
        data: [
          {
            name: '2021 Q3',
            value: 111
          },
          {
            name: '2021 Q4',
            value: 32
          }
        ]
      },
      {
        total: 2344,
        title: 'Apps 1D Click',
        data: [
          {
            name: '2021 Q3',
            value: 112311
          },
          {
            name: '2021 Q4',
            value: 33334
          }
        ]
      },
      {
        total: 42342.43242,
        title: 'CPA 1D Click',
        data: [
          {
            name: '2021 Q3',
            value: 3123
          },
          {
            name: '2021 Q4',
            value: 1111.1
          }
        ]
      },
      {
        total: 12312,
        title: 'App CR% 1D Click',
        data: [
          {
            name: '2021 Q3',
            value: 11.11
          },
          {
            name: '2021 Q4',
            value: 3.21
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021 Q3',
            value: 444
          },
          {
            name: '2021 Q4',
            value: 331
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021 Q3',
            value: 322
          },
          {
            name: '2021 Q4',
            value: 33132
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021 Q3',
            value: 111
          },
          {
            name: '2021 Q4',
            value: 321
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021',
            value: 432
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021 Q3',
            value: 333
          },
          {
            name: '2021 Q4',
            value: 111
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021 Q3',
            value: 321322
          },
          {
            name: '2021 Q4',
            value: 3333
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021 Q3',
            value: 322
          },
          {
            name: '2021 Q4',
            value: 33543.132
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021 Q3',
            value: 322
          },
          {
            name: '2021 Q4',
            value: 33132
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.WEEKLY) {
    return [
      {
        title: 'Spend',
        total: 7811454.684808718,
        round: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            tooltipValue: '28/02/2022',
            value: 106912.99031786062,
            label: 106912.99031786062
          },
          {
            id: 1,
            name: '',
            tooltipValue: '01/03/2022',
            value: 103083.17650614679,
            label: ''
          },
          {
            id: 2,
            name: '02/03/2022',
            tooltipValue: '02/03/2022',
            value: 97229.0868687667,
            label: ''
          },
          {
            id: 3,
            name: '',
            tooltipValue: '03/03/2022',
            value: 93391.38450358808,
            label: ''
          },
          {
            id: 4,
            name: '04/03/2022',
            tooltipValue: '04/03/2022',
            value: 89964.26796924695,
            label: 89964.26796924695
          },
          {
            id: 5,
            name: '',
            tooltipValue: '05/03/2022',
            value: 90530.76024404354,
            label: ''
          },
          {
            id: 6,
            name: '06/03/2022',
            tooltipValue: '06/03/2022',
            value: 85762.27307364903,
            label: ''
          },
          {
            id: 7,
            name: '',
            tooltipValue: '07/03/2022',
            value: 97566.78431035951,
            label: ''
          },
          {
            id: 8,
            name: '08/03/2022',
            tooltipValue: '08/03/2022',
            value: 103728.7238691058,
            label: 103728.7238691058
          },
          {
            id: 9,
            name: '',
            tooltipValue: '09/03/2022',
            value: 105717.91718311794,
            label: ''
          },
          {
            id: 10,
            name: '10/03/2022',
            tooltipValue: '10/03/2022',
            value: 100677.58312167972,
            label: ''
          },
          {
            id: 11,
            name: '',
            tooltipValue: '11/03/2022',
            value: 86867.10550497845,
            label: ''
          },
          {
            id: 12,
            name: '12/03/2022',
            tooltipValue: '12/03/2022',
            value: 87165.18263792992,
            label: 87165.18263792992
          },
          {
            id: 13,
            name: '',
            tooltipValue: '13/03/2022',
            value: 80309.4985588044,
            label: ''
          },
          {
            id: 14,
            name: '14/03/2022',
            tooltipValue: '14/03/2022',
            value: 76813.87455246225,
            label: ''
          },
          {
            id: 15,
            name: '',
            tooltipValue: '15/03/2022',
            value: 88045.45923215896,
            label: ''
          },
          {
            id: 16,
            name: '16/03/2022',
            tooltipValue: '16/03/2022',
            value: 88619.60117107444,
            label: 88619.60117107444
          },
          {
            id: 17,
            name: '',
            tooltipValue: '17/03/2022',
            value: 85881.4786144197,
            label: ''
          },
          {
            id: 18,
            name: '18/03/2022',
            tooltipValue: '18/03/2022',
            value: 80068.32514959574,
            label: ''
          },
          {
            id: 19,
            name: '',
            tooltipValue: '19/03/2022',
            value: 70418.68209084496,
            label: ''
          },
          {
            id: 20,
            name: '20/03/2022',
            tooltipValue: '20/03/2022',
            value: 61755.53280308843,
            label: 61755.53280308843
          },
          {
            id: 21,
            name: '',
            tooltipValue: '21/03/2022',
            value: 65800.71323402226,
            label: ''
          },
          {
            id: 22,
            name: '22/03/2022',
            tooltipValue: '22/03/2022',
            value: 71872.19728127867,
            label: ''
          },
          {
            id: 23,
            name: '',
            tooltipValue: '23/03/2022',
            value: 76097.08948096633,
            label: ''
          },
          {
            id: 24,
            name: '24/03/2022',
            tooltipValue: '24/03/2022',
            value: 76921.91871904396,
            label: 76921.91871904396
          },
          {
            id: 25,
            name: '',
            tooltipValue: '25/03/2022',
            value: 77437.45687120035,
            label: ''
          },
          {
            id: 26,
            name: '26/03/2022',
            tooltipValue: '26/03/2022',
            value: 77900.23781904951,
            label: ''
          },
          {
            id: 27,
            name: '',
            tooltipValue: '27/03/2022',
            value: 78299.14500867948,
            label: ''
          },
          {
            id: 28,
            name: '28/03/2022',
            tooltipValue: '28/03/2022',
            value: 76620.0958955586,
            label: 76620.0958955586
          },
          {
            id: 29,
            name: '',
            tooltipValue: '29/03/2022',
            value: 80993.49795014784,
            label: ''
          },
          {
            id: 30,
            name: '30/03/2022',
            tooltipValue: '30/03/2022',
            value: 93620.91792287305,
            label: ''
          },
          {
            id: 31,
            name: '',
            tooltipValue: '31/03/2022',
            value: 70868.92821926996,
            label: ''
          },
          {
            id: 32,
            name: '01/04/2022',
            tooltipValue: '01/04/2022',
            value: 80956.9670966547,
            label: 80956.9670966547
          },
          {
            id: 33,
            name: '',
            tooltipValue: '02/04/2022',
            value: 72260.79680402204,
            label: ''
          },
          {
            id: 34,
            name: '03/04/2022',
            tooltipValue: '03/04/2022',
            value: 90108.0174298361,
            label: ''
          },
          {
            id: 35,
            name: '',
            tooltipValue: '04/04/2022',
            value: 90530.46542922407,
            label: ''
          },
          {
            id: 36,
            name: '05/04/2022',
            tooltipValue: '05/04/2022',
            value: 84414.27782770991,
            label: 84414.27782770991
          },
          {
            id: 37,
            name: '',
            tooltipValue: '06/04/2022',
            value: 80033.57837764919,
            label: ''
          },
          {
            id: 38,
            name: '07/04/2022',
            tooltipValue: '07/04/2022',
            value: 85637.02563622594,
            label: ''
          },
          {
            id: 39,
            name: '',
            tooltipValue: '08/04/2022',
            value: 92292.42394237407,
            label: ''
          },
          {
            id: 40,
            name: '09/04/2022',
            tooltipValue: '09/04/2022',
            value: 92325.7266280409,
            label: 92325.7266280409
          },
          {
            id: 41,
            name: '',
            tooltipValue: '10/04/2022',
            value: 92560.66450650245,
            label: ''
          },
          {
            id: 42,
            name: '11/04/2022',
            tooltipValue: '11/04/2022',
            value: 86740.98304644227,
            label: ''
          },
          {
            id: 43,
            name: '',
            tooltipValue: '12/04/2022',
            value: 87582.15178948641,
            label: ''
          },
          {
            id: 44,
            name: '13/04/2022',
            tooltipValue: '13/04/2022',
            value: 90728.36529386789,
            label: 90728.36529386789
          },
          {
            id: 45,
            name: '',
            tooltipValue: '14/04/2022',
            value: 91884.18500316143,
            label: ''
          },
          {
            id: 46,
            name: '15/04/2022',
            tooltipValue: '15/04/2022',
            value: 81930.2101945579,
            label: ''
          },
          {
            id: 47,
            name: '',
            tooltipValue: '16/04/2022',
            value: 81150.85890394449,
            label: ''
          },
          {
            id: 48,
            name: '17/04/2022',
            tooltipValue: '17/04/2022',
            value: 75725.30574515462,
            label: 75725.30574515462
          },
          {
            id: 49,
            name: '',
            tooltipValue: '18/04/2022',
            value: 83970.76773315668,
            label: ''
          },
          {
            id: 50,
            name: '19/04/2022',
            tooltipValue: '19/04/2022',
            value: 92887.05587691069,
            label: ''
          },
          {
            id: 51,
            name: '',
            tooltipValue: '20/04/2022',
            value: 94146.89864575863,
            label: ''
          },
          {
            id: 52,
            name: '21/04/2022',
            tooltipValue: '21/04/2022',
            value: 94173.26310301386,
            label: 94173.26310301386
          },
          {
            id: 53,
            name: '',
            tooltipValue: '22/04/2022',
            value: 96070.8956682533,
            label: ''
          },
          {
            id: 54,
            name: '23/04/2022',
            tooltipValue: '23/04/2022',
            value: 107128.6228756737,
            label: ''
          },
          {
            id: 55,
            name: '',
            tooltipValue: '24/04/2022',
            value: 110517.7187099047,
            label: ''
          },
          {
            id: 56,
            name: '25/04/2022',
            tooltipValue: '25/04/2022',
            value: 112487.02900285274,
            label: 112487.02900285274
          },
          {
            id: 57,
            name: '',
            tooltipValue: '26/04/2022',
            value: 118663.44379099458,
            label: ''
          },
          {
            id: 58,
            name: '27/04/2022',
            tooltipValue: '27/04/2022',
            value: 116231.4529784806,
            label: ''
          },
          {
            id: 59,
            name: '',
            tooltipValue: '28/04/2022',
            value: 111532.90972863138,
            label: ''
          },
          {
            id: 60,
            name: '29/04/2022',
            tooltipValue: '29/04/2022',
            value: 105034.7418769002,
            label: 105034.7418769002
          },
          {
            id: 61,
            name: '',
            tooltipValue: '30/04/2022',
            value: 98906.27956822887,
            label: ''
          },
          {
            id: 62,
            name: '01/05/2022',
            tooltipValue: '01/05/2022',
            value: 98800.65311899222,
            label: ''
          },
          {
            id: 63,
            name: '',
            tooltipValue: '02/05/2022',
            value: 105371.68094205856,
            label: ''
          },
          {
            id: 64,
            name: '03/05/2022',
            tooltipValue: '03/05/2022',
            value: 111025.96462795138,
            label: 111025.96462795138
          },
          {
            id: 65,
            name: '',
            tooltipValue: '04/05/2022',
            value: 118995.25940082408,
            label: ''
          },
          {
            id: 66,
            name: '05/05/2022',
            tooltipValue: '05/05/2022',
            value: 116043.91960761696,
            label: ''
          },
          {
            id: 67,
            name: '',
            tooltipValue: '06/05/2022',
            value: 109559.32571898401,
            label: ''
          },
          {
            id: 68,
            name: '07/05/2022',
            tooltipValue: '07/05/2022',
            value: 109880.86361900903,
            label: 109880.86361900903
          },
          {
            id: 69,
            name: '',
            tooltipValue: '08/05/2022',
            value: 108310.09921140596,
            label: ''
          },
          {
            id: 70,
            name: '09/05/2022',
            tooltipValue: '09/05/2022',
            value: 108993.53885171376,
            label: ''
          },
          {
            id: 71,
            name: '',
            tooltipValue: '10/05/2022',
            value: 106015.34584183991,
            label: ''
          },
          {
            id: 72,
            name: '11/05/2022',
            tooltipValue: '11/05/2022',
            value: 119477.20895774662,
            label: 119477.20895774662
          },
          {
            id: 73,
            name: '',
            tooltipValue: '12/05/2022',
            value: 104888.87111184001,
            label: ''
          },
          {
            id: 74,
            name: '13/05/2022',
            tooltipValue: '13/05/2022',
            value: 93680.90083256364,
            label: ''
          },
          {
            id: 75,
            name: '',
            tooltipValue: '14/05/2022',
            value: 79042.01540143788,
            label: ''
          },
          {
            id: 76,
            name: '15/05/2022',
            tooltipValue: '15/05/2022',
            value: 88084.65963338315,
            label: 88084.65963338315
          },
          {
            id: 77,
            name: '',
            tooltipValue: '16/05/2022',
            value: 106634.81982709467,
            label: ''
          },
          {
            id: 78,
            name: '17/05/2022',
            tooltipValue: '17/05/2022',
            value: 105480.94972342253,
            label: ''
          },
          {
            id: 79,
            name: '',
            tooltipValue: '18/05/2022',
            value: 94124.72429814935,
            label: ''
          },
          {
            id: 80,
            name: '19/05/2022',
            tooltipValue: '19/05/2022',
            value: 86741.74934591353,
            label: 86741.74934591353
          },
          {
            id: 81,
            name: '',
            tooltipValue: '20/05/2022',
            value: 84120.44018043205,
            label: ''
          },
          {
            id: 82,
            name: '21/05/2022',
            tooltipValue: '21/05/2022',
            value: 73954.8849144131,
            label: ''
          },
          {
            id: 83,
            name: '',
            tooltipValue: '22/05/2022',
            value: 78941.81985810772,
            label: ''
          },
          {
            id: 84,
            name: '23/05/2022',
            tooltipValue: '23/05/2022',
            value: 75548.13491150737,
            label: 75548.13491150737
          },
          {
            id: 85,
            name: '',
            tooltipValue: '24/05/2022',
            value: 2181.884973684326,
            label: ''
          }
        ]
      },
      {
        title: 'CPC',
        total: 5.5511292311012275,
        round: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            tooltipValue: '28/02/2022',
            valueToShow: 6.0382350795131945,
            value: 6.0382350795131945,
            label: 6.0382350795131945
          },
          {
            id: 1,
            name: '',
            tooltipValue: '01/03/2022',
            valueToShow: '',
            value: 5.124437090184271,
            label: ''
          },
          {
            id: 2,
            name: '02/03/2022',
            tooltipValue: '02/03/2022',
            valueToShow: 5.171759939828016,
            value: 5.171759939828016,
            label: ''
          },
          {
            id: 3,
            name: '',
            tooltipValue: '03/03/2022',
            valueToShow: '',
            value: 4.948150074366223,
            label: ''
          },
          {
            id: 4,
            name: '04/03/2022',
            tooltipValue: '04/03/2022',
            valueToShow: 4.82770421085307,
            value: 4.82770421085307,
            label: 4.82770421085307
          },
          {
            id: 5,
            name: '',
            tooltipValue: '05/03/2022',
            valueToShow: '',
            value: 5.127188097867336,
            label: ''
          },
          {
            id: 6,
            name: '06/03/2022',
            tooltipValue: '06/03/2022',
            valueToShow: 4.56352221963758,
            value: 4.56352221963758,
            label: ''
          },
          {
            id: 7,
            name: '',
            tooltipValue: '07/03/2022',
            valueToShow: '',
            value: 4.617234598947495,
            label: ''
          },
          {
            id: 8,
            name: '08/03/2022',
            tooltipValue: '08/03/2022',
            valueToShow: 4.803812525777141,
            value: 4.803812525777141,
            label: 4.803812525777141
          },
          {
            id: 9,
            name: '',
            tooltipValue: '09/03/2022',
            valueToShow: '',
            value: 4.970282895304088,
            label: ''
          },
          {
            id: 10,
            name: '10/03/2022',
            tooltipValue: '10/03/2022',
            valueToShow: 4.8886852054811945,
            value: 4.8886852054811945,
            label: ''
          },
          {
            id: 11,
            name: '',
            tooltipValue: '11/03/2022',
            valueToShow: '',
            value: 4.570028698704674,
            label: ''
          },
          {
            id: 12,
            name: '12/03/2022',
            tooltipValue: '12/03/2022',
            valueToShow: 4.64484613865128,
            value: 4.64484613865128,
            label: 4.64484613865128
          },
          {
            id: 13,
            name: '',
            tooltipValue: '13/03/2022',
            valueToShow: '',
            value: 4.2411015292989225,
            label: ''
          },
          {
            id: 14,
            name: '14/03/2022',
            tooltipValue: '14/03/2022',
            valueToShow: 4.856105357975866,
            value: 4.856105357975866,
            label: ''
          },
          {
            id: 15,
            name: '',
            tooltipValue: '15/03/2022',
            valueToShow: '',
            value: 4.44718957632887,
            label: ''
          },
          {
            id: 16,
            name: '16/03/2022',
            tooltipValue: '16/03/2022',
            valueToShow: 4.466938916834238,
            value: 4.466938916834238,
            label: 4.466938916834238
          },
          {
            id: 17,
            name: '',
            tooltipValue: '17/03/2022',
            valueToShow: '',
            value: 5.365245118661816,
            label: ''
          },
          {
            id: 18,
            name: '18/03/2022',
            tooltipValue: '18/03/2022',
            valueToShow: 5.501843272836923,
            value: 5.501843272836923,
            label: ''
          },
          {
            id: 19,
            name: '',
            tooltipValue: '19/03/2022',
            valueToShow: '',
            value: 5.2181313146235615,
            label: ''
          },
          {
            id: 20,
            name: '20/03/2022',
            tooltipValue: '20/03/2022',
            valueToShow: 5.322376351209896,
            value: 5.322376351209896,
            label: 5.322376351209896
          },
          {
            id: 21,
            name: '',
            tooltipValue: '21/03/2022',
            valueToShow: '',
            value: 5.506335835483034,
            label: ''
          },
          {
            id: 22,
            name: '22/03/2022',
            tooltipValue: '22/03/2022',
            valueToShow: 4.891261554462956,
            value: 4.891261554462956,
            label: ''
          },
          {
            id: 23,
            name: '',
            tooltipValue: '23/03/2022',
            valueToShow: '',
            value: 5.187966285858081,
            label: ''
          },
          {
            id: 24,
            name: '24/03/2022',
            tooltipValue: '24/03/2022',
            valueToShow: 5.559548910020523,
            value: 5.559548910020523,
            label: 5.559548910020523
          },
          {
            id: 25,
            name: '',
            tooltipValue: '25/03/2022',
            valueToShow: '',
            value: 5.686404528653279,
            label: ''
          },
          {
            id: 26,
            name: '26/03/2022',
            tooltipValue: '26/03/2022',
            valueToShow: 5.659709228352914,
            value: 5.659709228352914,
            label: ''
          },
          {
            id: 27,
            name: '',
            tooltipValue: '27/03/2022',
            valueToShow: '',
            value: 5.868621271824275,
            label: ''
          },
          {
            id: 28,
            name: '28/03/2022',
            tooltipValue: '28/03/2022',
            valueToShow: 5.255150610120617,
            value: 5.255150610120617,
            label: 5.255150610120617
          },
          {
            id: 29,
            name: '',
            tooltipValue: '29/03/2022',
            valueToShow: '',
            value: 5.591928883605899,
            label: ''
          },
          {
            id: 30,
            name: '30/03/2022',
            tooltipValue: '30/03/2022',
            valueToShow: 6.29807722320034,
            value: 6.29807722320034,
            label: ''
          },
          {
            id: 31,
            name: '',
            tooltipValue: '31/03/2022',
            valueToShow: '',
            value: 6.9106707186026295,
            label: ''
          },
          {
            id: 32,
            name: '01/04/2022',
            tooltipValue: '01/04/2022',
            valueToShow: 6.606035666801689,
            value: 6.606035666801689,
            label: 6.606035666801689
          },
          {
            id: 33,
            name: '',
            tooltipValue: '02/04/2022',
            valueToShow: '',
            value: 7.158787081832974,
            label: ''
          },
          {
            id: 34,
            name: '03/04/2022',
            tooltipValue: '03/04/2022',
            valueToShow: 7.446328190218668,
            value: 7.446328190218668,
            label: ''
          },
          {
            id: 35,
            name: '',
            tooltipValue: '04/04/2022',
            valueToShow: '',
            value: 6.135163013636763,
            label: ''
          },
          {
            id: 36,
            name: '05/04/2022',
            tooltipValue: '05/04/2022',
            valueToShow: 5.733497101657944,
            value: 5.733497101657944,
            label: 5.733497101657944
          },
          {
            id: 37,
            name: '',
            tooltipValue: '06/04/2022',
            valueToShow: '',
            value: 5.833776396067439,
            label: ''
          },
          {
            id: 38,
            name: '07/04/2022',
            tooltipValue: '07/04/2022',
            valueToShow: 5.244153437613346,
            value: 5.244153437613346,
            label: ''
          },
          {
            id: 39,
            name: '',
            tooltipValue: '08/04/2022',
            valueToShow: '',
            value: 5.558445190458568,
            label: ''
          },
          {
            id: 40,
            name: '09/04/2022',
            tooltipValue: '09/04/2022',
            valueToShow: 5.428370568440786,
            value: 5.428370568440786,
            label: 5.428370568440786
          },
          {
            id: 41,
            name: '',
            tooltipValue: '10/04/2022',
            valueToShow: '',
            value: 5.551863274142422,
            label: ''
          },
          {
            id: 42,
            name: '11/04/2022',
            tooltipValue: '11/04/2022',
            valueToShow: 4.954645744356101,
            value: 4.954645744356101,
            label: ''
          },
          {
            id: 43,
            name: '',
            tooltipValue: '12/04/2022',
            valueToShow: '',
            value: 5.282078993395236,
            label: ''
          },
          {
            id: 44,
            name: '13/04/2022',
            tooltipValue: '13/04/2022',
            valueToShow: 5.0187169650330725,
            value: 5.0187169650330725,
            label: 5.0187169650330725
          },
          {
            id: 45,
            name: '',
            tooltipValue: '14/04/2022',
            valueToShow: '',
            value: 5.581593063003367,
            label: ''
          },
          {
            id: 46,
            name: '15/04/2022',
            tooltipValue: '15/04/2022',
            valueToShow: 6.0127851309671145,
            value: 6.0127851309671145,
            label: ''
          },
          {
            id: 47,
            name: '',
            tooltipValue: '16/04/2022',
            valueToShow: '',
            value: 6.218933167594796,
            label: ''
          },
          {
            id: 48,
            name: '17/04/2022',
            tooltipValue: '17/04/2022',
            valueToShow: 5.919739348432976,
            value: 5.919739348432976,
            label: 5.919739348432976
          },
          {
            id: 49,
            name: '',
            tooltipValue: '18/04/2022',
            valueToShow: '',
            value: 5.461158151219867,
            label: ''
          },
          {
            id: 50,
            name: '19/04/2022',
            tooltipValue: '19/04/2022',
            valueToShow: 6.250390678750467,
            value: 6.250390678750467,
            label: ''
          },
          {
            id: 51,
            name: '',
            tooltipValue: '20/04/2022',
            valueToShow: '',
            value: 5.972651059173928,
            label: ''
          },
          {
            id: 52,
            name: '21/04/2022',
            tooltipValue: '21/04/2022',
            valueToShow: 5.847091959705319,
            value: 5.847091959705319,
            label: 5.847091959705319
          },
          {
            id: 53,
            name: '',
            tooltipValue: '22/04/2022',
            valueToShow: '',
            value: 6.310489731230511,
            label: ''
          },
          {
            id: 54,
            name: '23/04/2022',
            tooltipValue: '23/04/2022',
            valueToShow: 6.868540288239642,
            value: 6.868540288239642,
            label: ''
          },
          {
            id: 55,
            name: '',
            tooltipValue: '24/04/2022',
            valueToShow: '',
            value: 6.722080086971881,
            label: ''
          },
          {
            id: 56,
            name: '25/04/2022',
            tooltipValue: '25/04/2022',
            valueToShow: 5.904520970177562,
            value: 5.904520970177562,
            label: 5.904520970177562
          },
          {
            id: 57,
            name: '',
            tooltipValue: '26/04/2022',
            valueToShow: '',
            value: 5.560871821125385,
            label: ''
          },
          {
            id: 58,
            name: '27/04/2022',
            tooltipValue: '27/04/2022',
            valueToShow: 5.8504783298173155,
            value: 5.8504783298173155,
            label: ''
          },
          {
            id: 59,
            name: '',
            tooltipValue: '28/04/2022',
            valueToShow: '',
            value: 5.663869070111283,
            label: ''
          },
          {
            id: 60,
            name: '29/04/2022',
            tooltipValue: '29/04/2022',
            valueToShow: 5.608732945848251,
            value: 5.608732945848251,
            label: 5.608732945848251
          },
          {
            id: 61,
            name: '',
            tooltipValue: '30/04/2022',
            valueToShow: '',
            value: 6.0140021627282545,
            label: ''
          },
          {
            id: 62,
            name: '01/05/2022',
            tooltipValue: '01/05/2022',
            valueToShow: 5.9112512336360075,
            value: 5.9112512336360075,
            label: ''
          },
          {
            id: 63,
            name: '',
            tooltipValue: '02/05/2022',
            valueToShow: '',
            value: 5.395375368256967,
            label: ''
          },
          {
            id: 64,
            name: '03/05/2022',
            tooltipValue: '03/05/2022',
            valueToShow: 5.487912838117314,
            value: 5.487912838117314,
            label: 5.487912838117314
          },
          {
            id: 65,
            name: '',
            tooltipValue: '04/05/2022',
            valueToShow: '',
            value: 5.863568512901551,
            label: ''
          },
          {
            id: 66,
            name: '05/05/2022',
            tooltipValue: '05/05/2022',
            valueToShow: 6.19032964939811,
            value: 6.19032964939811,
            label: ''
          },
          {
            id: 67,
            name: '',
            tooltipValue: '06/05/2022',
            valueToShow: '',
            value: 6.142250699051635,
            label: ''
          },
          {
            id: 68,
            name: '07/05/2022',
            tooltipValue: '07/05/2022',
            valueToShow: 5.85531618986513,
            value: 5.85531618986513,
            label: 5.85531618986513
          },
          {
            id: 69,
            name: '',
            tooltipValue: '08/05/2022',
            valueToShow: '',
            value: 5.773151708939074,
            label: ''
          },
          {
            id: 70,
            name: '09/05/2022',
            tooltipValue: '09/05/2022',
            valueToShow: 5.783377844195785,
            value: 5.783377844195785,
            label: ''
          },
          {
            id: 71,
            name: '',
            tooltipValue: '10/05/2022',
            valueToShow: '',
            value: 5.558399089909291,
            label: ''
          },
          {
            id: 72,
            name: '11/05/2022',
            tooltipValue: '11/05/2022',
            valueToShow: 5.698889051168454,
            value: 5.698889051168454,
            label: 5.698889051168454
          },
          {
            id: 73,
            name: '',
            tooltipValue: '12/05/2022',
            valueToShow: '',
            value: 5.451887889798846,
            label: ''
          },
          {
            id: 74,
            name: '13/05/2022',
            tooltipValue: '13/05/2022',
            valueToShow: 5.788488682190042,
            value: 5.788488682190042,
            label: ''
          },
          {
            id: 75,
            name: '',
            tooltipValue: '14/05/2022',
            valueToShow: '',
            value: 5.074602940513475,
            label: ''
          },
          {
            id: 76,
            name: '15/05/2022',
            tooltipValue: '15/05/2022',
            valueToShow: 5.51287142529623,
            value: 5.51287142529623,
            label: 5.51287142529623
          },
          {
            id: 77,
            name: '',
            tooltipValue: '16/05/2022',
            valueToShow: '',
            value: 6.224669886585411,
            label: ''
          },
          {
            id: 78,
            name: '17/05/2022',
            tooltipValue: '17/05/2022',
            valueToShow: 5.924231941781664,
            value: 5.924231941781664,
            label: ''
          },
          {
            id: 79,
            name: '',
            tooltipValue: '18/05/2022',
            valueToShow: '',
            value: 6.060441973997125,
            label: ''
          },
          {
            id: 80,
            name: '19/05/2022',
            tooltipValue: '19/05/2022',
            valueToShow: 6.063736410060366,
            value: 6.063736410060366,
            label: 6.063736410060366
          },
          {
            id: 81,
            name: '',
            tooltipValue: '20/05/2022',
            valueToShow: '',
            value: 6.044872102646742,
            label: ''
          },
          {
            id: 82,
            name: '21/05/2022',
            tooltipValue: '21/05/2022',
            valueToShow: 5.374237694528966,
            value: 5.374237694528966,
            label: ''
          },
          {
            id: 83,
            name: '',
            tooltipValue: '22/05/2022',
            valueToShow: '',
            value: 5.840187901021507,
            label: ''
          },
          {
            id: 84,
            name: '23/05/2022',
            tooltipValue: '23/05/2022',
            valueToShow: 5.496008650626172,
            value: 5.496008650626172,
            label: 5.496008650626172
          },
          {
            id: 85,
            name: '',
            tooltipValue: '24/05/2022',
            valueToShow: '',
            value: 2.6099102556032605,
            label: ''
          }
        ]
      },
      {
        title: 'Hero Metric 1',
        total: 55865,
        round: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            valueToShow: 793,
            tooltipValue: '28/02/2022',
            value: 793,
            label: 793,
            percentage: 1.42
          },
          {
            id: 1,
            name: '',
            valueToShow: '',
            tooltipValue: '01/03/2022',
            value: 704,
            label: '',
            percentage: 1.26
          },
          {
            id: 2,
            name: '02/03/2022',
            valueToShow: 715,
            tooltipValue: '02/03/2022',
            value: 715,
            label: '',
            percentage: 1.28
          },
          {
            id: 3,
            name: '',
            valueToShow: '',
            tooltipValue: '03/03/2022',
            value: 616,
            label: '',
            percentage: 1.1
          },
          {
            id: 4,
            name: '04/03/2022',
            valueToShow: 604,
            tooltipValue: '04/03/2022',
            value: 604,
            label: 604,
            percentage: 1.08
          },
          {
            id: 5,
            name: '',
            valueToShow: '',
            tooltipValue: '05/03/2022',
            value: 542,
            label: '',
            percentage: 0.97
          },
          {
            id: 6,
            name: '06/03/2022',
            valueToShow: 658,
            tooltipValue: '06/03/2022',
            value: 658,
            label: '',
            percentage: 1.18
          },
          {
            id: 7,
            name: '',
            valueToShow: '',
            tooltipValue: '07/03/2022',
            value: 661,
            label: '',
            percentage: 1.18
          },
          {
            id: 8,
            name: '08/03/2022',
            valueToShow: 635,
            tooltipValue: '08/03/2022',
            value: 635,
            label: 635,
            percentage: 1.14
          },
          {
            id: 9,
            name: '',
            valueToShow: '',
            tooltipValue: '09/03/2022',
            value: 662,
            label: '',
            percentage: 1.18
          },
          {
            id: 10,
            name: '10/03/2022',
            valueToShow: 573,
            tooltipValue: '10/03/2022',
            value: 573,
            label: '',
            percentage: 1.03
          },
          {
            id: 11,
            name: '',
            valueToShow: '',
            tooltipValue: '11/03/2022',
            value: 618,
            label: '',
            percentage: 1.11
          },
          {
            id: 12,
            name: '12/03/2022',
            valueToShow: 617,
            tooltipValue: '12/03/2022',
            value: 617,
            label: 617,
            percentage: 1.1
          },
          {
            id: 13,
            name: '',
            valueToShow: '',
            tooltipValue: '13/03/2022',
            value: 588,
            label: '',
            percentage: 1.05
          },
          {
            id: 14,
            name: '14/03/2022',
            valueToShow: 554,
            tooltipValue: '14/03/2022',
            value: 554,
            label: '',
            percentage: 0.99
          },
          {
            id: 15,
            name: '',
            valueToShow: '',
            tooltipValue: '15/03/2022',
            value: 702,
            label: '',
            percentage: 1.26
          },
          {
            id: 16,
            name: '16/03/2022',
            valueToShow: 609,
            tooltipValue: '16/03/2022',
            value: 609,
            label: 609,
            percentage: 1.09
          },
          {
            id: 17,
            name: '',
            valueToShow: '',
            tooltipValue: '17/03/2022',
            value: 484,
            label: '',
            percentage: 0.87
          },
          {
            id: 18,
            name: '18/03/2022',
            valueToShow: 416,
            tooltipValue: '18/03/2022',
            value: 416,
            label: '',
            percentage: 0.74
          },
          {
            id: 19,
            name: '',
            valueToShow: '',
            tooltipValue: '19/03/2022',
            value: 432,
            label: '',
            percentage: 0.77
          },
          {
            id: 20,
            name: '20/03/2022',
            valueToShow: 446,
            tooltipValue: '20/03/2022',
            value: 446,
            label: 446,
            percentage: 0.8
          },
          {
            id: 21,
            name: '',
            valueToShow: '',
            tooltipValue: '21/03/2022',
            value: 507,
            label: '',
            percentage: 0.91
          },
          {
            id: 22,
            name: '22/03/2022',
            valueToShow: 692,
            tooltipValue: '22/03/2022',
            value: 692,
            label: '',
            percentage: 1.24
          },
          {
            id: 23,
            name: '',
            valueToShow: '',
            tooltipValue: '23/03/2022',
            value: 688,
            label: '',
            percentage: 1.23
          },
          {
            id: 24,
            name: '24/03/2022',
            valueToShow: 640,
            tooltipValue: '24/03/2022',
            value: 640,
            label: 640,
            percentage: 1.15
          },
          {
            id: 25,
            name: '',
            valueToShow: '',
            tooltipValue: '25/03/2022',
            value: 636,
            label: '',
            percentage: 1.14
          },
          {
            id: 26,
            name: '26/03/2022',
            valueToShow: 660,
            tooltipValue: '26/03/2022',
            value: 660,
            label: '',
            percentage: 1.18
          },
          {
            id: 27,
            name: '',
            valueToShow: '',
            tooltipValue: '27/03/2022',
            value: 727,
            label: '',
            percentage: 1.3
          },
          {
            id: 28,
            name: '28/03/2022',
            valueToShow: 784,
            tooltipValue: '28/03/2022',
            value: 784,
            label: 784,
            percentage: 1.4
          },
          {
            id: 29,
            name: '',
            valueToShow: '',
            tooltipValue: '29/03/2022',
            value: 872,
            label: '',
            percentage: 1.56
          },
          {
            id: 30,
            name: '30/03/2022',
            valueToShow: 904,
            tooltipValue: '30/03/2022',
            value: 904,
            label: '',
            percentage: 1.62
          },
          {
            id: 31,
            name: '',
            valueToShow: '',
            tooltipValue: '31/03/2022',
            value: 662,
            label: '',
            percentage: 1.18
          },
          {
            id: 32,
            name: '01/04/2022',
            valueToShow: 584,
            tooltipValue: '01/04/2022',
            value: 584,
            label: 584,
            percentage: 1.05
          },
          {
            id: 33,
            name: '',
            valueToShow: '',
            tooltipValue: '02/04/2022',
            value: 423,
            label: '',
            percentage: 0.76
          },
          {
            id: 34,
            name: '03/04/2022',
            valueToShow: 599,
            tooltipValue: '03/04/2022',
            value: 599,
            label: '',
            percentage: 1.07
          },
          {
            id: 35,
            name: '',
            valueToShow: '',
            tooltipValue: '04/04/2022',
            value: 708,
            label: '',
            percentage: 1.27
          },
          {
            id: 36,
            name: '05/04/2022',
            valueToShow: 696,
            tooltipValue: '05/04/2022',
            value: 696,
            label: 696,
            percentage: 1.25
          },
          {
            id: 37,
            name: '',
            valueToShow: '',
            tooltipValue: '06/04/2022',
            value: 594,
            label: '',
            percentage: 1.06
          },
          {
            id: 38,
            name: '07/04/2022',
            valueToShow: 755,
            tooltipValue: '07/04/2022',
            value: 755,
            label: '',
            percentage: 1.35
          },
          {
            id: 39,
            name: '',
            valueToShow: '',
            tooltipValue: '08/04/2022',
            value: 658,
            label: '',
            percentage: 1.18
          },
          {
            id: 40,
            name: '09/04/2022',
            valueToShow: 594,
            tooltipValue: '09/04/2022',
            value: 594,
            label: 594,
            percentage: 1.06
          },
          {
            id: 41,
            name: '',
            valueToShow: '',
            tooltipValue: '10/04/2022',
            value: 566,
            label: '',
            percentage: 1.01
          },
          {
            id: 42,
            name: '11/04/2022',
            valueToShow: 597,
            tooltipValue: '11/04/2022',
            value: 597,
            label: '',
            percentage: 1.07
          },
          {
            id: 43,
            name: '',
            valueToShow: '',
            tooltipValue: '12/04/2022',
            value: 682,
            label: '',
            percentage: 1.22
          },
          {
            id: 44,
            name: '13/04/2022',
            valueToShow: 720,
            tooltipValue: '13/04/2022',
            value: 720,
            label: 720,
            percentage: 1.29
          },
          {
            id: 45,
            name: '',
            valueToShow: '',
            tooltipValue: '14/04/2022',
            value: 696,
            label: '',
            percentage: 1.25
          },
          {
            id: 46,
            name: '15/04/2022',
            valueToShow: 523,
            tooltipValue: '15/04/2022',
            value: 523,
            label: '',
            percentage: 0.94
          },
          {
            id: 47,
            name: '',
            valueToShow: '',
            tooltipValue: '16/04/2022',
            value: 474,
            label: '',
            percentage: 0.85
          },
          {
            id: 48,
            name: '17/04/2022',
            valueToShow: 480,
            tooltipValue: '17/04/2022',
            value: 480,
            label: 480,
            percentage: 0.86
          },
          {
            id: 49,
            name: '',
            valueToShow: '',
            tooltipValue: '18/04/2022',
            value: 718,
            label: '',
            percentage: 1.29
          },
          {
            id: 50,
            name: '19/04/2022',
            valueToShow: 855,
            tooltipValue: '19/04/2022',
            value: 855,
            label: '',
            percentage: 1.53
          },
          {
            id: 51,
            name: '',
            valueToShow: '',
            tooltipValue: '20/04/2022',
            value: 768,
            label: '',
            percentage: 1.37
          },
          {
            id: 52,
            name: '21/04/2022',
            valueToShow: 699,
            tooltipValue: '21/04/2022',
            value: 699,
            label: 699,
            percentage: 1.25
          },
          {
            id: 53,
            name: '',
            valueToShow: '',
            tooltipValue: '22/04/2022',
            value: 631,
            label: '',
            percentage: 1.13
          },
          {
            id: 54,
            name: '23/04/2022',
            valueToShow: 728,
            tooltipValue: '23/04/2022',
            value: 728,
            label: '',
            percentage: 1.3
          },
          {
            id: 55,
            name: '',
            valueToShow: '',
            tooltipValue: '24/04/2022',
            value: 732,
            label: '',
            percentage: 1.31
          },
          {
            id: 56,
            name: '25/04/2022',
            valueToShow: 755,
            tooltipValue: '25/04/2022',
            value: 755,
            label: 755,
            percentage: 1.35
          },
          {
            id: 57,
            name: '',
            valueToShow: '',
            tooltipValue: '26/04/2022',
            value: 864,
            label: '',
            percentage: 1.55
          },
          {
            id: 58,
            name: '27/04/2022',
            valueToShow: 817,
            tooltipValue: '27/04/2022',
            value: 817,
            label: '',
            percentage: 1.46
          },
          {
            id: 59,
            name: '',
            valueToShow: '',
            tooltipValue: '28/04/2022',
            value: 859,
            label: '',
            percentage: 1.54
          },
          {
            id: 60,
            name: '29/04/2022',
            valueToShow: 669,
            tooltipValue: '29/04/2022',
            value: 669,
            label: 669,
            percentage: 1.2
          },
          {
            id: 61,
            name: '',
            valueToShow: '',
            tooltipValue: '30/04/2022',
            value: 719,
            label: '',
            percentage: 1.29
          },
          {
            id: 62,
            name: '01/05/2022',
            valueToShow: 704,
            tooltipValue: '01/05/2022',
            value: 704,
            label: '',
            percentage: 1.26
          },
          {
            id: 63,
            name: '',
            valueToShow: '',
            tooltipValue: '02/05/2022',
            value: 891,
            label: '',
            percentage: 1.59
          },
          {
            id: 64,
            name: '03/05/2022',
            valueToShow: 997,
            tooltipValue: '03/05/2022',
            value: 997,
            label: 997,
            percentage: 1.78
          },
          {
            id: 65,
            name: '',
            valueToShow: '',
            tooltipValue: '04/05/2022',
            value: 1127,
            label: '',
            percentage: 2.02
          },
          {
            id: 66,
            name: '05/05/2022',
            valueToShow: 927,
            tooltipValue: '05/05/2022',
            value: 927,
            label: '',
            percentage: 1.66
          },
          {
            id: 67,
            name: '',
            valueToShow: '',
            tooltipValue: '06/05/2022',
            value: 756,
            label: '',
            percentage: 1.35
          },
          {
            id: 68,
            name: '07/05/2022',
            valueToShow: 731,
            tooltipValue: '07/05/2022',
            value: 731,
            label: 731,
            percentage: 1.31
          },
          {
            id: 69,
            name: '',
            valueToShow: '',
            tooltipValue: '08/05/2022',
            value: 492,
            label: '',
            percentage: 0.88
          },
          {
            id: 70,
            name: '09/05/2022',
            valueToShow: 590,
            tooltipValue: '09/05/2022',
            value: 590,
            label: '',
            percentage: 1.06
          },
          {
            id: 71,
            name: '',
            valueToShow: '',
            tooltipValue: '10/05/2022',
            value: 1020,
            label: '',
            percentage: 1.83
          },
          {
            id: 72,
            name: '11/05/2022',
            valueToShow: 1013,
            tooltipValue: '11/05/2022',
            value: 1013,
            label: 1013,
            percentage: 1.81
          },
          {
            id: 73,
            name: '',
            valueToShow: '',
            tooltipValue: '12/05/2022',
            value: 709,
            label: '',
            percentage: 1.27
          },
          {
            id: 74,
            name: '13/05/2022',
            valueToShow: 712,
            tooltipValue: '13/05/2022',
            value: 712,
            label: '',
            percentage: 1.27
          },
          {
            id: 75,
            name: '',
            valueToShow: '',
            tooltipValue: '14/05/2022',
            value: 497,
            label: '',
            percentage: 0.89
          },
          {
            id: 76,
            name: '15/05/2022',
            valueToShow: 560,
            tooltipValue: '15/05/2022',
            value: 560,
            label: 560,
            percentage: 1
          },
          {
            id: 77,
            name: '',
            valueToShow: '',
            tooltipValue: '16/05/2022',
            value: 626,
            label: '',
            percentage: 1.12
          },
          {
            id: 78,
            name: '17/05/2022',
            valueToShow: 570,
            tooltipValue: '17/05/2022',
            value: 570,
            label: '',
            percentage: 1.02
          },
          {
            id: 79,
            name: '',
            valueToShow: '',
            tooltipValue: '18/05/2022',
            value: 539,
            label: '',
            percentage: 0.96
          },
          {
            id: 80,
            name: '19/05/2022',
            valueToShow: 407,
            tooltipValue: '19/05/2022',
            value: 407,
            label: 407,
            percentage: 0.73
          },
          {
            id: 81,
            name: '',
            valueToShow: '',
            tooltipValue: '20/05/2022',
            value: 433,
            label: '',
            percentage: 0.78
          },
          {
            id: 82,
            name: '21/05/2022',
            valueToShow: 308,
            tooltipValue: '21/05/2022',
            value: 308,
            label: '',
            percentage: 0.55
          },
          {
            id: 83,
            name: '',
            valueToShow: '',
            tooltipValue: '22/05/2022',
            value: 302,
            label: '',
            percentage: 0.54
          },
          {
            id: 84,
            name: '23/05/2022',
            valueToShow: 370,
            tooltipValue: '23/05/2022',
            value: 370,
            label: 370,
            percentage: 0.66
          },
          {
            id: 85,
            name: '',
            valueToShow: '',
            tooltipValue: '24/05/2022',
            value: 20,
            label: '',
            percentage: 0.04
          }
        ]
      },
      {
        title: 'Hero Metric 1 CPQ',
        total: 139.8273460092852,
        round: true,
        data: [
          {
            id: 0,
            tooltipValue: '28/02/2022',
            name: '28/02/2022',
            value: 134.82092095568805,
            valueToShow: 134.82092095568805,
            label: 134.82092095568805
          },
          {
            id: 1,
            tooltipValue: '01/03/2022',
            name: '01/03/2022',
            value: 146.42496662804942,
            valueToShow: '',
            label: ''
          },
          {
            id: 2,
            tooltipValue: '02/03/2022',
            name: '02/03/2022',
            value: 135.98473687939398,
            valueToShow: 135.98473687939398,
            label: ''
          },
          {
            id: 3,
            tooltipValue: '03/03/2022',
            name: '03/03/2022',
            value: 151.60939042790272,
            valueToShow: '',
            label: ''
          },
          {
            id: 4,
            tooltipValue: '04/03/2022',
            name: '04/03/2022',
            value: 148.9474635252433,
            valueToShow: 148.9474635252433,
            label: 148.9474635252433
          },
          {
            id: 5,
            tooltipValue: '05/03/2022',
            name: '05/03/2022',
            value: 167.03092295948994,
            valueToShow: '',
            label: ''
          },
          {
            id: 6,
            tooltipValue: '06/03/2022',
            name: '06/03/2022',
            value: 130.33780102378273,
            valueToShow: 130.33780102378273,
            label: ''
          },
          {
            id: 7,
            tooltipValue: '07/03/2022',
            name: '07/03/2022',
            value: 147.60481741355449,
            valueToShow: '',
            label: ''
          },
          {
            id: 8,
            tooltipValue: '08/03/2022',
            name: '08/03/2022',
            value: 163.35232105370991,
            valueToShow: 163.35232105370991,
            label: 163.35232105370991
          },
          {
            id: 9,
            tooltipValue: '09/03/2022',
            name: '09/03/2022',
            value: 159.69473894730808,
            valueToShow: '',
            label: ''
          },
          {
            id: 10,
            tooltipValue: '10/03/2022',
            name: '10/03/2022',
            value: 175.7025883449908,
            valueToShow: 175.7025883449908,
            label: ''
          },
          {
            id: 11,
            tooltipValue: '11/03/2022',
            name: '11/03/2022',
            value: 140.561659393169,
            valueToShow: '',
            label: ''
          },
          {
            id: 12,
            tooltipValue: '12/03/2022',
            name: '12/03/2022',
            value: 141.27258126082646,
            valueToShow: 141.27258126082646,
            label: 141.27258126082646
          },
          {
            id: 13,
            tooltipValue: '13/03/2022',
            name: '13/03/2022',
            value: 136.58077986191222,
            valueToShow: '',
            label: ''
          },
          {
            id: 14,
            tooltipValue: '14/03/2022',
            name: '14/03/2022',
            value: 138.65320316328925,
            valueToShow: 138.65320316328925,
            label: ''
          },
          {
            id: 15,
            tooltipValue: '15/03/2022',
            name: '15/03/2022',
            value: 125.42088209709253,
            valueToShow: '',
            label: ''
          },
          {
            id: 16,
            tooltipValue: '16/03/2022',
            name: '16/03/2022',
            value: 145.51658648780696,
            valueToShow: 145.51658648780696,
            label: 145.51658648780696
          },
          {
            id: 17,
            tooltipValue: '17/03/2022',
            name: '17/03/2022',
            value: 177.44107151739607,
            valueToShow: '',
            label: ''
          },
          {
            id: 18,
            tooltipValue: '18/03/2022',
            name: '18/03/2022',
            value: 192.471935455759,
            valueToShow: 192.471935455759,
            label: ''
          },
          {
            id: 19,
            tooltipValue: '19/03/2022',
            name: '19/03/2022',
            value: 163.0062085436226,
            valueToShow: '',
            label: ''
          },
          {
            id: 20,
            tooltipValue: '20/03/2022',
            name: '20/03/2022',
            value: 138.4653201862969,
            valueToShow: 138.4653201862969,
            label: 138.4653201862969
          },
          {
            id: 21,
            tooltipValue: '21/03/2022',
            name: '21/03/2022',
            value: 129.78444424856463,
            valueToShow: '',
            label: ''
          },
          {
            id: 22,
            tooltipValue: '22/03/2022',
            name: '22/03/2022',
            value: 103.86155676485357,
            valueToShow: 103.86155676485357,
            label: ''
          },
          {
            id: 23,
            tooltipValue: '23/03/2022',
            name: '23/03/2022',
            value: 110.60623471070687,
            valueToShow: '',
            label: ''
          },
          {
            id: 24,
            tooltipValue: '24/03/2022',
            name: '24/03/2022',
            value: 120.19049799850617,
            valueToShow: 120.19049799850617,
            label: 120.19049799850617
          },
          {
            id: 25,
            tooltipValue: '25/03/2022',
            name: '25/03/2022',
            value: 121.75700765912005,
            valueToShow: '',
            label: ''
          },
          {
            id: 26,
            tooltipValue: '26/03/2022',
            name: '26/03/2022',
            value: 118.03066336219622,
            valueToShow: 118.03066336219622,
            label: ''
          },
          {
            id: 27,
            tooltipValue: '27/03/2022',
            name: '27/03/2022',
            value: 107.7017125291327,
            valueToShow: '',
            label: ''
          },
          {
            id: 28,
            tooltipValue: '28/03/2022',
            name: '28/03/2022',
            value: 97.7297141524982,
            valueToShow: 97.7297141524982,
            label: 97.7297141524982
          },
          {
            id: 29,
            tooltipValue: '29/03/2022',
            name: '29/03/2022',
            value: 92.88245177769248,
            valueToShow: '',
            label: ''
          },
          {
            id: 30,
            tooltipValue: '30/03/2022',
            name: '30/03/2022',
            value: 103.5629623040631,
            valueToShow: 103.5629623040631,
            label: ''
          },
          {
            id: 31,
            tooltipValue: '31/03/2022',
            name: '31/03/2022',
            value: 107.05276166052865,
            valueToShow: '',
            label: ''
          },
          {
            id: 32,
            tooltipValue: '01/04/2022',
            name: '01/04/2022',
            value: 138.6249436586553,
            valueToShow: 138.6249436586553,
            label: 138.6249436586553
          },
          {
            id: 33,
            tooltipValue: '02/04/2022',
            name: '02/04/2022',
            value: 170.82930686530034,
            valueToShow: '',
            label: ''
          },
          {
            id: 34,
            tooltipValue: '03/04/2022',
            name: '03/04/2022',
            value: 150.43074696132905,
            valueToShow: 150.43074696132905,
            label: ''
          },
          {
            id: 35,
            tooltipValue: '04/04/2022',
            name: '04/04/2022',
            value: 127.8678890243278,
            valueToShow: '',
            label: ''
          },
          {
            id: 36,
            tooltipValue: '05/04/2022',
            name: '05/04/2022',
            value: 121.28488193636481,
            valueToShow: 121.28488193636481,
            label: 121.28488193636481
          },
          {
            id: 37,
            tooltipValue: '06/04/2022',
            name: '06/04/2022',
            value: 134.73666393543635,
            valueToShow: '',
            label: ''
          },
          {
            id: 38,
            tooltipValue: '07/04/2022',
            name: '07/04/2022',
            value: 113.4265240214913,
            valueToShow: 113.4265240214913,
            label: ''
          },
          {
            id: 39,
            tooltipValue: '08/04/2022',
            name: '08/04/2022',
            value: 140.2620424656141,
            valueToShow: '',
            label: ''
          },
          {
            id: 40,
            tooltipValue: '09/04/2022',
            name: '09/04/2022',
            value: 155.43051620882306,
            valueToShow: 155.43051620882306,
            label: 155.43051620882306
          },
          {
            id: 41,
            tooltipValue: '10/04/2022',
            name: '10/04/2022',
            value: 163.53474294435063,
            valueToShow: '',
            label: ''
          },
          {
            id: 42,
            tooltipValue: '11/04/2022',
            name: '11/04/2022',
            value: 145.2947789722651,
            valueToShow: 145.2947789722651,
            label: ''
          },
          {
            id: 43,
            tooltipValue: '12/04/2022',
            name: '12/04/2022',
            value: 128.419577403939,
            valueToShow: '',
            label: ''
          },
          {
            id: 44,
            tooltipValue: '13/04/2022',
            name: '13/04/2022',
            value: 126.0116184637054,
            valueToShow: 126.0116184637054,
            label: 126.0116184637054
          },
          {
            id: 45,
            tooltipValue: '14/04/2022',
            name: '14/04/2022',
            value: 132.01750718845034,
            valueToShow: '',
            label: ''
          },
          {
            id: 46,
            tooltipValue: '15/04/2022',
            name: '15/04/2022',
            value: 156.65432159571301,
            valueToShow: 156.65432159571301,
            label: ''
          },
          {
            id: 47,
            tooltipValue: '16/04/2022',
            name: '16/04/2022',
            value: 171.2043436792078,
            valueToShow: '',
            label: ''
          },
          {
            id: 48,
            tooltipValue: '17/04/2022',
            name: '17/04/2022',
            value: 157.7610536357388,
            valueToShow: 157.7610536357388,
            label: 157.7610536357388
          },
          {
            id: 49,
            tooltipValue: '18/04/2022',
            name: '18/04/2022',
            value: 116.95092999046892,
            valueToShow: '',
            label: ''
          },
          {
            id: 50,
            tooltipValue: '19/04/2022',
            name: '19/04/2022',
            value: 108.63983143498326,
            valueToShow: 108.63983143498326,
            label: ''
          },
          {
            id: 51,
            tooltipValue: '20/04/2022',
            name: '20/04/2022',
            value: 122.58710761166488,
            valueToShow: '',
            label: ''
          },
          {
            id: 52,
            tooltipValue: '21/04/2022',
            name: '21/04/2022',
            value: 134.72569828757346,
            valueToShow: 134.72569828757346,
            label: 134.72569828757346
          },
          {
            id: 53,
            tooltipValue: '22/04/2022',
            name: '22/04/2022',
            value: 152.2518156390702,
            valueToShow: '',
            label: ''
          },
          {
            id: 54,
            tooltipValue: '23/04/2022',
            name: '23/04/2022',
            value: 147.15470175229905,
            valueToShow: 147.15470175229905,
            label: ''
          },
          {
            id: 55,
            tooltipValue: '24/04/2022',
            name: '24/04/2022',
            value: 150.98049004085343,
            valueToShow: '',
            label: ''
          },
          {
            id: 56,
            tooltipValue: '25/04/2022',
            name: '25/04/2022',
            value: 148.98944238788442,
            valueToShow: 148.98944238788442,
            label: 148.98944238788442
          },
          {
            id: 57,
            tooltipValue: '26/04/2022',
            name: '26/04/2022',
            value: 137.34194883216966,
            valueToShow: '',
            label: ''
          },
          {
            id: 58,
            tooltipValue: '27/04/2022',
            name: '27/04/2022',
            value: 142.26616031637772,
            valueToShow: 142.26616031637772,
            label: ''
          },
          {
            id: 59,
            tooltipValue: '28/04/2022',
            name: '28/04/2022',
            value: 129.8404071346116,
            valueToShow: '',
            label: ''
          },
          {
            id: 60,
            tooltipValue: '29/04/2022',
            name: '29/04/2022',
            value: 157.00260370239192,
            valueToShow: 157.00260370239192,
            label: 157.00260370239192
          },
          {
            id: 61,
            tooltipValue: '30/04/2022',
            name: '30/04/2022',
            value: 137.56088952465768,
            valueToShow: '',
            label: ''
          },
          {
            id: 62,
            tooltipValue: '01/05/2022',
            name: '01/05/2022',
            value: 140.34183681675032,
            valueToShow: 140.34183681675032,
            label: ''
          },
          {
            id: 63,
            tooltipValue: '02/05/2022',
            name: '02/05/2022',
            value: 118.26226817290524,
            valueToShow: '',
            label: ''
          },
          {
            id: 64,
            tooltipValue: '03/05/2022',
            name: '03/05/2022',
            value: 111.3600447622381,
            valueToShow: 111.3600447622381,
            label: 111.3600447622381
          },
          {
            id: 65,
            tooltipValue: '04/05/2022',
            name: '04/05/2022',
            value: 105.58585572389005,
            valueToShow: '',
            label: ''
          },
          {
            id: 66,
            tooltipValue: '05/05/2022',
            name: '05/05/2022',
            value: 125.18222179893955,
            valueToShow: 125.18222179893955,
            label: ''
          },
          {
            id: 67,
            tooltipValue: '06/05/2022',
            name: '06/05/2022',
            value: 144.9197430145291,
            valueToShow: '',
            label: ''
          },
          {
            id: 68,
            tooltipValue: '07/05/2022',
            name: '07/05/2022',
            value: 150.31581890425312,
            valueToShow: 150.31581890425312,
            label: 150.31581890425312
          },
          {
            id: 69,
            tooltipValue: '08/05/2022',
            name: '08/05/2022',
            value: 220.14247807196332,
            valueToShow: '',
            label: ''
          },
          {
            id: 70,
            tooltipValue: '09/05/2022',
            name: '09/05/2022',
            value: 184.73481161307419,
            valueToShow: 184.73481161307419,
            label: ''
          },
          {
            id: 71,
            tooltipValue: '10/05/2022',
            name: '10/05/2022',
            value: 103.93661357043128,
            valueToShow: '',
            label: ''
          },
          {
            id: 72,
            tooltipValue: '11/05/2022',
            name: '11/05/2022',
            value: 117.9439377667785,
            valueToShow: 117.9439377667785,
            label: 117.9439377667785
          },
          {
            id: 73,
            tooltipValue: '12/05/2022',
            name: '12/05/2022',
            value: 147.93916941021158,
            valueToShow: '',
            label: ''
          },
          {
            id: 74,
            tooltipValue: '13/05/2022',
            name: '13/05/2022',
            value: 131.57429892213995,
            valueToShow: 131.57429892213995,
            label: ''
          },
          {
            id: 75,
            tooltipValue: '14/05/2022',
            name: '14/05/2022',
            value: 159.03826036506615,
            valueToShow: '',
            label: ''
          },
          {
            id: 76,
            tooltipValue: '15/05/2022',
            name: '15/05/2022',
            value: 157.29403505961278,
            valueToShow: 157.29403505961278,
            label: 157.29403505961278
          },
          {
            id: 77,
            tooltipValue: '16/05/2022',
            name: '16/05/2022',
            value: 170.34316266309054,
            valueToShow: '',
            label: ''
          },
          {
            id: 78,
            tooltipValue: '17/05/2022',
            name: '17/05/2022',
            value: 185.0542977603904,
            valueToShow: 185.0542977603904,
            label: ''
          },
          {
            id: 79,
            tooltipValue: '18/05/2022',
            name: '18/05/2022',
            value: 174.6284309798689,
            valueToShow: '',
            label: ''
          },
          {
            id: 80,
            tooltipValue: '19/05/2022',
            name: '19/05/2022',
            value: 213.1246912676008,
            valueToShow: 213.1246912676008,
            label: 213.1246912676008
          },
          {
            id: 81,
            tooltipValue: '20/05/2022',
            name: '20/05/2022',
            value: 194.27353390400012,
            valueToShow: '',
            label: ''
          },
          {
            id: 82,
            tooltipValue: '21/05/2022',
            name: '21/05/2022',
            value: 240.11326270913344,
            valueToShow: 240.11326270913344,
            label: ''
          },
          {
            id: 83,
            tooltipValue: '22/05/2022',
            name: '22/05/2022',
            value: 261.39675449704544,
            valueToShow: '',
            label: ''
          },
          {
            id: 84,
            tooltipValue: '23/05/2022',
            name: '23/05/2022',
            value: 204.18414840947938,
            valueToShow: 204.18414840947938,
            label: 204.18414840947938
          },
          {
            id: 85,
            tooltipValue: '24/05/2022',
            name: '24/05/2022',
            value: 109.0942486842163,
            valueToShow: '',
            label: ''
          }
        ]
      },
      {
        title: 'Hero Metric 1 CVR%',
        total: 3.9699882673397844,
        round: true,
        hasPercentage: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            tooltipValue: '28/02/2022',
            valueToShow: 4.47870778267254,
            value: 4.47870778267254,
            label: 4.47870778267254
          },
          {
            id: 1,
            name: '',
            tooltipValue: '01/03/2022',
            valueToShow: '',
            value: 3.499701729966196,
            label: ''
          },
          {
            id: 2,
            name: '02/03/2022',
            tooltipValue: '02/03/2022',
            valueToShow: 3.8031914893617023,
            value: 3.8031914893617023,
            label: ''
          },
          {
            id: 3,
            name: '',
            tooltipValue: '03/03/2022',
            valueToShow: '',
            value: 3.2637490727985585,
            label: ''
          },
          {
            id: 4,
            name: '04/03/2022',
            tooltipValue: '04/03/2022',
            valueToShow: 3.2412127716662193,
            value: 3.2412127716662193,
            label: 3.2412127716662193
          },
          {
            id: 5,
            name: '',
            tooltipValue: '05/03/2022',
            valueToShow: '',
            value: 3.0696041230107043,
            label: ''
          },
          {
            id: 6,
            name: '06/03/2022',
            tooltipValue: '06/03/2022',
            valueToShow: 3.5013036769009735,
            value: 3.5013036769009735,
            label: ''
          },
          {
            id: 7,
            name: '',
            tooltipValue: '07/03/2022',
            valueToShow: '',
            value: 3.1281056268042216,
            label: ''
          },
          {
            id: 8,
            name: '08/03/2022',
            tooltipValue: '08/03/2022',
            valueToShow: 2.940767841430093,
            value: 2.940767841430093,
            label: 2.940767841430093
          },
          {
            id: 9,
            name: '',
            tooltipValue: '09/03/2022',
            valueToShow: '',
            value: 3.1123648330982605,
            label: ''
          },
          {
            id: 10,
            name: '10/03/2022',
            tooltipValue: '10/03/2022',
            valueToShow: 2.7823637952801787,
            value: 2.7823637952801787,
            label: ''
          },
          {
            id: 11,
            name: '',
            tooltipValue: '11/03/2022',
            valueToShow: '',
            value: 3.2512626262626263,
            label: ''
          },
          {
            id: 12,
            name: '12/03/2022',
            tooltipValue: '12/03/2022',
            valueToShow: 3.2878610252584464,
            value: 3.2878610252584464,
            label: 3.2878610252584464
          },
          {
            id: 13,
            name: '',
            tooltipValue: '13/03/2022',
            valueToShow: '',
            value: 3.1051964512040557,
            label: ''
          },
          {
            id: 14,
            name: '14/03/2022',
            tooltipValue: '14/03/2022',
            valueToShow: 3.5023391073460615,
            value: 3.5023391073460615,
            label: ''
          },
          {
            id: 15,
            name: '',
            tooltipValue: '15/03/2022',
            valueToShow: '',
            value: 3.545812708354379,
            label: ''
          },
          {
            id: 16,
            name: '16/03/2022',
            tooltipValue: '16/03/2022',
            valueToShow: 3.069711174958415,
            value: 3.069711174958415,
            label: 3.069711174958415
          },
          {
            id: 17,
            name: '',
            tooltipValue: '17/03/2022',
            valueToShow: '',
            value: 3.023677141250703,
            label: ''
          },
          {
            id: 18,
            name: '18/03/2022',
            tooltipValue: '18/03/2022',
            valueToShow: 2.85851714423143,
            value: 2.85851714423143,
            label: ''
          },
          {
            id: 19,
            name: '',
            tooltipValue: '19/03/2022',
            valueToShow: '',
            value: 3.2011856243052987,
            label: ''
          },
          {
            id: 20,
            name: '20/03/2022',
            tooltipValue: '20/03/2022',
            valueToShow: 3.843833491338447,
            value: 3.843833491338447,
            label: 3.843833491338447
          },
          {
            id: 21,
            name: '',
            tooltipValue: '21/03/2022',
            valueToShow: '',
            value: 4.2426778242677825,
            label: ''
          },
          {
            id: 22,
            name: '22/03/2022',
            tooltipValue: '22/03/2022',
            valueToShow: 4.709405199401116,
            value: 4.709405199401116,
            label: ''
          },
          {
            id: 23,
            name: '',
            tooltipValue: '23/03/2022',
            valueToShow: '',
            value: 4.690482683392419,
            label: ''
          },
          {
            id: 24,
            name: '24/03/2022',
            tooltipValue: '24/03/2022',
            valueToShow: 4.625614339404452,
            value: 4.625614339404452,
            label: 4.625614339404452
          },
          {
            id: 25,
            name: '',
            tooltipValue: '25/03/2022',
            valueToShow: '',
            value: 4.670289322954913,
            label: ''
          },
          {
            id: 26,
            name: '26/03/2022',
            tooltipValue: '26/03/2022',
            valueToShow: 4.795117698343505,
            value: 4.795117698343505,
            label: ''
          },
          {
            id: 27,
            name: '',
            tooltipValue: '27/03/2022',
            valueToShow: '',
            value: 5.44895817718483,
            label: ''
          },
          {
            id: 28,
            name: '28/03/2022',
            tooltipValue: '28/03/2022',
            valueToShow: 5.377229080932785,
            value: 5.377229080932785,
            label: 5.377229080932785
          },
          {
            id: 29,
            name: '',
            tooltipValue: '29/03/2022',
            valueToShow: '',
            value: 6.020436343551506,
            label: ''
          },
          {
            id: 30,
            name: '30/03/2022',
            tooltipValue: '30/03/2022',
            valueToShow: 6.0813992600067275,
            value: 6.0813992600067275,
            label: ''
          },
          {
            id: 31,
            name: '',
            tooltipValue: '31/03/2022',
            valueToShow: '',
            value: 6.4553876157971715,
            label: ''
          },
          {
            id: 32,
            name: '01/04/2022',
            tooltipValue: '01/04/2022',
            valueToShow: 4.765401876784986,
            value: 4.765401876784986,
            label: 4.765401876784986
          },
          {
            id: 33,
            name: '',
            tooltipValue: '02/04/2022',
            valueToShow: '',
            value: 4.19060828214781,
            label: ''
          },
          {
            id: 34,
            name: '03/04/2022',
            tooltipValue: '03/04/2022',
            valueToShow: 4.950004131889926,
            value: 4.950004131889926,
            label: ''
          },
          {
            id: 35,
            name: '',
            tooltipValue: '04/04/2022',
            valueToShow: '',
            value: 4.798048251558687,
            label: ''
          },
          {
            id: 36,
            name: '05/04/2022',
            tooltipValue: '05/04/2022',
            valueToShow: 4.7272974257963725,
            value: 4.7272974257963725,
            label: 4.7272974257963725
          },
          {
            id: 37,
            name: '',
            tooltipValue: '06/04/2022',
            valueToShow: '',
            value: 4.3297616444347256,
            label: ''
          },
          {
            id: 38,
            name: '07/04/2022',
            tooltipValue: '07/04/2022',
            valueToShow: 4.623392529087568,
            value: 4.623392529087568,
            label: ''
          },
          {
            id: 39,
            name: '',
            tooltipValue: '08/04/2022',
            valueToShow: '',
            value: 3.962900505902192,
            label: ''
          },
          {
            id: 40,
            name: '09/04/2022',
            tooltipValue: '09/04/2022',
            valueToShow: 3.49247412982126,
            value: 3.49247412982126,
            label: 3.49247412982126
          },
          {
            id: 41,
            name: '',
            tooltipValue: '10/04/2022',
            valueToShow: '',
            value: 3.394913627639155,
            label: ''
          },
          {
            id: 42,
            name: '11/04/2022',
            tooltipValue: '11/04/2022',
            valueToShow: 3.4100645456103273,
            value: 3.4100645456103273,
            label: ''
          },
          {
            id: 43,
            name: '',
            tooltipValue: '12/04/2022',
            valueToShow: '',
            value: 4.11314154755443,
            label: ''
          },
          {
            id: 44,
            name: '13/04/2022',
            tooltipValue: '13/04/2022',
            valueToShow: 3.9827414537006307,
            value: 3.9827414537006307,
            label: 3.9827414537006307
          },
          {
            id: 45,
            name: '',
            tooltipValue: '14/04/2022',
            valueToShow: '',
            value: 4.227918843396914,
            label: ''
          },
          {
            id: 46,
            name: '15/04/2022',
            tooltipValue: '15/04/2022',
            valueToShow: 3.8382504036401,
            value: 3.8382504036401,
            label: ''
          },
          {
            id: 47,
            name: '',
            tooltipValue: '16/04/2022',
            valueToShow: '',
            value: 3.6324622576442636,
            label: ''
          },
          {
            id: 48,
            name: '17/04/2022',
            tooltipValue: '17/04/2022',
            valueToShow: 3.75234521575985,
            value: 3.75234521575985,
            label: 3.75234521575985
          },
          {
            id: 49,
            name: '',
            tooltipValue: '18/04/2022',
            valueToShow: '',
            value: 4.669614984391259,
            label: ''
          },
          {
            id: 50,
            name: '19/04/2022',
            tooltipValue: '19/04/2022',
            valueToShow: 5.753314043469484,
            value: 5.753314043469484,
            label: ''
          },
          {
            id: 51,
            name: '',
            tooltipValue: '20/04/2022',
            valueToShow: '',
            value: 4.872169003362304,
            label: ''
          },
          {
            id: 52,
            name: '21/04/2022',
            tooltipValue: '21/04/2022',
            valueToShow: 4.339997516453495,
            value: 4.339997516453495,
            label: 4.339997516453495
          },
          {
            id: 53,
            name: '',
            tooltipValue: '22/04/2022',
            valueToShow: '',
            value: 4.144771413557541,
            label: ''
          },
          {
            id: 54,
            name: '23/04/2022',
            tooltipValue: '23/04/2022',
            valueToShow: 4.667564275181125,
            value: 4.667564275181125,
            label: ''
          },
          {
            id: 55,
            name: '',
            tooltipValue: '24/04/2022',
            valueToShow: '',
            value: 4.452283924335503,
            label: ''
          },
          {
            id: 56,
            name: '25/04/2022',
            tooltipValue: '25/04/2022',
            valueToShow: 3.9630465592357353,
            value: 3.9630465592357353,
            label: 3.9630465592357353
          },
          {
            id: 57,
            name: '',
            tooltipValue: '26/04/2022',
            valueToShow: '',
            value: 4.048924504428511,
            label: ''
          },
          {
            id: 58,
            name: '27/04/2022',
            tooltipValue: '27/04/2022',
            valueToShow: 4.112347108269995,
            value: 4.112347108269995,
            label: ''
          },
          {
            id: 59,
            name: '',
            tooltipValue: '28/04/2022',
            valueToShow: '',
            value: 4.362177534023969,
            label: ''
          },
          {
            id: 60,
            name: '29/04/2022',
            tooltipValue: '29/04/2022',
            valueToShow: 3.5723821220697385,
            value: 3.5723821220697385,
            label: 3.5723821220697385
          },
          {
            id: 61,
            name: '',
            tooltipValue: '30/04/2022',
            valueToShow: '',
            value: 4.371883740727228,
            label: ''
          },
          {
            id: 62,
            name: '01/05/2022',
            tooltipValue: '01/05/2022',
            valueToShow: 4.212037812612182,
            value: 4.212037812612182,
            label: ''
          },
          {
            id: 63,
            name: '',
            tooltipValue: '02/05/2022',
            valueToShow: '',
            value: 4.56221198156682,
            label: ''
          },
          {
            id: 64,
            name: '03/05/2022',
            tooltipValue: '03/05/2022',
            valueToShow: 4.928080668281351,
            value: 4.928080668281351,
            label: 4.928080668281351
          },
          {
            id: 65,
            name: '',
            tooltipValue: '04/05/2022',
            valueToShow: '',
            value: 5.553365526756677,
            label: ''
          },
          {
            id: 66,
            name: '05/05/2022',
            tooltipValue: '05/05/2022',
            valueToShow: 4.945054945054945,
            value: 4.945054945054945,
            label: ''
          },
          {
            id: 67,
            name: '',
            tooltipValue: '06/05/2022',
            valueToShow: '',
            value: 4.238380893648035,
            label: ''
          },
          {
            id: 68,
            name: '07/05/2022',
            tooltipValue: '07/05/2022',
            valueToShow: 3.8953426409463927,
            value: 3.8953426409463927,
            label: 3.8953426409463927
          },
          {
            id: 69,
            name: '',
            tooltipValue: '08/05/2022',
            valueToShow: '',
            value: 2.6224614892596345,
            label: ''
          },
          {
            id: 70,
            name: '09/05/2022',
            tooltipValue: '09/05/2022',
            valueToShow: 3.1306378011249074,
            value: 3.1306378011249074,
            label: ''
          },
          {
            id: 71,
            name: '',
            tooltipValue: '10/05/2022',
            valueToShow: '',
            value: 5.34787395795103,
            label: ''
          },
          {
            id: 72,
            name: '11/05/2022',
            tooltipValue: '11/05/2022',
            valueToShow: 4.831862628189841,
            value: 4.831862628189841,
            label: 4.831862628189841
          },
          {
            id: 73,
            name: '',
            tooltipValue: '12/05/2022',
            valueToShow: '',
            value: 3.6852227246738396,
            label: ''
          },
          {
            id: 74,
            name: '13/05/2022',
            tooltipValue: '13/05/2022',
            valueToShow: 4.39940682155215,
            value: 4.39940682155215,
            label: ''
          },
          {
            id: 75,
            name: '',
            tooltipValue: '14/05/2022',
            valueToShow: '',
            value: 3.1908063687724706,
            label: ''
          },
          {
            id: 76,
            name: '15/05/2022',
            tooltipValue: '15/05/2022',
            valueToShow: 3.5048191262986603,
            value: 3.5048191262986603,
            label: 3.5048191262986603
          },
          {
            id: 77,
            name: '',
            tooltipValue: '16/05/2022',
            valueToShow: '',
            value: 3.6541941509544102,
            label: ''
          },
          {
            id: 78,
            name: '17/05/2022',
            tooltipValue: '17/05/2022',
            valueToShow: 3.201347935973041,
            value: 3.201347935973041,
            label: ''
          },
          {
            id: 79,
            name: '',
            tooltipValue: '18/05/2022',
            valueToShow: '',
            value: 3.470478398042624,
            label: ''
          },
          {
            id: 80,
            name: '19/05/2022',
            tooltipValue: '19/05/2022',
            valueToShow: 2.8451590353023417,
            value: 2.8451590353023417,
            label: 2.8451590353023417
          },
          {
            id: 81,
            name: '',
            tooltipValue: '20/05/2022',
            valueToShow: '',
            value: 3.1115263006611094,
            label: ''
          },
          {
            id: 82,
            name: '21/05/2022',
            tooltipValue: '21/05/2022',
            valueToShow: 2.238209432454037,
            value: 2.238209432454037,
            label: ''
          },
          {
            id: 83,
            name: '',
            tooltipValue: '22/05/2022',
            valueToShow: '',
            value: 2.234223570318858,
            label: ''
          },
          {
            id: 84,
            name: '23/05/2022',
            tooltipValue: '23/05/2022',
            valueToShow: 2.6916921286192346,
            value: 2.6916921286192346,
            label: 2.6916921286192346
          },
          {
            id: 85,
            name: '',
            tooltipValue: '24/05/2022',
            valueToShow: '',
            value: 2.3923444976076556,
            label: ''
          }
        ]
      },
      {
        title: 'Hero Metric 2',
        total: 28869,
        round: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            valueToShow: 451,
            tooltipValue: '28/02/2022',
            value: 451,
            label: 451,
            percentage: 1.56
          },
          {
            id: 1,
            name: '',
            valueToShow: '',
            tooltipValue: '01/03/2022',
            value: 385,
            label: '',
            percentage: 1.33
          },
          {
            id: 2,
            name: '02/03/2022',
            valueToShow: 415,
            tooltipValue: '02/03/2022',
            value: 415,
            label: '',
            percentage: 1.44
          },
          {
            id: 3,
            name: '',
            valueToShow: '',
            tooltipValue: '03/03/2022',
            value: 359,
            label: '',
            percentage: 1.24
          },
          {
            id: 4,
            name: '04/03/2022',
            valueToShow: 356,
            tooltipValue: '04/03/2022',
            value: 356,
            label: 356,
            percentage: 1.23
          },
          {
            id: 5,
            name: '',
            valueToShow: '',
            tooltipValue: '05/03/2022',
            value: 309,
            label: '',
            percentage: 1.07
          },
          {
            id: 6,
            name: '06/03/2022',
            valueToShow: 276,
            tooltipValue: '06/03/2022',
            value: 276,
            label: '',
            percentage: 0.96
          },
          {
            id: 7,
            name: '',
            valueToShow: '',
            tooltipValue: '07/03/2022',
            value: 328,
            label: '',
            percentage: 1.14
          },
          {
            id: 8,
            name: '08/03/2022',
            valueToShow: 334,
            tooltipValue: '08/03/2022',
            value: 334,
            label: 334,
            percentage: 1.16
          },
          {
            id: 9,
            name: '',
            valueToShow: '',
            tooltipValue: '09/03/2022',
            value: 407,
            label: '',
            percentage: 1.41
          },
          {
            id: 10,
            name: '10/03/2022',
            valueToShow: 355,
            tooltipValue: '10/03/2022',
            value: 355,
            label: '',
            percentage: 1.23
          },
          {
            id: 11,
            name: '',
            valueToShow: '',
            tooltipValue: '11/03/2022',
            value: 339,
            label: '',
            percentage: 1.17
          },
          {
            id: 12,
            name: '12/03/2022',
            valueToShow: 301,
            tooltipValue: '12/03/2022',
            value: 301,
            label: 301,
            percentage: 1.04
          },
          {
            id: 13,
            name: '',
            valueToShow: '',
            tooltipValue: '13/03/2022',
            value: 331,
            label: '',
            percentage: 1.15
          },
          {
            id: 14,
            name: '14/03/2022',
            valueToShow: 362,
            tooltipValue: '14/03/2022',
            value: 362,
            label: '',
            percentage: 1.25
          },
          {
            id: 15,
            name: '',
            valueToShow: '',
            tooltipValue: '15/03/2022',
            value: 394,
            label: '',
            percentage: 1.36
          },
          {
            id: 16,
            name: '16/03/2022',
            valueToShow: 316,
            tooltipValue: '16/03/2022',
            value: 316,
            label: 316,
            percentage: 1.09
          },
          {
            id: 17,
            name: '',
            valueToShow: '',
            tooltipValue: '17/03/2022',
            value: 350,
            label: '',
            percentage: 1.21
          },
          {
            id: 18,
            name: '18/03/2022',
            valueToShow: 254,
            tooltipValue: '18/03/2022',
            value: 254,
            label: '',
            percentage: 0.88
          },
          {
            id: 19,
            name: '',
            valueToShow: '',
            tooltipValue: '19/03/2022',
            value: 270,
            label: '',
            percentage: 0.94
          },
          {
            id: 20,
            name: '20/03/2022',
            valueToShow: 222,
            tooltipValue: '20/03/2022',
            value: 222,
            label: 222,
            percentage: 0.77
          },
          {
            id: 21,
            name: '',
            valueToShow: '',
            tooltipValue: '21/03/2022',
            value: 297,
            label: '',
            percentage: 1.03
          },
          {
            id: 22,
            name: '22/03/2022',
            valueToShow: 314,
            tooltipValue: '22/03/2022',
            value: 314,
            label: '',
            percentage: 1.09
          },
          {
            id: 23,
            name: '',
            valueToShow: '',
            tooltipValue: '23/03/2022',
            value: 303,
            label: '',
            percentage: 1.05
          },
          {
            id: 24,
            name: '24/03/2022',
            valueToShow: 342,
            tooltipValue: '24/03/2022',
            value: 342,
            label: 342,
            percentage: 1.18
          },
          {
            id: 25,
            name: '',
            valueToShow: '',
            tooltipValue: '25/03/2022',
            value: 293,
            label: '',
            percentage: 1.01
          },
          {
            id: 26,
            name: '26/03/2022',
            valueToShow: 286,
            tooltipValue: '26/03/2022',
            value: 286,
            label: '',
            percentage: 0.99
          },
          {
            id: 27,
            name: '',
            valueToShow: '',
            tooltipValue: '27/03/2022',
            value: 271,
            label: '',
            percentage: 0.94
          },
          {
            id: 28,
            name: '28/03/2022',
            valueToShow: 353,
            tooltipValue: '28/03/2022',
            value: 353,
            label: 353,
            percentage: 1.22
          },
          {
            id: 29,
            name: '',
            valueToShow: '',
            tooltipValue: '29/03/2022',
            value: 387,
            label: '',
            percentage: 1.34
          },
          {
            id: 30,
            name: '30/03/2022',
            valueToShow: 487,
            tooltipValue: '30/03/2022',
            value: 487,
            label: '',
            percentage: 1.69
          },
          {
            id: 31,
            name: '',
            valueToShow: '',
            tooltipValue: '31/03/2022',
            value: 283,
            label: '',
            percentage: 0.98
          },
          {
            id: 32,
            name: '01/04/2022',
            valueToShow: 354,
            tooltipValue: '01/04/2022',
            value: 354,
            label: 354,
            percentage: 1.23
          },
          {
            id: 33,
            name: '',
            valueToShow: '',
            tooltipValue: '02/04/2022',
            value: 357,
            label: '',
            percentage: 1.24
          },
          {
            id: 34,
            name: '03/04/2022',
            valueToShow: 373,
            tooltipValue: '03/04/2022',
            value: 373,
            label: '',
            percentage: 1.29
          },
          {
            id: 35,
            name: '',
            valueToShow: '',
            tooltipValue: '04/04/2022',
            value: 499,
            label: '',
            percentage: 1.73
          },
          {
            id: 36,
            name: '05/04/2022',
            valueToShow: 511,
            tooltipValue: '05/04/2022',
            value: 511,
            label: 511,
            percentage: 1.77
          },
          {
            id: 37,
            name: '',
            valueToShow: '',
            tooltipValue: '06/04/2022',
            value: 368,
            label: '',
            percentage: 1.27
          },
          {
            id: 38,
            name: '07/04/2022',
            valueToShow: 520,
            tooltipValue: '07/04/2022',
            value: 520,
            label: '',
            percentage: 1.8
          },
          {
            id: 39,
            name: '',
            valueToShow: '',
            tooltipValue: '08/04/2022',
            value: 401,
            label: '',
            percentage: 1.39
          },
          {
            id: 40,
            name: '09/04/2022',
            valueToShow: 338,
            tooltipValue: '09/04/2022',
            value: 338,
            label: 338,
            percentage: 1.17
          },
          {
            id: 41,
            name: '',
            valueToShow: '',
            tooltipValue: '10/04/2022',
            value: 293,
            label: '',
            percentage: 1.01
          },
          {
            id: 42,
            name: '11/04/2022',
            valueToShow: 374,
            tooltipValue: '11/04/2022',
            value: 374,
            label: '',
            percentage: 1.3
          },
          {
            id: 43,
            name: '',
            valueToShow: '',
            tooltipValue: '12/04/2022',
            value: 375,
            label: '',
            percentage: 1.3
          },
          {
            id: 44,
            name: '13/04/2022',
            valueToShow: 402,
            tooltipValue: '13/04/2022',
            value: 402,
            label: 402,
            percentage: 1.39
          },
          {
            id: 45,
            name: '',
            valueToShow: '',
            tooltipValue: '14/04/2022',
            value: 357,
            label: '',
            percentage: 1.24
          },
          {
            id: 46,
            name: '15/04/2022',
            valueToShow: 249,
            tooltipValue: '15/04/2022',
            value: 249,
            label: '',
            percentage: 0.86
          },
          {
            id: 47,
            name: '',
            valueToShow: '',
            tooltipValue: '16/04/2022',
            value: 265,
            label: '',
            percentage: 0.92
          },
          {
            id: 48,
            name: '17/04/2022',
            valueToShow: 187,
            tooltipValue: '17/04/2022',
            value: 187,
            label: 187,
            percentage: 0.65
          },
          {
            id: 49,
            name: '',
            valueToShow: '',
            tooltipValue: '18/04/2022',
            value: 341,
            label: '',
            percentage: 1.18
          },
          {
            id: 50,
            name: '19/04/2022',
            valueToShow: 420,
            tooltipValue: '19/04/2022',
            value: 420,
            label: '',
            percentage: 1.45
          },
          {
            id: 51,
            name: '',
            valueToShow: '',
            tooltipValue: '20/04/2022',
            value: 333,
            label: '',
            percentage: 1.15
          },
          {
            id: 52,
            name: '21/04/2022',
            valueToShow: 361,
            tooltipValue: '21/04/2022',
            value: 361,
            label: 361,
            percentage: 1.25
          },
          {
            id: 53,
            name: '',
            valueToShow: '',
            tooltipValue: '22/04/2022',
            value: 317,
            label: '',
            percentage: 1.1
          },
          {
            id: 54,
            name: '23/04/2022',
            valueToShow: 350,
            tooltipValue: '23/04/2022',
            value: 350,
            label: '',
            percentage: 1.21
          },
          {
            id: 55,
            name: '',
            valueToShow: '',
            tooltipValue: '24/04/2022',
            value: 336,
            label: '',
            percentage: 1.16
          },
          {
            id: 56,
            name: '25/04/2022',
            valueToShow: 443,
            tooltipValue: '25/04/2022',
            value: 443,
            label: 443,
            percentage: 1.53
          },
          {
            id: 57,
            name: '',
            valueToShow: '',
            tooltipValue: '26/04/2022',
            value: 528,
            label: '',
            percentage: 1.83
          },
          {
            id: 58,
            name: '27/04/2022',
            valueToShow: 458,
            tooltipValue: '27/04/2022',
            value: 458,
            label: '',
            percentage: 1.59
          },
          {
            id: 59,
            name: '',
            valueToShow: '',
            tooltipValue: '28/04/2022',
            value: 463,
            label: '',
            percentage: 1.6
          },
          {
            id: 60,
            name: '29/04/2022',
            valueToShow: 470,
            tooltipValue: '29/04/2022',
            value: 470,
            label: 470,
            percentage: 1.63
          },
          {
            id: 61,
            name: '',
            valueToShow: '',
            tooltipValue: '30/04/2022',
            value: 597,
            label: '',
            percentage: 2.07
          },
          {
            id: 62,
            name: '01/05/2022',
            valueToShow: 371,
            tooltipValue: '01/05/2022',
            value: 371,
            label: '',
            percentage: 1.29
          },
          {
            id: 63,
            name: '',
            valueToShow: '',
            tooltipValue: '02/05/2022',
            value: 395,
            label: '',
            percentage: 1.37
          },
          {
            id: 64,
            name: '03/05/2022',
            valueToShow: 417,
            tooltipValue: '03/05/2022',
            value: 417,
            label: 417,
            percentage: 1.44
          },
          {
            id: 65,
            name: '',
            valueToShow: '',
            tooltipValue: '04/05/2022',
            value: 496,
            label: '',
            percentage: 1.72
          },
          {
            id: 66,
            name: '05/05/2022',
            valueToShow: 411,
            tooltipValue: '05/05/2022',
            value: 411,
            label: '',
            percentage: 1.42
          },
          {
            id: 67,
            name: '',
            valueToShow: '',
            tooltipValue: '06/05/2022',
            value: 357,
            label: '',
            percentage: 1.24
          },
          {
            id: 68,
            name: '07/05/2022',
            valueToShow: 289,
            tooltipValue: '07/05/2022',
            value: 289,
            label: 289,
            percentage: 1
          },
          {
            id: 69,
            name: '',
            valueToShow: '',
            tooltipValue: '08/05/2022',
            value: 253,
            label: '',
            percentage: 0.88
          },
          {
            id: 70,
            name: '09/05/2022',
            valueToShow: 283,
            tooltipValue: '09/05/2022',
            value: 283,
            label: '',
            percentage: 0.98
          },
          {
            id: 71,
            name: '',
            valueToShow: '',
            tooltipValue: '10/05/2022',
            value: 347,
            label: '',
            percentage: 1.2
          },
          {
            id: 72,
            name: '11/05/2022',
            valueToShow: 360,
            tooltipValue: '11/05/2022',
            value: 360,
            label: 360,
            percentage: 1.25
          },
          {
            id: 73,
            name: '',
            valueToShow: '',
            tooltipValue: '12/05/2022',
            value: 308,
            label: '',
            percentage: 1.07
          },
          {
            id: 74,
            name: '13/05/2022',
            valueToShow: 250,
            tooltipValue: '13/05/2022',
            value: 250,
            label: '',
            percentage: 0.87
          },
          {
            id: 75,
            name: '',
            valueToShow: '',
            tooltipValue: '14/05/2022',
            value: 238,
            label: '',
            percentage: 0.82
          },
          {
            id: 76,
            name: '15/05/2022',
            valueToShow: 289,
            tooltipValue: '15/05/2022',
            value: 289,
            label: 289,
            percentage: 1
          },
          {
            id: 77,
            name: '',
            valueToShow: '',
            tooltipValue: '16/05/2022',
            value: 253,
            label: '',
            percentage: 0.88
          },
          {
            id: 78,
            name: '17/05/2022',
            valueToShow: 271,
            tooltipValue: '17/05/2022',
            value: 271,
            label: '',
            percentage: 0.94
          },
          {
            id: 79,
            name: '',
            valueToShow: '',
            tooltipValue: '18/05/2022',
            value: 224,
            label: '',
            percentage: 0.78
          },
          {
            id: 80,
            name: '19/05/2022',
            valueToShow: 109,
            tooltipValue: '19/05/2022',
            value: 109,
            label: 109,
            percentage: 0.38
          },
          {
            id: 81,
            name: '',
            valueToShow: '',
            tooltipValue: '20/05/2022',
            value: 187,
            label: '',
            percentage: 0.65
          },
          {
            id: 82,
            name: '21/05/2022',
            valueToShow: 140,
            tooltipValue: '21/05/2022',
            value: 140,
            label: '',
            percentage: 0.48
          },
          {
            id: 83,
            name: '',
            valueToShow: '',
            tooltipValue: '22/05/2022',
            value: 141,
            label: '',
            percentage: 0.49
          },
          {
            id: 84,
            name: '23/05/2022',
            valueToShow: 109,
            tooltipValue: '23/05/2022',
            value: 109,
            label: 109,
            percentage: 0.38
          },
          {
            id: 85,
            name: '',
            valueToShow: '',
            tooltipValue: '24/05/2022',
            value: 1,
            label: '',
            percentage: 0
          }
        ]
      },
      {
        title: 'Hero Metric 2 CPQ',
        total: 270.5827941670552,
        round: true,
        data: [
          {
            id: 0,
            tooltipValue: '28/02/2022',
            name: '28/02/2022',
            value: 237.0576281992475,
            valueToShow: 237.0576281992475,
            label: 237.0576281992475
          },
          {
            id: 1,
            tooltipValue: '01/03/2022',
            name: '01/03/2022',
            value: 267.7485104055761,
            valueToShow: '',
            label: ''
          },
          {
            id: 2,
            tooltipValue: '02/03/2022',
            name: '02/03/2022',
            value: 234.2869563102812,
            valueToShow: 234.2869563102812,
            label: ''
          },
          {
            id: 3,
            tooltipValue: '03/03/2022',
            name: '03/03/2022',
            value: 260.14313232197236,
            valueToShow: '',
            label: ''
          },
          {
            id: 4,
            tooltipValue: '04/03/2022',
            name: '04/03/2022',
            value: 252.70861789114312,
            valueToShow: 252.70861789114312,
            label: 252.70861789114312
          },
          {
            id: 5,
            tooltipValue: '05/03/2022',
            name: '05/03/2022',
            value: 292.97980661502766,
            valueToShow: '',
            label: ''
          },
          {
            id: 6,
            tooltipValue: '06/03/2022',
            name: '06/03/2022',
            value: 310.7328734552501,
            valueToShow: 310.7328734552501,
            label: ''
          },
          {
            id: 7,
            tooltipValue: '07/03/2022',
            name: '07/03/2022',
            value: 297.4597082632912,
            valueToShow: '',
            label: ''
          },
          {
            id: 8,
            tooltipValue: '08/03/2022',
            name: '08/03/2022',
            value: 310.56504152426885,
            valueToShow: 310.56504152426885,
            label: 310.56504152426885
          },
          {
            id: 9,
            tooltipValue: '09/03/2022',
            name: '09/03/2022',
            value: 259.749182268103,
            valueToShow: '',
            label: ''
          },
          {
            id: 10,
            tooltipValue: '10/03/2022',
            name: '10/03/2022',
            value: 283.59882569487246,
            valueToShow: 283.59882569487246,
            label: ''
          },
          {
            id: 11,
            tooltipValue: '11/03/2022',
            name: '11/03/2022',
            value: 256.2451489822373,
            valueToShow: '',
            label: ''
          },
          {
            id: 12,
            tooltipValue: '12/03/2022',
            name: '12/03/2022',
            value: 289.5853243785047,
            valueToShow: 289.5853243785047,
            label: 289.5853243785047
          },
          {
            id: 13,
            tooltipValue: '13/03/2022',
            name: '13/03/2022',
            value: 242.62688386345738,
            valueToShow: '',
            label: ''
          },
          {
            id: 14,
            tooltipValue: '14/03/2022',
            name: '14/03/2022',
            value: 212.19302362558633,
            valueToShow: 212.19302362558633,
            label: ''
          },
          {
            id: 15,
            tooltipValue: '15/03/2022',
            name: '15/03/2022',
            value: 223.46563256893137,
            valueToShow: '',
            label: ''
          },
          {
            id: 16,
            tooltipValue: '16/03/2022',
            name: '16/03/2022',
            value: 280.44177585783046,
            valueToShow: 280.44177585783046,
            label: 280.44177585783046
          },
          {
            id: 17,
            tooltipValue: '17/03/2022',
            name: '17/03/2022',
            value: 245.3756531840563,
            valueToShow: '',
            label: ''
          },
          {
            id: 18,
            tooltipValue: '18/03/2022',
            name: '18/03/2022',
            value: 315.2296265732116,
            valueToShow: 315.2296265732116,
            label: ''
          },
          {
            id: 19,
            tooltipValue: '19/03/2022',
            name: '19/03/2022',
            value: 260.8099336697961,
            valueToShow: '',
            label: ''
          },
          {
            id: 20,
            tooltipValue: '20/03/2022',
            name: '20/03/2022',
            value: 278.1780756895875,
            valueToShow: 278.1780756895875,
            label: 278.1780756895875
          },
          {
            id: 21,
            tooltipValue: '21/03/2022',
            name: '21/03/2022',
            value: 221.55122301017596,
            valueToShow: '',
            label: ''
          },
          {
            id: 22,
            tooltipValue: '22/03/2022',
            name: '22/03/2022',
            value: 228.8923480295499,
            valueToShow: 228.8923480295499,
            label: ''
          },
          {
            id: 23,
            tooltipValue: '23/03/2022',
            name: '23/03/2022',
            value: 251.1455098381727,
            valueToShow: '',
            label: ''
          },
          {
            id: 24,
            tooltipValue: '24/03/2022',
            name: '24/03/2022',
            value: 224.9178909913566,
            valueToShow: 224.9178909913566,
            label: 224.9178909913566
          },
          {
            id: 25,
            tooltipValue: '25/03/2022',
            name: '25/03/2022',
            value: 264.2916616764517,
            valueToShow: '',
            label: ''
          },
          {
            id: 26,
            tooltipValue: '26/03/2022',
            name: '26/03/2022',
            value: 272.3784539127605,
            valueToShow: 272.3784539127605,
            label: ''
          },
          {
            id: 27,
            tooltipValue: '27/03/2022',
            name: '27/03/2022',
            value: 288.92673434937075,
            valueToShow: '',
            label: ''
          },
          {
            id: 28,
            tooltipValue: '28/03/2022',
            name: '28/03/2022',
            value: 217.05409602141245,
            valueToShow: 217.05409602141245,
            label: 217.05409602141245
          },
          {
            id: 29,
            tooltipValue: '29/03/2022',
            name: '29/03/2022',
            value: 209.28552441898665,
            valueToShow: '',
            label: ''
          },
          {
            id: 30,
            tooltipValue: '30/03/2022',
            name: '30/03/2022',
            value: 192.24007787037587,
            valueToShow: 192.24007787037587,
            label: ''
          },
          {
            id: 31,
            tooltipValue: '31/03/2022',
            name: '31/03/2022',
            value: 250.42024105749104,
            valueToShow: '',
            label: ''
          },
          {
            id: 32,
            tooltipValue: '01/04/2022',
            name: '01/04/2022',
            value: 228.69199744817712,
            valueToShow: 228.69199744817712,
            label: 228.69199744817712
          },
          {
            id: 33,
            tooltipValue: '02/04/2022',
            name: '02/04/2022',
            value: 202.4111955294735,
            valueToShow: '',
            label: ''
          },
          {
            id: 34,
            tooltipValue: '03/04/2022',
            name: '03/04/2022',
            value: 241.5764542354855,
            valueToShow: 241.5764542354855,
            label: ''
          },
          {
            id: 35,
            tooltipValue: '04/04/2022',
            name: '04/04/2022',
            value: 181.42377841527872,
            valueToShow: '',
            label: ''
          },
          {
            id: 36,
            tooltipValue: '05/04/2022',
            name: '05/04/2022',
            value: 165.1942814632288,
            valueToShow: 165.1942814632288,
            label: 165.1942814632288
          },
          {
            id: 37,
            tooltipValue: '06/04/2022',
            name: '06/04/2022',
            value: 217.4825499392641,
            valueToShow: '',
            label: ''
          },
          {
            id: 38,
            tooltipValue: '07/04/2022',
            name: '07/04/2022',
            value: 164.68658776197296,
            valueToShow: 164.68658776197296,
            label: ''
          },
          {
            id: 39,
            tooltipValue: '08/04/2022',
            name: '08/04/2022',
            value: 230.15567067923706,
            valueToShow: '',
            label: ''
          },
          {
            id: 40,
            tooltipValue: '09/04/2022',
            name: '09/04/2022',
            value: 273.15303736106773,
            valueToShow: 273.15303736106773,
            label: 273.15303736106773
          },
          {
            id: 41,
            tooltipValue: '10/04/2022',
            name: '10/04/2022',
            value: 315.90670480034964,
            valueToShow: '',
            label: ''
          },
          {
            id: 42,
            tooltipValue: '11/04/2022',
            name: '11/04/2022',
            value: 231.92776215626276,
            valueToShow: 231.92776215626276,
            label: ''
          },
          {
            id: 43,
            tooltipValue: '12/04/2022',
            name: '12/04/2022',
            value: 233.55240477196375,
            valueToShow: '',
            label: ''
          },
          {
            id: 44,
            tooltipValue: '13/04/2022',
            name: '13/04/2022',
            value: 225.69245097977085,
            valueToShow: 225.69245097977085,
            label: 225.69245097977085
          },
          {
            id: 45,
            tooltipValue: '14/04/2022',
            name: '14/04/2022',
            value: 257.37866947664264,
            valueToShow: '',
            label: ''
          },
          {
            id: 46,
            tooltipValue: '15/04/2022',
            name: '15/04/2022',
            value: 329.03698873316426,
            valueToShow: 329.03698873316426,
            label: ''
          },
          {
            id: 47,
            tooltipValue: '16/04/2022',
            name: '16/04/2022',
            value: 306.22965624129995,
            valueToShow: '',
            label: ''
          },
          {
            id: 48,
            tooltipValue: '17/04/2022',
            name: '17/04/2022',
            value: 404.948159064998,
            valueToShow: 404.948159064998,
            label: 404.948159064998
          },
          {
            id: 49,
            tooltipValue: '18/04/2022',
            name: '18/04/2022',
            value: 246.2485857277322,
            valueToShow: '',
            label: ''
          },
          {
            id: 50,
            tooltipValue: '19/04/2022',
            name: '19/04/2022',
            value: 221.15965684978735,
            valueToShow: 221.15965684978735,
            label: ''
          },
          {
            id: 51,
            tooltipValue: '20/04/2022',
            name: '20/04/2022',
            value: 282.7234193566325,
            valueToShow: '',
            label: ''
          },
          {
            id: 52,
            tooltipValue: '21/04/2022',
            name: '21/04/2022',
            value: 260.8677648282932,
            valueToShow: 260.8677648282932,
            label: 260.8677648282932
          },
          {
            id: 53,
            tooltipValue: '22/04/2022',
            name: '22/04/2022',
            value: 303.0627623604205,
            valueToShow: '',
            label: ''
          },
          {
            id: 54,
            tooltipValue: '23/04/2022',
            name: '23/04/2022',
            value: 306.081779644782,
            valueToShow: 306.081779644782,
            label: ''
          },
          {
            id: 55,
            tooltipValue: '24/04/2022',
            name: '24/04/2022',
            value: 328.9217818747164,
            valueToShow: '',
            label: ''
          },
          {
            id: 56,
            tooltipValue: '25/04/2022',
            name: '25/04/2022',
            value: 253.92105869718452,
            valueToShow: 253.92105869718452,
            label: 253.92105869718452
          },
          {
            id: 57,
            tooltipValue: '26/04/2022',
            name: '26/04/2022',
            value: 224.74137081627762,
            valueToShow: '',
            label: ''
          },
          {
            id: 58,
            tooltipValue: '27/04/2022',
            name: '27/04/2022',
            value: 253.78046501851662,
            valueToShow: 253.78046501851662,
            label: ''
          },
          {
            id: 59,
            tooltipValue: '28/04/2022',
            name: '28/04/2022',
            value: 240.89181366875027,
            valueToShow: '',
            label: ''
          },
          {
            id: 60,
            tooltipValue: '29/04/2022',
            name: '29/04/2022',
            value: 223.47817420617062,
            valueToShow: 223.47817420617062,
            label: 223.47817420617062
          },
          {
            id: 61,
            tooltipValue: '30/04/2022',
            name: '30/04/2022',
            value: 165.67216008078537,
            valueToShow: '',
            label: ''
          },
          {
            id: 62,
            tooltipValue: '01/05/2022',
            name: '01/05/2022',
            value: 266.30903805658284,
            valueToShow: 266.30903805658284,
            label: ''
          },
          {
            id: 63,
            tooltipValue: '02/05/2022',
            name: '02/05/2022',
            value: 266.76374922040145,
            valueToShow: '',
            label: ''
          },
          {
            id: 64,
            tooltipValue: '03/05/2022',
            name: '03/05/2022',
            value: 266.24931565455967,
            valueToShow: 266.24931565455967,
            label: 266.24931565455967
          },
          {
            id: 65,
            tooltipValue: '04/05/2022',
            name: '04/05/2022',
            value: 239.9097971790808,
            valueToShow: '',
            label: ''
          },
          {
            id: 66,
            tooltipValue: '05/05/2022',
            name: '05/05/2022',
            value: 282.3453031815498,
            valueToShow: 282.3453031815498,
            label: ''
          },
          {
            id: 67,
            tooltipValue: '06/05/2022',
            name: '06/05/2022',
            value: 306.8888675601793,
            valueToShow: '',
            label: ''
          },
          {
            id: 68,
            tooltipValue: '07/05/2022',
            name: '07/05/2022',
            value: 380.21060075781674,
            valueToShow: 380.21060075781674,
            label: 380.21060075781674
          },
          {
            id: 69,
            tooltipValue: '08/05/2022',
            name: '08/05/2022',
            value: 428.1031589383635,
            valueToShow: '',
            label: ''
          },
          {
            id: 70,
            tooltipValue: '09/05/2022',
            name: '09/05/2022',
            value: 385.1361796880345,
            valueToShow: 385.1361796880345,
            label: ''
          },
          {
            id: 71,
            tooltipValue: '10/05/2022',
            name: '10/05/2022',
            value: 305.5197286508355,
            valueToShow: '',
            label: ''
          },
          {
            id: 72,
            tooltipValue: '11/05/2022',
            name: '11/05/2022',
            value: 331.8811359937406,
            valueToShow: 331.8811359937406,
            label: 331.8811359937406
          },
          {
            id: 73,
            tooltipValue: '12/05/2022',
            name: '12/05/2022',
            value: 340.5482828306494,
            valueToShow: '',
            label: ''
          },
          {
            id: 74,
            tooltipValue: '13/05/2022',
            name: '13/05/2022',
            value: 374.72360333025455,
            valueToShow: 374.72360333025455,
            label: ''
          },
          {
            id: 75,
            tooltipValue: '14/05/2022',
            name: '14/05/2022',
            value: 332.10930840940284,
            valueToShow: '',
            label: ''
          },
          {
            id: 76,
            tooltipValue: '15/05/2022',
            name: '15/05/2022',
            value: 304.79120980409397,
            valueToShow: 304.79120980409397,
            label: 304.79120980409397
          },
          {
            id: 77,
            tooltipValue: '16/05/2022',
            name: '16/05/2022',
            value: 421.4815012928643,
            valueToShow: '',
            label: ''
          },
          {
            id: 78,
            tooltipValue: '17/05/2022',
            name: '17/05/2022',
            value: 389.2285967653968,
            valueToShow: 389.2285967653968,
            label: ''
          },
          {
            id: 79,
            tooltipValue: '18/05/2022',
            name: '18/05/2022',
            value: 420.1996620453096,
            valueToShow: '',
            label: ''
          },
          {
            id: 80,
            tooltipValue: '19/05/2022',
            name: '19/05/2022',
            value: 795.7958655588398,
            valueToShow: 795.7958655588398,
            label: 795.7958655588398
          },
          {
            id: 81,
            tooltipValue: '20/05/2022',
            name: '20/05/2022',
            value: 449.8419260985671,
            valueToShow: '',
            label: ''
          },
          {
            id: 82,
            tooltipValue: '21/05/2022',
            name: '21/05/2022',
            value: 528.2491779600936,
            valueToShow: 528.2491779600936,
            label: ''
          },
          {
            id: 83,
            tooltipValue: '22/05/2022',
            name: '22/05/2022',
            value: 559.8710628234589,
            valueToShow: '',
            label: ''
          },
          {
            id: 84,
            tooltipValue: '23/05/2022',
            name: '23/05/2022',
            value: 693.1021551514438,
            valueToShow: 693.1021551514438,
            label: 693.1021551514438
          },
          {
            id: 85,
            tooltipValue: '24/05/2022',
            name: '24/05/2022',
            value: 2181.884973684326,
            valueToShow: '',
            label: ''
          }
        ]
      },
      {
        title: 'Hero Metric 2 CVR%',
        total: 2.0515455345893177,
        round: true,
        hasPercentage: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            tooltipValue: '28/02/2022',
            valueToShow: 2.5471591550886705,
            value: 2.5471591550886705,
            label: 2.5471591550886705
          },
          {
            id: 1,
            name: '',
            tooltipValue: '01/03/2022',
            valueToShow: '',
            value: 1.9138993835752633,
            label: ''
          },
          {
            id: 2,
            name: '02/03/2022',
            tooltipValue: '02/03/2022',
            valueToShow: 2.2074468085106385,
            value: 2.2074468085106385,
            label: ''
          },
          {
            id: 3,
            name: '',
            tooltipValue: '03/03/2022',
            valueToShow: '',
            value: 1.9020875278160432,
            label: ''
          },
          {
            id: 4,
            name: '04/03/2022',
            tooltipValue: '04/03/2022',
            valueToShow: 1.9103836866112154,
            value: 1.9103836866112154,
            label: 1.9103836866112154
          },
          {
            id: 5,
            name: '',
            tooltipValue: '05/03/2022',
            valueToShow: '',
            value: 1.7500141586906042,
            label: ''
          },
          {
            id: 6,
            name: '06/03/2022',
            tooltipValue: '06/03/2022',
            valueToShow: 1.4686319374235086,
            value: 1.4686319374235086,
            label: ''
          },
          {
            id: 7,
            name: '',
            tooltipValue: '07/03/2022',
            valueToShow: '',
            value: 1.5522218541479342,
            label: ''
          },
          {
            id: 8,
            name: '08/03/2022',
            tooltipValue: '08/03/2022',
            valueToShow: 1.5467975732876396,
            value: 1.5467975732876396,
            label: 1.5467975732876396
          },
          {
            id: 9,
            name: '',
            tooltipValue: '09/03/2022',
            valueToShow: '',
            value: 1.9134931828866948,
            label: ''
          },
          {
            id: 10,
            name: '10/03/2022',
            tooltipValue: '10/03/2022',
            valueToShow: 1.7238030494318732,
            value: 1.7238030494318732,
            label: ''
          },
          {
            id: 11,
            name: '',
            tooltipValue: '11/03/2022',
            valueToShow: '',
            value: 1.783459595959596,
            label: ''
          },
          {
            id: 12,
            name: '12/03/2022',
            tooltipValue: '12/03/2022',
            valueToShow: 1.6039646168602792,
            value: 1.6039646168602792,
            label: 1.6039646168602792
          },
          {
            id: 13,
            name: '',
            tooltipValue: '13/03/2022',
            valueToShow: '',
            value: 1.7479932403886775,
            label: ''
          },
          {
            id: 14,
            name: '14/03/2022',
            tooltipValue: '14/03/2022',
            valueToShow: 2.288532052092553,
            value: 2.288532052092553,
            label: ''
          },
          {
            id: 15,
            name: '',
            tooltipValue: '15/03/2022',
            valueToShow: '',
            value: 1.9901000101020305,
            label: ''
          },
          {
            id: 16,
            name: '16/03/2022',
            tooltipValue: '16/03/2022',
            valueToShow: 1.5928222188618377,
            value: 1.5928222188618377,
            label: 1.5928222188618377
          },
          {
            id: 17,
            name: '',
            tooltipValue: '17/03/2022',
            valueToShow: '',
            value: 2.1865433872680704,
            label: ''
          },
          {
            id: 18,
            name: '18/03/2022',
            tooltipValue: '18/03/2022',
            valueToShow: 1.7453446024874597,
            value: 1.7453446024874597,
            label: ''
          },
          {
            id: 19,
            name: '',
            tooltipValue: '19/03/2022',
            valueToShow: '',
            value: 2.0007410151908114,
            label: ''
          },
          {
            id: 20,
            name: '20/03/2022',
            tooltipValue: '20/03/2022',
            valueToShow: 1.913298284926312,
            value: 1.913298284926312,
            label: 1.913298284926312
          },
          {
            id: 21,
            name: '',
            tooltipValue: '21/03/2022',
            valueToShow: '',
            value: 2.4853556485355646,
            label: ''
          },
          {
            id: 22,
            name: '22/03/2022',
            tooltipValue: '22/03/2022',
            valueToShow: 2.1369266367224715,
            value: 2.1369266367224715,
            label: ''
          },
          {
            id: 23,
            name: '',
            tooltipValue: '23/03/2022',
            valueToShow: '',
            value: 2.065721298063812,
            label: ''
          },
          {
            id: 24,
            name: '24/03/2022',
            tooltipValue: '24/03/2022',
            valueToShow: 2.471812662619254,
            value: 2.471812662619254,
            label: 2.471812662619254
          },
          {
            id: 25,
            name: '',
            tooltipValue: '25/03/2022',
            valueToShow: '',
            value: 2.151564106329858,
            label: ''
          },
          {
            id: 26,
            name: '26/03/2022',
            tooltipValue: '26/03/2022',
            valueToShow: 2.077884335948852,
            value: 2.077884335948852,
            label: ''
          },
          {
            id: 27,
            name: '',
            tooltipValue: '27/03/2022',
            valueToShow: '',
            value: 2.0311797331734374,
            label: ''
          },
          {
            id: 28,
            name: '28/03/2022',
            tooltipValue: '28/03/2022',
            valueToShow: 2.421124828532236,
            value: 2.421124828532236,
            label: 2.421124828532236
          },
          {
            id: 29,
            name: '',
            tooltipValue: '29/03/2022',
            valueToShow: '',
            value: 2.671913835956918,
            label: ''
          },
          {
            id: 30,
            name: '30/03/2022',
            tooltipValue: '30/03/2022',
            valueToShow: 3.2761520349815,
            value: 3.2761520349815,
            label: ''
          },
          {
            id: 31,
            name: '',
            tooltipValue: '31/03/2022',
            valueToShow: '',
            value: 2.7596294490492443,
            label: ''
          },
          {
            id: 32,
            name: '01/04/2022',
            tooltipValue: '01/04/2022',
            valueToShow: 2.8886168910648715,
            value: 2.8886168910648715,
            label: 2.8886168910648715
          },
          {
            id: 33,
            name: '',
            tooltipValue: '02/04/2022',
            valueToShow: '',
            value: 3.536754507628294,
            label: ''
          },
          {
            id: 34,
            name: '03/04/2022',
            tooltipValue: '03/04/2022',
            valueToShow: 3.08238988513346,
            value: 3.08238988513346,
            label: ''
          },
          {
            id: 35,
            name: '',
            tooltipValue: '04/04/2022',
            valueToShow: '',
            value: 3.38167525074546,
            label: ''
          },
          {
            id: 36,
            name: '05/04/2022',
            tooltipValue: '05/04/2022',
            valueToShow: 3.4707600353188885,
            value: 3.4707600353188885,
            label: 3.4707600353188885
          },
          {
            id: 37,
            name: '',
            tooltipValue: '06/04/2022',
            valueToShow: '',
            value: 2.682411254464611,
            label: ''
          },
          {
            id: 38,
            name: '07/04/2022',
            tooltipValue: '07/04/2022',
            valueToShow: 3.184323331292101,
            value: 3.184323331292101,
            label: ''
          },
          {
            id: 39,
            name: '',
            tooltipValue: '08/04/2022',
            valueToShow: '',
            value: 2.415080703444953,
            label: ''
          },
          {
            id: 40,
            name: '09/04/2022',
            tooltipValue: '09/04/2022',
            valueToShow: 1.9873000940733774,
            value: 1.9873000940733774,
            label: 1.9873000940733774
          },
          {
            id: 41,
            name: '',
            tooltipValue: '10/04/2022',
            valueToShow: '',
            value: 1.7574376199616122,
            label: ''
          },
          {
            id: 42,
            name: '11/04/2022',
            tooltipValue: '11/04/2022',
            valueToShow: 2.136288341806135,
            value: 2.136288341806135,
            label: ''
          },
          {
            id: 43,
            name: '',
            tooltipValue: '12/04/2022',
            valueToShow: '',
            value: 2.2616247512212775,
            label: ''
          },
          {
            id: 44,
            name: '13/04/2022',
            tooltipValue: '13/04/2022',
            valueToShow: 2.2236973116495187,
            value: 2.2236973116495187,
            label: 2.2236973116495187
          },
          {
            id: 45,
            name: '',
            tooltipValue: '14/04/2022',
            valueToShow: '',
            value: 2.1686307860527276,
            label: ''
          },
          {
            id: 46,
            name: '15/04/2022',
            tooltipValue: '15/04/2022',
            valueToShow: 1.8273888154997797,
            value: 1.8273888154997797,
            label: ''
          },
          {
            id: 47,
            name: '',
            tooltipValue: '16/04/2022',
            valueToShow: '',
            value: 2.030806958387616,
            label: ''
          },
          {
            id: 48,
            name: '17/04/2022',
            tooltipValue: '17/04/2022',
            valueToShow: 1.4618511569731083,
            value: 1.4618511569731083,
            label: 1.4618511569731083
          },
          {
            id: 49,
            name: '',
            tooltipValue: '18/04/2022',
            valueToShow: '',
            value: 2.217741935483871,
            label: ''
          },
          {
            id: 50,
            name: '19/04/2022',
            tooltipValue: '19/04/2022',
            valueToShow: 2.8261893546867642,
            value: 2.8261893546867642,
            label: ''
          },
          {
            id: 51,
            name: '',
            tooltipValue: '20/04/2022',
            valueToShow: '',
            value: 2.112542028801624,
            label: ''
          },
          {
            id: 52,
            name: '21/04/2022',
            tooltipValue: '21/04/2022',
            valueToShow: 2.2414007202284862,
            value: 2.2414007202284862,
            label: 2.2414007202284862
          },
          {
            id: 53,
            name: '',
            tooltipValue: '22/04/2022',
            valueToShow: '',
            value: 2.0822385706778768,
            label: ''
          },
          {
            id: 54,
            name: '23/04/2022',
            tooltipValue: '23/04/2022',
            valueToShow: 2.2440212861447715,
            value: 2.2440212861447715,
            label: ''
          },
          {
            id: 55,
            name: '',
            tooltipValue: '24/04/2022',
            valueToShow: '',
            value: 2.0436713095310504,
            label: ''
          },
          {
            id: 56,
            name: '25/04/2022',
            tooltipValue: '25/04/2022',
            valueToShow: 2.325337252637657,
            value: 2.325337252637657,
            label: 2.325337252637657
          },
          {
            id: 57,
            name: '',
            tooltipValue: '26/04/2022',
            valueToShow: '',
            value: 2.4743427527063124,
            label: ''
          },
          {
            id: 58,
            name: '27/04/2022',
            tooltipValue: '27/04/2022',
            valueToShow: 2.3053304474757135,
            value: 2.3053304474757135,
            label: ''
          },
          {
            id: 59,
            name: '',
            tooltipValue: '28/04/2022',
            valueToShow: '',
            value: 2.351208612634572,
            label: ''
          },
          {
            id: 60,
            name: '29/04/2022',
            tooltipValue: '29/04/2022',
            valueToShow: 2.5097452875527315,
            value: 2.5097452875527315,
            label: 2.5097452875527315
          },
          {
            id: 61,
            name: '',
            tooltipValue: '30/04/2022',
            valueToShow: '',
            value: 3.6300620211601604,
            label: ''
          },
          {
            id: 62,
            name: '01/05/2022',
            tooltipValue: '01/05/2022',
            valueToShow: 2.2196960631805673,
            value: 2.2196960631805673,
            label: ''
          },
          {
            id: 63,
            name: '',
            tooltipValue: '02/05/2022',
            valueToShow: '',
            value: 2.0225294418842807,
            label: ''
          },
          {
            id: 64,
            name: '03/05/2022',
            tooltipValue: '03/05/2022',
            valueToShow: 2.0611932183283077,
            value: 2.0611932183283077,
            label: 2.0611932183283077
          },
          {
            id: 65,
            name: '',
            tooltipValue: '04/05/2022',
            valueToShow: '',
            value: 2.444072139548635,
            label: ''
          },
          {
            id: 66,
            name: '05/05/2022',
            tooltipValue: '05/05/2022',
            valueToShow: 2.192467726448309,
            value: 2.192467726448309,
            label: ''
          },
          {
            id: 67,
            name: '',
            tooltipValue: '06/05/2022',
            valueToShow: '',
            value: 2.001457644222683,
            label: ''
          },
          {
            id: 68,
            name: '07/05/2022',
            tooltipValue: '07/05/2022',
            valueToShow: 1.5400191836299693,
            value: 1.5400191836299693,
            label: 1.5400191836299693
          },
          {
            id: 69,
            name: '',
            tooltipValue: '08/05/2022',
            valueToShow: '',
            value: 1.3485421885826983,
            label: ''
          },
          {
            id: 70,
            name: '09/05/2022',
            tooltipValue: '09/05/2022',
            valueToShow: 1.5016449113870318,
            value: 1.5016449113870318,
            label: ''
          },
          {
            id: 71,
            name: '',
            tooltipValue: '10/05/2022',
            valueToShow: '',
            value: 1.8193257484402032,
            label: ''
          },
          {
            id: 72,
            name: '11/05/2022',
            tooltipValue: '11/05/2022',
            valueToShow: 1.7171476269973767,
            value: 1.7171476269973767,
            label: 1.7171476269973767
          },
          {
            id: 73,
            name: '',
            tooltipValue: '12/05/2022',
            valueToShow: '',
            value: 1.6009148084619784,
            label: ''
          },
          {
            id: 74,
            name: '13/05/2022',
            tooltipValue: '13/05/2022',
            valueToShow: 1.5447355412753336,
            value: 1.5447355412753336,
            label: ''
          },
          {
            id: 75,
            name: '',
            tooltipValue: '14/05/2022',
            valueToShow: '',
            value: 1.5279917822290703,
            label: ''
          },
          {
            id: 76,
            name: '15/05/2022',
            tooltipValue: '15/05/2022',
            valueToShow: 1.808737013393416,
            value: 1.808737013393416,
            label: 1.808737013393416
          },
          {
            id: 77,
            name: '',
            tooltipValue: '16/05/2022',
            valueToShow: '',
            value: 1.476854824587006,
            label: ''
          },
          {
            id: 78,
            name: '17/05/2022',
            tooltipValue: '17/05/2022',
            valueToShow: 1.5220443695591126,
            value: 1.5220443695591126,
            label: ''
          },
          {
            id: 79,
            name: '',
            tooltipValue: '18/05/2022',
            valueToShow: '',
            value: 1.442276736848883,
            label: ''
          },
          {
            id: 80,
            name: '19/05/2022',
            tooltipValue: '19/05/2022',
            valueToShow: 0.7619713386927648,
            value: 0.7619713386927648,
            label: 0.7619713386927648
          },
          {
            id: 81,
            name: '',
            tooltipValue: '20/05/2022',
            valueToShow: '',
            value: 1.3437769473986778,
            label: ''
          },
          {
            id: 82,
            name: '21/05/2022',
            tooltipValue: '21/05/2022',
            valueToShow: 1.017367923842744,
            value: 1.017367923842744,
            label: ''
          },
          {
            id: 83,
            name: '',
            tooltipValue: '22/05/2022',
            valueToShow: '',
            value: 1.0431308722349633,
            label: ''
          },
          {
            id: 84,
            name: '23/05/2022',
            tooltipValue: '23/05/2022',
            valueToShow: 0.7929579514040448,
            value: 0.7929579514040448,
            label: 0.7929579514040448
          },
          {
            id: 85,
            name: '',
            tooltipValue: '24/05/2022',
            valueToShow: '',
            value: 0.11961722488038277,
            label: ''
          }
        ]
      },
      {
        title: 'Spend',
        total: 7811454.684808718,
        round: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            tooltipValue: '28/02/2022',
            value: 106912.99031786062,
            label: 106912.99031786062
          },
          {
            id: 1,
            name: '',
            tooltipValue: '01/03/2022',
            value: 103083.17650614679,
            label: ''
          },
          {
            id: 2,
            name: '02/03/2022',
            tooltipValue: '02/03/2022',
            value: 97229.0868687667,
            label: ''
          },
          {
            id: 3,
            name: '',
            tooltipValue: '03/03/2022',
            value: 93391.38450358808,
            label: ''
          },
          {
            id: 4,
            name: '04/03/2022',
            tooltipValue: '04/03/2022',
            value: 89964.26796924695,
            label: 89964.26796924695
          },
          {
            id: 5,
            name: '',
            tooltipValue: '05/03/2022',
            value: 90530.76024404354,
            label: ''
          },
          {
            id: 6,
            name: '06/03/2022',
            tooltipValue: '06/03/2022',
            value: 85762.27307364903,
            label: ''
          },
          {
            id: 7,
            name: '',
            tooltipValue: '07/03/2022',
            value: 97566.78431035951,
            label: ''
          },
          {
            id: 8,
            name: '08/03/2022',
            tooltipValue: '08/03/2022',
            value: 103728.7238691058,
            label: 103728.7238691058
          },
          {
            id: 9,
            name: '',
            tooltipValue: '09/03/2022',
            value: 105717.91718311794,
            label: ''
          },
          {
            id: 10,
            name: '10/03/2022',
            tooltipValue: '10/03/2022',
            value: 100677.58312167972,
            label: ''
          },
          {
            id: 11,
            name: '',
            tooltipValue: '11/03/2022',
            value: 86867.10550497845,
            label: ''
          },
          {
            id: 12,
            name: '12/03/2022',
            tooltipValue: '12/03/2022',
            value: 87165.18263792992,
            label: 87165.18263792992
          },
          {
            id: 13,
            name: '',
            tooltipValue: '13/03/2022',
            value: 80309.4985588044,
            label: ''
          },
          {
            id: 14,
            name: '14/03/2022',
            tooltipValue: '14/03/2022',
            value: 76813.87455246225,
            label: ''
          },
          {
            id: 15,
            name: '',
            tooltipValue: '15/03/2022',
            value: 88045.45923215896,
            label: ''
          },
          {
            id: 16,
            name: '16/03/2022',
            tooltipValue: '16/03/2022',
            value: 88619.60117107444,
            label: 88619.60117107444
          },
          {
            id: 17,
            name: '',
            tooltipValue: '17/03/2022',
            value: 85881.4786144197,
            label: ''
          },
          {
            id: 18,
            name: '18/03/2022',
            tooltipValue: '18/03/2022',
            value: 80068.32514959574,
            label: ''
          },
          {
            id: 19,
            name: '',
            tooltipValue: '19/03/2022',
            value: 70418.68209084496,
            label: ''
          },
          {
            id: 20,
            name: '20/03/2022',
            tooltipValue: '20/03/2022',
            value: 61755.53280308843,
            label: 61755.53280308843
          },
          {
            id: 21,
            name: '',
            tooltipValue: '21/03/2022',
            value: 65800.71323402226,
            label: ''
          },
          {
            id: 22,
            name: '22/03/2022',
            tooltipValue: '22/03/2022',
            value: 71872.19728127867,
            label: ''
          },
          {
            id: 23,
            name: '',
            tooltipValue: '23/03/2022',
            value: 76097.08948096633,
            label: ''
          },
          {
            id: 24,
            name: '24/03/2022',
            tooltipValue: '24/03/2022',
            value: 76921.91871904396,
            label: 76921.91871904396
          },
          {
            id: 25,
            name: '',
            tooltipValue: '25/03/2022',
            value: 77437.45687120035,
            label: ''
          },
          {
            id: 26,
            name: '26/03/2022',
            tooltipValue: '26/03/2022',
            value: 77900.23781904951,
            label: ''
          },
          {
            id: 27,
            name: '',
            tooltipValue: '27/03/2022',
            value: 78299.14500867948,
            label: ''
          },
          {
            id: 28,
            name: '28/03/2022',
            tooltipValue: '28/03/2022',
            value: 76620.0958955586,
            label: 76620.0958955586
          },
          {
            id: 29,
            name: '',
            tooltipValue: '29/03/2022',
            value: 80993.49795014784,
            label: ''
          },
          {
            id: 30,
            name: '30/03/2022',
            tooltipValue: '30/03/2022',
            value: 93620.91792287305,
            label: ''
          },
          {
            id: 31,
            name: '',
            tooltipValue: '31/03/2022',
            value: 70868.92821926996,
            label: ''
          },
          {
            id: 32,
            name: '01/04/2022',
            tooltipValue: '01/04/2022',
            value: 80956.9670966547,
            label: 80956.9670966547
          },
          {
            id: 33,
            name: '',
            tooltipValue: '02/04/2022',
            value: 72260.79680402204,
            label: ''
          },
          {
            id: 34,
            name: '03/04/2022',
            tooltipValue: '03/04/2022',
            value: 90108.0174298361,
            label: ''
          },
          {
            id: 35,
            name: '',
            tooltipValue: '04/04/2022',
            value: 90530.46542922407,
            label: ''
          },
          {
            id: 36,
            name: '05/04/2022',
            tooltipValue: '05/04/2022',
            value: 84414.27782770991,
            label: 84414.27782770991
          },
          {
            id: 37,
            name: '',
            tooltipValue: '06/04/2022',
            value: 80033.57837764919,
            label: ''
          },
          {
            id: 38,
            name: '07/04/2022',
            tooltipValue: '07/04/2022',
            value: 85637.02563622594,
            label: ''
          },
          {
            id: 39,
            name: '',
            tooltipValue: '08/04/2022',
            value: 92292.42394237407,
            label: ''
          },
          {
            id: 40,
            name: '09/04/2022',
            tooltipValue: '09/04/2022',
            value: 92325.7266280409,
            label: 92325.7266280409
          },
          {
            id: 41,
            name: '',
            tooltipValue: '10/04/2022',
            value: 92560.66450650245,
            label: ''
          },
          {
            id: 42,
            name: '11/04/2022',
            tooltipValue: '11/04/2022',
            value: 86740.98304644227,
            label: ''
          },
          {
            id: 43,
            name: '',
            tooltipValue: '12/04/2022',
            value: 87582.15178948641,
            label: ''
          },
          {
            id: 44,
            name: '13/04/2022',
            tooltipValue: '13/04/2022',
            value: 90728.36529386789,
            label: 90728.36529386789
          },
          {
            id: 45,
            name: '',
            tooltipValue: '14/04/2022',
            value: 91884.18500316143,
            label: ''
          },
          {
            id: 46,
            name: '15/04/2022',
            tooltipValue: '15/04/2022',
            value: 81930.2101945579,
            label: ''
          },
          {
            id: 47,
            name: '',
            tooltipValue: '16/04/2022',
            value: 81150.85890394449,
            label: ''
          },
          {
            id: 48,
            name: '17/04/2022',
            tooltipValue: '17/04/2022',
            value: 75725.30574515462,
            label: 75725.30574515462
          },
          {
            id: 49,
            name: '',
            tooltipValue: '18/04/2022',
            value: 83970.76773315668,
            label: ''
          },
          {
            id: 50,
            name: '19/04/2022',
            tooltipValue: '19/04/2022',
            value: 92887.05587691069,
            label: ''
          },
          {
            id: 51,
            name: '',
            tooltipValue: '20/04/2022',
            value: 94146.89864575863,
            label: ''
          },
          {
            id: 52,
            name: '21/04/2022',
            tooltipValue: '21/04/2022',
            value: 94173.26310301386,
            label: 94173.26310301386
          },
          {
            id: 53,
            name: '',
            tooltipValue: '22/04/2022',
            value: 96070.8956682533,
            label: ''
          },
          {
            id: 54,
            name: '23/04/2022',
            tooltipValue: '23/04/2022',
            value: 107128.6228756737,
            label: ''
          },
          {
            id: 55,
            name: '',
            tooltipValue: '24/04/2022',
            value: 110517.7187099047,
            label: ''
          },
          {
            id: 56,
            name: '25/04/2022',
            tooltipValue: '25/04/2022',
            value: 112487.02900285274,
            label: 112487.02900285274
          },
          {
            id: 57,
            name: '',
            tooltipValue: '26/04/2022',
            value: 118663.44379099458,
            label: ''
          },
          {
            id: 58,
            name: '27/04/2022',
            tooltipValue: '27/04/2022',
            value: 116231.4529784806,
            label: ''
          },
          {
            id: 59,
            name: '',
            tooltipValue: '28/04/2022',
            value: 111532.90972863138,
            label: ''
          },
          {
            id: 60,
            name: '29/04/2022',
            tooltipValue: '29/04/2022',
            value: 105034.7418769002,
            label: 105034.7418769002
          },
          {
            id: 61,
            name: '',
            tooltipValue: '30/04/2022',
            value: 98906.27956822887,
            label: ''
          },
          {
            id: 62,
            name: '01/05/2022',
            tooltipValue: '01/05/2022',
            value: 98800.65311899222,
            label: ''
          },
          {
            id: 63,
            name: '',
            tooltipValue: '02/05/2022',
            value: 105371.68094205856,
            label: ''
          },
          {
            id: 64,
            name: '03/05/2022',
            tooltipValue: '03/05/2022',
            value: 111025.96462795138,
            label: 111025.96462795138
          },
          {
            id: 65,
            name: '',
            tooltipValue: '04/05/2022',
            value: 118995.25940082408,
            label: ''
          },
          {
            id: 66,
            name: '05/05/2022',
            tooltipValue: '05/05/2022',
            value: 116043.91960761696,
            label: ''
          },
          {
            id: 67,
            name: '',
            tooltipValue: '06/05/2022',
            value: 109559.32571898401,
            label: ''
          },
          {
            id: 68,
            name: '07/05/2022',
            tooltipValue: '07/05/2022',
            value: 109880.86361900903,
            label: 109880.86361900903
          },
          {
            id: 69,
            name: '',
            tooltipValue: '08/05/2022',
            value: 108310.09921140596,
            label: ''
          },
          {
            id: 70,
            name: '09/05/2022',
            tooltipValue: '09/05/2022',
            value: 108993.53885171376,
            label: ''
          },
          {
            id: 71,
            name: '',
            tooltipValue: '10/05/2022',
            value: 106015.34584183991,
            label: ''
          },
          {
            id: 72,
            name: '11/05/2022',
            tooltipValue: '11/05/2022',
            value: 119477.20895774662,
            label: 119477.20895774662
          },
          {
            id: 73,
            name: '',
            tooltipValue: '12/05/2022',
            value: 104888.87111184001,
            label: ''
          },
          {
            id: 74,
            name: '13/05/2022',
            tooltipValue: '13/05/2022',
            value: 93680.90083256364,
            label: ''
          },
          {
            id: 75,
            name: '',
            tooltipValue: '14/05/2022',
            value: 79042.01540143788,
            label: ''
          },
          {
            id: 76,
            name: '15/05/2022',
            tooltipValue: '15/05/2022',
            value: 88084.65963338315,
            label: 88084.65963338315
          },
          {
            id: 77,
            name: '',
            tooltipValue: '16/05/2022',
            value: 106634.81982709467,
            label: ''
          },
          {
            id: 78,
            name: '17/05/2022',
            tooltipValue: '17/05/2022',
            value: 105480.94972342253,
            label: ''
          },
          {
            id: 79,
            name: '',
            tooltipValue: '18/05/2022',
            value: 94124.72429814935,
            label: ''
          },
          {
            id: 80,
            name: '19/05/2022',
            tooltipValue: '19/05/2022',
            value: 86741.74934591353,
            label: 86741.74934591353
          },
          {
            id: 81,
            name: '',
            tooltipValue: '20/05/2022',
            value: 84120.44018043205,
            label: ''
          },
          {
            id: 82,
            name: '21/05/2022',
            tooltipValue: '21/05/2022',
            value: 73954.8849144131,
            label: ''
          },
          {
            id: 83,
            name: '',
            tooltipValue: '22/05/2022',
            value: 78941.81985810772,
            label: ''
          },
          {
            id: 84,
            name: '23/05/2022',
            tooltipValue: '23/05/2022',
            value: 75548.13491150737,
            label: 75548.13491150737
          },
          {
            id: 85,
            name: '',
            tooltipValue: '24/05/2022',
            value: 2181.884973684326,
            label: ''
          }
        ]
      },
      {
        title: 'CPC',
        total: 5.5511292311012275,
        round: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            tooltipValue: '28/02/2022',
            valueToShow: 6.0382350795131945,
            value: 6.0382350795131945,
            label: 6.0382350795131945
          },
          {
            id: 1,
            name: '',
            tooltipValue: '01/03/2022',
            valueToShow: '',
            value: 5.124437090184271,
            label: ''
          },
          {
            id: 2,
            name: '02/03/2022',
            tooltipValue: '02/03/2022',
            valueToShow: 5.171759939828016,
            value: 5.171759939828016,
            label: ''
          },
          {
            id: 3,
            name: '',
            tooltipValue: '03/03/2022',
            valueToShow: '',
            value: 4.948150074366223,
            label: ''
          },
          {
            id: 4,
            name: '04/03/2022',
            tooltipValue: '04/03/2022',
            valueToShow: 4.82770421085307,
            value: 4.82770421085307,
            label: 4.82770421085307
          },
          {
            id: 5,
            name: '',
            tooltipValue: '05/03/2022',
            valueToShow: '',
            value: 5.127188097867336,
            label: ''
          },
          {
            id: 6,
            name: '06/03/2022',
            tooltipValue: '06/03/2022',
            valueToShow: 4.56352221963758,
            value: 4.56352221963758,
            label: ''
          },
          {
            id: 7,
            name: '',
            tooltipValue: '07/03/2022',
            valueToShow: '',
            value: 4.617234598947495,
            label: ''
          },
          {
            id: 8,
            name: '08/03/2022',
            tooltipValue: '08/03/2022',
            valueToShow: 4.803812525777141,
            value: 4.803812525777141,
            label: 4.803812525777141
          },
          {
            id: 9,
            name: '',
            tooltipValue: '09/03/2022',
            valueToShow: '',
            value: 4.970282895304088,
            label: ''
          },
          {
            id: 10,
            name: '10/03/2022',
            tooltipValue: '10/03/2022',
            valueToShow: 4.8886852054811945,
            value: 4.8886852054811945,
            label: ''
          },
          {
            id: 11,
            name: '',
            tooltipValue: '11/03/2022',
            valueToShow: '',
            value: 4.570028698704674,
            label: ''
          },
          {
            id: 12,
            name: '12/03/2022',
            tooltipValue: '12/03/2022',
            valueToShow: 4.64484613865128,
            value: 4.64484613865128,
            label: 4.64484613865128
          },
          {
            id: 13,
            name: '',
            tooltipValue: '13/03/2022',
            valueToShow: '',
            value: 4.2411015292989225,
            label: ''
          },
          {
            id: 14,
            name: '14/03/2022',
            tooltipValue: '14/03/2022',
            valueToShow: 4.856105357975866,
            value: 4.856105357975866,
            label: ''
          },
          {
            id: 15,
            name: '',
            tooltipValue: '15/03/2022',
            valueToShow: '',
            value: 4.44718957632887,
            label: ''
          },
          {
            id: 16,
            name: '16/03/2022',
            tooltipValue: '16/03/2022',
            valueToShow: 4.466938916834238,
            value: 4.466938916834238,
            label: 4.466938916834238
          },
          {
            id: 17,
            name: '',
            tooltipValue: '17/03/2022',
            valueToShow: '',
            value: 5.365245118661816,
            label: ''
          },
          {
            id: 18,
            name: '18/03/2022',
            tooltipValue: '18/03/2022',
            valueToShow: 5.501843272836923,
            value: 5.501843272836923,
            label: ''
          },
          {
            id: 19,
            name: '',
            tooltipValue: '19/03/2022',
            valueToShow: '',
            value: 5.2181313146235615,
            label: ''
          },
          {
            id: 20,
            name: '20/03/2022',
            tooltipValue: '20/03/2022',
            valueToShow: 5.322376351209896,
            value: 5.322376351209896,
            label: 5.322376351209896
          },
          {
            id: 21,
            name: '',
            tooltipValue: '21/03/2022',
            valueToShow: '',
            value: 5.506335835483034,
            label: ''
          },
          {
            id: 22,
            name: '22/03/2022',
            tooltipValue: '22/03/2022',
            valueToShow: 4.891261554462956,
            value: 4.891261554462956,
            label: ''
          },
          {
            id: 23,
            name: '',
            tooltipValue: '23/03/2022',
            valueToShow: '',
            value: 5.187966285858081,
            label: ''
          },
          {
            id: 24,
            name: '24/03/2022',
            tooltipValue: '24/03/2022',
            valueToShow: 5.559548910020523,
            value: 5.559548910020523,
            label: 5.559548910020523
          },
          {
            id: 25,
            name: '',
            tooltipValue: '25/03/2022',
            valueToShow: '',
            value: 5.686404528653279,
            label: ''
          },
          {
            id: 26,
            name: '26/03/2022',
            tooltipValue: '26/03/2022',
            valueToShow: 5.659709228352914,
            value: 5.659709228352914,
            label: ''
          },
          {
            id: 27,
            name: '',
            tooltipValue: '27/03/2022',
            valueToShow: '',
            value: 5.868621271824275,
            label: ''
          },
          {
            id: 28,
            name: '28/03/2022',
            tooltipValue: '28/03/2022',
            valueToShow: 5.255150610120617,
            value: 5.255150610120617,
            label: 5.255150610120617
          },
          {
            id: 29,
            name: '',
            tooltipValue: '29/03/2022',
            valueToShow: '',
            value: 5.591928883605899,
            label: ''
          },
          {
            id: 30,
            name: '30/03/2022',
            tooltipValue: '30/03/2022',
            valueToShow: 6.29807722320034,
            value: 6.29807722320034,
            label: ''
          },
          {
            id: 31,
            name: '',
            tooltipValue: '31/03/2022',
            valueToShow: '',
            value: 6.9106707186026295,
            label: ''
          },
          {
            id: 32,
            name: '01/04/2022',
            tooltipValue: '01/04/2022',
            valueToShow: 6.606035666801689,
            value: 6.606035666801689,
            label: 6.606035666801689
          },
          {
            id: 33,
            name: '',
            tooltipValue: '02/04/2022',
            valueToShow: '',
            value: 7.158787081832974,
            label: ''
          },
          {
            id: 34,
            name: '03/04/2022',
            tooltipValue: '03/04/2022',
            valueToShow: 7.446328190218668,
            value: 7.446328190218668,
            label: ''
          },
          {
            id: 35,
            name: '',
            tooltipValue: '04/04/2022',
            valueToShow: '',
            value: 6.135163013636763,
            label: ''
          },
          {
            id: 36,
            name: '05/04/2022',
            tooltipValue: '05/04/2022',
            valueToShow: 5.733497101657944,
            value: 5.733497101657944,
            label: 5.733497101657944
          },
          {
            id: 37,
            name: '',
            tooltipValue: '06/04/2022',
            valueToShow: '',
            value: 5.833776396067439,
            label: ''
          },
          {
            id: 38,
            name: '07/04/2022',
            tooltipValue: '07/04/2022',
            valueToShow: 5.244153437613346,
            value: 5.244153437613346,
            label: ''
          },
          {
            id: 39,
            name: '',
            tooltipValue: '08/04/2022',
            valueToShow: '',
            value: 5.558445190458568,
            label: ''
          },
          {
            id: 40,
            name: '09/04/2022',
            tooltipValue: '09/04/2022',
            valueToShow: 5.428370568440786,
            value: 5.428370568440786,
            label: 5.428370568440786
          },
          {
            id: 41,
            name: '',
            tooltipValue: '10/04/2022',
            valueToShow: '',
            value: 5.551863274142422,
            label: ''
          },
          {
            id: 42,
            name: '11/04/2022',
            tooltipValue: '11/04/2022',
            valueToShow: 4.954645744356101,
            value: 4.954645744356101,
            label: ''
          },
          {
            id: 43,
            name: '',
            tooltipValue: '12/04/2022',
            valueToShow: '',
            value: 5.282078993395236,
            label: ''
          },
          {
            id: 44,
            name: '13/04/2022',
            tooltipValue: '13/04/2022',
            valueToShow: 5.0187169650330725,
            value: 5.0187169650330725,
            label: 5.0187169650330725
          },
          {
            id: 45,
            name: '',
            tooltipValue: '14/04/2022',
            valueToShow: '',
            value: 5.581593063003367,
            label: ''
          },
          {
            id: 46,
            name: '15/04/2022',
            tooltipValue: '15/04/2022',
            valueToShow: 6.0127851309671145,
            value: 6.0127851309671145,
            label: ''
          },
          {
            id: 47,
            name: '',
            tooltipValue: '16/04/2022',
            valueToShow: '',
            value: 6.218933167594796,
            label: ''
          },
          {
            id: 48,
            name: '17/04/2022',
            tooltipValue: '17/04/2022',
            valueToShow: 5.919739348432976,
            value: 5.919739348432976,
            label: 5.919739348432976
          },
          {
            id: 49,
            name: '',
            tooltipValue: '18/04/2022',
            valueToShow: '',
            value: 5.461158151219867,
            label: ''
          },
          {
            id: 50,
            name: '19/04/2022',
            tooltipValue: '19/04/2022',
            valueToShow: 6.250390678750467,
            value: 6.250390678750467,
            label: ''
          },
          {
            id: 51,
            name: '',
            tooltipValue: '20/04/2022',
            valueToShow: '',
            value: 5.972651059173928,
            label: ''
          },
          {
            id: 52,
            name: '21/04/2022',
            tooltipValue: '21/04/2022',
            valueToShow: 5.847091959705319,
            value: 5.847091959705319,
            label: 5.847091959705319
          },
          {
            id: 53,
            name: '',
            tooltipValue: '22/04/2022',
            valueToShow: '',
            value: 6.310489731230511,
            label: ''
          },
          {
            id: 54,
            name: '23/04/2022',
            tooltipValue: '23/04/2022',
            valueToShow: 6.868540288239642,
            value: 6.868540288239642,
            label: ''
          },
          {
            id: 55,
            name: '',
            tooltipValue: '24/04/2022',
            valueToShow: '',
            value: 6.722080086971881,
            label: ''
          },
          {
            id: 56,
            name: '25/04/2022',
            tooltipValue: '25/04/2022',
            valueToShow: 5.904520970177562,
            value: 5.904520970177562,
            label: 5.904520970177562
          },
          {
            id: 57,
            name: '',
            tooltipValue: '26/04/2022',
            valueToShow: '',
            value: 5.560871821125385,
            label: ''
          },
          {
            id: 58,
            name: '27/04/2022',
            tooltipValue: '27/04/2022',
            valueToShow: 5.8504783298173155,
            value: 5.8504783298173155,
            label: ''
          },
          {
            id: 59,
            name: '',
            tooltipValue: '28/04/2022',
            valueToShow: '',
            value: 5.663869070111283,
            label: ''
          },
          {
            id: 60,
            name: '29/04/2022',
            tooltipValue: '29/04/2022',
            valueToShow: 5.608732945848251,
            value: 5.608732945848251,
            label: 5.608732945848251
          },
          {
            id: 61,
            name: '',
            tooltipValue: '30/04/2022',
            valueToShow: '',
            value: 6.0140021627282545,
            label: ''
          },
          {
            id: 62,
            name: '01/05/2022',
            tooltipValue: '01/05/2022',
            valueToShow: 5.9112512336360075,
            value: 5.9112512336360075,
            label: ''
          },
          {
            id: 63,
            name: '',
            tooltipValue: '02/05/2022',
            valueToShow: '',
            value: 5.395375368256967,
            label: ''
          },
          {
            id: 64,
            name: '03/05/2022',
            tooltipValue: '03/05/2022',
            valueToShow: 5.487912838117314,
            value: 5.487912838117314,
            label: 5.487912838117314
          },
          {
            id: 65,
            name: '',
            tooltipValue: '04/05/2022',
            valueToShow: '',
            value: 5.863568512901551,
            label: ''
          },
          {
            id: 66,
            name: '05/05/2022',
            tooltipValue: '05/05/2022',
            valueToShow: 6.19032964939811,
            value: 6.19032964939811,
            label: ''
          },
          {
            id: 67,
            name: '',
            tooltipValue: '06/05/2022',
            valueToShow: '',
            value: 6.142250699051635,
            label: ''
          },
          {
            id: 68,
            name: '07/05/2022',
            tooltipValue: '07/05/2022',
            valueToShow: 5.85531618986513,
            value: 5.85531618986513,
            label: 5.85531618986513
          },
          {
            id: 69,
            name: '',
            tooltipValue: '08/05/2022',
            valueToShow: '',
            value: 5.773151708939074,
            label: ''
          },
          {
            id: 70,
            name: '09/05/2022',
            tooltipValue: '09/05/2022',
            valueToShow: 5.783377844195785,
            value: 5.783377844195785,
            label: ''
          },
          {
            id: 71,
            name: '',
            tooltipValue: '10/05/2022',
            valueToShow: '',
            value: 5.558399089909291,
            label: ''
          },
          {
            id: 72,
            name: '11/05/2022',
            tooltipValue: '11/05/2022',
            valueToShow: 5.698889051168454,
            value: 5.698889051168454,
            label: 5.698889051168454
          },
          {
            id: 73,
            name: '',
            tooltipValue: '12/05/2022',
            valueToShow: '',
            value: 5.451887889798846,
            label: ''
          },
          {
            id: 74,
            name: '13/05/2022',
            tooltipValue: '13/05/2022',
            valueToShow: 5.788488682190042,
            value: 5.788488682190042,
            label: ''
          },
          {
            id: 75,
            name: '',
            tooltipValue: '14/05/2022',
            valueToShow: '',
            value: 5.074602940513475,
            label: ''
          },
          {
            id: 76,
            name: '15/05/2022',
            tooltipValue: '15/05/2022',
            valueToShow: 5.51287142529623,
            value: 5.51287142529623,
            label: 5.51287142529623
          },
          {
            id: 77,
            name: '',
            tooltipValue: '16/05/2022',
            valueToShow: '',
            value: 6.224669886585411,
            label: ''
          },
          {
            id: 78,
            name: '17/05/2022',
            tooltipValue: '17/05/2022',
            valueToShow: 5.924231941781664,
            value: 5.924231941781664,
            label: ''
          },
          {
            id: 79,
            name: '',
            tooltipValue: '18/05/2022',
            valueToShow: '',
            value: 6.060441973997125,
            label: ''
          },
          {
            id: 80,
            name: '19/05/2022',
            tooltipValue: '19/05/2022',
            valueToShow: 6.063736410060366,
            value: 6.063736410060366,
            label: 6.063736410060366
          },
          {
            id: 81,
            name: '',
            tooltipValue: '20/05/2022',
            valueToShow: '',
            value: 6.044872102646742,
            label: ''
          },
          {
            id: 82,
            name: '21/05/2022',
            tooltipValue: '21/05/2022',
            valueToShow: 5.374237694528966,
            value: 5.374237694528966,
            label: ''
          },
          {
            id: 83,
            name: '',
            tooltipValue: '22/05/2022',
            valueToShow: '',
            value: 5.840187901021507,
            label: ''
          },
          {
            id: 84,
            name: '23/05/2022',
            tooltipValue: '23/05/2022',
            valueToShow: 5.496008650626172,
            value: 5.496008650626172,
            label: 5.496008650626172
          },
          {
            id: 85,
            name: '',
            tooltipValue: '24/05/2022',
            valueToShow: '',
            value: 2.6099102556032605,
            label: ''
          }
        ]
      },
      {
        title: 'Hero Metric 3',
        total: 17988,
        round: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            valueToShow: 201,
            tooltipValue: '28/02/2022',
            value: 201,
            label: 201,
            percentage: 1.12
          },
          {
            id: 1,
            name: '',
            valueToShow: '',
            tooltipValue: '01/03/2022',
            value: 231,
            label: '',
            percentage: 1.28
          },
          {
            id: 2,
            name: '02/03/2022',
            valueToShow: 220,
            tooltipValue: '02/03/2022',
            value: 220,
            label: '',
            percentage: 1.22
          },
          {
            id: 3,
            name: '',
            valueToShow: '',
            tooltipValue: '03/03/2022',
            value: 175,
            label: '',
            percentage: 0.97
          },
          {
            id: 4,
            name: '04/03/2022',
            valueToShow: 197,
            tooltipValue: '04/03/2022',
            value: 197,
            label: 197,
            percentage: 1.1
          },
          {
            id: 5,
            name: '',
            valueToShow: '',
            tooltipValue: '05/03/2022',
            value: 165,
            label: '',
            percentage: 0.92
          },
          {
            id: 6,
            name: '06/03/2022',
            valueToShow: 160,
            tooltipValue: '06/03/2022',
            value: 160,
            label: '',
            percentage: 0.89
          },
          {
            id: 7,
            name: '',
            valueToShow: '',
            tooltipValue: '07/03/2022',
            value: 198,
            label: '',
            percentage: 1.1
          },
          {
            id: 8,
            name: '08/03/2022',
            valueToShow: 214,
            tooltipValue: '08/03/2022',
            value: 214,
            label: 214,
            percentage: 1.19
          },
          {
            id: 9,
            name: '',
            valueToShow: '',
            tooltipValue: '09/03/2022',
            value: 259,
            label: '',
            percentage: 1.44
          },
          {
            id: 10,
            name: '10/03/2022',
            valueToShow: 206,
            tooltipValue: '10/03/2022',
            value: 206,
            label: '',
            percentage: 1.15
          },
          {
            id: 11,
            name: '',
            valueToShow: '',
            tooltipValue: '11/03/2022',
            value: 169,
            label: '',
            percentage: 0.94
          },
          {
            id: 12,
            name: '12/03/2022',
            valueToShow: 151,
            tooltipValue: '12/03/2022',
            value: 151,
            label: 151,
            percentage: 0.84
          },
          {
            id: 13,
            name: '',
            valueToShow: '',
            tooltipValue: '13/03/2022',
            value: 144,
            label: '',
            percentage: 0.8
          },
          {
            id: 14,
            name: '14/03/2022',
            valueToShow: 190,
            tooltipValue: '14/03/2022',
            value: 190,
            label: '',
            percentage: 1.06
          },
          {
            id: 15,
            name: '',
            valueToShow: '',
            tooltipValue: '15/03/2022',
            value: 263,
            label: '',
            percentage: 1.46
          },
          {
            id: 16,
            name: '16/03/2022',
            valueToShow: 200,
            tooltipValue: '16/03/2022',
            value: 200,
            label: 200,
            percentage: 1.11
          },
          {
            id: 17,
            name: '',
            valueToShow: '',
            tooltipValue: '17/03/2022',
            value: 241,
            label: '',
            percentage: 1.34
          },
          {
            id: 18,
            name: '18/03/2022',
            valueToShow: 178,
            tooltipValue: '18/03/2022',
            value: 178,
            label: '',
            percentage: 0.99
          },
          {
            id: 19,
            name: '',
            valueToShow: '',
            tooltipValue: '19/03/2022',
            value: 179,
            label: '',
            percentage: 1
          },
          {
            id: 20,
            name: '20/03/2022',
            valueToShow: 168,
            tooltipValue: '20/03/2022',
            value: 168,
            label: 168,
            percentage: 0.93
          },
          {
            id: 21,
            name: '',
            valueToShow: '',
            tooltipValue: '21/03/2022',
            value: 192,
            label: '',
            percentage: 1.07
          },
          {
            id: 22,
            name: '22/03/2022',
            valueToShow: 220,
            tooltipValue: '22/03/2022',
            value: 220,
            label: '',
            percentage: 1.22
          },
          {
            id: 23,
            name: '',
            valueToShow: '',
            tooltipValue: '23/03/2022',
            value: 244,
            label: '',
            percentage: 1.36
          },
          {
            id: 24,
            name: '24/03/2022',
            valueToShow: 231,
            tooltipValue: '24/03/2022',
            value: 231,
            label: 231,
            percentage: 1.28
          },
          {
            id: 25,
            name: '',
            valueToShow: '',
            tooltipValue: '25/03/2022',
            value: 198,
            label: '',
            percentage: 1.1
          },
          {
            id: 26,
            name: '26/03/2022',
            valueToShow: 182,
            tooltipValue: '26/03/2022',
            value: 182,
            label: '',
            percentage: 1.01
          },
          {
            id: 27,
            name: '',
            valueToShow: '',
            tooltipValue: '27/03/2022',
            value: 206,
            label: '',
            percentage: 1.15
          },
          {
            id: 28,
            name: '28/03/2022',
            valueToShow: 216,
            tooltipValue: '28/03/2022',
            value: 216,
            label: 216,
            percentage: 1.2
          },
          {
            id: 29,
            name: '',
            valueToShow: '',
            tooltipValue: '29/03/2022',
            value: 262,
            label: '',
            percentage: 1.46
          },
          {
            id: 30,
            name: '30/03/2022',
            valueToShow: 252,
            tooltipValue: '30/03/2022',
            value: 252,
            label: '',
            percentage: 1.4
          },
          {
            id: 31,
            name: '',
            valueToShow: '',
            tooltipValue: '31/03/2022',
            value: 213,
            label: '',
            percentage: 1.18
          },
          {
            id: 32,
            name: '01/04/2022',
            valueToShow: 189,
            tooltipValue: '01/04/2022',
            value: 189,
            label: 189,
            percentage: 1.05
          },
          {
            id: 33,
            name: '',
            valueToShow: '',
            tooltipValue: '02/04/2022',
            value: 178,
            label: '',
            percentage: 0.99
          },
          {
            id: 34,
            name: '03/04/2022',
            valueToShow: 237,
            tooltipValue: '03/04/2022',
            value: 237,
            label: '',
            percentage: 1.32
          },
          {
            id: 35,
            name: '',
            valueToShow: '',
            tooltipValue: '04/04/2022',
            value: 210,
            label: '',
            percentage: 1.17
          },
          {
            id: 36,
            name: '05/04/2022',
            valueToShow: 318,
            tooltipValue: '05/04/2022',
            value: 318,
            label: 318,
            percentage: 1.77
          },
          {
            id: 37,
            name: '',
            valueToShow: '',
            tooltipValue: '06/04/2022',
            value: 219,
            label: '',
            percentage: 1.22
          },
          {
            id: 38,
            name: '07/04/2022',
            valueToShow: 241,
            tooltipValue: '07/04/2022',
            value: 241,
            label: '',
            percentage: 1.34
          },
          {
            id: 39,
            name: '',
            valueToShow: '',
            tooltipValue: '08/04/2022',
            value: 198,
            label: '',
            percentage: 1.1
          },
          {
            id: 40,
            name: '09/04/2022',
            valueToShow: 172,
            tooltipValue: '09/04/2022',
            value: 172,
            label: 172,
            percentage: 0.96
          },
          {
            id: 41,
            name: '',
            valueToShow: '',
            tooltipValue: '10/04/2022',
            value: 142,
            label: '',
            percentage: 0.79
          },
          {
            id: 42,
            name: '11/04/2022',
            valueToShow: 151,
            tooltipValue: '11/04/2022',
            value: 151,
            label: '',
            percentage: 0.84
          },
          {
            id: 43,
            name: '',
            valueToShow: '',
            tooltipValue: '12/04/2022',
            value: 195,
            label: '',
            percentage: 1.08
          },
          {
            id: 44,
            name: '13/04/2022',
            valueToShow: 237,
            tooltipValue: '13/04/2022',
            value: 237,
            label: 237,
            percentage: 1.32
          },
          {
            id: 45,
            name: '',
            valueToShow: '',
            tooltipValue: '14/04/2022',
            value: 189,
            label: '',
            percentage: 1.05
          },
          {
            id: 46,
            name: '15/04/2022',
            valueToShow: 170,
            tooltipValue: '15/04/2022',
            value: 170,
            label: '',
            percentage: 0.95
          },
          {
            id: 47,
            name: '',
            valueToShow: '',
            tooltipValue: '16/04/2022',
            value: 181,
            label: '',
            percentage: 1.01
          },
          {
            id: 48,
            name: '17/04/2022',
            valueToShow: 171,
            tooltipValue: '17/04/2022',
            value: 171,
            label: 171,
            percentage: 0.95
          },
          {
            id: 49,
            name: '',
            valueToShow: '',
            tooltipValue: '18/04/2022',
            value: 273,
            label: '',
            percentage: 1.52
          },
          {
            id: 50,
            name: '19/04/2022',
            valueToShow: 404,
            tooltipValue: '19/04/2022',
            value: 404,
            label: '',
            percentage: 2.25
          },
          {
            id: 51,
            name: '',
            valueToShow: '',
            tooltipValue: '20/04/2022',
            value: 351,
            label: '',
            percentage: 1.95
          },
          {
            id: 52,
            name: '21/04/2022',
            valueToShow: 309,
            tooltipValue: '21/04/2022',
            value: 309,
            label: 309,
            percentage: 1.72
          },
          {
            id: 53,
            name: '',
            valueToShow: '',
            tooltipValue: '22/04/2022',
            value: 265,
            label: '',
            percentage: 1.47
          },
          {
            id: 54,
            name: '23/04/2022',
            valueToShow: 282,
            tooltipValue: '23/04/2022',
            value: 282,
            label: '',
            percentage: 1.57
          },
          {
            id: 55,
            name: '',
            valueToShow: '',
            tooltipValue: '24/04/2022',
            value: 276,
            label: '',
            percentage: 1.53
          },
          {
            id: 56,
            name: '25/04/2022',
            valueToShow: 287,
            tooltipValue: '25/04/2022',
            value: 287,
            label: 287,
            percentage: 1.6
          },
          {
            id: 57,
            name: '',
            valueToShow: '',
            tooltipValue: '26/04/2022',
            value: 337,
            label: '',
            percentage: 1.87
          },
          {
            id: 58,
            name: '27/04/2022',
            valueToShow: 298,
            tooltipValue: '27/04/2022',
            value: 298,
            label: '',
            percentage: 1.66
          },
          {
            id: 59,
            name: '',
            valueToShow: '',
            tooltipValue: '28/04/2022',
            value: 254,
            label: '',
            percentage: 1.41
          },
          {
            id: 60,
            name: '29/04/2022',
            valueToShow: 245,
            tooltipValue: '29/04/2022',
            value: 245,
            label: 245,
            percentage: 1.36
          },
          {
            id: 61,
            name: '',
            valueToShow: '',
            tooltipValue: '30/04/2022',
            value: 200,
            label: '',
            percentage: 1.11
          },
          {
            id: 62,
            name: '01/05/2022',
            valueToShow: 163,
            tooltipValue: '01/05/2022',
            value: 163,
            label: '',
            percentage: 0.91
          },
          {
            id: 63,
            name: '',
            valueToShow: '',
            tooltipValue: '02/05/2022',
            value: 248,
            label: '',
            percentage: 1.38
          },
          {
            id: 64,
            name: '03/05/2022',
            valueToShow: 268,
            tooltipValue: '03/05/2022',
            value: 268,
            label: 268,
            percentage: 1.49
          },
          {
            id: 65,
            name: '',
            valueToShow: '',
            tooltipValue: '04/05/2022',
            value: 326,
            label: '',
            percentage: 1.81
          },
          {
            id: 66,
            name: '05/05/2022',
            valueToShow: 270,
            tooltipValue: '05/05/2022',
            value: 270,
            label: '',
            percentage: 1.5
          },
          {
            id: 67,
            name: '',
            valueToShow: '',
            tooltipValue: '06/05/2022',
            value: 260,
            label: '',
            percentage: 1.45
          },
          {
            id: 68,
            name: '07/05/2022',
            valueToShow: 163,
            tooltipValue: '07/05/2022',
            value: 163,
            label: 163,
            percentage: 0.91
          },
          {
            id: 69,
            name: '',
            valueToShow: '',
            tooltipValue: '08/05/2022',
            value: 140,
            label: '',
            percentage: 0.78
          },
          {
            id: 70,
            name: '09/05/2022',
            valueToShow: 154,
            tooltipValue: '09/05/2022',
            value: 154,
            label: '',
            percentage: 0.86
          },
          {
            id: 71,
            name: '',
            valueToShow: '',
            tooltipValue: '10/05/2022',
            value: 217,
            label: '',
            percentage: 1.21
          },
          {
            id: 72,
            name: '11/05/2022',
            valueToShow: 200,
            tooltipValue: '11/05/2022',
            value: 200,
            label: 200,
            percentage: 1.11
          },
          {
            id: 73,
            name: '',
            valueToShow: '',
            tooltipValue: '12/05/2022',
            value: 179,
            label: '',
            percentage: 1
          },
          {
            id: 74,
            name: '13/05/2022',
            valueToShow: 164,
            tooltipValue: '13/05/2022',
            value: 164,
            label: '',
            percentage: 0.91
          },
          {
            id: 75,
            name: '',
            valueToShow: '',
            tooltipValue: '14/05/2022',
            value: 144,
            label: '',
            percentage: 0.8
          },
          {
            id: 76,
            name: '15/05/2022',
            valueToShow: 145,
            tooltipValue: '15/05/2022',
            value: 145,
            label: 145,
            percentage: 0.81
          },
          {
            id: 77,
            name: '',
            valueToShow: '',
            tooltipValue: '16/05/2022',
            value: 159,
            label: '',
            percentage: 0.88
          },
          {
            id: 78,
            name: '17/05/2022',
            valueToShow: 182,
            tooltipValue: '17/05/2022',
            value: 182,
            label: '',
            percentage: 1.01
          },
          {
            id: 79,
            name: '',
            valueToShow: '',
            tooltipValue: '18/05/2022',
            value: 248,
            label: '',
            percentage: 1.38
          },
          {
            id: 80,
            name: '19/05/2022',
            valueToShow: 148,
            tooltipValue: '19/05/2022',
            value: 148,
            label: 148,
            percentage: 0.82
          },
          {
            id: 81,
            name: '',
            valueToShow: '',
            tooltipValue: '20/05/2022',
            value: 167,
            label: '',
            percentage: 0.93
          },
          {
            id: 82,
            name: '21/05/2022',
            valueToShow: 84,
            tooltipValue: '21/05/2022',
            value: 84,
            label: '',
            percentage: 0.47
          },
          {
            id: 83,
            name: '',
            valueToShow: '',
            tooltipValue: '22/05/2022',
            value: 118,
            label: '',
            percentage: 0.66
          },
          {
            id: 84,
            name: '23/05/2022',
            valueToShow: 133,
            tooltipValue: '23/05/2022',
            value: 133,
            label: 133,
            percentage: 0.74
          },
          {
            id: 85,
            name: '',
            valueToShow: '',
            tooltipValue: '24/05/2022',
            value: 4,
            label: '',
            percentage: 0.02
          }
        ]
      },
      {
        title: 'Hero Metric 3 CPQ',
        total: 434.25921085216356,
        round: true,
        data: [
          {
            id: 0,
            tooltipValue: '28/02/2022',
            name: '28/02/2022',
            value: 531.9054244669683,
            valueToShow: 531.9054244669683,
            label: 531.9054244669683
          },
          {
            id: 1,
            tooltipValue: '01/03/2022',
            name: '01/03/2022',
            value: 446.2475173426268,
            valueToShow: '',
            label: ''
          },
          {
            id: 2,
            tooltipValue: '02/03/2022',
            name: '02/03/2022',
            value: 441.95039485803045,
            valueToShow: 441.95039485803045,
            label: ''
          },
          {
            id: 3,
            tooltipValue: '03/03/2022',
            name: '03/03/2022',
            value: 533.6650543062176,
            valueToShow: '',
            label: ''
          },
          {
            id: 4,
            tooltipValue: '04/03/2022',
            name: '04/03/2022',
            value: 456.67141101140584,
            valueToShow: 456.67141101140584,
            label: 456.67141101140584
          },
          {
            id: 5,
            tooltipValue: '05/03/2022',
            name: '05/03/2022',
            value: 548.6712742063245,
            valueToShow: '',
            label: ''
          },
          {
            id: 6,
            tooltipValue: '06/03/2022',
            name: '06/03/2022',
            value: 536.0142067103064,
            valueToShow: 536.0142067103064,
            label: ''
          },
          {
            id: 7,
            tooltipValue: '07/03/2022',
            name: '07/03/2022',
            value: 492.76153692100763,
            valueToShow: '',
            label: ''
          },
          {
            id: 8,
            tooltipValue: '08/03/2022',
            name: '08/03/2022',
            value: 484.7136629397467,
            valueToShow: 484.7136629397467,
            label: 484.7136629397467
          },
          {
            id: 9,
            tooltipValue: '09/03/2022',
            name: '09/03/2022',
            value: 408.1772864213048,
            valueToShow: '',
            label: ''
          },
          {
            id: 10,
            tooltipValue: '10/03/2022',
            name: '10/03/2022',
            value: 488.7261316586394,
            valueToShow: 488.7261316586394,
            label: ''
          },
          {
            id: 11,
            tooltipValue: '11/03/2022',
            name: '11/03/2022',
            value: 514.0065414495766,
            valueToShow: '',
            label: ''
          },
          {
            id: 12,
            tooltipValue: '12/03/2022',
            name: '12/03/2022',
            value: 577.2528651518537,
            valueToShow: 577.2528651518537,
            label: 577.2528651518537
          },
          {
            id: 13,
            tooltipValue: '13/03/2022',
            name: '13/03/2022',
            value: 557.7048511028083,
            valueToShow: '',
            label: ''
          },
          {
            id: 14,
            tooltipValue: '14/03/2022',
            name: '14/03/2022',
            value: 404.2835502761171,
            valueToShow: 404.2835502761171,
            label: ''
          },
          {
            id: 15,
            tooltipValue: '15/03/2022',
            name: '15/03/2022',
            value: 334.7736092477527,
            valueToShow: '',
            label: ''
          },
          {
            id: 16,
            tooltipValue: '16/03/2022',
            name: '16/03/2022',
            value: 443.09800585537215,
            valueToShow: 443.09800585537215,
            label: 443.09800585537215
          },
          {
            id: 17,
            tooltipValue: '17/03/2022',
            name: '17/03/2022',
            value: 356.35468304738464,
            valueToShow: '',
            label: ''
          },
          {
            id: 18,
            tooltipValue: '18/03/2022',
            name: '18/03/2022',
            value: 449.8220514022232,
            valueToShow: 449.8220514022232,
            label: ''
          },
          {
            id: 19,
            tooltipValue: '19/03/2022',
            name: '19/03/2022',
            value: 393.40045860807237,
            valueToShow: '',
            label: ''
          },
          {
            id: 20,
            tooltipValue: '20/03/2022',
            name: '20/03/2022',
            value: 367.59245716124065,
            valueToShow: 367.59245716124065,
            label: 367.59245716124065
          },
          {
            id: 21,
            tooltipValue: '21/03/2022',
            name: '21/03/2022',
            value: 342.7120480938659,
            valueToShow: '',
            label: ''
          },
          {
            id: 22,
            tooltipValue: '22/03/2022',
            name: '22/03/2022',
            value: 326.69180582399395,
            valueToShow: 326.69180582399395,
            label: ''
          },
          {
            id: 23,
            tooltipValue: '23/03/2022',
            name: '23/03/2022',
            value: 311.873317544944,
            valueToShow: '',
            label: ''
          },
          {
            id: 24,
            tooltipValue: '24/03/2022',
            name: '24/03/2022',
            value: 332.9953191300604,
            valueToShow: 332.9953191300604,
            label: 332.9953191300604
          },
          {
            id: 25,
            tooltipValue: '25/03/2022',
            name: '25/03/2022',
            value: 391.0982670262644,
            valueToShow: '',
            label: ''
          },
          {
            id: 26,
            tooltipValue: '26/03/2022',
            name: '26/03/2022',
            value: 428.02328472005223,
            valueToShow: 428.02328472005223,
            label: ''
          },
          {
            id: 27,
            tooltipValue: '27/03/2022',
            name: '27/03/2022',
            value: 380.09293693533726,
            valueToShow: '',
            label: ''
          },
          {
            id: 28,
            tooltipValue: '28/03/2022',
            name: '28/03/2022',
            value: 354.72266618314165,
            valueToShow: 354.72266618314165,
            label: 354.72266618314165
          },
          {
            id: 29,
            tooltipValue: '29/03/2022',
            name: '29/03/2022',
            value: 309.1354883593429,
            valueToShow: '',
            label: ''
          },
          {
            id: 30,
            tooltipValue: '30/03/2022',
            name: '30/03/2022',
            value: 371.51157905902005,
            valueToShow: 371.51157905902005,
            label: ''
          },
          {
            id: 31,
            tooltipValue: '31/03/2022',
            name: '31/03/2022',
            value: 332.7179728604224,
            valueToShow: '',
            label: ''
          },
          {
            id: 32,
            tooltipValue: '01/04/2022',
            name: '01/04/2022',
            value: 428.3437412521413,
            valueToShow: 428.3437412521413,
            label: 428.3437412521413
          },
          {
            id: 33,
            tooltipValue: '02/04/2022',
            name: '02/04/2022',
            value: 405.9595326068654,
            valueToShow: '',
            label: ''
          },
          {
            id: 34,
            tooltipValue: '03/04/2022',
            name: '03/04/2022',
            value: 380.20260518918184,
            valueToShow: 380.20260518918184,
            label: ''
          },
          {
            id: 35,
            tooltipValue: '04/04/2022',
            name: '04/04/2022',
            value: 431.09745442487656,
            valueToShow: '',
            label: ''
          },
          {
            id: 36,
            tooltipValue: '05/04/2022',
            name: '05/04/2022',
            value: 265.453703860723,
            valueToShow: 265.453703860723,
            label: 265.453703860723
          },
          {
            id: 37,
            tooltipValue: '06/04/2022',
            name: '06/04/2022',
            value: 365.4501295783068,
            valueToShow: '',
            label: ''
          },
          {
            id: 38,
            tooltipValue: '07/04/2022',
            name: '07/04/2022',
            value: 355.34035533703707,
            valueToShow: 355.34035533703707,
            label: ''
          },
          {
            id: 39,
            tooltipValue: '08/04/2022',
            name: '08/04/2022',
            value: 466.1233532443135,
            valueToShow: '',
            label: ''
          },
          {
            id: 40,
            tooltipValue: '09/04/2022',
            name: '09/04/2022',
            value: 536.7774803955866,
            valueToShow: 536.7774803955866,
            label: 536.7774803955866
          },
          {
            id: 41,
            tooltipValue: '10/04/2022',
            name: '10/04/2022',
            value: 651.8356655387496,
            valueToShow: '',
            label: ''
          },
          {
            id: 42,
            tooltipValue: '11/04/2022',
            name: '11/04/2022',
            value: 574.4435963340547,
            valueToShow: 574.4435963340547,
            label: ''
          },
          {
            id: 43,
            tooltipValue: '12/04/2022',
            name: '12/04/2022',
            value: 449.13923994608416,
            valueToShow: '',
            label: ''
          },
          {
            id: 44,
            tooltipValue: '13/04/2022',
            name: '13/04/2022',
            value: 382.82010672518095,
            valueToShow: 382.82010672518095,
            label: 382.82010672518095
          },
          {
            id: 45,
            tooltipValue: '14/04/2022',
            name: '14/04/2022',
            value: 486.1597090114361,
            valueToShow: '',
            label: ''
          },
          {
            id: 46,
            tooltipValue: '15/04/2022',
            name: '15/04/2022',
            value: 481.94241290916415,
            valueToShow: 481.94241290916415,
            label: ''
          },
          {
            id: 47,
            tooltipValue: '16/04/2022',
            name: '16/04/2022',
            value: 448.34728676212427,
            valueToShow: '',
            label: ''
          },
          {
            id: 48,
            tooltipValue: '17/04/2022',
            name: '17/04/2022',
            value: 442.8380452933019,
            valueToShow: 442.8380452933019,
            label: 442.8380452933019
          },
          {
            id: 49,
            tooltipValue: '18/04/2022',
            name: '18/04/2022',
            value: 307.5852297917827,
            valueToShow: '',
            label: ''
          },
          {
            id: 50,
            tooltipValue: '19/04/2022',
            name: '19/04/2022',
            value: 229.91845514086805,
            valueToShow: 229.91845514086805,
            label: ''
          },
          {
            id: 51,
            tooltipValue: '20/04/2022',
            name: '20/04/2022',
            value: 268.2247824665488,
            valueToShow: '',
            label: ''
          },
          {
            id: 52,
            tooltipValue: '21/04/2022',
            name: '21/04/2022',
            value: 304.7678417573264,
            valueToShow: 304.7678417573264,
            label: 304.7678417573264
          },
          {
            id: 53,
            tooltipValue: '22/04/2022',
            name: '22/04/2022',
            value: 362.5316817669936,
            valueToShow: '',
            label: ''
          },
          {
            id: 54,
            tooltipValue: '23/04/2022',
            name: '23/04/2022',
            value: 379.88873360167975,
            valueToShow: 379.88873360167975,
            label: ''
          },
          {
            id: 55,
            tooltipValue: '24/04/2022',
            name: '24/04/2022',
            value: 400.4265170648721,
            valueToShow: '',
            label: ''
          },
          {
            id: 56,
            tooltipValue: '25/04/2022',
            name: '25/04/2022',
            value: 391.9408676057587,
            valueToShow: 391.9408676057587,
            label: 391.9408676057587
          },
          {
            id: 57,
            tooltipValue: '26/04/2022',
            name: '26/04/2022',
            value: 352.11704389019167,
            valueToShow: '',
            label: ''
          },
          {
            id: 58,
            tooltipValue: '27/04/2022',
            name: '27/04/2022',
            value: 390.0384328136933,
            valueToShow: 390.0384328136933,
            label: ''
          },
          {
            id: 59,
            tooltipValue: '28/04/2022',
            name: '28/04/2022',
            value: 439.1059438135094,
            valueToShow: '',
            label: ''
          },
          {
            id: 60,
            tooltipValue: '29/04/2022',
            name: '29/04/2022',
            value: 428.71323215061307,
            valueToShow: 428.71323215061307,
            label: 428.71323215061307
          },
          {
            id: 61,
            tooltipValue: '30/04/2022',
            name: '30/04/2022',
            value: 494.5313978411443,
            valueToShow: '',
            label: ''
          },
          {
            id: 62,
            tooltipValue: '01/05/2022',
            name: '01/05/2022',
            value: 606.1389761901363,
            valueToShow: 606.1389761901363,
            label: ''
          },
          {
            id: 63,
            tooltipValue: '02/05/2022',
            name: '02/05/2022',
            value: 424.88581025023615,
            valueToShow: '',
            label: ''
          },
          {
            id: 64,
            tooltipValue: '03/05/2022',
            name: '03/05/2022',
            value: 414.275987417729,
            valueToShow: 414.275987417729,
            label: 414.275987417729
          },
          {
            id: 65,
            tooltipValue: '04/05/2022',
            name: '04/05/2022',
            value: 365.0161331313622,
            valueToShow: '',
            label: ''
          },
          {
            id: 66,
            tooltipValue: '05/05/2022',
            name: '05/05/2022',
            value: 429.7922948430258,
            valueToShow: 429.7922948430258,
            label: ''
          },
          {
            id: 67,
            tooltipValue: '06/05/2022',
            name: '06/05/2022',
            value: 421.38202199609236,
            valueToShow: '',
            label: ''
          },
          {
            id: 68,
            tooltipValue: '07/05/2022',
            name: '07/05/2022',
            value: 674.1157277239818,
            valueToShow: 674.1157277239818,
            label: 674.1157277239818
          },
          {
            id: 69,
            tooltipValue: '08/05/2022',
            name: '08/05/2022',
            value: 773.6435657957569,
            valueToShow: '',
            label: ''
          },
          {
            id: 70,
            tooltipValue: '09/05/2022',
            name: '09/05/2022',
            value: 707.7502522838556,
            valueToShow: 707.7502522838556,
            label: ''
          },
          {
            id: 71,
            tooltipValue: '10/05/2022',
            name: '10/05/2022',
            value: 488.54998083797193,
            valueToShow: '',
            label: ''
          },
          {
            id: 72,
            tooltipValue: '11/05/2022',
            name: '11/05/2022',
            value: 597.3860447887331,
            valueToShow: 597.3860447887331,
            label: 597.3860447887331
          },
          {
            id: 73,
            tooltipValue: '12/05/2022',
            name: '12/05/2022',
            value: 585.9713469935197,
            valueToShow: '',
            label: ''
          },
          {
            id: 74,
            tooltipValue: '13/05/2022',
            name: '13/05/2022',
            value: 571.2250050766075,
            valueToShow: 571.2250050766075,
            label: ''
          },
          {
            id: 75,
            tooltipValue: '14/05/2022',
            name: '14/05/2022',
            value: 548.9028847322074,
            valueToShow: '',
            label: ''
          },
          {
            id: 76,
            tooltipValue: '15/05/2022',
            name: '15/05/2022',
            value: 607.4804112647114,
            valueToShow: 607.4804112647114,
            label: 607.4804112647114
          },
          {
            id: 77,
            tooltipValue: '16/05/2022',
            name: '16/05/2022',
            value: 670.659244195564,
            valueToShow: '',
            label: ''
          },
          {
            id: 78,
            tooltipValue: '17/05/2022',
            name: '17/05/2022',
            value: 579.5656578210029,
            valueToShow: 579.5656578210029,
            label: ''
          },
          {
            id: 79,
            tooltipValue: '18/05/2022',
            name: '18/05/2022',
            value: 379.53517862156997,
            valueToShow: '',
            label: ''
          },
          {
            id: 80,
            tooltipValue: '19/05/2022',
            name: '19/05/2022',
            value: 586.0929009859022,
            valueToShow: 586.0929009859022,
            label: 586.0929009859022
          },
          {
            id: 81,
            tooltipValue: '20/05/2022',
            name: '20/05/2022',
            value: 503.7152106612698,
            valueToShow: '',
            label: ''
          },
          {
            id: 82,
            tooltipValue: '21/05/2022',
            name: '21/05/2022',
            value: 880.4152966001559,
            valueToShow: 880.4152966001559,
            label: ''
          },
          {
            id: 83,
            tooltipValue: '22/05/2022',
            name: '22/05/2022',
            value: 668.9984733737942,
            valueToShow: '',
            label: ''
          },
          {
            id: 84,
            tooltipValue: '23/05/2022',
            name: '23/05/2022',
            value: 568.0310895602058,
            valueToShow: 568.0310895602058,
            label: 568.0310895602058
          },
          {
            id: 85,
            tooltipValue: '24/05/2022',
            name: '24/05/2022',
            value: 545.4712434210815,
            valueToShow: '',
            label: ''
          }
        ]
      },
      {
        title: 'Hero Metric 3 CVR%',
        total: 1.278298558183264,
        round: true,
        hasPercentage: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            tooltipValue: '28/02/2022',
            valueToShow: 1.135208403930871,
            value: 1.135208403930871,
            label: 1.135208403930871
          },
          {
            id: 1,
            name: '',
            tooltipValue: '01/03/2022',
            valueToShow: '',
            value: 1.148339630145158,
            label: ''
          },
          {
            id: 2,
            name: '02/03/2022',
            tooltipValue: '02/03/2022',
            valueToShow: 1.1702127659574468,
            value: 1.1702127659574468,
            label: ''
          },
          {
            id: 3,
            name: '',
            tooltipValue: '03/03/2022',
            valueToShow: '',
            value: 0.9272014411359543,
            label: ''
          },
          {
            id: 4,
            name: '04/03/2022',
            tooltipValue: '04/03/2022',
            valueToShow: 1.0571505232090153,
            value: 1.0571505232090153,
            label: 1.0571505232090153
          },
          {
            id: 5,
            name: '',
            tooltipValue: '05/03/2022',
            valueToShow: '',
            value: 0.9344735798833324,
            label: ''
          },
          {
            id: 6,
            name: '06/03/2022',
            tooltipValue: '06/03/2022',
            valueToShow: 0.8513808332889906,
            value: 0.8513808332889906,
            label: ''
          },
          {
            id: 7,
            name: '',
            tooltipValue: '07/03/2022',
            valueToShow: '',
            value: 0.9370119729307652,
            label: ''
          },
          {
            id: 8,
            name: '08/03/2022',
            tooltipValue: '08/03/2022',
            valueToShow: 0.9910619182142361,
            value: 0.9910619182142361,
            label: 0.9910619182142361
          },
          {
            id: 9,
            name: '',
            tooltipValue: '09/03/2022',
            valueToShow: '',
            value: 1.2176774800188057,
            label: ''
          },
          {
            id: 10,
            name: '10/03/2022',
            tooltipValue: '10/03/2022',
            valueToShow: 1.0002913469942702,
            value: 1.0002913469942702,
            label: ''
          },
          {
            id: 11,
            name: '',
            tooltipValue: '11/03/2022',
            valueToShow: '',
            value: 0.8890993265993266,
            label: ''
          },
          {
            id: 12,
            name: '12/03/2022',
            tooltipValue: '12/03/2022',
            valueToShow: 0.8046467014814025,
            value: 0.8046467014814025,
            label: 0.8046467014814025
          },
          {
            id: 13,
            name: '',
            tooltipValue: '13/03/2022',
            valueToShow: '',
            value: 0.7604562737642585,
            label: ''
          },
          {
            id: 14,
            name: '14/03/2022',
            tooltipValue: '14/03/2022',
            valueToShow: 1.2011632317612846,
            value: 1.2011632317612846,
            label: ''
          },
          {
            id: 15,
            name: '',
            tooltipValue: '15/03/2022',
            valueToShow: '',
            value: 1.3284170118193757,
            label: ''
          },
          {
            id: 16,
            name: '16/03/2022',
            tooltipValue: '16/03/2022',
            valueToShow: 1.0081153283935682,
            value: 1.0081153283935682,
            label: 1.0081153283935682
          },
          {
            id: 17,
            name: '',
            tooltipValue: '17/03/2022',
            valueToShow: '',
            value: 1.5055913038045856,
            label: ''
          },
          {
            id: 18,
            name: '18/03/2022',
            tooltipValue: '18/03/2022',
            valueToShow: 1.2231155088297947,
            value: 1.2231155088297947,
            label: ''
          },
          {
            id: 19,
            name: '',
            tooltipValue: '19/03/2022',
            valueToShow: '',
            value: 1.3264171915524268,
            label: ''
          },
          {
            id: 20,
            name: '20/03/2022',
            tooltipValue: '20/03/2022',
            valueToShow: 1.447901404809101,
            value: 1.447901404809101,
            label: 1.447901404809101
          },
          {
            id: 21,
            name: '',
            tooltipValue: '21/03/2022',
            valueToShow: '',
            value: 1.6066945606694563,
            label: ''
          },
          {
            id: 22,
            name: '22/03/2022',
            tooltipValue: '22/03/2022',
            valueToShow: 1.4972097454743432,
            value: 1.4972097454743432,
            label: ''
          },
          {
            id: 23,
            name: '',
            tooltipValue: '23/03/2022',
            valueToShow: '',
            value: 1.663485137714753,
            label: ''
          },
          {
            id: 24,
            name: '24/03/2022',
            tooltipValue: '24/03/2022',
            valueToShow: 1.6695576756287946,
            value: 1.6695576756287946,
            label: 1.6695576756287946
          },
          {
            id: 25,
            name: '',
            tooltipValue: '25/03/2022',
            valueToShow: '',
            value: 1.4539579967689822,
            label: ''
          },
          {
            id: 26,
            name: '26/03/2022',
            tooltipValue: '26/03/2022',
            valueToShow: 1.3222900319674513,
            value: 1.3222900319674513,
            label: ''
          },
          {
            id: 27,
            name: '',
            tooltipValue: '27/03/2022',
            valueToShow: '',
            value: 1.54399640233848,
            label: ''
          },
          {
            id: 28,
            name: '28/03/2022',
            tooltipValue: '28/03/2022',
            valueToShow: 1.4814814814814816,
            value: 1.4814814814814816,
            label: 1.4814814814814816
          },
          {
            id: 29,
            name: '',
            tooltipValue: '29/03/2022',
            valueToShow: '',
            value: 1.8088925711129522,
            label: ''
          },
          {
            id: 30,
            name: '30/03/2022',
            tooltipValue: '30/03/2022',
            valueToShow: 1.6952573158425834,
            value: 1.6952573158425834,
            label: ''
          },
          {
            id: 31,
            name: '',
            tooltipValue: '31/03/2022',
            valueToShow: '',
            value: 2.077035592393954,
            label: ''
          },
          {
            id: 32,
            name: '01/04/2022',
            tooltipValue: '01/04/2022',
            valueToShow: 1.5422276621787026,
            value: 1.5422276621787026,
            label: 1.5422276621787026
          },
          {
            id: 33,
            name: '',
            tooltipValue: '02/04/2022',
            valueToShow: '',
            value: 1.763423816128393,
            label: ''
          },
          {
            id: 34,
            name: '03/04/2022',
            tooltipValue: '03/04/2022',
            valueToShow: 1.9585158251384183,
            value: 1.9585158251384183,
            label: ''
          },
          {
            id: 35,
            name: '',
            tooltipValue: '04/04/2022',
            valueToShow: '',
            value: 1.4231499051233396,
            label: ''
          },
          {
            id: 36,
            name: '05/04/2022',
            tooltipValue: '05/04/2022',
            valueToShow: 2.1598858928207565,
            value: 2.1598858928207565,
            label: 2.1598858928207565
          },
          {
            id: 37,
            name: '',
            tooltipValue: '06/04/2022',
            valueToShow: '',
            value: 1.5963262628471462,
            label: ''
          },
          {
            id: 38,
            name: '07/04/2022',
            tooltipValue: '07/04/2022',
            valueToShow: 1.4758113900796082,
            value: 1.4758113900796082,
            label: ''
          },
          {
            id: 39,
            name: '',
            tooltipValue: '08/04/2022',
            valueToShow: '',
            value: 1.1924837388581067,
            label: ''
          },
          {
            id: 40,
            name: '09/04/2022',
            tooltipValue: '09/04/2022',
            valueToShow: 1.011288805268109,
            value: 1.011288805268109,
            label: 1.011288805268109
          },
          {
            id: 41,
            name: '',
            tooltipValue: '10/04/2022',
            valueToShow: '',
            value: 0.8517274472168906,
            label: ''
          },
          {
            id: 42,
            name: '11/04/2022',
            tooltipValue: '11/04/2022',
            valueToShow: 0.8625121380019422,
            value: 0.8625121380019422,
            label: ''
          },
          {
            id: 43,
            name: '',
            tooltipValue: '12/04/2022',
            valueToShow: '',
            value: 1.1760448706350644,
            label: ''
          },
          {
            id: 44,
            name: '13/04/2022',
            tooltipValue: '13/04/2022',
            valueToShow: 1.310985728509791,
            value: 1.310985728509791,
            label: 1.310985728509791
          },
          {
            id: 45,
            name: '',
            tooltipValue: '14/04/2022',
            valueToShow: '',
            value: 1.1480986514396794,
            label: ''
          },
          {
            id: 46,
            name: '15/04/2022',
            tooltipValue: '15/04/2022',
            valueToShow: 1.247614853955673,
            value: 1.247614853955673,
            label: ''
          },
          {
            id: 47,
            name: '',
            tooltipValue: '16/04/2022',
            valueToShow: '',
            value: 1.387079469691164,
            label: ''
          },
          {
            id: 48,
            name: '17/04/2022',
            tooltipValue: '17/04/2022',
            valueToShow: 1.3367729831144466,
            value: 1.3367729831144466,
            label: 1.3367729831144466
          },
          {
            id: 49,
            name: '',
            tooltipValue: '18/04/2022',
            valueToShow: '',
            value: 1.7754942767950053,
            label: ''
          },
          {
            id: 50,
            name: '19/04/2022',
            tooltipValue: '19/04/2022',
            valueToShow: 2.7185249983177444,
            value: 2.7185249983177444,
            label: ''
          },
          {
            id: 51,
            name: '',
            tooltipValue: '20/04/2022',
            valueToShow: '',
            value: 2.226733489817928,
            label: ''
          },
          {
            id: 52,
            name: '21/04/2022',
            tooltipValue: '21/04/2022',
            valueToShow: 1.918539674655408,
            value: 1.918539674655408,
            label: 1.918539674655408
          },
          {
            id: 53,
            name: '',
            tooltipValue: '22/04/2022',
            valueToShow: '',
            value: 1.7406726221755122,
            label: ''
          },
          {
            id: 54,
            name: '23/04/2022',
            tooltipValue: '23/04/2022',
            valueToShow: 1.8080400076937873,
            value: 1.8080400076937873,
            label: ''
          },
          {
            id: 55,
            name: '',
            tooltipValue: '24/04/2022',
            valueToShow: '',
            value: 1.6787300042576485,
            label: ''
          },
          {
            id: 56,
            name: '25/04/2022',
            tooltipValue: '25/04/2022',
            valueToShow: 1.5064825993386175,
            value: 1.5064825993386175,
            label: 1.5064825993386175
          },
          {
            id: 57,
            name: '',
            tooltipValue: '26/04/2022',
            valueToShow: '',
            value: 1.5792680069356575,
            label: ''
          },
          {
            id: 58,
            name: '27/04/2022',
            tooltipValue: '27/04/2022',
            valueToShow: 1.4999748326370363,
            value: 1.4999748326370363,
            label: ''
          },
          {
            id: 59,
            name: '',
            tooltipValue: '28/04/2022',
            valueToShow: '',
            value: 1.2898639041235018,
            label: ''
          },
          {
            id: 60,
            name: '29/04/2022',
            tooltipValue: '29/04/2022',
            valueToShow: 1.308271479681743,
            value: 1.308271479681743,
            label: 1.308271479681743
          },
          {
            id: 61,
            name: '',
            tooltipValue: '30/04/2022',
            valueToShow: '',
            value: 1.2161011796181442,
            label: ''
          },
          {
            id: 62,
            name: '01/05/2022',
            tooltipValue: '01/05/2022',
            valueToShow: 0.9752303458178773,
            value: 0.9752303458178773,
            label: ''
          },
          {
            id: 63,
            name: '',
            tooltipValue: '02/05/2022',
            valueToShow: '',
            value: 1.2698412698412698,
            label: ''
          },
          {
            id: 64,
            name: '03/05/2022',
            tooltipValue: '03/05/2022',
            valueToShow: 1.3246997182541642,
            value: 1.3246997182541642,
            label: 1.3246997182541642
          },
          {
            id: 65,
            name: '',
            tooltipValue: '04/05/2022',
            valueToShow: '',
            value: 1.6063861239775303,
            label: ''
          },
          {
            id: 66,
            name: '05/05/2022',
            tooltipValue: '05/05/2022',
            valueToShow: 1.440307265549984,
            value: 1.440307265549984,
            label: ''
          },
          {
            id: 67,
            name: '',
            tooltipValue: '06/05/2022',
            valueToShow: '',
            value: 1.4576442226831865,
            label: ''
          },
          {
            id: 68,
            name: '07/05/2022',
            tooltipValue: '07/05/2022',
            valueToShow: 0.8685921347117126,
            value: 0.8685921347117126,
            label: 0.8685921347117126
          },
          {
            id: 69,
            name: '',
            tooltipValue: '08/05/2022',
            valueToShow: '',
            value: 0.7462288790576195,
            label: ''
          },
          {
            id: 70,
            name: '09/05/2022',
            tooltipValue: '09/05/2022',
            valueToShow: 0.8171495277512468,
            value: 0.8171495277512468,
            label: ''
          },
          {
            id: 71,
            name: '',
            tooltipValue: '10/05/2022',
            valueToShow: '',
            value: 1.1377339694856603,
            label: ''
          },
          {
            id: 72,
            name: '11/05/2022',
            tooltipValue: '11/05/2022',
            valueToShow: 0.9539709038874313,
            value: 0.9539709038874313,
            label: 0.9539709038874313
          },
          {
            id: 73,
            name: '',
            tooltipValue: '12/05/2022',
            valueToShow: '',
            value: 0.9304017880347212,
            label: ''
          },
          {
            id: 74,
            name: '13/05/2022',
            tooltipValue: '13/05/2022',
            valueToShow: 1.013346515076619,
            value: 1.013346515076619,
            label: ''
          },
          {
            id: 75,
            name: '',
            tooltipValue: '14/05/2022',
            valueToShow: '',
            value: 0.9244992295839755,
            label: ''
          },
          {
            id: 76,
            name: '15/05/2022',
            tooltipValue: '15/05/2022',
            valueToShow: 0.907497809488046,
            value: 0.907497809488046,
            label: 0.907497809488046
          },
          {
            id: 77,
            name: '',
            tooltipValue: '16/05/2022',
            valueToShow: '',
            value: 0.9281419648590274,
            label: ''
          },
          {
            id: 78,
            name: '17/05/2022',
            tooltipValue: '17/05/2022',
            valueToShow: 1.0221847795563044,
            value: 1.0221847795563044,
            label: ''
          },
          {
            id: 79,
            name: '',
            tooltipValue: '18/05/2022',
            valueToShow: '',
            value: 1.5968063872255487,
            label: ''
          },
          {
            id: 80,
            name: '19/05/2022',
            tooltipValue: '19/05/2022',
            valueToShow: 1.034603285564488,
            value: 1.034603285564488,
            label: 1.034603285564488
          },
          {
            id: 81,
            name: '',
            tooltipValue: '20/05/2022',
            valueToShow: '',
            value: 1.200057487783846,
            label: ''
          },
          {
            id: 82,
            name: '21/05/2022',
            tooltipValue: '21/05/2022',
            valueToShow: 0.6104207543056464,
            value: 0.6104207543056464,
            label: ''
          },
          {
            id: 83,
            name: '',
            tooltipValue: '22/05/2022',
            valueToShow: '',
            value: 0.8729747725086927,
            label: ''
          },
          {
            id: 84,
            name: '23/05/2022',
            tooltipValue: '23/05/2022',
            valueToShow: 0.9675541975847518,
            value: 0.9675541975847518,
            label: 0.9675541975847518
          },
          {
            id: 85,
            name: '',
            tooltipValue: '24/05/2022',
            valueToShow: '',
            value: 0.4784688995215311,
            label: ''
          }
        ]
      },
      {
        title: 'Hero Metric 4',
        total: 24288,
        round: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            valueToShow: 249,
            tooltipValue: '28/02/2022',
            value: 249,
            label: 249,
            percentage: 1.03
          },
          {
            id: 1,
            name: '',
            valueToShow: '',
            tooltipValue: '01/03/2022',
            value: 283,
            label: '',
            percentage: 1.17
          },
          {
            id: 2,
            name: '02/03/2022',
            valueToShow: 241,
            tooltipValue: '02/03/2022',
            value: 241,
            label: '',
            percentage: 0.99
          },
          {
            id: 3,
            name: '',
            valueToShow: '',
            tooltipValue: '03/03/2022',
            value: 257,
            label: '',
            percentage: 1.06
          },
          {
            id: 4,
            name: '04/03/2022',
            valueToShow: 230,
            tooltipValue: '04/03/2022',
            value: 230,
            label: 230,
            percentage: 0.95
          },
          {
            id: 5,
            name: '',
            valueToShow: '',
            tooltipValue: '05/03/2022',
            value: 228,
            label: '',
            percentage: 0.94
          },
          {
            id: 6,
            name: '06/03/2022',
            valueToShow: 295,
            tooltipValue: '06/03/2022',
            value: 295,
            label: '',
            percentage: 1.21
          },
          {
            id: 7,
            name: '',
            valueToShow: '',
            tooltipValue: '07/03/2022',
            value: 317,
            label: '',
            percentage: 1.31
          },
          {
            id: 8,
            name: '08/03/2022',
            valueToShow: 342,
            tooltipValue: '08/03/2022',
            value: 342,
            label: 342,
            percentage: 1.41
          },
          {
            id: 9,
            name: '',
            valueToShow: '',
            tooltipValue: '09/03/2022',
            value: 347,
            label: '',
            percentage: 1.43
          },
          {
            id: 10,
            name: '10/03/2022',
            valueToShow: 270,
            tooltipValue: '10/03/2022',
            value: 270,
            label: '',
            percentage: 1.11
          },
          {
            id: 11,
            name: '',
            valueToShow: '',
            tooltipValue: '11/03/2022',
            value: 279,
            label: '',
            percentage: 1.15
          },
          {
            id: 12,
            name: '12/03/2022',
            valueToShow: 263,
            tooltipValue: '12/03/2022',
            value: 263,
            label: 263,
            percentage: 1.08
          },
          {
            id: 13,
            name: '',
            valueToShow: '',
            tooltipValue: '13/03/2022',
            value: 276,
            label: '',
            percentage: 1.14
          },
          {
            id: 14,
            name: '14/03/2022',
            valueToShow: 277,
            tooltipValue: '14/03/2022',
            value: 277,
            label: '',
            percentage: 1.14
          },
          {
            id: 15,
            name: '',
            valueToShow: '',
            tooltipValue: '15/03/2022',
            value: 357,
            label: '',
            percentage: 1.47
          },
          {
            id: 16,
            name: '16/03/2022',
            valueToShow: 268,
            tooltipValue: '16/03/2022',
            value: 268,
            label: 268,
            percentage: 1.1
          },
          {
            id: 17,
            name: '',
            valueToShow: '',
            tooltipValue: '17/03/2022',
            value: 279,
            label: '',
            percentage: 1.15
          },
          {
            id: 18,
            name: '18/03/2022',
            valueToShow: 169,
            tooltipValue: '18/03/2022',
            value: 169,
            label: '',
            percentage: 0.7
          },
          {
            id: 19,
            name: '',
            valueToShow: '',
            tooltipValue: '19/03/2022',
            value: 199,
            label: '',
            percentage: 0.82
          },
          {
            id: 20,
            name: '20/03/2022',
            valueToShow: 180,
            tooltipValue: '20/03/2022',
            value: 180,
            label: 180,
            percentage: 0.74
          },
          {
            id: 21,
            name: '',
            valueToShow: '',
            tooltipValue: '21/03/2022',
            value: 210,
            label: '',
            percentage: 0.86
          },
          {
            id: 22,
            name: '22/03/2022',
            valueToShow: 310,
            tooltipValue: '22/03/2022',
            value: 310,
            label: '',
            percentage: 1.28
          },
          {
            id: 23,
            name: '',
            valueToShow: '',
            tooltipValue: '23/03/2022',
            value: 324,
            label: '',
            percentage: 1.33
          },
          {
            id: 24,
            name: '24/03/2022',
            valueToShow: 258,
            tooltipValue: '24/03/2022',
            value: 258,
            label: 258,
            percentage: 1.06
          },
          {
            id: 25,
            name: '',
            valueToShow: '',
            tooltipValue: '25/03/2022',
            value: 226,
            label: '',
            percentage: 0.93
          },
          {
            id: 26,
            name: '26/03/2022',
            valueToShow: 214,
            tooltipValue: '26/03/2022',
            value: 214,
            label: '',
            percentage: 0.88
          },
          {
            id: 27,
            name: '',
            valueToShow: '',
            tooltipValue: '27/03/2022',
            value: 286,
            label: '',
            percentage: 1.18
          },
          {
            id: 28,
            name: '28/03/2022',
            valueToShow: 309,
            tooltipValue: '28/03/2022',
            value: 309,
            label: 309,
            percentage: 1.27
          },
          {
            id: 29,
            name: '',
            valueToShow: '',
            tooltipValue: '29/03/2022',
            value: 315,
            label: '',
            percentage: 1.3
          },
          {
            id: 30,
            name: '30/03/2022',
            valueToShow: 315,
            tooltipValue: '30/03/2022',
            value: 315,
            label: '',
            percentage: 1.3
          },
          {
            id: 31,
            name: '',
            valueToShow: '',
            tooltipValue: '31/03/2022',
            value: 251,
            label: '',
            percentage: 1.03
          },
          {
            id: 32,
            name: '01/04/2022',
            valueToShow: 237,
            tooltipValue: '01/04/2022',
            value: 237,
            label: 237,
            percentage: 0.98
          },
          {
            id: 33,
            name: '',
            valueToShow: '',
            tooltipValue: '02/04/2022',
            value: 202,
            label: '',
            percentage: 0.83
          },
          {
            id: 34,
            name: '03/04/2022',
            valueToShow: 267,
            tooltipValue: '03/04/2022',
            value: 267,
            label: '',
            percentage: 1.1
          },
          {
            id: 35,
            name: '',
            valueToShow: '',
            tooltipValue: '04/04/2022',
            value: 270,
            label: '',
            percentage: 1.11
          },
          {
            id: 36,
            name: '05/04/2022',
            valueToShow: 363,
            tooltipValue: '05/04/2022',
            value: 363,
            label: 363,
            percentage: 1.49
          },
          {
            id: 37,
            name: '',
            valueToShow: '',
            tooltipValue: '06/04/2022',
            value: 227,
            label: '',
            percentage: 0.93
          },
          {
            id: 38,
            name: '07/04/2022',
            valueToShow: 292,
            tooltipValue: '07/04/2022',
            value: 292,
            label: '',
            percentage: 1.2
          },
          {
            id: 39,
            name: '',
            valueToShow: '',
            tooltipValue: '08/04/2022',
            value: 243,
            label: '',
            percentage: 1
          },
          {
            id: 40,
            name: '09/04/2022',
            valueToShow: 185,
            tooltipValue: '09/04/2022',
            value: 185,
            label: 185,
            percentage: 0.76
          },
          {
            id: 41,
            name: '',
            valueToShow: '',
            tooltipValue: '10/04/2022',
            value: 170,
            label: '',
            percentage: 0.7
          },
          {
            id: 42,
            name: '11/04/2022',
            valueToShow: 200,
            tooltipValue: '11/04/2022',
            value: 200,
            label: '',
            percentage: 0.82
          },
          {
            id: 43,
            name: '',
            valueToShow: '',
            tooltipValue: '12/04/2022',
            value: 267,
            label: '',
            percentage: 1.1
          },
          {
            id: 44,
            name: '13/04/2022',
            valueToShow: 267,
            tooltipValue: '13/04/2022',
            value: 267,
            label: 267,
            percentage: 1.1
          },
          {
            id: 45,
            name: '',
            valueToShow: '',
            tooltipValue: '14/04/2022',
            value: 251,
            label: '',
            percentage: 1.03
          },
          {
            id: 46,
            name: '15/04/2022',
            valueToShow: 205,
            tooltipValue: '15/04/2022',
            value: 205,
            label: '',
            percentage: 0.84
          },
          {
            id: 47,
            name: '',
            valueToShow: '',
            tooltipValue: '16/04/2022',
            value: 223,
            label: '',
            percentage: 0.92
          },
          {
            id: 48,
            name: '17/04/2022',
            valueToShow: 252,
            tooltipValue: '17/04/2022',
            value: 252,
            label: 252,
            percentage: 1.04
          },
          {
            id: 49,
            name: '',
            valueToShow: '',
            tooltipValue: '18/04/2022',
            value: 403,
            label: '',
            percentage: 1.66
          },
          {
            id: 50,
            name: '19/04/2022',
            valueToShow: 587,
            tooltipValue: '19/04/2022',
            value: 587,
            label: '',
            percentage: 2.42
          },
          {
            id: 51,
            name: '',
            valueToShow: '',
            tooltipValue: '20/04/2022',
            value: 456,
            label: '',
            percentage: 1.88
          },
          {
            id: 52,
            name: '21/04/2022',
            valueToShow: 357,
            tooltipValue: '21/04/2022',
            value: 357,
            label: 357,
            percentage: 1.47
          },
          {
            id: 53,
            name: '',
            valueToShow: '',
            tooltipValue: '22/04/2022',
            value: 311,
            label: '',
            percentage: 1.28
          },
          {
            id: 54,
            name: '23/04/2022',
            valueToShow: 447,
            tooltipValue: '23/04/2022',
            value: 447,
            label: '',
            percentage: 1.84
          },
          {
            id: 55,
            name: '',
            valueToShow: '',
            tooltipValue: '24/04/2022',
            value: 458,
            label: '',
            percentage: 1.89
          },
          {
            id: 56,
            name: '25/04/2022',
            valueToShow: 457,
            tooltipValue: '25/04/2022',
            value: 457,
            label: 457,
            percentage: 1.88
          },
          {
            id: 57,
            name: '',
            valueToShow: '',
            tooltipValue: '26/04/2022',
            value: 433,
            label: '',
            percentage: 1.78
          },
          {
            id: 58,
            name: '27/04/2022',
            valueToShow: 408,
            tooltipValue: '27/04/2022',
            value: 408,
            label: '',
            percentage: 1.68
          },
          {
            id: 59,
            name: '',
            valueToShow: '',
            tooltipValue: '28/04/2022',
            value: 392,
            label: '',
            percentage: 1.61
          },
          {
            id: 60,
            name: '29/04/2022',
            valueToShow: 317,
            tooltipValue: '29/04/2022',
            value: 317,
            label: 317,
            percentage: 1.31
          },
          {
            id: 61,
            name: '',
            valueToShow: '',
            tooltipValue: '30/04/2022',
            value: 328,
            label: '',
            percentage: 1.35
          },
          {
            id: 62,
            name: '01/05/2022',
            valueToShow: 350,
            tooltipValue: '01/05/2022',
            value: 350,
            label: '',
            percentage: 1.44
          },
          {
            id: 63,
            name: '',
            valueToShow: '',
            tooltipValue: '02/05/2022',
            value: 435,
            label: '',
            percentage: 1.79
          },
          {
            id: 64,
            name: '03/05/2022',
            valueToShow: 445,
            tooltipValue: '03/05/2022',
            value: 445,
            label: 445,
            percentage: 1.83
          },
          {
            id: 65,
            name: '',
            valueToShow: '',
            tooltipValue: '04/05/2022',
            value: 498,
            label: '',
            percentage: 2.05
          },
          {
            id: 66,
            name: '05/05/2022',
            valueToShow: 335,
            tooltipValue: '05/05/2022',
            value: 335,
            label: '',
            percentage: 1.38
          },
          {
            id: 67,
            name: '',
            valueToShow: '',
            tooltipValue: '06/05/2022',
            value: 326,
            label: '',
            percentage: 1.34
          },
          {
            id: 68,
            name: '07/05/2022',
            valueToShow: 271,
            tooltipValue: '07/05/2022',
            value: 271,
            label: 271,
            percentage: 1.12
          },
          {
            id: 69,
            name: '',
            valueToShow: '',
            tooltipValue: '08/05/2022',
            value: 218,
            label: '',
            percentage: 0.9
          },
          {
            id: 70,
            name: '09/05/2022',
            valueToShow: 227,
            tooltipValue: '09/05/2022',
            value: 227,
            label: '',
            percentage: 0.93
          },
          {
            id: 71,
            name: '',
            valueToShow: '',
            tooltipValue: '10/05/2022',
            value: 286,
            label: '',
            percentage: 1.18
          },
          {
            id: 72,
            name: '11/05/2022',
            valueToShow: 280,
            tooltipValue: '11/05/2022',
            value: 280,
            label: 280,
            percentage: 1.15
          },
          {
            id: 73,
            name: '',
            valueToShow: '',
            tooltipValue: '12/05/2022',
            value: 228,
            label: '',
            percentage: 0.94
          },
          {
            id: 74,
            name: '13/05/2022',
            valueToShow: 220,
            tooltipValue: '13/05/2022',
            value: 220,
            label: '',
            percentage: 0.91
          },
          {
            id: 75,
            name: '',
            valueToShow: '',
            tooltipValue: '14/05/2022',
            value: 211,
            label: '',
            percentage: 0.87
          },
          {
            id: 76,
            name: '15/05/2022',
            valueToShow: 226,
            tooltipValue: '15/05/2022',
            value: 226,
            label: 226,
            percentage: 0.93
          },
          {
            id: 77,
            name: '',
            valueToShow: '',
            tooltipValue: '16/05/2022',
            value: 226,
            label: '',
            percentage: 0.93
          },
          {
            id: 78,
            name: '17/05/2022',
            valueToShow: 276,
            tooltipValue: '17/05/2022',
            value: 276,
            label: '',
            percentage: 1.14
          },
          {
            id: 79,
            name: '',
            valueToShow: '',
            tooltipValue: '18/05/2022',
            value: 288,
            label: '',
            percentage: 1.19
          },
          {
            id: 80,
            name: '19/05/2022',
            valueToShow: 204,
            tooltipValue: '19/05/2022',
            value: 204,
            label: 204,
            percentage: 0.84
          },
          {
            id: 81,
            name: '',
            valueToShow: '',
            tooltipValue: '20/05/2022',
            value: 221,
            label: '',
            percentage: 0.91
          },
          {
            id: 82,
            name: '21/05/2022',
            valueToShow: 117,
            tooltipValue: '21/05/2022',
            value: 117,
            label: '',
            percentage: 0.48
          },
          {
            id: 83,
            name: '',
            valueToShow: '',
            tooltipValue: '22/05/2022',
            value: 138,
            label: '',
            percentage: 0.57
          },
          {
            id: 84,
            name: '23/05/2022',
            valueToShow: 159,
            tooltipValue: '23/05/2022',
            value: 159,
            label: 159,
            percentage: 0.65
          },
          {
            id: 85,
            name: '',
            valueToShow: '',
            tooltipValue: '24/05/2022',
            value: 4,
            label: '',
            percentage: 0.02
          }
        ]
      },
      {
        title: 'Hero Metric 4 CPQ',
        total: 321.6178641637318,
        round: true,
        data: [
          {
            id: 0,
            tooltipValue: '28/02/2022',
            name: '28/02/2022',
            value: 429.3694390275527,
            valueToShow: 429.3694390275527,
            label: 429.3694390275527
          },
          {
            id: 1,
            tooltipValue: '01/03/2022',
            name: '01/03/2022',
            value: 364.2515070888579,
            valueToShow: '',
            label: ''
          },
          {
            id: 2,
            tooltipValue: '02/03/2022',
            name: '02/03/2022',
            value: 403.44019447621037,
            valueToShow: 403.44019447621037,
            label: ''
          },
          {
            id: 3,
            tooltipValue: '03/03/2022',
            name: '03/03/2022',
            value: 363.39060118127657,
            valueToShow: '',
            label: ''
          },
          {
            id: 4,
            tooltipValue: '04/03/2022',
            name: '04/03/2022',
            value: 391.1489911706389,
            valueToShow: 391.1489911706389,
            label: 391.1489911706389
          },
          {
            id: 5,
            tooltipValue: '05/03/2022',
            name: '05/03/2022',
            value: 397.0647379124717,
            valueToShow: '',
            label: ''
          },
          {
            id: 6,
            tooltipValue: '06/03/2022',
            name: '06/03/2022',
            value: 290.7195697411832,
            valueToShow: 290.7195697411832,
            label: ''
          },
          {
            id: 7,
            tooltipValue: '07/03/2022',
            name: '07/03/2022',
            value: 307.7816539758975,
            valueToShow: '',
            label: ''
          },
          {
            id: 8,
            tooltipValue: '08/03/2022',
            name: '08/03/2022',
            value: 303.3003621903678,
            valueToShow: 303.3003621903678,
            label: 303.3003621903678
          },
          {
            id: 9,
            tooltipValue: '09/03/2022',
            name: '09/03/2022',
            value: 304.66258554212664,
            valueToShow: '',
            label: ''
          },
          {
            id: 10,
            tooltipValue: '10/03/2022',
            name: '10/03/2022',
            value: 372.8799374877027,
            valueToShow: 372.8799374877027,
            label: ''
          },
          {
            id: 11,
            tooltipValue: '11/03/2022',
            name: '11/03/2022',
            value: 311.35163263433134,
            valueToShow: '',
            label: ''
          },
          {
            id: 12,
            tooltipValue: '12/03/2022',
            name: '12/03/2022',
            value: 331.4265499541061,
            valueToShow: 331.4265499541061,
            label: 331.4265499541061
          },
          {
            id: 13,
            tooltipValue: '13/03/2022',
            name: '13/03/2022',
            value: 290.9764440536391,
            valueToShow: '',
            label: ''
          },
          {
            id: 14,
            tooltipValue: '14/03/2022',
            name: '14/03/2022',
            value: 277.3064063265785,
            valueToShow: 277.3064063265785,
            label: ''
          },
          {
            id: 15,
            tooltipValue: '15/03/2022',
            name: '15/03/2022',
            value: 246.6259362245349,
            valueToShow: '',
            label: ''
          },
          {
            id: 16,
            tooltipValue: '16/03/2022',
            name: '16/03/2022',
            value: 330.6701536234121,
            valueToShow: 330.6701536234121,
            label: 330.6701536234121
          },
          {
            id: 17,
            tooltipValue: '17/03/2022',
            name: '17/03/2022',
            value: 307.8189197649452,
            valueToShow: '',
            label: ''
          },
          {
            id: 18,
            tooltipValue: '18/03/2022',
            name: '18/03/2022',
            value: 473.777071891099,
            valueToShow: 473.777071891099,
            label: ''
          },
          {
            id: 19,
            tooltipValue: '19/03/2022',
            name: '19/03/2022',
            value: 353.8627240745978,
            valueToShow: '',
            label: ''
          },
          {
            id: 20,
            tooltipValue: '20/03/2022',
            name: '20/03/2022',
            value: 343.08629335049125,
            valueToShow: 343.08629335049125,
            label: 343.08629335049125
          },
          {
            id: 21,
            tooltipValue: '21/03/2022',
            name: '21/03/2022',
            value: 313.3367296858203,
            valueToShow: '',
            label: ''
          },
          {
            id: 22,
            tooltipValue: '22/03/2022',
            name: '22/03/2022',
            value: 231.8457976815441,
            valueToShow: 231.8457976815441,
            label: ''
          },
          {
            id: 23,
            tooltipValue: '23/03/2022',
            name: '23/03/2022',
            value: 234.8675601264393,
            valueToShow: '',
            label: ''
          },
          {
            id: 24,
            tooltipValue: '24/03/2022',
            name: '24/03/2022',
            value: 298.1469717792401,
            valueToShow: 298.1469717792401,
            label: 298.1469717792401
          },
          {
            id: 25,
            tooltipValue: '25/03/2022',
            name: '25/03/2022',
            value: 342.6436144743378,
            valueToShow: '',
            label: ''
          },
          {
            id: 26,
            tooltipValue: '26/03/2022',
            name: '26/03/2022',
            value: 364.0198028927547,
            valueToShow: 364.0198028927547,
            label: ''
          },
          {
            id: 27,
            tooltipValue: '27/03/2022',
            name: '27/03/2022',
            value: 273.7732342960821,
            valueToShow: '',
            label: ''
          },
          {
            id: 28,
            tooltipValue: '28/03/2022',
            name: '28/03/2022',
            value: 247.96147539015726,
            valueToShow: 247.96147539015726,
            label: 247.96147539015726
          },
          {
            id: 29,
            tooltipValue: '29/03/2022',
            name: '29/03/2022',
            value: 257.12221571475504,
            valueToShow: '',
            label: ''
          },
          {
            id: 30,
            tooltipValue: '30/03/2022',
            name: '30/03/2022',
            value: 297.20926324721603,
            valueToShow: 297.20926324721603,
            label: ''
          },
          {
            id: 31,
            tooltipValue: '31/03/2022',
            name: '31/03/2022',
            value: 282.3463275668126,
            valueToShow: '',
            label: ''
          },
          {
            id: 32,
            tooltipValue: '01/04/2022',
            name: '01/04/2022',
            value: 341.59057846689745,
            valueToShow: 341.59057846689745,
            label: 341.59057846689745
          },
          {
            id: 33,
            tooltipValue: '02/04/2022',
            name: '02/04/2022',
            value: 357.7267168515943,
            valueToShow: '',
            label: ''
          },
          {
            id: 34,
            tooltipValue: '03/04/2022',
            name: '03/04/2022',
            value: 337.4832113477007,
            valueToShow: 337.4832113477007,
            label: ''
          },
          {
            id: 35,
            tooltipValue: '04/04/2022',
            name: '04/04/2022',
            value: 335.2980201082373,
            valueToShow: '',
            label: ''
          },
          {
            id: 36,
            tooltipValue: '05/04/2022',
            name: '05/04/2022',
            value: 232.5462199110466,
            valueToShow: 232.5462199110466,
            label: 232.5462199110466
          },
          {
            id: 37,
            tooltipValue: '06/04/2022',
            name: '06/04/2022',
            value: 352.5708298574854,
            valueToShow: '',
            label: ''
          },
          {
            id: 38,
            tooltipValue: '07/04/2022',
            name: '07/04/2022',
            value: 293.27748505556826,
            valueToShow: 293.27748505556826,
            label: ''
          },
          {
            id: 39,
            tooltipValue: '08/04/2022',
            name: '08/04/2022',
            value: 379.8042137546258,
            valueToShow: '',
            label: ''
          },
          {
            id: 40,
            tooltipValue: '09/04/2022',
            name: '09/04/2022',
            value: 499.057981773194,
            valueToShow: 499.057981773194,
            label: 499.057981773194
          },
          {
            id: 41,
            tooltipValue: '10/04/2022',
            name: '10/04/2022',
            value: 544.4744970970733,
            valueToShow: '',
            label: ''
          },
          {
            id: 42,
            tooltipValue: '11/04/2022',
            name: '11/04/2022',
            value: 433.70491523221136,
            valueToShow: 433.70491523221136,
            label: ''
          },
          {
            id: 43,
            tooltipValue: '12/04/2022',
            name: '12/04/2022',
            value: 328.02304041006147,
            valueToShow: '',
            label: ''
          },
          {
            id: 44,
            tooltipValue: '13/04/2022',
            name: '13/04/2022',
            value: 339.8066115875202,
            valueToShow: 339.8066115875202,
            label: 339.8066115875202
          },
          {
            id: 45,
            tooltipValue: '14/04/2022',
            name: '14/04/2022',
            value: 366.0724502117985,
            valueToShow: '',
            label: ''
          },
          {
            id: 46,
            tooltipValue: '15/04/2022',
            name: '15/04/2022',
            value: 399.6595619246727,
            valueToShow: 399.6595619246727,
            label: ''
          },
          {
            id: 47,
            tooltipValue: '16/04/2022',
            name: '16/04/2022',
            value: 363.9051968786749,
            valueToShow: '',
            label: ''
          },
          {
            id: 48,
            tooltipValue: '17/04/2022',
            name: '17/04/2022',
            value: 300.49724502045484,
            valueToShow: 300.49724502045484,
            label: 300.49724502045484
          },
          {
            id: 49,
            tooltipValue: '18/04/2022',
            name: '18/04/2022',
            value: 208.36418792346572,
            valueToShow: '',
            label: ''
          },
          {
            id: 50,
            tooltipValue: '19/04/2022',
            name: '19/04/2022',
            value: 158.24029961995006,
            valueToShow: 158.24029961995006,
            label: ''
          },
          {
            id: 51,
            tooltipValue: '20/04/2022',
            name: '20/04/2022',
            value: 206.46249703017244,
            valueToShow: '',
            label: ''
          },
          {
            id: 52,
            tooltipValue: '21/04/2022',
            name: '21/04/2022',
            value: 263.7906529496187,
            valueToShow: 263.7906529496187,
            label: 263.7906529496187
          },
          {
            id: 53,
            tooltipValue: '22/04/2022',
            name: '22/04/2022',
            value: 308.9096323738048,
            valueToShow: '',
            label: ''
          },
          {
            id: 54,
            tooltipValue: '23/04/2022',
            name: '23/04/2022',
            value: 239.66134871515368,
            valueToShow: 239.66134871515368,
            label: ''
          },
          {
            id: 55,
            tooltipValue: '24/04/2022',
            name: '24/04/2022',
            value: 241.30506268538144,
            valueToShow: '',
            label: ''
          },
          {
            id: 56,
            tooltipValue: '25/04/2022',
            name: '25/04/2022',
            value: 246.14229541105632,
            valueToShow: 246.14229541105632,
            label: 246.14229541105632
          },
          {
            id: 57,
            tooltipValue: '26/04/2022',
            name: '26/04/2022',
            value: 274.04952376673117,
            valueToShow: '',
            label: ''
          },
          {
            id: 58,
            tooltipValue: '27/04/2022',
            name: '27/04/2022',
            value: 284.88101220215833,
            valueToShow: 284.88101220215833,
            label: ''
          },
          {
            id: 59,
            tooltipValue: '28/04/2022',
            name: '28/04/2022',
            value: 284.5227288995698,
            valueToShow: '',
            label: ''
          },
          {
            id: 60,
            tooltipValue: '29/04/2022',
            name: '29/04/2022',
            value: 331.3398797378555,
            valueToShow: 331.3398797378555,
            label: 331.3398797378555
          },
          {
            id: 61,
            tooltipValue: '30/04/2022',
            name: '30/04/2022',
            value: 301.54353526899047,
            valueToShow: '',
            label: ''
          },
          {
            id: 62,
            tooltipValue: '01/05/2022',
            name: '01/05/2022',
            value: 282.2875803399778,
            valueToShow: 282.2875803399778,
            label: ''
          },
          {
            id: 63,
            tooltipValue: '02/05/2022',
            name: '02/05/2022',
            value: 242.23374929208865,
            valueToShow: '',
            label: ''
          },
          {
            id: 64,
            tooltipValue: '03/05/2022',
            name: '03/05/2022',
            value: 249.49654972573344,
            valueToShow: 249.49654972573344,
            label: 249.49654972573344
          },
          {
            id: 65,
            tooltipValue: '04/05/2022',
            name: '04/05/2022',
            value: 238.94630401771906,
            valueToShow: '',
            label: ''
          },
          {
            id: 66,
            tooltipValue: '05/05/2022',
            name: '05/05/2022',
            value: 346.3997600227372,
            valueToShow: 346.3997600227372,
            label: ''
          },
          {
            id: 67,
            tooltipValue: '06/05/2022',
            name: '06/05/2022',
            value: 336.07155128522703,
            valueToShow: '',
            label: ''
          },
          {
            id: 68,
            tooltipValue: '07/05/2022',
            name: '07/05/2022',
            value: 405.4644413985573,
            valueToShow: 405.4644413985573,
            label: 405.4644413985573
          },
          {
            id: 69,
            tooltipValue: '08/05/2022',
            name: '08/05/2022',
            value: 496.83531748351356,
            valueToShow: '',
            label: ''
          },
          {
            id: 70,
            tooltipValue: '09/05/2022',
            name: '09/05/2022',
            value: 480.1477482454351,
            valueToShow: 480.1477482454351,
            label: ''
          },
          {
            id: 71,
            tooltipValue: '10/05/2022',
            name: '10/05/2022',
            value: 370.6830274190207,
            valueToShow: '',
            label: ''
          },
          {
            id: 72,
            tooltipValue: '11/05/2022',
            name: '11/05/2022',
            value: 426.70431770623793,
            valueToShow: 426.70431770623793,
            label: 426.70431770623793
          },
          {
            id: 73,
            tooltipValue: '12/05/2022',
            name: '12/05/2022',
            value: 460.0389083852632,
            valueToShow: '',
            label: ''
          },
          {
            id: 74,
            tooltipValue: '13/05/2022',
            name: '13/05/2022',
            value: 425.8222765116529,
            valueToShow: 425.8222765116529,
            label: ''
          },
          {
            id: 75,
            tooltipValue: '14/05/2022',
            name: '14/05/2022',
            value: 374.60670806368665,
            valueToShow: '',
            label: ''
          },
          {
            id: 76,
            tooltipValue: '15/05/2022',
            name: '15/05/2022',
            value: 389.7551311211644,
            valueToShow: 389.7551311211644,
            label: 389.7551311211644
          },
          {
            id: 77,
            tooltipValue: '16/05/2022',
            name: '16/05/2022',
            value: 471.8354859605959,
            valueToShow: '',
            label: ''
          },
          {
            id: 78,
            tooltipValue: '17/05/2022',
            name: '17/05/2022',
            value: 382.17735407037145,
            valueToShow: 382.17735407037145,
            label: ''
          },
          {
            id: 79,
            tooltipValue: '18/05/2022',
            name: '18/05/2022',
            value: 326.82195936857414,
            valueToShow: '',
            label: ''
          },
          {
            id: 80,
            tooltipValue: '19/05/2022',
            name: '19/05/2022',
            value: 425.2046536564389,
            valueToShow: 425.2046536564389,
            label: 425.2046536564389
          },
          {
            id: 81,
            tooltipValue: '20/05/2022',
            name: '20/05/2022',
            value: 380.6354759295568,
            valueToShow: '',
            label: ''
          },
          {
            id: 82,
            tooltipValue: '21/05/2022',
            name: '21/05/2022',
            value: 632.0930334565222,
            valueToShow: 632.0930334565222,
            label: ''
          },
          {
            id: 83,
            tooltipValue: '22/05/2022',
            name: '22/05/2022',
            value: 572.0421728848385,
            valueToShow: '',
            label: ''
          },
          {
            id: 84,
            tooltipValue: '23/05/2022',
            name: '23/05/2022',
            value: 475.14550258809663,
            valueToShow: 475.14550258809663,
            label: 475.14550258809663
          },
          {
            id: 85,
            tooltipValue: '24/05/2022',
            name: '24/05/2022',
            value: 545.4712434210815,
            valueToShow: '',
            label: ''
          }
        ]
      },
      {
        title: 'Hero Metric 4 CVR%',
        total: 1.7260015221900775,
        round: true,
        hasPercentage: true,
        data: [
          {
            id: 0,
            name: '28/02/2022',
            tooltipValue: '28/02/2022',
            valueToShow: 1.4063029481531684,
            value: 1.4063029481531684,
            label: 1.4063029481531684
          },
          {
            id: 1,
            name: '',
            tooltipValue: '01/03/2022',
            valueToShow: '',
            value: 1.4068403261085705,
            label: ''
          },
          {
            id: 2,
            name: '02/03/2022',
            tooltipValue: '02/03/2022',
            valueToShow: 1.2819148936170213,
            value: 1.2819148936170213,
            label: ''
          },
          {
            id: 3,
            name: '',
            tooltipValue: '03/03/2022',
            valueToShow: '',
            value: 1.3616615449825156,
            label: ''
          },
          {
            id: 4,
            name: '04/03/2022',
            tooltipValue: '04/03/2022',
            valueToShow: 1.2342366514623022,
            value: 1.2342366514623022,
            label: 1.2342366514623022
          },
          {
            id: 5,
            name: '',
            tooltipValue: '05/03/2022',
            valueToShow: '',
            value: 1.291272583111514,
            label: ''
          },
          {
            id: 6,
            name: '06/03/2022',
            tooltipValue: '06/03/2022',
            valueToShow: 1.5697334113765764,
            value: 1.5697334113765764,
            label: ''
          },
          {
            id: 7,
            name: '',
            tooltipValue: '07/03/2022',
            valueToShow: '',
            value: 1.5001656334295586,
            label: ''
          },
          {
            id: 8,
            name: '08/03/2022',
            tooltipValue: '08/03/2022',
            valueToShow: 1.5838466169591998,
            value: 1.5838466169591998,
            label: 1.5838466169591998
          },
          {
            id: 9,
            name: '',
            tooltipValue: '09/03/2022',
            valueToShow: '',
            value: 1.6314057357780913,
            label: ''
          },
          {
            id: 10,
            name: '10/03/2022',
            tooltipValue: '10/03/2022',
            valueToShow: 1.311061474215791,
            value: 1.311061474215791,
            label: ''
          },
          {
            id: 11,
            name: '',
            tooltipValue: '11/03/2022',
            valueToShow: '',
            value: 1.4678030303030303,
            label: ''
          },
          {
            id: 12,
            name: '12/03/2022',
            tooltipValue: '12/03/2022',
            valueToShow: 1.4014707449642971,
            value: 1.4014707449642971,
            label: 1.4014707449642971
          },
          {
            id: 13,
            name: '',
            tooltipValue: '13/03/2022',
            valueToShow: '',
            value: 1.4575411913814955,
            label: ''
          },
          {
            id: 14,
            name: '14/03/2022',
            tooltipValue: '14/03/2022',
            valueToShow: 1.7511695536730307,
            value: 1.7511695536730307,
            label: ''
          },
          {
            id: 15,
            name: '',
            tooltipValue: '15/03/2022',
            valueToShow: '',
            value: 1.803212445701586,
            label: ''
          },
          {
            id: 16,
            name: '16/03/2022',
            tooltipValue: '16/03/2022',
            valueToShow: 1.3508745400473814,
            value: 1.3508745400473814,
            label: 1.3508745400473814
          },
          {
            id: 17,
            name: '',
            tooltipValue: '17/03/2022',
            valueToShow: '',
            value: 1.7429874429936905,
            label: ''
          },
          {
            id: 18,
            name: '18/03/2022',
            tooltipValue: '18/03/2022',
            valueToShow: 1.1612725898440184,
            value: 1.1612725898440184,
            label: ''
          },
          {
            id: 19,
            name: '',
            tooltipValue: '19/03/2022',
            valueToShow: '',
            value: 1.474620229714709,
            label: ''
          },
          {
            id: 20,
            name: '20/03/2022',
            tooltipValue: '20/03/2022',
            valueToShow: 1.5513229337240368,
            value: 1.5513229337240368,
            label: 1.5513229337240368
          },
          {
            id: 21,
            name: '',
            tooltipValue: '21/03/2022',
            valueToShow: '',
            value: 1.7573221757322177,
            label: ''
          },
          {
            id: 22,
            name: '22/03/2022',
            tooltipValue: '22/03/2022',
            valueToShow: 2.109704641350211,
            value: 2.109704641350211,
            label: ''
          },
          {
            id: 23,
            name: '',
            tooltipValue: '23/03/2022',
            valueToShow: '',
            value: 2.208890100899918,
            label: ''
          },
          {
            id: 24,
            name: '24/03/2022',
            tooltipValue: '24/03/2022',
            valueToShow: 1.8647007805724198,
            value: 1.8647007805724198,
            label: 1.8647007805724198
          },
          {
            id: 25,
            name: '',
            tooltipValue: '25/03/2022',
            valueToShow: '',
            value: 1.6595682185342928,
            label: ''
          },
          {
            id: 26,
            name: '26/03/2022',
            tooltipValue: '26/03/2022',
            valueToShow: 1.5547805870386515,
            value: 1.5547805870386515,
            label: ''
          },
          {
            id: 27,
            name: '',
            tooltipValue: '27/03/2022',
            valueToShow: '',
            value: 2.1436066556738123,
            label: ''
          },
          {
            id: 28,
            name: '28/03/2022',
            tooltipValue: '28/03/2022',
            valueToShow: 2.119341563786008,
            value: 2.119341563786008,
            label: 2.119341563786008
          },
          {
            id: 29,
            name: '',
            tooltipValue: '29/03/2022',
            valueToShow: '',
            value: 2.1748135874067938,
            label: ''
          },
          {
            id: 30,
            name: '30/03/2022',
            tooltipValue: '30/03/2022',
            valueToShow: 2.119071644803229,
            value: 2.119071644803229,
            label: ''
          },
          {
            id: 31,
            name: '',
            tooltipValue: '31/03/2022',
            valueToShow: '',
            value: 2.4475865431496833,
            label: ''
          },
          {
            id: 32,
            name: '01/04/2022',
            tooltipValue: '01/04/2022',
            valueToShow: 1.9339045287637697,
            value: 1.9339045287637697,
            label: 1.9339045287637697
          },
          {
            id: 33,
            name: '',
            tooltipValue: '02/04/2022',
            valueToShow: '',
            value: 2.001188825044581,
            label: ''
          },
          {
            id: 34,
            name: '03/04/2022',
            tooltipValue: '03/04/2022',
            valueToShow: 2.2064292207255596,
            value: 2.2064292207255596,
            label: ''
          },
          {
            id: 35,
            name: '',
            tooltipValue: '04/04/2022',
            valueToShow: '',
            value: 1.8297641637300082,
            label: ''
          },
          {
            id: 36,
            name: '05/04/2022',
            tooltipValue: '05/04/2022',
            valueToShow: 2.4655301229369013,
            value: 2.4655301229369013,
            label: 2.4655301229369013
          },
          {
            id: 37,
            name: '',
            tooltipValue: '06/04/2022',
            valueToShow: '',
            value: 1.6546395509876812,
            label: ''
          },
          {
            id: 38,
            name: '07/04/2022',
            tooltipValue: '07/04/2022',
            valueToShow: 1.7881200244947948,
            value: 1.7881200244947948,
            label: ''
          },
          {
            id: 39,
            name: '',
            tooltipValue: '08/04/2022',
            valueToShow: '',
            value: 1.4635027704167671,
            label: ''
          },
          {
            id: 40,
            name: '09/04/2022',
            tooltipValue: '09/04/2022',
            valueToShow: 1.0877234242709315,
            value: 1.0877234242709315,
            label: 1.0877234242709315
          },
          {
            id: 41,
            name: '',
            tooltipValue: '10/04/2022',
            valueToShow: '',
            value: 1.0196737044145874,
            label: ''
          },
          {
            id: 42,
            name: '11/04/2022',
            tooltipValue: '11/04/2022',
            valueToShow: 1.1424001827840293,
            value: 1.1424001827840293,
            label: ''
          },
          {
            id: 43,
            name: '',
            tooltipValue: '12/04/2022',
            valueToShow: '',
            value: 1.6102768228695497,
            label: ''
          },
          {
            id: 44,
            name: '13/04/2022',
            tooltipValue: '13/04/2022',
            valueToShow: 1.4769332890806504,
            value: 1.4769332890806504,
            label: 1.4769332890806504
          },
          {
            id: 45,
            name: '',
            tooltipValue: '14/04/2022',
            valueToShow: '',
            value: 1.524723605880209,
            label: ''
          },
          {
            id: 46,
            name: '15/04/2022',
            tooltipValue: '15/04/2022',
            valueToShow: 1.5044767356524291,
            value: 1.5044767356524291,
            label: ''
          },
          {
            id: 47,
            name: '',
            tooltipValue: '16/04/2022',
            valueToShow: '',
            value: 1.7089432140393899,
            label: ''
          },
          {
            id: 48,
            name: '17/04/2022',
            tooltipValue: '17/04/2022',
            valueToShow: 1.9699812382739212,
            value: 1.9699812382739212,
            label: 1.9699812382739212
          },
          {
            id: 49,
            name: '',
            tooltipValue: '18/04/2022',
            valueToShow: '',
            value: 2.620967741935484,
            label: ''
          },
          {
            id: 50,
            name: '19/04/2022',
            tooltipValue: '19/04/2022',
            valueToShow: 3.9499360742884058,
            value: 3.9499360742884058,
            label: ''
          },
          {
            id: 51,
            name: '',
            tooltipValue: '20/04/2022',
            valueToShow: '',
            value: 2.892850345746368,
            label: ''
          },
          {
            id: 52,
            name: '21/04/2022',
            tooltipValue: '21/04/2022',
            valueToShow: 2.2165652551844035,
            value: 2.2165652551844035,
            label: 2.2165652551844035
          },
          {
            id: 53,
            name: '',
            tooltipValue: '22/04/2022',
            valueToShow: '',
            value: 2.0428271150814505,
            label: ''
          },
          {
            id: 54,
            name: '23/04/2022',
            tooltipValue: '23/04/2022',
            valueToShow: 2.8659357568763224,
            value: 2.8659357568763224,
            label: ''
          },
          {
            id: 55,
            name: '',
            tooltipValue: '24/04/2022',
            valueToShow: '',
            value: 2.7857186302536343,
            label: ''
          },
          {
            id: 56,
            name: '25/04/2022',
            tooltipValue: '25/04/2022',
            valueToShow: 2.398824208702955,
            value: 2.398824208702955,
            label: 2.398824208702955
          },
          {
            id: 57,
            name: '',
            tooltipValue: '26/04/2022',
            valueToShow: '',
            value: 2.0291485074277147,
            label: ''
          },
          {
            id: 58,
            name: '27/04/2022',
            tooltipValue: '27/04/2022',
            valueToShow: 2.0536568178386267,
            value: 2.0536568178386267,
            label: ''
          },
          {
            id: 59,
            name: '',
            tooltipValue: '28/04/2022',
            valueToShow: '',
            value: 1.990656104001625,
            label: ''
          },
          {
            id: 60,
            name: '29/04/2022',
            tooltipValue: '29/04/2022',
            valueToShow: 1.6927430982004592,
            value: 1.6927430982004592,
            label: 1.6927430982004592
          },
          {
            id: 61,
            name: '',
            tooltipValue: '30/04/2022',
            valueToShow: '',
            value: 1.9944059345737564,
            label: ''
          },
          {
            id: 62,
            name: '01/05/2022',
            tooltipValue: '01/05/2022',
            valueToShow: 2.0940528897929878,
            value: 2.0940528897929878,
            label: ''
          },
          {
            id: 63,
            name: '',
            tooltipValue: '02/05/2022',
            valueToShow: '',
            value: 2.227342549923195,
            label: ''
          },
          {
            id: 64,
            name: '03/05/2022',
            tooltipValue: '03/05/2022',
            valueToShow: 2.1995946814294896,
            value: 2.1995946814294896,
            label: 2.1995946814294896
          },
          {
            id: 65,
            name: '',
            tooltipValue: '04/05/2022',
            valueToShow: '',
            value: 2.4539272691435894,
            label: ''
          },
          {
            id: 66,
            name: '05/05/2022',
            tooltipValue: '05/05/2022',
            valueToShow: 1.787047903552758,
            value: 1.787047903552758,
            label: ''
          },
          {
            id: 67,
            name: '',
            tooltipValue: '06/05/2022',
            valueToShow: '',
            value: 1.8276616022873802,
            label: ''
          },
          {
            id: 68,
            name: '07/05/2022',
            tooltipValue: '07/05/2022',
            valueToShow: 1.444101033784504,
            value: 1.444101033784504,
            label: 1.444101033784504
          },
          {
            id: 69,
            name: '',
            tooltipValue: '08/05/2022',
            valueToShow: '',
            value: 1.1619849688182933,
            label: ''
          },
          {
            id: 70,
            name: '09/05/2022',
            tooltipValue: '09/05/2022',
            valueToShow: 1.2044996285683964,
            value: 1.2044996285683964,
            label: ''
          },
          {
            id: 71,
            name: '',
            tooltipValue: '10/05/2022',
            valueToShow: '',
            value: 1.4995019136999947,
            label: ''
          },
          {
            id: 72,
            name: '11/05/2022',
            tooltipValue: '11/05/2022',
            valueToShow: 1.335559265442404,
            value: 1.335559265442404,
            label: 1.335559265442404
          },
          {
            id: 73,
            name: '',
            tooltipValue: '12/05/2022',
            valueToShow: '',
            value: 1.1850927802900357,
            label: ''
          },
          {
            id: 74,
            name: '13/05/2022',
            tooltipValue: '13/05/2022',
            valueToShow: 1.3593672763222935,
            value: 1.3593672763222935,
            label: ''
          },
          {
            id: 75,
            name: '',
            tooltipValue: '14/05/2022',
            valueToShow: '',
            value: 1.354648176682075,
            label: ''
          },
          {
            id: 76,
            name: '15/05/2022',
            tooltipValue: '15/05/2022',
            valueToShow: 1.4144448616848166,
            value: 1.4144448616848166,
            label: 1.4144448616848166
          },
          {
            id: 77,
            name: '',
            tooltipValue: '16/05/2022',
            valueToShow: '',
            value: 1.3192458116864165,
            label: ''
          },
          {
            id: 78,
            name: '17/05/2022',
            tooltipValue: '17/05/2022',
            valueToShow: 1.5501263689974727,
            value: 1.5501263689974727,
            label: ''
          },
          {
            id: 79,
            name: '',
            tooltipValue: '18/05/2022',
            valueToShow: '',
            value: 1.8543558045199922,
            label: ''
          },
          {
            id: 80,
            name: '19/05/2022',
            tooltipValue: '19/05/2022',
            valueToShow: 1.4260747990213212,
            value: 1.4260747990213212,
            label: 1.4260747990213212
          },
          {
            id: 81,
            name: '',
            tooltipValue: '20/05/2022',
            valueToShow: '',
            value: 1.588100028743892,
            label: ''
          },
          {
            id: 82,
            name: '21/05/2022',
            tooltipValue: '21/05/2022',
            valueToShow: 0.8502289077828646,
            value: 0.8502289077828646,
            label: ''
          },
          {
            id: 83,
            name: '',
            tooltipValue: '22/05/2022',
            valueToShow: '',
            value: 1.0209365983576237,
            label: ''
          },
          {
            id: 84,
            name: '23/05/2022',
            tooltipValue: '23/05/2022',
            valueToShow: 1.1567001309471847,
            value: 1.1567001309471847,
            label: 1.1567001309471847
          },
          {
            id: 85,
            name: '',
            tooltipValue: '24/05/2022',
            valueToShow: '',
            value: 0.4784688995215311,
            label: ''
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.DAILY) {
    return [
      {
        total: 12312,
        title: 'Spend',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 432,
        title: 'Cost Per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 45333,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 424.2343,
        title: 'Unq CPL 1D Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 6575,
        title: 'Unique Quote CR% 1D Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 25.3
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 24.5
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 10.5
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 11
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.4
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 15.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 16.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 13.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 11.22
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 13.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 11.66
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.13
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 13.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 13.22
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 13.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 13.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          }
        ]
      },
      {
        total: 2344,
        title: 'Apps 1D Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 42342.43242,
        title: 'CPA 1D Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 12312,
        title: 'App CR% 1D Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 13.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 12.32
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 12.34
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 15.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 14.78
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 18.95
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 19.45
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 16.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 17.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 13.22
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 13.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 12.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 13.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.13
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 11.74
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 23.12
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 11.23
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 11.23
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      },
      {
        total: 12312,
        title: 'Cost per Click',
        data: [
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          },
          {
            name: '2021-06-10T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 2333.123
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 1123.232
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 1123
          }
        ]
      }
    ];
  }
  return [
    {
      total: 12312,
      title: 'Spend',
      data: [
        {
          name: 'Monday',
          value: 411.123
        }
      ]
    },
    {
      total: 432,
      title: 'Cost Per Click',
      data: [
        {
          name: 'Monday',
          value: 23444
        }
      ]
    },
    {
      total: 45333,
      title: 'Unq Quotes 1D Click',
      data: [
        {
          name: 'Monday',
          value: 32.43
        }
      ]
    },
    {
      total: 424.2343,
      title: 'Unq CPL 1D Click',
      data: [
        {
          name: 'Monday',
          value: 444
        }
      ]
    },
    {
      total: 6575,
      title: 'Unique Quote CR% 1D Click',
      data: [
        {
          name: 'Monday',
          value: 64.234
        }
      ]
    },
    {
      total: 2344,
      title: 'Apps 1D Click',
      data: [
        {
          name: 'Monday',
          value: 665
        }
      ]
    },
    {
      total: 42342.43242,
      title: 'CPA 1D Click',
      data: [
        {
          name: 'Monday',
          value: 234
        }
      ]
    },
    {
      total: 12312,
      title: 'App CR% 1D Click',
      data: [
        {
          name: 'Monday',
          value: 44.44
        }
      ]
    },
    {
      total: 12312,
      title: 'Cost per Click',
      data: [
        {
          name: 'Monday',
          value: 234
        }
      ]
    },
    {
      total: 12312,
      title: 'Cost per Click',
      data: [
        {
          name: 'Monday',
          value: 4123
        }
      ]
    },
    {
      total: 12312,
      title: 'Cost per Click',
      data: [
        {
          name: 'Monday',
          value: 34
        }
      ]
    },
    {
      total: 12312,
      title: 'Cost per Click',
      data: [
        {
          name: 'Monday',
          value: 432
        }
      ]
    },
    {
      total: 12312,
      title: 'Cost per Click',
      data: [
        {
          name: 'Monday',
          value: 1333
        }
      ]
    },
    {
      total: 12312,
      title: 'Cost per Click',
      data: [
        {
          name: 'Monday',
          value: 11.12
        }
      ]
    },
    {
      total: 12312,
      title: 'Cost per Click',
      data: [
        {
          name: 'Monday',
          value: 32
        }
      ]
    },
    {
      total: 12312,
      title: 'Cost per Click',
      data: [
        {
          name: 'Monday',
          value: 123
        }
      ]
    }
  ];
};

export const timeSeriesData = (timeframe, heroMetric) => {
  if (timeframe === TIME_FRAMES.YEARLY && heroMetric === HERO_METRICS_LABELS.HERO_METRICS_CVR) {
    return [
      {
        total: 15.09,
        title: 'Unq Quote CR% 1D Click',
        data: [
          {
            name: '2020',
            value: 14
          },
          {
            name: '2021',
            value: 63
          }
        ]
      }
    ];
  }

  if (timeframe === TIME_FRAMES.QUARTERLY && heroMetric === HERO_METRICS_LABELS.HERO_METRICS_CVR) {
    return [
      {
        total: 12312,
        title: 'Unq Quote CR% 1D Click',
        data: [
          {
            name: '2020 Q1',
            value: 55.2
          },
          {
            name: '2020 Q2',
            value: 38.0
          },
          {
            name: '2020 Q3',
            value: 34.0
          },
          {
            name: '2020 Q4',
            value: 23.6
          },
          {
            name: '2021 Q1',
            value: 22.3
          },
          {
            name: '2021 Q2',
            value: 31.3
          },
          {
            name: '2021 Q3',
            value: 29.7
          },
          {
            name: '2021 Q4',
            value: 13.0
          }
        ]
      }
    ];
  }

  if (timeframe === TIME_FRAMES.MONTHLY && heroMetric === HERO_METRICS_LABELS.HERO_METRICS_CVR) {
    return [
      {
        total: 15.09,
        title: 'Unq Quote CR% 1D Click',
        data: [
          {
            name: 'Jan 2020',
            value: 23.5
          },
          {
            name: 'Feb 2020',
            value: 15.9
          },
          {
            name: 'Mar 2020',
            value: 15.8
          },
          {
            name: 'Apr 2020',
            value: 15.5
          },
          {
            name: 'May 2020',
            value: 15.0
          },
          {
            name: 'Jun 2020',
            value: 7.51
          },
          {
            name: 'Jul 2020',
            value: 11.9
          },
          {
            name: 'Aug 2020',
            value: 17.9
          },
          {
            name: 'Sep 2020',
            value: 13.4
          },
          {
            name: 'Oct 2020',
            value: 13.8
          },
          {
            name: 'Nov 2020',
            value: 62.8
          },
          {
            name: 'Dec 2020',
            value: 34.9
          },
          {
            name: 'Jan 2021',
            value: 54.3
          },
          {
            name: 'Feb 2021',
            value: 67.0
          },
          {
            name: 'Mar 2021',
            value: 62.1
          },
          {
            name: 'Apr 2021',
            value: 10.4
          },
          {
            name: 'May 2021',
            value: 22.1
          },
          {
            name: 'Jun 2021',
            value: 11.7
          },
          {
            name: 'Jul 2021',
            value: 12.2
          },
          {
            name: 'Aug 2021',
            value: 34.6
          },
          {
            name: 'Sep 2021',
            value: 40.6
          },
          {
            name: 'Oct 2021',
            value: 58.5
          },
          {
            name: 'Nov 2021',
            value: 16.6
          },
          {
            name: 'Dec 2021',
            value: 28.8
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.YEARLY) {
    return [
      {
        total: 15.09,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: '2020',
            value: 15090
          },
          {
            name: '2021',
            value: 9570
          }
        ]
      },
      {
        total: 15.09,
        title: 'Unq Quote CR% 1D Click',
        data: [
          {
            name: '2020',
            value: 14620
          },
          {
            name: '2021',
            value: 6380
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.MONTHLY) {
    return [
      {
        total: 15.09,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: 'Jan 2020',
            value: 2350
          },
          {
            name: 'Feb 2020',
            value: 1590
          },
          {
            name: 'Mar 2020',
            value: 1580
          },
          {
            name: 'Apr 2020',
            value: 1550
          },
          {
            name: 'May 2020',
            value: 1500
          },
          {
            name: 'Jun 2020',
            value: 751
          },
          {
            name: 'Jul 2020',
            value: 1190
          },
          {
            name: 'Aug 2020',
            value: 879
          },
          {
            name: 'Sep 2020',
            value: 1340
          },
          {
            name: 'Oct 2020',
            value: 1380
          },
          {
            name: 'Nov 2020',
            value: 628
          },
          {
            name: 'Dec 2020',
            value: 349
          },
          {
            name: 'Jan 2021',
            value: 843
          },
          {
            name: 'Feb 2021',
            value: 770
          },
          {
            name: 'Mar 2021',
            value: 621
          },
          {
            name: 'Apr 2021',
            value: 1040
          },
          {
            name: 'May 2021',
            value: 921
          },
          {
            name: 'Jun 2021',
            value: 1170
          },
          {
            name: 'Jul 2021',
            value: 1220
          },
          {
            name: 'Aug 2021',
            value: 846
          },
          {
            name: 'Sep 2021',
            value: 906
          },
          {
            name: 'Oct 2021',
            value: 785
          },
          {
            name: 'Nov 2021',
            value: 166
          },
          {
            name: 'Dec 2021',
            value: 288
          }
        ]
      }
    ];
  }

  if (timeframe === TIME_FRAMES.QUARTERLY) {
    return [
      {
        total: 12312,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: '2020 Q1',
            value: 5520
          },
          {
            name: '2020 Q2',
            value: 3800
          },
          {
            name: '2020 Q3',
            value: 3400
          },
          {
            name: '2020 Q4',
            value: 2360
          },
          {
            name: '2021 Q1',
            value: 2230
          },
          {
            name: '2021 Q2',
            value: 3130
          },
          {
            name: '2021 Q3',
            value: 2970
          },
          {
            name: '2021 Q4',
            value: 1300
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.WEEKLY) {
    return [
      {
        total: 12312,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: '2021-10-03T19:02:28.000Z',
            value: 230
          },
          {
            name: '2021-10-11T19:02:28.000Z',
            value: 242
          },
          {
            name: '2021-10-18T19:02:28.000Z',
            value: 189
          },
          {
            name: '2021-10-25T19:02:28.000Z',
            value: 7
          },
          {
            name: '2021-11-01T19:02:28.000Z',
            value: 32
          },
          {
            name: '2021-11-08T19:02:28.000Z',
            value: 49
          },
          {
            name: '2021-11-15T19:02:28.000Z',
            value: 41
          },
          {
            name: '2021-11-22T19:02:28.000Z',
            value: 32
          },
          {
            name: '2021-11-29T19:02:28.000Z',
            value: 36
          },
          {
            name: '2021-12-06T19:02:28.000Z',
            value: 51
          },
          {
            name: '2021-12-13T19:02:28.000Z',
            value: 83
          },
          {
            name: '2021-12-20T19:02:28.000Z',
            value: 130
          },
          {
            name: '2021-12-27T19:02:28.000Z',
            value: 75
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.DAILY) {
    return [
      {
        total: 12312,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: '2021-05-16T19:02:28.000Z',
            value: 10
          },
          {
            name: '2021-12-01T19:02:28.000Z',
            value: 7
          },
          {
            name: '2021-12-02T19:02:28.000Z',
            value: 4
          },
          {
            name: '2021-12-03T19:02:28.000Z',
            value: 5
          },
          {
            name: '2021-12-04T19:02:28.000Z',
            value: 6
          },
          {
            name: '2021-12-05T19:02:28.000Z',
            value: 2
          },
          {
            name: '2021-12-06T19:02:28.000Z',
            value: 6
          },
          {
            name: '2021-12-07T19:02:28.000Z',
            value: 10
          },
          {
            name: '2021-12-08T19:02:28.000Z',
            value: 8
          },
          {
            name: '2021-12-09T19:02:28.000Z',
            value: 10
          },
          {
            name: '2021-12-10T19:02:28.000Z',
            value: 6
          },
          {
            name: '2021-12-11T19:02:28.000Z',
            value: 3
          },
          {
            name: '2021-12-12T19:02:28.000Z',
            value: 8
          },
          {
            name: '2021-12-13T19:02:28.000Z',
            value: 14
          },
          {
            name: '2021-12-14T19:02:28.000Z',
            value: 4
          },
          {
            name: '2021-12-15T19:02:28.000Z',
            value: 9
          },
          {
            name: '2021-12-16T19:02:28.000Z',
            value: 13
          },
          {
            name: '2021-12-17T19:02:28.000Z',
            value: 11
          },
          {
            name: '2021-12-18T19:02:28.000Z',
            value: 22
          },
          {
            name: '2021-12-19T19:02:28.000Z',
            value: 17
          },
          {
            name: '2021-12-20T19:02:28.000Z',
            value: 19
          },
          {
            name: '2021-12-21T19:02:28.000Z',
            value: 14
          },
          {
            name: '2021-12-22T19:02:28.000Z',
            value: 18
          },
          {
            name: '2021-12-23T19:02:28.000Z',
            value: 7
          },
          {
            name: '2021-12-24T19:02:28.000Z',
            value: 17
          },
          {
            name: '2021-12-25T19:02:28.000Z',
            value: 31
          },
          {
            name: '2021-12-26T19:02:28.000Z',
            value: 24
          },
          {
            name: '2021-12-27T19:02:28.000Z',
            value: 17
          },
          {
            name: '2021-12-29T19:02:28.000Z',
            value: 1
          },
          {
            name: '2021-12-28T19:02:28.000Z',
            value: 48
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.DAYOFWEEK) {
    return [
      {
        total: 12312,
        title: 'Unq Quotes 1D Click',
        data: [
          {
            name: 'Monday',
            value: 3470
          },
          {
            name: 'Tuesday',
            value: 3680
          },
          {
            name: 'Wednesday',
            value: 3550
          },
          {
            name: 'Thursday',
            value: 3570
          },
          {
            name: 'Friday',
            value: 3320
          },
          {
            name: 'Saturday',
            value: 3630
          },
          {
            name: 'Sunday',
            value: 3520
          }
        ]
      }
    ];
  }
  return [
    {
      total: 15.09,
      title: 'Unq Quotes 1D Click',
      data: [
        {
          name: '2020',
          value: 15090
        },
        {
          name: '2021',
          value: 9570
        }
      ]
    },
    {
      total: 15.09,
      title: 'Unq Quote CR% 1D Click',
      data: [
        {
          name: '2020',
          value: 14620
        },
        {
          name: '2021',
          value: 6380
        }
      ]
    }
  ];
};

export const volumeEfficiencyData = (timeframe) => {
  if (timeframe === TIME_FRAMES.YEARLY) {
    return [
      {
        title: 'Hero Metrics 1 Vs Hero Metrics 1 CPQ',
        barName: 'Unq Quotes 1D Click',
        lineName: 'Unq CPL 1D Click',
        data: [
          {
            name: '2021',
            barValue: 36,
            lineValue: 192.15
          }
        ]
      },
      {
        title: 'Hero Metrics 2 Vs Hero Metrics 2 CPQ',
        barName: 'Apps 1D Click',
        lineName: 'CPA 1D Click',
        data: [
          {
            name: '2021',
            barValue: 5,
            lineValue: 1380
          }
        ]
      },
      {
        title: 'Hero Metrics 3 Vs Hero Metrics 3 CPQ',
        barName: 'Unq Leads 28D Click',
        lineName: 'Unq CPL 28D Click',
        data: [
          {
            name: '2021',
            barValue: 38,
            lineValue: 182.4
          }
        ]
      },
      {
        title: 'Hero Metrics 4 Vs Hero Metrics 4 CPQ',
        barName: 'Unq Quote CR% 28D Click',
        lineName: 'Apps 28D Click',
        data: [
          {
            name: '2021'
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.MONTHLY) {
    return [
      {
        title: 'Hero Metrics 1 Vs Hero Metrics 1 CPQ',
        barName: 'Unq Quotes 1D Click',
        lineName: 'Unq CPL 1D Click',
        data: [
          {
            name: 'Nov 2021',
            barValue: 12,
            lineValue: 103.7
          },
          {
            name: 'Dec 2021',
            barValue: 24,
            lineValue: 263.69
          }
        ]
      },
      {
        title: 'Hero Metrics 2 Vs Hero Metrics 2 CPQ',
        barName: 'Apps 1D Click',
        lineName: 'CPA 1D Click',
        data: [
          {
            name: 'Nov 2021',
            barValue: 2,
            lineValue: 618.4
          },
          {
            name: 'Dec 2021',
            barValue: 3,
            lineValue: 1890
          }
        ]
      },
      {
        title: 'Hero Metrics 3 Vs Hero Metrics 3 CPQ',
        barName: 'Unq Leads 28D Click',
        lineName: 'Unq CPL 28D Click',
        data: [
          {
            name: 'Nov 2021',
            barValue: 13,
            lineValue: 95.14
          },
          {
            name: 'Dec 2021',
            barValue: 25,
            lineValue: 227.22
          }
        ]
      },
      {
        title: 'Hero Metrics 4 Vs Hero Metrics 4 CPQ',
        barName: 'Unq Quote CR% 28D Click',
        lineName: 'Apps 28D Click',
        data: [
          {
            name: 'Nov 2021'
          },
          {
            name: 'Dec 2021'
          }
        ]
      }
    ];
  }

  if (timeframe === TIME_FRAMES.QUARTERLY) {
    return [
      {
        title: 'Hero Metrics 1 Vs Hero Metrics 1 CPQ',
        barName: 'Unq Quotes 1D Click',
        lineName: 'Unq CPL 1D Click',
        data: [
          {
            name: '2021 Q4',
            barValue: 36,
            lineValue: 192.15
          }
        ]
      },
      {
        title: 'Hero Metrics 2 Vs Hero Metrics 2 CPQ',
        barName: 'Apps 1D Click',
        lineName: 'CPA 1D Click',
        data: [
          {
            name: '2021 Q4',
            barValue: 5,
            lineValue: 1380
          }
        ]
      },
      {
        title: 'Hero Metrics 3 Vs Hero Metrics 3 CPQ',
        barName: 'Unq Leads 28D Click',
        lineName: 'Unq CPL 28D Click',
        data: [
          {
            name: '2021 Q4',
            barValue: 38,
            lineValue: 182.04
          }
        ]
      },
      {
        title: 'Hero Metrics 4 Vs Hero Metrics 4 CPQ',
        barName: 'Unq Quote CR% 28D Click',
        lineName: 'Apps 28D Click',
        data: [
          {
            name: '2021 Q4'
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.WEEKLY) {
    return [
      {
        title: 'Hero Metrics 1 Vs Hero Metrics 1 CPQ',
        barName: 'Unq Quotes 1D Click',
        lineName: 'Unq CPL 1D Click',
        data: [
          {
            name: '2021-11-29T19:02:28.000Z',
            barValue: 36,
            lineValue: 192.15
          }
        ]
      },
      {
        title: 'Hero Metrics 2 Vs Hero Metrics 2 CPQ',
        barName: 'Apps 1D Click',
        lineName: 'CPA 1D Click',
        data: [
          {
            name: '2021-11-29T19:02:28.000Z',
            barValue: 5,
            lineValue: 1380
          }
        ]
      },
      {
        title: 'Hero Metrics 3 Vs Hero Metrics 3 CPQ',
        barName: 'Unq Leads 28D Click',
        lineName: 'Unq CPL 28D Click',
        data: [
          {
            name: '2021-11-29T19:02:28.000Z',
            barValue: 38,
            lineValue: 182.04
          }
        ]
      },
      {
        title: 'Hero Metrics 4 Vs Hero Metrics 4 CPQ',
        barName: 'Unq Quote CR% 28D Click',
        lineName: 'Apps 28D Click',
        data: [
          {
            name: '2021-11-29T19:02:28.000Z'
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.DAILY) {
    return [
      {
        title: 'Hero Metrics 1 Vs Hero Metrics 1 CPQ',
        barName: 'Unq Quotes 1D Click',
        lineName: 'Unq CPL 1D Click',
        data: [
          {},
          {
            name: '2021-11-29T19:02:28.000Z',
            barValue: 2,
            lineValue: 206.04
          },
          {
            name: '2021-11-30T19:02:28.000Z',
            barValue: 10,
            lineValue: 82.47
          },
          {
            name: '2021-12-01T19:02:28.000Z',
            barValue: 7,
            lineValue: 147.4
          },
          {
            name: '2021-12-02T19:02:28.000Z',
            barValue: 4,
            lineValue: 261.88
          },
          {
            name: '2021-12-03T19:02:28.000Z',
            barValue: 5,
            lineValue: 209.33
          },
          {
            name: '2021-12-04T19:02:28.000Z',
            barValue: 6,
            lineValue: 234.77
          },
          {
            name: '2021-12-05T19:02:28.000Z',
            barValue: 2,
            lineValue: 572.95
          }
        ]
      },
      {
        title: 'Hero Metrics 2 Vs Hero Metrics 2 CPQ',
        barName: 'Apps 1D Click',
        lineName: 'CPA 1D Click',
        data: [
          {},
          {
            name: '2021-11-29T19:02:28.000Z'
          },
          {
            name: '2021-11-30T19:02:28.000Z',
            barValue: 2,
            lineValue: 412.37
          },
          {
            name: '2021-12-01T19:02:28.000Z',
            barValue: 2,
            lineValue: 515.19
          },
          {
            name: '2021-12-02T19:02:28.000Z'
          },
          {
            name: '2021-12-03T19:02:28.000Z'
          },
          {
            name: '2021-12-04T19:02:28.000Z',
            barValue: 1,
            lineValue: 1400
          },
          {
            name: '2021-12-05T19:02:28.000Z'
          }
        ]
      },
      {
        title: 'Hero Metrics 3 Vs Hero Metrics 3 CPQ',
        barName: 'Unq Leads 28D Click',
        lineName: 'Unq CPL 28D Click',
        data: [
          {
            name: '2021-11-29T19:02:28.000Z',
            barValue: 2,
            lineValue: 206.04
          },
          {
            name: '2021-11-30T19:02:28.000Z',
            barValue: 11,
            lineValue: 74.94
          },
          {
            name: '2021-12-01T19:02:28.000Z',
            barValue: 7,
            lineValue: 147.4
          },
          {
            name: '2021-12-02T19:02:28.000Z',
            barValue: 5,
            lineValue: 209.5
          },
          {
            name: '2021-12-03T19:02:28.000Z',
            barValue: 5,
            lineValue: 209.33
          },
          {
            name: '2021-12-04T19:02:28.000Z',
            barValue: 6,
            lineValue: 234.77
          },
          {
            name: '2021-12-05T19:02:28.000Z',
            barValue: 2,
            lineValue: 572.95
          }
        ]
      },
      {
        title: 'Hero Metrics 4 Vs Hero Metrics 4 CPQ',
        barName: 'Unq Quote CR% 28D Click',
        lineName: 'Apps 28D Click',
        data: [
          {},
          {
            name: '2021-11-29T19:02:28.000Z'
          },
          {
            name: '2021-11-30T19:02:28.000Z'
          },
          {
            name: '2021-12-01T19:02:28.000Z'
          },
          {
            name: '2021-12-02T19:02:28.000Z'
          },
          {
            name: '2021-12-03T19:02:28.000Z'
          },
          {
            name: '2021-12-04T19:02:28.000Z'
          },
          {
            name: '2021-12-05T19:02:28.000Z'
          }
        ]
      }
    ];
  }
  if (timeframe === TIME_FRAMES.DAYOFWEEK) {
    return [
      {
        title: 'Hero Metrics 1 Vs Hero Metrics 1 CPQ',
        barName: 'Unq Quotes 1D Click',
        lineName: 'Unq CPL 1D Click',
        data: [
          {
            name: 'Monday',
            barValue: 2,
            lineValue: 206.04
          },
          {
            name: 'Tuesday',
            barValue: 10,
            lineValue: 82.47
          },
          {
            name: 'Wednesday',
            barValue: 6,
            lineValue: 96.45
          },
          {
            name: 'Thursday',
            barValue: 8,
            lineValue: 370
          },
          {
            name: 'Friday',
            barValue: 5,
            lineValue: 120
          },
          {
            name: 'Saturday',
            barValue: 9,
            lineValue: 196.45
          },
          {
            name: 'Sunday',
            barValue: 4,
            lineValue: 134
          }
        ]
      },
      {
        title: 'Hero Metrics 2 Vs Hero Metrics 2 CPQ',
        barName: 'Apps 1D Click',
        lineName: 'CPA 1D Click',
        data: [
          {
            name: 'Monday',
            barValue: 2,
            lineValue: 206.04
          },
          {
            name: 'Tuesday',
            barValue: 10,
            lineValue: 82.47
          },
          {
            name: 'Wednesday',
            barValue: 6,
            lineValue: 96.45
          },
          {
            name: 'Thursday',
            barValue: 8,
            lineValue: 370
          },
          {
            name: 'Friday',
            barValue: 5,
            lineValue: 120
          },
          {
            name: 'Saturday',
            barValue: 9,
            lineValue: 196.45
          },
          {
            name: 'Sunday',
            barValue: 4,
            lineValue: 134
          }
        ]
      },
      {
        title: 'Hero Metrics 3 Vs Hero Metrics 3 CPQ',
        barName: 'Unq Leads 28D Click',
        lineName: 'Unq CPL 28D Click',
        data: [
          {
            name: 'Monday',
            barValue: 2,
            lineValue: 206.04
          },
          {
            name: 'Tuesday',
            barValue: 10,
            lineValue: 82.47
          },
          {
            name: 'Wednesday',
            barValue: 6,
            lineValue: 96.45
          },
          {
            name: 'Thursday',
            barValue: 8,
            lineValue: 370
          },
          {
            name: 'Friday',
            barValue: 5,
            lineValue: 120
          },
          {
            name: 'Saturday',
            barValue: 9,
            lineValue: 196.45
          },
          {
            name: 'Sunday',
            barValue: 4,
            lineValue: 134
          }
        ]
      },
      {
        title: 'Hero Metrics 4 Vs Hero Metrics 4 CPQ',
        barName: 'Unq Quote CR% 28D Click',
        lineName: 'Apps 28D Click',
        data: [
          {
            name: 'Monday',
            barValue: 2,
            lineValue: 206.04
          },
          {
            name: 'Tuesday',
            barValue: 10,
            lineValue: 82.47
          },
          {
            name: 'Wednesday',
            barValue: 6,
            lineValue: 96.45
          },
          {
            name: 'Thursday',
            barValue: 8,
            lineValue: 370
          },
          {
            name: 'Friday',
            barValue: 5,
            lineValue: 120
          },
          {
            name: 'Saturday',
            barValue: 9,
            lineValue: 196.45
          },
          {
            name: 'Sunday',
            barValue: 4,
            lineValue: 134
          }
        ]
      }
    ];
  }
  return [
    {
      title: 'Hero Metrics 1 Vs Hero Metrics 1 CPQ',
      barName: 'Unq Quotes 1D Click',
      lineName: 'Unq CPL 1D Click',
      data: [
        {
          name: '2021',
          barValue: 36,
          lineValue: 192.15
        }
      ]
    },
    {
      title: 'Hero Metrics 2 Vs Hero Metrics 2 CPQ',
      barName: 'Apps 1D Click',
      lineName: 'CPA 1D Click',
      data: [
        {
          name: '2021',
          barValue: 5,
          lineValue: 1380
        }
      ]
    },
    {
      title: 'Hero Metrics 3 Vs Hero Metrics 3 CPQ',
      barName: 'Unq Leads 28D Click',
      lineName: 'Unq CPL 28D Click',
      data: [
        {
          name: '2021',
          barValue: 38,
          lineValue: 182.4
        }
      ]
    },
    {
      title: 'Hero Metrics 4 Vs Hero Metrics 4 CPQ',
      barName: 'Unq Quote CR% 28D Click',
      lineName: 'Apps 28D Click',
      data: [
        {
          name: '2021'
        }
      ]
    }
  ];
};

export const companyProjectsData = [
  {
    id: '12345678a',
    name: 'Company 1',
    projects: [
      { id: '113456', name: 'Project 1.1', companyId: '12345678a' },
      { id: '123456', name: 'Project 1.2', companyId: '12345678a' },
      { id: '133456', name: 'Project 1.3', companyId: '12345678a' },
      { id: '143456', name: 'Project 1.4', companyId: '12345678a' },
      { id: '153456', name: 'Project 1.5', companyId: '12345678a' }
    ]
  },
  {
    id: '12345678b',
    name: 'Company 2',
    projects: [
      { id: '2134567', name: 'Project 2.1', companyId: '12345678b' },
      { id: '2234568', name: 'Project 2.2', companyId: '12345678b' },
      { id: '2334569', name: 'Project 2.3', companyId: '12345678b' },
      { id: '2434561', name: 'Project 2.4', companyId: '12345678b' },
      { id: '2534561', name: 'Project 2.5', companyId: '12345678b' }
    ]
  },
  {
    id: '12345678c',
    name: 'Company 3',
    projects: [
      { id: '313456', name: 'Project 3.1', companyId: '12345678c' },
      { id: '323456', name: 'Project 3.2', companyId: '12345678c' },
      { id: '333456', name: 'Project 3.3', companyId: '12345678c' },
      { id: '343456', name: 'Project 3.4', companyId: '12345678c' },
      { id: '353456', name: 'Project 3.5', companyId: '12345678c' }
    ]
  },
  {
    id: '12345678d',
    name: 'SBLI',
    projects: [
      { id: '413456', name: 'SBLi Term Life', companyId: '12345678d' },
      { id: '423456', name: 'SBLI Organic', companyId: '12345678d' },
      { id: '433456', name: 'SBLI Final Expense', companyId: '12345678d' }
    ]
  }
];

export const currentDashboardFiltersData = [
  {
    id: '122312',
    name: 'Toggle',
    type: 'singleSelect',
    options: [
      {
        id: '123123',
        name: 'gross'
      },
      {
        id: '12312323',
        name: 'net'
      }
    ]
  },
  {
    id: '122312312',
    name: 'Last 13 Months',
    type: 'checkbox'
  },
  {
    id: '3213213',
    name: 'Last 13 Weeks',
    type: 'checkbox'
  },
  {
    id: '123123',
    name: 'Date Range',
    type: 'multipleSelect',
    extraType: 'date'
  },
  {
    id: '123234123',
    name: 'Rocketer Product',
    type: 'multipleSelect',
    options: [
      {
        id: '1',
        name: 'City'
      },
      {
        id: '2',
        name: 'Lookalike City'
      },
      {
        id: '3',
        name: 'Lookalike Regional'
      },
      {
        id: '4',
        name: 'Other'
      },
      {
        id: '5',
        name: 'Regional'
      },
      {
        id: 'City',
        name: 'city'
      },
      {
        id: 'LLCity',
        name: 'lookAlike city'
      }
    ]
  },
  {
    id: '12323412323',
    name: 'Company Name',
    type: 'multipleSelect',
    options: [
      {
        id: 59,
        name: 'SBLI'
      }
    ]
  },
  {
    id: '1232341232323',
    name: 'Project Name',
    type: 'multipleSelect',
    options: [
      {
        id: 334,
        name: 'SBLi Term Life',
        companyId: 59,
        clientId: 327
      },
      {
        id: 352,
        name: 'SBLI Organic',
        companyId: 59,
        clientId: 345
      },
      {
        id: 360,
        name: 'SBLI Final Expense',
        companyId: 59,
        clientId: 353
      }
    ]
  },
  {
    id: '12321233412323',
    name: 'DayOfWeek',
    type: 'multipleSelect',
    options: [
      {
        id: '1111222',
        name: '1-Monday'
      },
      {
        id: '1111222',
        name: '2-Tuesday'
      },
      {
        id: '111221222',
        name: '3-Wednesday'
      },
      {
        id: '444233',
        name: '4-Thursday'
      },
      {
        id: '1111222',
        name: '5-Friday'
      },
      {
        id: '11431221222',
        name: '6-Saturday'
      },
      {
        id: '444243233',
        name: '7-Sunday'
      }
    ]
  },
  {
    id: '33333',
    name: 'Weekly',
    type: 'multipleSelect',
    options: [
      {
        id: '22',
        name: '07 2020'
      },
      {
        id: '33',
        name: '06 2020'
      },
      {
        id: '11',
        name: '05 2020'
      },
      {
        id: '213',
        name: '04 2020'
      },
      {
        id: '231',
        name: '03 2020'
      },
      {
        id: '321',
        name: '02 2020'
      },
      {
        id: '321332',
        name: '01 2020'
      }
    ]
  }
];

export const timeSeriesBarChartData = [
  {
    total: 12312,
    title: 'Spend by Vertical',
    data: [
      {
        name: 'Insurance',
        value: 1600000
      }
    ]
  },
  {
    total: 432,
    title: 'Spend by Sub-Vertical',
    data: [
      {
        name: 'Term life DR',
        value: 1600000
      },
      {
        name: 'Guaranteed acceptance DR',
        value: 1033200
      },
      {
        name: 'Branding & engagement',
        value: 1009800
      }
    ]
  },
  {
    total: 432,
    title: 'Spend by Currency',
    data: [
      {
        name: 'USD',
        value: 1600000
      }
    ]
  },
  {
    total: 432,
    title: 'Spend by Country',
    data: [
      {
        name: 'United States',
        value: 1600000
      }
    ]
  }
];

export const projectsBarChartData = [
  {
    total: 12312,
    title: 'by Hero Metrics 1',
    data: [
      {
        name: 'SBLi Term Life',
        value: 868
      }
    ]
  },
  {
    total: 432,
    title: 'by Hero Metrics 1 CVR%',
    data: [
      {
        name: 'SBLi Term Life',
        value: 10.49
      }
    ]
  },
  {
    total: 432,
    title: 'by Hero Metrics 1 CPQ',
    data: [
      {
        name: 'SBLi Term Life',
        value: 34.46
      }
    ]
  },
  {
    total: 432,
    title: 'by CPC',
    data: [
      {
        name: 'SBLi Term Life',
        value: 3.62
      }
    ]
  }
];

export const heroMetricsData = () => [
  { label: 'herometrics1', filters: {} },
  { label: 'herometrics1_cvr%', filters: {} },
  { label: 'herometrics1_cpq', filters: {} },
  { label: 'cpc', filters: {} }
];

export const regionBarChartData = [
  {
    total: 12312,
    title: 'Regions by Hero Metrics 1',
    legendValue: 'Unq Quotes 1D Click',
    data: [
      {
        name: 'Texas',
        value: 12
      },
      {
        name: 'Georgia',
        value: 12
      },
      {
        name: 'Pennsylvania',
        value: 11
      },
      {
        name: 'North Carolina',
        value: 9
      },
      {
        name: 'Florida',
        value: 8
      },
      {
        name: 'New Jersey',
        value: 7
      },
      {
        name: 'Virginia',
        value: 7
      },
      {
        name: 'Tennessee',
        value: 6
      },
      {
        name: 'Illinois',
        value: 5
      },
      {
        name: 'South Carolina',
        value: 4
      }
    ]
  },
  {
    total: 432,
    title: 'by Hero Metrics 1 CVR%',
    legendValue: 'Unq Quote CR% 1D Click',
    data: [
      {
        name: 'Texas',
        value: 1.84
      },
      {
        name: 'Georgia',
        value: 1.64
      },
      {
        name: 'Pennsylvania',
        value: 1.49
      },
      {
        name: 'North Carolina',
        value: 1.47
      },
      {
        name: 'Florida',
        value: 1.35
      },
      {
        name: 'New Jersey',
        value: 1.23
      },
      {
        name: 'Virginia',
        value: 1.0
      },
      {
        name: 'Tennessee',
        value: 0.93
      },
      {
        name: 'Illinois',
        value: 0.92
      },
      {
        name: 'South Carolina',
        value: 0.91
      }
    ]
  },
  {
    total: 432,
    title: 'by Hero Metrics 1 CPQ',
    legendValue: 'Unq CPL 1D Click',
    data: [
      {
        name: 'Texas',
        value: 1338.21
      },
      {
        name: 'Georgia',
        value: 1229.2
      },
      {
        name: 'Pennsylvania',
        value: 1101.91
      },
      {
        name: 'North Carolina',
        value: 903.71
      },
      {
        name: 'Florida',
        value: 845.77
      },
      {
        name: 'New Jersey',
        value: 832.69
      },
      {
        name: 'Virginia',
        value: 784.91
      },
      {
        name: 'Tennessee',
        value: 755.59
      },
      {
        name: 'Illinois',
        value: 755.59
      },
      {
        name: 'South Carolina',
        value: 650.53
      }
    ]
  },
  {
    total: 432,
    title: 'by CPC',
    legendValue: 'CPC',
    data: [
      {
        name: 'Texas',
        value: 12.87
      },
      {
        name: 'Georgia',
        value: 12.32
      },
      {
        name: 'Pennsylvania',
        value: 12.27
      },
      {
        name: 'North Carolina',
        value: 12.09
      },
      {
        name: 'Florida',
        value: 11.97
      },
      {
        name: 'New Jersey',
        value: 11.4
      },
      {
        name: 'Virginia',
        value: 11.29
      },
      {
        name: 'Tennessee',
        value: 11.14
      },
      {
        name: 'Illinois',
        value: 11.02
      },
      {
        name: 'South Carolina',
        value: 10.94
      }
    ]
  }
];

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 180 }
];

export const pieChartData = [
  { title: 'Unq Quotes 1D Click %Split', data: [...data] },
  { title: 'Apps 1D Click %Split', data: [...data] },
  { title: 'Unq Leads 28D Click %Split', data: [...data] },
  { title: 'Unq Quote CR% 28D Click %Split', data: [...data] }
];

export const topCitiesBarChartData = [
  {
    total: 12312,
    title: 'Hero Metrics 1',
    data: [
      {
        name: 'Silver Spring',
        tooltipValue: 'Silver Spring',
        value: 14
      },
      {
        name: 'Atlanta',
        tooltipValue: 'Atlanta',
        value: 13
      },
      {
        name: 'New Baltimore',
        tooltipValue: 'New Baltimore',
        value: 3
      },
      {
        name: 'Waycross',
        tooltipValue: 'Waycross',
        value: 2
      },
      {
        name: 'Linwood',
        tooltipValue: 'Linwood',
        value: 1
      },
      {
        name: 'Waterford Township',
        tooltipValue: 'Waterford Township',
        value: 1
      },
      {
        name: 'Cooperville',
        tooltipValue: 'Cooperville',
        value: 0
      },
      {
        name: 'Clem',
        tooltipValue: 'Clem',
        value: 0
      },
      {
        name: 'Roxbury',
        tooltipValue: 'Roxbury',
        value: 0
      },
      {
        name: 'Cairo',
        tooltipValue: 'Cairo',
        value: 0
      }
    ]
  },
  {
    total: 432,
    title: 'Hero Metrics 1 CVR%',
    data: [
      {
        name: 'Linwood',
        tooltipValue: 'Linwood',
        value: 20.0
      },
      {
        name: 'Waycross',
        tooltipValue: 'Waycross',
        value: 16.67
      },
      {
        name: 'Atlanta',
        tooltipValue: 'Atlanta',
        value: 14.44
      },
      {
        name: 'Silver Spring',
        tooltipValue: 'Silver Spring',
        value: 10.45
      },
      {
        name: 'New Baltimore',
        tooltipValue: 'New Baltimore',
        value: 9.38
      },
      {
        name: 'Clem',
        tooltipValue: 'Clem',
        value: 0
      },
      {
        name: 'Cairo',
        tooltipValue: 'Cairo',
        value: 0
      },
      {
        name: 'Coopersville',
        tooltipValue: 'Coopersville',
        value: 0
      },
      {
        name: 'Roxbury',
        tooltipValue: 'Roxbury',
        value: 0
      },
      {
        name: 'Waterford Township',
        tooltipValue: 'Waterford Township',
        value: 1
      }
    ]
  },
  {
    total: 432,
    title: 'Hero Metrics 1 CPQ',
    data: [
      {
        name: 'Waterford Township',
        tooltipValue: 'Waterford Township',
        value: 325.9
      },
      {
        name: 'New Baltimore',
        tooltipValue: 'New Baltimore',
        value: 75.09
      },
      {
        name: 'Silver Spring',
        tooltipValue: 'Silver Spring',
        value: 62.36
      },
      {
        name: 'Waycross',
        tooltipValue: 'Waycross',
        value: 40.13
      },
      {
        name: 'Atlanta',
        tooltipValue: 'Atlanta',
        value: 37.5
      },
      {
        name: 'Linwood',
        tooltipValue: 'Linwood',
        value: 30.5
      },
      {
        name: 'Clem',
        tooltipValue: 'Clem',
        value: 0
      },
      {
        name: 'Coopersville',
        tooltipValue: 'Coopersville',
        value: 0
      },
      {
        name: 'Roxbury',
        tooltipValue: 'Roxbury',
        value: 0
      },
      {
        name: 'Cairo',
        tooltipValue: 'Cairo',
        value: 0
      }
    ]
  },
  {
    total: 432,
    title: 'CPC',
    data: [
      {
        name: 'New Baltimore',
        tooltipValue: 'New Baltimore',
        value: 7.13
      },
      {
        name: 'Waycross',
        tooltipValue: 'Waycross',
        value: 6.69
      },
      {
        name: 'Silver Spring',
        tooltipValue: 'Silver Spring',
        value: 6.52
      },
      {
        name: 'Waterford Township',
        tooltipValue: 'Waterford Township',
        value: 6.15
      },
      {
        name: 'Linwood',
        tooltipValue: 'Linwood',
        value: 6.15
      },
      {
        name: 'Clem',
        tooltipValue: 'Clem',
        value: 5.49
      },
      {
        name: 'Atlanta',
        tooltipValue: 'Atlanta',
        value: 5.42
      },
      {
        name: 'Cairo',
        tooltipValue: 'Cairo',
        value: 0.17
      },
      {
        name: 'Corpsville',
        tooltipValue: 'Corpsville',
        value: 0
      },
      {
        name: 'Ruxbury',
        tooltipValue: 'Ruxbury',
        value: 0
      }
    ]
  }
];

export const multiLineChart = [
  {
    title: 'Unq CPL 1D Click Per Product',
    category: 'Lookalike Regional',
    data: [
      {
        name: '2021-05-16T19:02:28.000Z',
        value: 1181
      },
      {
        name: '2021-12-01T19:02:28.000Z',
        value: 1004
      },
      {
        name: '2021-12-02T19:02:28.000Z',
        value: 1539
      },
      {
        name: '2021-12-03T19:02:28.000Z',
        value: 1305
      },
      {
        name: '2021-12-04T19:02:28.000Z',
        value: 2002
      },
      {
        name: '2021-12-05T19:02:28.000Z',
        value: 2240
      },
      {
        name: '2021-12-06T19:02:28.000Z',
        value: 2533
      },
      {
        name: '2021-12-07T19:02:28.000Z',
        value: 1962
      },
      {
        name: '2021-12-08T19:02:28.000Z',
        value: 619
      },
      {
        name: '2021-12-09T19:02:28.000Z',
        value: 1303
      },
      {
        name: '2021-12-10T19:02:28.000Z',
        value: 3902
      },
      {
        name: '2021-12-11T19:02:28.000Z',
        value: 3644
      },
      {
        name: '2021-12-12T19:02:28.000Z',
        value: 1713
      }
    ]
  },
  {
    title: 'Unq CPL 1D Click Per Product',
    category: 'Lookalike City',
    data: [
      {
        name: '2021-05-16T19:02:28.000Z',
        value: 1646
      },
      {
        name: '2021-12-01T19:02:28.000Z',
        value: 550
      },
      {
        name: '2021-12-02T19:02:28.000Z',
        value: 352
      },
      {
        name: '2021-12-03T19:02:28.000Z',
        value: 450
      },
      {
        name: '2021-12-04T19:02:28.000Z',
        value: 600
      },
      {
        name: '2021-12-05T19:02:28.000Z',
        value: 650
      },
      {
        name: '2021-12-06T19:02:28.000Z',
        value: 1102
      }
    ]
  },
  {
    title: 'Unq CPL 1D Click Per Product',
    category: 'City',
    data: [
      {
        name: '2021-05-16T19:02:28.000Z',
        value: 60
      },
      {
        name: '2021-12-01T19:02:28.000Z',
        value: 67
      },
      {
        name: '2021-12-02T19:02:28.000Z',
        value: 70
      },
      {
        name: '2021-12-03T19:02:28.000Z',
        value: 87
      },
      {
        name: '2021-12-04T19:02:28.000Z',
        value: 92
      },
      {
        name: '2021-12-05T19:02:28.000Z',
        value: 98
      },
      {
        name: '2021-12-06T19:02:28.000Z',
        value: 100
      },
      {
        name: '2021-12-07T19:02:28.000Z',
        value: 120
      },
      {
        name: '2021-12-08T19:02:28.000Z',
        value: 160
      },
      {
        name: '2021-12-09T19:02:28.000Z',
        value: 140
      },
      {
        name: '2021-12-10T19:02:28.000Z',
        value: 90
      },
      {
        name: '2021-12-11T19:02:28.000Z',
        value: 150
      },
      {
        name: '2021-12-12T19:02:28.000Z',
        value: 200
      }
    ]
  }
];

export const timeOfDayChartData = [
  {
    title: 'Hourly Hero Metrics 1 Vs Hero Metrics 1 CPQ',
    barName: 'Unq Quotes 1D Click',
    lineName: 'Unq CPL 1D Click',
    data: [
      {
        name: '00',
        barValue: 226,
        lineValue: 182.15
      },
      {
        name: '01',
        barValue: 287,
        lineValue: 192.15
      },
      {
        name: '02',
        barValue: 241,
        lineValue: 195.15
      },
      {
        name: '03',
        barValue: 230,
        lineValue: 172.15
      },
      {
        name: '04',
        barValue: 226,
        lineValue: 182.15
      },
      {
        name: '05',
        barValue: 287,
        lineValue: 192.15
      },
      {
        name: '06',
        barValue: 241,
        lineValue: 195.15
      },
      {
        name: '07',
        barValue: 230,
        lineValue: 172.15
      },
      {
        name: '08',
        barValue: 226,
        lineValue: 182.15
      },
      {
        name: '09',
        barValue: 287,
        lineValue: 192.15
      },
      {
        name: '10',
        barValue: 241,
        lineValue: 195.15
      },
      {
        name: '11',
        barValue: 230,
        lineValue: 172.15
      },
      {
        name: '12',
        barValue: 226,
        lineValue: 182.15
      },
      {
        name: '13',
        barValue: 287,
        lineValue: 192.15
      },
      {
        name: '14',
        barValue: 241,
        lineValue: 195.15
      },
      {
        name: '15',
        barValue: 230,
        lineValue: 172.15
      },
      {
        name: '16',
        barValue: 226,
        lineValue: 182.15
      },
      {
        name: '17',
        barValue: 287,
        lineValue: 192.15
      },
      {
        name: '18',
        barValue: 241,
        lineValue: 195.15
      },
      {
        name: '19',
        barValue: 230,
        lineValue: 172.15
      },
      {
        name: '20',
        barValue: 226,
        lineValue: 182.15
      },
      {
        name: '21',
        barValue: 287,
        lineValue: 192.15
      },
      {
        name: '22',
        barValue: 241,
        lineValue: 195.15
      },
      {
        name: '23',
        barValue: 230,
        lineValue: 172.15
      }
    ]
  },
  {
    title: 'Hourly CPC & Hero Metrics 1 CVR%',
    barName: 'CPC',
    lineName: 'Unq Qoute CR% 1D Click',
    data: [
      {
        name: '00',
        barValue: 26,
        lineValue: 18.15
      },
      {
        name: '01',
        barValue: 28,
        lineValue: 19.15
      },
      {
        name: '02',
        barValue: 24,
        lineValue: 19.15
      },
      {
        name: '03',
        barValue: 23,
        lineValue: 17.15
      },
      {
        name: '04',
        barValue: 22,
        lineValue: 18.15
      },
      {
        name: '05',
        barValue: 28,
        lineValue: 19.15
      },
      {
        name: '06',
        barValue: 24,
        lineValue: 19.15
      },
      {
        name: '07',
        barValue: 23,
        lineValue: 17.15
      },
      {
        name: '08',
        barValue: 22,
        lineValue: 18.15
      },
      {
        name: '09',
        barValue: 28,
        lineValue: 19.15
      },
      {
        name: '10',
        barValue: 24,
        lineValue: 19.15
      },
      {
        name: '11',
        barValue: 20,
        lineValue: 12.15
      },
      {
        name: '12',
        barValue: 22,
        lineValue: 18.15
      },
      {
        name: '13',
        barValue: 28,
        lineValue: 12.15
      },
      {
        name: '14',
        barValue: 24,
        lineValue: 19.15
      },
      {
        name: '15',
        barValue: 23,
        lineValue: 17.15
      },
      {
        name: '16',
        barValue: 22,
        lineValue: 18.15
      },
      {
        name: '17',
        barValue: 28,
        lineValue: 19.15
      },
      {
        name: '18',
        barValue: 24,
        lineValue: 19.15
      },
      {
        name: '19',
        barValue: 23,
        lineValue: 17.15
      },
      {
        name: '20',
        barValue: 22,
        lineValue: 18.15
      },
      {
        name: '21',
        barValue: 28,
        lineValue: 19.15
      },
      {
        name: '22',
        barValue: 24,
        lineValue: 19.15
      },
      {
        name: '23',
        barValue: 23,
        lineValue: 17.15
      }
    ]
  }
];

export const radarChartData = [
  {
    title: 'Unq Quotes 1D Click',
    data: [
      {
        name: '5452',
        value: 2120
      },
      {
        name: '5993',
        value: 3500
      },
      {
        name: '5455',
        value: 4000
      },
      {
        name: '5940',
        value: 5500
      },
      {
        name: '5922',
        value: 6500
      },
      {
        name: '5924',
        value: 7200
      }
    ]
  },
  {
    title: 'Unq Quotes 1D Click',
    data: [
      {
        name: '5452',
        value: 9120
      },
      {
        name: '5993',
        value: 8500
      },
      {
        name: '5455',
        value: 9120
      },
      {
        name: '5940',
        value: 5500
      },
      {
        name: '5922',
        value: 7500
      },
      {
        name: '5924',
        value: 6200
      },
      {
        name: '5941',
        value: 7600
      },
      {
        name: '5995',
        value: 5200
      }
    ]
  }
];

export const stackedBarChartData = [
  {
    name: '2021-05-15T19:02:28.000Z',
    city: 4000,
    regional: 2400,
    unmapped: 2400
  },
  {
    name: '2021-05-16T19:02:28.000Z',
    city: 3000,
    regional: 1398,
    unmapped: 2210
  },
  {
    name: '2021-05-17T19:02:28.000Z',
    city: 2000,
    regional: 9800,
    unmapped: 2290
  },
  {
    name: '2021-05-18T19:02:28.000Z',
    city: 2780,
    regional: 3908,
    unmapped: 2000
  },
  {
    name: '2021-05-19T19:02:28.000Z',
    city: 3000,
    regional: 1398,
    unmapped: 2210
  },
  {
    name: '2021-05-20T19:02:28.000Z',
    city: 2000,
    regional: 9800,
    unmapped: 2290
  },
  {
    name: '2021-05-21T19:02:28.000Z',
    city: 2780,
    regional: 3908,
    unmapped: 2000
  },
  {
    name: '2021-05-22T19:02:28.000Z',
    city: 3000,
    regional: 1398,
    unmapped: 2210
  },
  {
    name: '2021-05-23T19:02:28.000Z',
    city: 2000,
    regional: 9800,
    unmapped: 2290
  },
  {
    name: '2021-05-24T19:02:28.000Z',
    city: 2780,
    regional: 3908,
    unmapped: 2000
  },
  {
    name: '2021-05-25T19:02:28.000Z',
    city: 3000,
    regional: 1398,
    unmapped: 2210
  },
  {
    name: '2021-05-26T19:02:28.000Z',
    city: 2000,
    regional: 9800,
    unmapped: 2290
  },
  {
    name: '2021-05-27T19:02:28.000Z',
    city: 2780,
    regional: 3908,
    unmapped: 2000
  }
];

export const mediaBarChartData = [
  {
    total: 12312,
    title: 'Unq Quotes 1D Click',
    data: [
      {
        name: '10878:SBLI-10878',
        value: 6005
      },
      {
        name: '9564:SBLI-9564',
        value: 907
      },
      {
        name: '9845:SBLI-9845',
        value: 308
      },
      {
        name: '1087:SBLI-1087',
        value: 213
      },
      {
        name: '1078:SBLI-1078',
        value: 432
      },
      {
        name: '10978:SBLI-10978',
        value: 222
      }
    ]
  },
  {
    total: 432,
    title: 'Unq Quote CR% 1D Click',
    data: [
      {
        name: '10878:SBLI-10878',
        value: 19.04
      },
      {
        name: '9564:SBLI-9564',
        value: 12.86
      },
      {
        name: '9845:SBLI-9845',
        value: 10.15
      },
      {
        name: '1087:SBLI-1087',
        value: 12.23
      },
      {
        name: '1078:SBLI-1078',
        value: 26.43
      },
      {
        name: '10978:SBLI-10978',
        value: 11.2
      }
    ]
  },
  {
    total: 432,
    title: 'Unq CPL 1D Click',
    data: [
      {
        name: '10878:SBLI-10878',
        value: 592.04
      },
      {
        name: '9564:SBLI-9564',
        value: 258.29
      },
      {
        name: '9845:SBLI-9845',
        value: 60.75
      },
      {
        name: '1087:SBLI-1087',
        value: 123.32
      },
      {
        name: '1078:SBLI-1078',
        value: 33.21
      },
      {
        name: '10978:SBLI-10978',
        value: 192.21
      }
    ]
  },
  {
    total: 432,
    title: 'CPC',
    data: [
      {
        name: '10878:SBLI-10878',
        value: 124.87
      },
      {
        name: '9564:SBLI-9564',
        value: 31.38
      },
      {
        name: '9845:SBLI-9845',
        value: 4.47
      },
      {
        name: '1087:SBLI-1087',
        value: 12.23
      },
      {
        name: '1078:SBLI-1078',
        value: 51.2
      },
      {
        name: '10978:SBLI-10978',
        value: 22.32
      }
    ]
  }
];

export const copyBarChartData = [
  {
    total: 12312,
    title: 'Unq Quotes 1D Click',
    data: [
      {
        name: '10878:SBLI-10878',
        value: 6005
      },
      {
        name: '9564:SBLI-9564',
        value: 907
      },
      {
        name: '9845:SBLI-9845',
        value: 308
      },
      {
        name: '1087:SBLI-1087',
        value: 213
      },
      {
        name: '1078:SBLI-1078',
        value: 432
      },
      {
        name: '10978:SBLI-10978',
        value: 222
      }
    ]
  },
  {
    total: 432,
    title: 'Unq Quote CR% 1D Click',
    data: [
      {
        name: '10878:SBLI-10878',
        value: 10.2
      },
      {
        name: '9564:SBLI-9564',
        value: 59.3
      },
      {
        name: '9845:SBLI-9845',
        value: 14.2
      },
      {
        name: '1087:SBLI-1087',
        value: 34.3
      },
      {
        name: '1078:SBLI-1078',
        value: 22.3
      },
      {
        name: '10978:SBLI-10978',
        value: 10.2
      }
    ]
  },
  {
    total: 432,
    title: 'Unq CPL 1D Click',
    data: [
      {
        name: '10878:SBLI-10878',
        value: 12312
      },
      {
        name: '9564:SBLI-9564',
        value: 232
      },
      {
        name: '9845:SBLI-9845',
        value: 231.2
      },
      {
        name: '1087:SBLI-1087',
        value: 232.4
      },
      {
        name: '1078:SBLI-1078',
        value: 3223
      },
      {
        name: '10978:SBLI-10978',
        value: 111
      }
    ]
  },
  {
    total: 432,
    title: 'CPC',
    data: [
      {
        name: '10878:SBLI-10878',
        value: 0.2
      },
      {
        name: '9564:SBLI-9564',
        value: 0.8
      },
      {
        name: '9845:SBLI-9845',
        value: 0.2
      },
      {
        name: '1087:SBLI-1087',
        value: 0.4
      },
      {
        name: '1078:SBLI-1078',
        value: 1.2
      },
      {
        name: '10978:SBLI-10978',
        value: 2
      }
    ]
  }
];

export const productAnalysisBarChartData = [
  {
    total: 12312,
    title: 'Hero Metrics 1',
    data: [
      {
        name: 'SBLi Term Life',
        value: 25340
      },
      {
        name: 'SBLI Final Expense',
        value: 125
      },
      {
        name: 'SBLI Organic',
        value: 0
      }
    ]
  },
  {
    total: 12312,
    title: 'Hero Metrics 1 CVR%',
    data: [
      {
        name: 'SBLi Term Life',
        value: 10.19
      },
      {
        name: 'SBLI Final Expense',
        value: 1.34
      },
      {
        name: 'SBLI Organic',
        value: 0.0
      }
    ]
  },
  {
    total: 12312,
    title: 'Hero Metrics 1 CPQ',
    data: [
      {
        name: 'SBLI Final Expense',
        value: 885.66
      },
      {
        name: 'SBLi Term Life',
        value: 64.37
      },
      {
        name: 'SBLI Organic',
        value: ''
      }
    ]
  },
  {
    total: 12312,
    title: 'CPC',
    data: [
      {
        name: 'SBLI Organic',
        value: 21.1
      },
      {
        name: 'SBLI Final Expense',
        value: 11.9
      },
      {
        name: 'SBLi Term Life',
        value: 6.56
      }
    ]
  }
];

export const productAnalysisMultiSeriesChartsData = [
  {
    title: 'Unq Quotes 1D Click Per Product',
    barSeries: {
      bars: {
        lookAlikeRegional: 'Lookalike Regional',
        regional: 'Regional',
        retargeting: 'Retargeting'
      },
      barsData: [
        {
          name: '2021-02-01T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-02T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 5,
          retargeting: 3
        },
        {
          name: '2021-02-03T19:02:28.000Z',
          lookAlikeRegional: 5,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-04T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 2
        },
        {
          name: '2021-02-05T19:02:28.000Z',
          lookAlikeRegional: 3,
          retargeting: 1
        },
        {
          name: '2021-02-06T19:02:28.000Z',
          lookAlikeRegional: 20,
          retargeting: 5
        },
        {
          name: '2021-02-07T19:02:28.000Z',
          lookAlikeRegional: 3,
          retargeting: 1
        },
        {
          name: '2021-02-08T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 2
        },
        {
          name: '2021-02-09T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-02-10T19:02:28.000Z',
          lookAlikeRegional: 19,
          regional: 4,
          retargeting: 2
        },
        {
          name: '2021-02-11T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-12T19:02:28.000Z',
          lookAlikeRegional: 32,
          regional: 2
        },
        {
          name: '2021-02-13T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-14T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 0.2,
          retargeting: 1
        },
        {
          name: '2021-02-15T19:02:28.000Z',
          lookAlikeRegional: 6,
          regional: 5
        },
        {
          name: '2021-02-16T19:02:28.000Z',
          lookAlikeRegional: 18,
          retargeting: 2
        },
        {
          name: '2021-02-17T19:02:28.000Z',
          lookAlikeRegional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-18T19:02:28.000Z',
          lookAlikeRegional: 33,
          regional: 5,
          retargeting: 7
        },
        {
          name: '2021-02-19T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-02-20T19:02:28.000Z',
          lookAlikeRegional: 3,
          regional: 1,
          retargeting: 1
        },
        {
          name: '2021-02-21T19:02:28.000Z',
          lookAlikeRegional: 2,
          retargeting: 0.2
        },
        {
          name: '2021-02-22T19:02:28.000Z',
          lookAlikeRegional: 37,
          regional: 4,
          retargeting: 2
        },
        {
          name: '2021-02-23T19:02:28.000Z',
          lookAlikeRegional: 4
        },
        {
          name: '2021-02-24T19:02:28.000Z',
          lookAlikeRegional: 28,
          regional: 3
        },
        {
          name: '2021-02-25T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 1,
          retargeting: 1
        },
        {
          name: '2021-02-26T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 3,
          retargeting: 5
        },
        {
          name: '2021-02-27T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-28T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 1,
          retargeting: 1
        },
        {
          name: '2021-03-01T19:02:28.000Z',
          lookAlikeRegional: 14,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-02T19:02:28.000Z',
          lookAlikeRegional: 22,
          regional: 3
        },
        {
          name: '2021-03-03T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-04T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-05T19:02:28.000Z',
          lookAlikeRegional: 7,
          regional: 3
        },
        {
          name: '2021-03-06T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-07T19:02:28.000Z',
          lookAlikeRegional: 2
        },
        {
          name: '2021-03-08T19:02:28.000Z',
          lookAlikeRegional: 7,
          regional: 3,
          retargeting: 1
        },
        {
          name: '2021-03-09T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-10T19:02:28.000Z',
          lookAlikeRegional: 2
        },
        {
          name: '2021-03-11T19:02:28.000Z',
          lookAlikeRegional: 17
        },
        {
          name: '2021-03-12T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-13T19:02:28.000Z',
          lookAlikeRegional: 16
        },
        {
          name: '2021-03-14T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 1
        },
        {
          name: '2021-03-15T19:02:28.000Z',
          lookAlikeRegional: 13
        },
        {
          name: '2021-03-16T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-17T19:02:28.000Z',
          lookAlikeRegional: 22,
          regional: 2,
          retargeting: 2
        },
        {
          name: '2021-03-18T19:02:28.000Z',
          lookAlikeRegional: 12,
          retargeting: 2
        },
        {
          name: '2021-03-19T19:02:28.000Z',
          lookAlikeRegional: 21,
          regional: 5
        },
        {
          name: '2021-03-20T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-21T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 3
        },
        {
          name: '2021-03-22T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-23T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-24T19:02:28.000Z',
          lookAlikeRegional: 1
        },
        {
          name: '2021-03-25T19:02:28.000Z',
          lookAlikeRegional: 8,
          regional: 7,
          retargeting: 2
        },
        {
          name: '2021-03-26T19:02:28.000Z',
          lookAlikeRegional: 8,
          regional: 9,
          retargeting: 1
        },
        {
          name: '2021-03-27T19:02:28.000Z',
          lookAlikeRegional: 16,
          regional: 8
        },
        {
          name: '2021-03-28T19:02:28.000Z',
          lookAlikeRegional: 8,
          regional: 7,
          retargeting: 2
        },
        {
          name: '2021-03-29T19:02:28.000Z',
          lookAlikeRegional: 8
        },
        {
          name: '2021-03-30T19:02:28.000Z',
          lookAlikeRegional: 26,
          regional: 7
        },
        {
          name: '2021-03-31T19:02:28.000Z',
          lookAlikeRegional: 22
        }
      ]
    },
    lineSeries: []
  },
  {
    title: 'Unq CPL 1D Click Per Product',
    barSeries: {},
    lineSeries: [
      {
        category: 'Lookalike Regional',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 117
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: 109
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: 121
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 89
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: 128
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: 129
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 170
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 118
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 115
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 117
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 95
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 93
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: 85
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 112
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 121
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 125
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: 140
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 148
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 159
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 160
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: 170
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 171
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 178
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 179
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 143
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 113
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 118
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: 156
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 134
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 178
          }
        ]
      },
      {
        category: 'Regional',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 375
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 482
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 363
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 231
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: 307
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 534
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 1079
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 504
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 510
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 155
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 540
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 376
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 263
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 973
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: 943
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 944
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 278
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 73
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 646
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 656
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 603
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 388
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 20
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 1070
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 491
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 480
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 317
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: 1066
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 56
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 444
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 174
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 414
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 400
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: 761
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 113
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 753
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 740
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 741
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 343
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-31T19:02:28.000Z',
            value: 395
          }
        ]
      },
      {
        category: 'Retargeting',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 89
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 307
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: 57
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 362
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: 168
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 225
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 91
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 94
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 259
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 77
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 182
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 105
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 227
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: 45
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 124
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 102
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 112
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 96
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: 261
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 210
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 201
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 91
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 230
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 72
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 266
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 230
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 220
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 49
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 198
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 190
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: 47
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 49
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 48
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 44
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: 98
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 98
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-31T19:02:28.000Z',
            value: 219
          }
        ]
      }
    ]
  },
  {
    title: 'Apps 1D Click Per Product',
    barSeries: {
      bars: {
        lookAlikeRegional: 'Lookalike Regional',
        regional: 'Regional',
        retargeting: 'Retargeting'
      },
      barsData: [
        {
          name: '2021-02-01T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-02T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 5,
          retargeting: 3
        },
        {
          name: '2021-02-03T19:02:28.000Z',
          lookAlikeRegional: 5,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-04T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 2
        },
        {
          name: '2021-02-05T19:02:28.000Z',
          lookAlikeRegional: 3,
          retargeting: 1
        },
        {
          name: '2021-02-06T19:02:28.000Z',
          lookAlikeRegional: 20,
          retargeting: 5
        },
        {
          name: '2021-02-07T19:02:28.000Z',
          lookAlikeRegional: 3,
          retargeting: 1
        },
        {
          name: '2021-02-08T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 2
        },
        {
          name: '2021-02-09T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-02-10T19:02:28.000Z',
          lookAlikeRegional: 19,
          regional: 4,
          retargeting: 2
        },
        {
          name: '2021-02-11T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-12T19:02:28.000Z',
          lookAlikeRegional: 32,
          regional: 2
        },
        {
          name: '2021-02-13T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-14T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 0.2,
          retargeting: 1
        },
        {
          name: '2021-02-15T19:02:28.000Z',
          lookAlikeRegional: 6,
          regional: 5
        },
        {
          name: '2021-02-16T19:02:28.000Z',
          lookAlikeRegional: 18,
          retargeting: 2
        },
        {
          name: '2021-02-17T19:02:28.000Z',
          lookAlikeRegional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-18T19:02:28.000Z',
          lookAlikeRegional: 33,
          regional: 5,
          retargeting: 7
        },
        {
          name: '2021-02-19T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-02-20T19:02:28.000Z',
          lookAlikeRegional: 3,
          regional: 1,
          retargeting: 1
        },
        {
          name: '2021-02-21T19:02:28.000Z',
          lookAlikeRegional: 2,
          retargeting: 0.2
        },
        {
          name: '2021-02-22T19:02:28.000Z',
          lookAlikeRegional: 37,
          regional: 4,
          retargeting: 2
        },
        {
          name: '2021-02-23T19:02:28.000Z',
          lookAlikeRegional: 4
        },
        {
          name: '2021-02-24T19:02:28.000Z',
          lookAlikeRegional: 28,
          regional: 3
        },
        {
          name: '2021-02-25T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 1,
          retargeting: 1
        },
        {
          name: '2021-02-26T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 3,
          retargeting: 5
        },
        {
          name: '2021-02-27T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-28T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 1,
          retargeting: 1
        },
        {
          name: '2021-03-01T19:02:28.000Z',
          lookAlikeRegional: 14,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-02T19:02:28.000Z',
          lookAlikeRegional: 22,
          regional: 3
        },
        {
          name: '2021-03-03T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-04T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-05T19:02:28.000Z',
          lookAlikeRegional: 7,
          regional: 3
        },
        {
          name: '2021-03-06T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-07T19:02:28.000Z',
          lookAlikeRegional: 2
        },
        {
          name: '2021-03-08T19:02:28.000Z',
          lookAlikeRegional: 7,
          regional: 3,
          retargeting: 1
        },
        {
          name: '2021-03-09T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-10T19:02:28.000Z',
          lookAlikeRegional: 2
        },
        {
          name: '2021-03-11T19:02:28.000Z',
          lookAlikeRegional: 17
        },
        {
          name: '2021-03-12T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-13T19:02:28.000Z',
          lookAlikeRegional: 16
        },
        {
          name: '2021-03-14T19:02:28.000Z',
          lookAlikeRegional: 4,
          regional: 1
        },
        {
          name: '2021-03-15T19:02:28.000Z',
          lookAlikeRegional: 13
        },
        {
          name: '2021-03-16T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-17T19:02:28.000Z',
          lookAlikeRegional: 22,
          regional: 2,
          retargeting: 2
        },
        {
          name: '2021-03-18T19:02:28.000Z',
          lookAlikeRegional: 12,
          retargeting: 2
        },
        {
          name: '2021-03-19T19:02:28.000Z',
          lookAlikeRegional: 21,
          regional: 5
        },
        {
          name: '2021-03-20T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-21T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 3
        },
        {
          name: '2021-03-22T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-23T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-24T19:02:28.000Z',
          lookAlikeRegional: 1
        },
        {
          name: '2021-03-25T19:02:28.000Z',
          lookAlikeRegional: 8,
          regional: 7,
          retargeting: 2
        },
        {
          name: '2021-03-26T19:02:28.000Z',
          lookAlikeRegional: 8,
          regional: 9,
          retargeting: 1
        },
        {
          name: '2021-03-27T19:02:28.000Z',
          lookAlikeRegional: 16,
          regional: 8
        },
        {
          name: '2021-03-28T19:02:28.000Z',
          lookAlikeRegional: 8,
          regional: 7,
          retargeting: 2
        },
        {
          name: '2021-03-29T19:02:28.000Z',
          lookAlikeRegional: 8
        },
        {
          name: '2021-03-30T19:02:28.000Z',
          lookAlikeRegional: 26,
          regional: 7
        },
        {
          name: '2021-03-31T19:02:28.000Z',
          lookAlikeRegional: 22
        }
      ]
    },
    lineSeries: []
  },
  {
    title: 'CPA 1D Click Per Product',
    barSeries: {},
    lineSeries: [
      {
        category: 'Lookalike Regional',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 117
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: 109
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: 121
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 89
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: 128
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: 129
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 170
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 118
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 115
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 117
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 95
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 93
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: 85
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 112
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 121
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 125
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: 140
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 148
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 159
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 160
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: 170
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 171
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 178
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 179
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 143
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 113
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 118
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: 156
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 134
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 178
          }
        ]
      },
      {
        category: 'Regional',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 375
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 482
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 363
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 231
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: 307
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 534
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 1079
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 504
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 510
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 155
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 540
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 376
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 263
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 973
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: 943
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 944
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 278
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 73
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 646
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 656
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 603
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 388
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 20
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 1070
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 491
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 480
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 317
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: 1066
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 56
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 444
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 174
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 414
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 400
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: 761
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 113
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 753
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 740
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 741
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 343
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-31T19:02:28.000Z',
            value: 395
          }
        ]
      },
      {
        category: 'Retargeting',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 89
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 307
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: 57
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 362
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: 168
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 225
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 91
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 94
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 259
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 77
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 182
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 105
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 227
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: 45
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 124
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 102
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 112
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 96
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: 261
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 210
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 201
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 91
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 230
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 72
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 266
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 230
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 220
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 49
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 198
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 190
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: 47
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 49
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 48
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 44
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: 98
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 98
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-31T19:02:28.000Z',
            value: null
          }
        ]
      }
    ]
  },
  {
    title: 'Unq Leads 28D Click Per Product',
    barSeries: {
      bars: {
        lookAlikeRegional: 'Lookalike Regional',
        regional: 'Regional',
        retargeting: 'Retargeting'
      },
      barsData: [
        {
          name: '2021-02-01T19:02:28.000Z',
          lookAlikeRegional: 17,
          regional: 4,
          retargeting: 3
        },
        {
          name: '2021-02-02T19:02:28.000Z',
          lookAlikeRegional: 26,
          regional: 4,
          retargeting: 5
        },
        {
          name: '2021-02-03T19:02:28.000Z',
          lookAlikeRegional: 20,
          regional: 4,
          retargeting: 6
        },
        {
          name: '2021-02-04T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 6,
          retargeting: 1
        },
        {
          name: '2021-02-05T19:02:28.000Z',
          lookAlikeRegional: 21,
          retargeting: 6
        },
        {
          name: '2021-02-06T19:02:28.000Z',
          lookAlikeRegional: 20,
          retargeting: 5
        },
        {
          name: '2021-02-07T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 2,
          retargeting: 4
        },
        {
          name: '2021-02-08T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 2
        },
        {
          name: '2021-02-09T19:02:28.000Z',
          lookAlikeRegional: 26,
          regional: 1,
          retargeting: 4
        },
        {
          name: '2021-02-10T19:02:28.000Z',
          lookAlikeRegional: 19,
          regional: 4,
          retargeting: 2
        },
        {
          name: '2021-02-11T19:02:28.000Z',
          lookAlikeRegional: 6,
          regional: 5,
          retargeting: 7
        },
        {
          name: '2021-02-12T19:02:28.000Z',
          lookAlikeRegional: 17,
          retargeting: 2
        },
        {
          name: '2021-02-13T19:02:28.000Z',
          lookAlikeRegional: 22,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-02-14T19:02:28.000Z',
          lookAlikeRegional: 39,
          regional: 10,
          retargeting: 1
        },
        {
          name: '2021-02-15T19:02:28.000Z',
          lookAlikeRegional: 32,
          regional: 9
        },
        {
          name: '2021-02-16T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-02-17T19:02:28.000Z',
          lookAlikeRegional: 25,
          retargeting: 5
        },
        {
          name: '2021-02-18T19:02:28.000Z',
          lookAlikeRegional: 33,
          regional: 5,
          retargeting: 7
        },
        {
          name: '2021-02-19T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-02-20T19:02:28.000Z',
          lookAlikeRegional: 16,
          regional: 5,
          retargeting: 6
        },
        {
          name: '2021-02-21T19:02:28.000Z',
          lookAlikeRegional: 28,
          retargeting: 2
        },
        {
          name: '2021-02-22T19:02:28.000Z',
          lookAlikeRegional: 27,
          regional: 4,
          retargeting: 2
        },
        {
          name: '2021-02-23T19:02:28.000Z',
          lookAlikeRegional: 17,
          retargeting: 6
        },
        {
          name: '2021-02-24T19:02:28.000Z',
          lookAlikeRegional: 28,
          regional: 3
        },
        {
          name: '2021-02-25T19:02:28.000Z',
          lookAlikeRegional: 21,
          regional: 1,
          retargeting: 1
        },
        {
          name: '2021-02-26T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 3,
          retargeting: 5
        },
        {
          name: '2021-02-27T19:02:28.000Z',
          lookAlikeRegional: 27,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-28T19:02:28.000Z',
          lookAlikeRegional: 12,
          regional: 1,
          retargeting: 4
        },
        {
          name: '2021-03-01T19:02:28.000Z',
          lookAlikeRegional: 14,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-02T19:02:28.000Z',
          lookAlikeRegional: 22,
          regional: 3
        },
        {
          name: '2021-03-03T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-04T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-05T19:02:28.000Z',
          lookAlikeRegional: 27,
          regional: 3
        },
        {
          name: '2021-03-06T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 3,
          retargeting: 2
        },
        {
          name: '2021-03-07T19:02:28.000Z',
          lookAlikeRegional: 26
        },
        {
          name: '2021-03-08T19:02:28.000Z',
          lookAlikeRegional: 17,
          regional: 3,
          retargeting: 1
        },
        {
          name: '2021-03-09T19:02:28.000Z',
          lookAlikeRegional: 13,
          regional: 1,
          retargeting: 8
        },
        {
          name: '2021-03-10T19:02:28.000Z',
          lookAlikeRegional: 21,
          retargeting: 6
        },
        {
          name: '2021-03-11T19:02:28.000Z',
          lookAlikeRegional: 17
        },
        {
          name: '2021-03-12T19:02:28.000Z',
          lookAlikeRegional: 14,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-13T19:02:28.000Z',
          lookAlikeRegional: 16
        },
        {
          name: '2021-03-14T19:02:28.000Z',
          lookAlikeRegional: 24,
          regional: 1
        },
        {
          name: '2021-03-15T19:02:28.000Z',
          lookAlikeRegional: 13
        },
        {
          name: '2021-03-16T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-17T19:02:28.000Z',
          lookAlikeRegional: 22,
          regional: 2,
          retargeting: 2
        },
        {
          name: '2021-03-18T19:02:28.000Z',
          lookAlikeRegional: 22,
          retargeting: 2
        },
        {
          name: '2021-03-19T19:02:28.000Z',
          lookAlikeRegional: 21,
          regional: 5
        },
        {
          name: '2021-03-20T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-21T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 3
        },
        {
          name: '2021-03-22T19:02:28.000Z',
          lookAlikeRegional: 23,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-23T19:02:28.000Z',
          lookAlikeRegional: 33,
          regional: 1,
          retargeting: 2
        },
        {
          name: '2021-03-24T19:02:28.000Z',
          lookAlikeRegional: 16
        },
        {
          name: '2021-03-25T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 7,
          retargeting: 2
        },
        {
          name: '2021-03-26T19:02:28.000Z',
          lookAlikeRegional: 19,
          regional: 9,
          retargeting: 1
        },
        {
          name: '2021-03-27T19:02:28.000Z',
          lookAlikeRegional: 17,
          regional: 8
        },
        {
          name: '2021-03-28T19:02:28.000Z',
          lookAlikeRegional: 18,
          regional: 2,
          retargeting: 2
        },
        {
          name: '2021-03-29T19:02:28.000Z',
          lookAlikeRegional: 12
        },
        {
          name: '2021-03-30T19:02:28.000Z',
          lookAlikeRegional: 26,
          regional: 7
        },
        {
          name: '2021-03-31T19:02:28.000Z',
          lookAlikeRegional: 22
        }
      ]
    },
    lineSeries: []
  },
  {
    title: 'Unq CPL 28D Click Per Product',
    barSeries: {},
    lineSeries: [
      {
        category: 'Lookalike Regional',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 117
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: 109
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: 121
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 89
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: 128
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: 129
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 170
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 118
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 115
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 117
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 95
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 93
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: 85
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 112
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 121
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 125
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: 140
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 148
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 159
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 160
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: 170
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 171
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 178
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 179
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 143
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: 111
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 113
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 118
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: 150
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: 156
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 134
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: 145
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 178
          }
        ]
      },
      {
        category: 'Regional',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 375
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 482
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 363
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 231
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: 307
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 534
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 1079
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 504
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 510
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 155
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 540
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 376
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 86
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 263
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 973
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: 943
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 944
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 278
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 73
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 646
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 656
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 603
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 388
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 20
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 1070
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 491
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 480
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 317
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: 1066
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 56
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 120
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 444
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 174
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 414
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 400
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: 761
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 113
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 753
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 740
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 741
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 343
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-31T19:02:28.000Z',
            value: 395
          }
        ]
      },
      {
        category: 'Retargeting',
        data: [
          {
            name: '2021-02-01T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-02T19:02:28.000Z',
            value: 90
          },
          {
            name: '2021-02-03T19:02:28.000Z',
            value: 89
          },
          {
            name: '2021-02-04T19:02:28.000Z',
            value: 307
          },
          {
            name: '2021-02-05T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-06T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-07T19:02:28.000Z',
            value: 57
          },
          {
            name: '2021-02-08T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-02-09T19:02:28.000Z',
            value: 362
          },
          {
            name: '2021-02-10T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-02-11T19:02:28.000Z',
            value: 168
          },
          {
            name: '2021-02-12T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-02-13T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-14T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-15T19:02:28.000Z',
            value: 225
          },
          {
            name: '2021-02-16T19:02:28.000Z',
            value: 91
          },
          {
            name: '2021-02-17T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-18T19:02:28.000Z',
            value: 94
          },
          {
            name: '2021-02-19T19:02:28.000Z',
            value: 259
          },
          {
            name: '2021-02-20T19:02:28.000Z',
            value: 77
          },
          {
            name: '2021-02-21T19:02:28.000Z',
            value: 139
          },
          {
            name: '2021-02-22T19:02:28.000Z',
            value: 182
          },
          {
            name: '2021-02-23T19:02:28.000Z',
            value: 105
          },
          {
            name: '2021-02-24T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-02-25T19:02:28.000Z',
            value: 227
          },
          {
            name: '2021-02-26T19:02:28.000Z',
            value: 100
          },
          {
            name: '2021-02-27T19:02:28.000Z',
            value: 45
          },
          {
            name: '2021-02-28T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-03-01T19:02:28.000Z',
            value: 124
          },
          {
            name: '2021-03-02T19:02:28.000Z',
            value: 102
          },
          {
            name: '2021-03-03T19:02:28.000Z',
            value: 112
          },
          {
            name: '2021-03-04T19:02:28.000Z',
            value: 96
          },
          {
            name: '2021-03-05T19:02:28.000Z',
            value: 261
          },
          {
            name: '2021-03-06T19:02:28.000Z',
            value: 210
          },
          {
            name: '2021-03-07T19:02:28.000Z',
            value: 201
          },
          {
            name: '2021-03-08T19:02:28.000Z',
            value: 91
          },
          {
            name: '2021-03-09T19:02:28.000Z',
            value: 230
          },
          {
            name: '2021-03-10T19:02:28.000Z',
            value: 72
          },
          {
            name: '2021-03-11T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-12T19:02:28.000Z',
            value: 266
          },
          {
            name: '2021-03-13T19:02:28.000Z',
            value: 230
          },
          {
            name: '2021-03-14T19:02:28.000Z',
            value: 220
          },
          {
            name: '2021-03-15T19:02:28.000Z',
            value: 49
          },
          {
            name: '2021-03-16T19:02:28.000Z',
            value: 198
          },
          {
            name: '2021-03-17T19:02:28.000Z',
            value: 190
          },
          {
            name: '2021-03-18T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-19T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-20T19:02:28.000Z',
            value: 47
          },
          {
            name: '2021-04-21T19:02:28.000Z',
            value: 49
          },
          {
            name: '2021-03-22T19:02:28.000Z',
            value: 48
          },
          {
            name: '2021-03-23T19:02:28.000Z',
            value: 44
          },
          {
            name: '2021-03-24T19:02:28.000Z',
            value: 99
          },
          {
            name: '2021-03-25T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-26T19:02:28.000Z',
            value: 98
          },
          {
            name: '2021-03-27T19:02:28.000Z',
            value: 98
          },
          {
            name: '2021-03-28T19:02:28.000Z',
            value: 104
          },
          {
            name: '2021-03-29T19:02:28.000Z',
            value: 103
          },
          {
            name: '2021-03-30T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-03-31T19:02:28.000Z',
            value: null
          }
        ]
      }
    ]
  },
  {
    title: 'Unq Quote CR% 28D Click Per Product',
    barSeries: {
      bars: {
        lookAlikeRegional: 'Lookalike Regional',
        regional: 'Regional',
        retargeting: 'Retargeting'
      },
      barsData: [
        {
          name: '2021-02-01T19:02:28.000Z',
          lookAlikeRegional: 1,
          regional: 2,
          retargeting: 1
        },
        {
          name: '2021-02-02T19:02:28.000Z',
          lookAlikeRegional: 2,
          regional: 1,
          retargeting: 1
        },
        {
          name: '2021-02-03T19:02:28.000Z',
          lookAlikeRegional: 1,
          regional: 1,
          retargeting: 4
        },
        {
          name: '2021-02-04T19:02:28.000Z',
          lookAlikeRegional: 1,
          retargeting: 2
        },
        {
          name: '2021-02-05T19:02:28.000Z',
          lookAlikeRegional: 1
        },
        {
          name: '2021-02-06T19:02:28.000Z'
        },
        {
          name: '2021-02-07T19:02:28.000Z'
        },
        {
          name: '2021-02-08T19:02:28.000Z',
          lookAlikeRegional: 2
        },
        {
          name: '2021-02-09T19:02:28.000Z'
        },
        {
          name: '2021-02-10T19:02:28.000Z'
        },
        {
          name: '2021-02-11T19:02:28.000Z'
        },
        {
          name: '2021-02-12T19:02:28.000Z'
        },
        {
          name: '2021-02-13T19:02:28.000Z'
        },
        {
          name: '2021-02-14T19:02:28.000Z'
        },
        {
          name: '2021-02-15T19:02:28.000Z'
        },
        {
          name: '2021-02-16T19:02:28.000Z',
          regional: 1
        }
      ]
    },
    lineSeries: []
  },
  {
    title: 'Apps 28D Click Per Product',
    barSeries: {},
    lineSeries: [
      {
        category: 'Lookalike Regional',
        data: [
          {
            name: '2021-01-01T19:02:28.000Z',
            value: 2361
          },
          {
            name: '2021-01-02T19:02:28.000Z',
            value: 1196
          },
          {
            name: '2021-01-03T19:02:28.000Z',
            value: 2344
          },
          {
            name: '2021-01-04T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-05T19:02:28.000Z',
            value: 2341
          },
          {
            name: '2021-01-06T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-07T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-08T19:02:28.000Z',
            value: 1181
          }
        ]
      },
      {
        category: 'Regional',
        data: [
          {
            name: '2021-01-01T19:02:28.000Z',
            value: 750
          },
          {
            name: '2021-01-02T19:02:28.000Z',
            value: 1446
          },
          {
            name: '2021-01-03T19:02:28.000Z',
            value: 1450
          },
          {
            name: '2021-01-04T19:02:28.000Z',
            value: 1388
          },
          {
            name: '2021-01-05T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-06T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-07T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-08T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-09T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-10T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-11T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-12T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-13T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-14T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-15T19:02:28.000Z',
            value: null
          },
          {
            name: '2021-01-16T19:02:28.000Z',
            value: 1113
          }
        ]
      },
      {
        category: 'Retargeting',
        data: [
          {
            name: '2021-01-01T19:02:28.000Z',
            value: 296
          },
          {
            name: '2021-01-02T19:02:28.000Z',
            value: 301
          },
          {
            name: '2021-01-03T19:02:28.000Z',
            value: 91
          },
          {
            name: '2021-01-04T19:02:28.000Z',
            value: 174
          }
        ]
      }
    ]
  }
];

export const lineChartData = {
  title: 'Hero Metric 1',
  total: 56981,
  round: true,
  data: [
    {
      id: 0,
      name: '28/02/2022',
      valueToShow: 793,
      tooltipValue: '28/02/2022',
      value: 793,
      label: 793,
      percentage: 1.39
    },
    {
      id: 1,
      name: '',
      valueToShow: '',
      tooltipValue: '01/03/2022',
      value: 704,
      label: 704,
      percentage: 1.24
    },
    {
      id: 2,
      name: '02/03/2022',
      valueToShow: 715,
      tooltipValue: '02/03/2022',
      value: 715,
      label: 715,
      percentage: 1.25
    },
    {
      id: 3,
      name: '',
      valueToShow: '',
      tooltipValue: '03/03/2022',
      value: 616,
      label: 616,
      percentage: 1.08
    },
    {
      id: 4,
      name: '04/03/2022',
      valueToShow: 604,
      tooltipValue: '04/03/2022',
      value: 604,
      label: 604,
      percentage: 1.06
    },
    {
      id: 5,
      name: '',
      valueToShow: '',
      tooltipValue: '05/03/2022',
      value: 542,
      label: 542,
      percentage: 0.95
    },
    {
      id: 6,
      name: '06/03/2022',
      valueToShow: 658,
      tooltipValue: '06/03/2022',
      value: 658,
      label: 658,
      percentage: 1.15
    },
    {
      id: 7,
      name: '',
      valueToShow: '',
      tooltipValue: '07/03/2022',
      value: 661,
      label: 661,
      percentage: 1.16
    },
    {
      id: 8,
      name: '08/03/2022',
      valueToShow: 635,
      tooltipValue: '08/03/2022',
      value: 635,
      label: 635,
      percentage: 1.11
    },
    {
      id: 9,
      name: '',
      valueToShow: '',
      tooltipValue: '09/03/2022',
      value: 662,
      label: 662,
      percentage: 1.16
    },
    {
      id: 10,
      name: '10/03/2022',
      valueToShow: 573,
      tooltipValue: '10/03/2022',
      value: 573,
      label: 573,
      percentage: 1.01
    },
    {
      id: 11,
      name: '',
      valueToShow: '',
      tooltipValue: '11/03/2022',
      value: 618,
      label: 618,
      percentage: 1.08
    },
    {
      id: 12,
      name: '12/03/2022',
      valueToShow: 617,
      tooltipValue: '12/03/2022',
      value: 617,
      label: 617,
      percentage: 1.08
    },
    {
      id: 13,
      name: '',
      valueToShow: '',
      tooltipValue: '13/03/2022',
      value: 588,
      label: 588,
      percentage: 1.03
    },
    {
      id: 14,
      name: '14/03/2022',
      valueToShow: 554,
      tooltipValue: '14/03/2022',
      value: 554,
      label: 554,
      percentage: 0.97
    },
    {
      id: 15,
      name: '',
      valueToShow: '',
      tooltipValue: '15/03/2022',
      value: 702,
      label: 702,
      percentage: 1.23
    },
    {
      id: 16,
      name: '16/03/2022',
      valueToShow: 609,
      tooltipValue: '16/03/2022',
      value: 609,
      label: 609,
      percentage: 1.07
    },
    {
      id: 17,
      name: '',
      valueToShow: '',
      tooltipValue: '17/03/2022',
      value: 484,
      label: 484,
      percentage: 0.85
    },
    {
      id: 18,
      name: '18/03/2022',
      valueToShow: 416,
      tooltipValue: '18/03/2022',
      value: 416,
      label: 416,
      percentage: 0.73
    },
    {
      id: 19,
      name: '',
      valueToShow: '',
      tooltipValue: '19/03/2022',
      value: 432,
      label: 432,
      percentage: 0.76
    },
    {
      id: 20,
      name: '20/03/2022',
      valueToShow: 446,
      tooltipValue: '20/03/2022',
      value: 446,
      label: 446,
      percentage: 0.78
    },
    {
      id: 21,
      name: '',
      valueToShow: '',
      tooltipValue: '21/03/2022',
      value: 507,
      label: 507,
      percentage: 0.89
    },
    {
      id: 22,
      name: '22/03/2022',
      valueToShow: 692,
      tooltipValue: '22/03/2022',
      value: 692,
      label: 692,
      percentage: 1.21
    },
    {
      id: 23,
      name: '',
      valueToShow: '',
      tooltipValue: '23/03/2022',
      value: 688,
      label: 688,
      percentage: 1.21
    },
    {
      id: 24,
      name: '24/03/2022',
      valueToShow: 640,
      tooltipValue: '24/03/2022',
      value: 640,
      label: 640,
      percentage: 1.12
    },
    {
      id: 25,
      name: '',
      valueToShow: '',
      tooltipValue: '25/03/2022',
      value: 636,
      label: 636,
      percentage: 1.12
    },
    {
      id: 26,
      name: '26/03/2022',
      valueToShow: 660,
      tooltipValue: '26/03/2022',
      value: 660,
      label: 660,
      percentage: 1.16
    },
    {
      id: 27,
      name: '',
      valueToShow: '',
      tooltipValue: '27/03/2022',
      value: 727,
      label: 727,
      percentage: 1.28
    },
    {
      id: 28,
      name: '28/03/2022',
      valueToShow: 784,
      tooltipValue: '28/03/2022',
      value: 784,
      label: 784,
      percentage: 1.38
    },
    {
      id: 29,
      name: '',
      valueToShow: '',
      tooltipValue: '29/03/2022',
      value: 872,
      label: 872,
      percentage: 1.53
    },
    {
      id: 30,
      name: '30/03/2022',
      valueToShow: 904,
      tooltipValue: '30/03/2022',
      value: 904,
      label: 904,
      percentage: 1.59
    },
    {
      id: 31,
      name: '',
      valueToShow: '',
      tooltipValue: '31/03/2022',
      value: 662,
      label: 662,
      percentage: 1.16
    },
    {
      id: 32,
      name: '01/04/2022',
      valueToShow: 584,
      tooltipValue: '01/04/2022',
      value: 584,
      label: 584,
      percentage: 1.02
    },
    {
      id: 33,
      name: '',
      valueToShow: '',
      tooltipValue: '02/04/2022',
      value: 423,
      label: 423,
      percentage: 0.74
    },
    {
      id: 34,
      name: '03/04/2022',
      valueToShow: 599,
      tooltipValue: '03/04/2022',
      value: 599,
      label: 599,
      percentage: 1.05
    },
    {
      id: 35,
      name: '',
      valueToShow: '',
      tooltipValue: '04/04/2022',
      value: 708,
      label: 708,
      percentage: 1.24
    },
    {
      id: 36,
      name: '05/04/2022',
      valueToShow: 696,
      tooltipValue: '05/04/2022',
      value: 696,
      label: 696,
      percentage: 1.22
    },
    {
      id: 37,
      name: '',
      valueToShow: '',
      tooltipValue: '06/04/2022',
      value: 594,
      label: 594,
      percentage: 1.04
    },
    {
      id: 38,
      name: '07/04/2022',
      valueToShow: 755,
      tooltipValue: '07/04/2022',
      value: 755,
      label: 755,
      percentage: 1.33
    },
    {
      id: 39,
      name: '',
      valueToShow: '',
      tooltipValue: '08/04/2022',
      value: 658,
      label: 658,
      percentage: 1.15
    },
    {
      id: 40,
      name: '09/04/2022',
      valueToShow: 594,
      tooltipValue: '09/04/2022',
      value: 594,
      label: 594,
      percentage: 1.04
    },
    {
      id: 41,
      name: '',
      valueToShow: '',
      tooltipValue: '10/04/2022',
      value: 566,
      label: 566,
      percentage: 0.99
    },
    {
      id: 42,
      name: '11/04/2022',
      valueToShow: 597,
      tooltipValue: '11/04/2022',
      value: 597,
      label: 597,
      percentage: 1.05
    },
    {
      id: 43,
      name: '',
      valueToShow: '',
      tooltipValue: '12/04/2022',
      value: 682,
      label: 682,
      percentage: 1.2
    },
    {
      id: 44,
      name: '13/04/2022',
      valueToShow: 720,
      tooltipValue: '13/04/2022',
      value: 720,
      label: 720,
      percentage: 1.26
    },
    {
      id: 45,
      name: '',
      valueToShow: '',
      tooltipValue: '14/04/2022',
      value: 696,
      label: 696,
      percentage: 1.22
    },
    {
      id: 46,
      name: '15/04/2022',
      valueToShow: 523,
      tooltipValue: '15/04/2022',
      value: 523,
      label: 523,
      percentage: 0.92
    },
    {
      id: 47,
      name: '',
      valueToShow: '',
      tooltipValue: '16/04/2022',
      value: 474,
      label: 474,
      percentage: 0.83
    },
    {
      id: 48,
      name: '17/04/2022',
      valueToShow: 480,
      tooltipValue: '17/04/2022',
      value: 480,
      label: 480,
      percentage: 0.84
    },
    {
      id: 49,
      name: '',
      valueToShow: '',
      tooltipValue: '18/04/2022',
      value: 718,
      label: 718,
      percentage: 1.26
    },
    {
      id: 50,
      name: '19/04/2022',
      valueToShow: 855,
      tooltipValue: '19/04/2022',
      value: 855,
      label: 855,
      percentage: 1.5
    },
    {
      id: 51,
      name: '',
      valueToShow: '',
      tooltipValue: '20/04/2022',
      value: 768,
      label: 768,
      percentage: 1.35
    },
    {
      id: 52,
      name: '21/04/2022',
      valueToShow: 699,
      tooltipValue: '21/04/2022',
      value: 699,
      label: 699,
      percentage: 1.23
    },
    {
      id: 53,
      name: '',
      valueToShow: '',
      tooltipValue: '22/04/2022',
      value: 631,
      label: 631,
      percentage: 1.11
    },
    {
      id: 54,
      name: '23/04/2022',
      valueToShow: 728,
      tooltipValue: '23/04/2022',
      value: 728,
      label: 728,
      percentage: 1.28
    },
    {
      id: 55,
      name: '',
      valueToShow: '',
      tooltipValue: '24/04/2022',
      value: 732,
      label: 732,
      percentage: 1.28
    },
    {
      id: 56,
      name: '25/04/2022',
      valueToShow: 755,
      tooltipValue: '25/04/2022',
      value: 755,
      label: 755,
      percentage: 1.33
    },
    {
      id: 57,
      name: '',
      valueToShow: '',
      tooltipValue: '26/04/2022',
      value: 864,
      label: 864,
      percentage: 1.52
    },
    {
      id: 58,
      name: '27/04/2022',
      valueToShow: 817,
      tooltipValue: '27/04/2022',
      value: 817,
      label: 817,
      percentage: 1.43
    },
    {
      id: 59,
      name: '',
      valueToShow: '',
      tooltipValue: '28/04/2022',
      value: 859,
      label: 859,
      percentage: 1.51
    },
    {
      id: 60,
      name: '29/04/2022',
      valueToShow: 669,
      tooltipValue: '29/04/2022',
      value: 669,
      label: 669,
      percentage: 1.17
    },
    {
      id: 61,
      name: '',
      valueToShow: '',
      tooltipValue: '30/04/2022',
      value: 719,
      label: 719,
      percentage: 1.26
    },
    {
      id: 62,
      name: '01/05/2022',
      valueToShow: 704,
      tooltipValue: '01/05/2022',
      value: 704,
      label: 704,
      percentage: 1.24
    },
    {
      id: 63,
      name: '',
      valueToShow: '',
      tooltipValue: '02/05/2022',
      value: 891,
      label: 891,
      percentage: 1.56
    },
    {
      id: 64,
      name: '03/05/2022',
      valueToShow: 997,
      tooltipValue: '03/05/2022',
      value: 997,
      label: 997,
      percentage: 1.75
    },
    {
      id: 65,
      name: '',
      valueToShow: '',
      tooltipValue: '04/05/2022',
      value: 1127,
      label: 1127,
      percentage: 1.98
    },
    {
      id: 66,
      name: '05/05/2022',
      valueToShow: 927,
      tooltipValue: '05/05/2022',
      value: 927,
      label: 927,
      percentage: 1.63
    },
    {
      id: 67,
      name: '',
      valueToShow: '',
      tooltipValue: '06/05/2022',
      value: 756,
      label: 756,
      percentage: 1.33
    },
    {
      id: 68,
      name: '07/05/2022',
      valueToShow: 731,
      tooltipValue: '07/05/2022',
      value: 731,
      label: 731,
      percentage: 1.28
    },
    {
      id: 69,
      name: '',
      valueToShow: '',
      tooltipValue: '08/05/2022',
      value: 492,
      label: 492,
      percentage: 0.86
    },
    {
      id: 70,
      name: '09/05/2022',
      valueToShow: 590,
      tooltipValue: '09/05/2022',
      value: 590,
      label: 590,
      percentage: 1.04
    },
    {
      id: 71,
      name: '',
      valueToShow: '',
      tooltipValue: '10/05/2022',
      value: 1020,
      label: 1020,
      percentage: 1.79
    },
    {
      id: 72,
      name: '11/05/2022',
      valueToShow: 1013,
      tooltipValue: '11/05/2022',
      value: 1013,
      label: 1013,
      percentage: 1.78
    },
    {
      id: 73,
      name: '',
      valueToShow: '',
      tooltipValue: '12/05/2022',
      value: 709,
      label: 709,
      percentage: 1.24
    },
    {
      id: 74,
      name: '13/05/2022',
      valueToShow: 712,
      tooltipValue: '13/05/2022',
      value: 712,
      label: 712,
      percentage: 1.25
    },
    {
      id: 75,
      name: '',
      valueToShow: '',
      tooltipValue: '14/05/2022',
      value: 497,
      label: 497,
      percentage: 0.87
    },
    {
      id: 76,
      name: '15/05/2022',
      valueToShow: 560,
      tooltipValue: '15/05/2022',
      value: 560,
      label: 560,
      percentage: 0.98
    },
    {
      id: 77,
      name: '',
      valueToShow: '',
      tooltipValue: '16/05/2022',
      value: 626,
      label: 626,
      percentage: 1.1
    },
    {
      id: 78,
      name: '17/05/2022',
      valueToShow: 570,
      tooltipValue: '17/05/2022',
      value: 570,
      label: 570,
      percentage: 1
    },
    {
      id: 79,
      name: '',
      valueToShow: '',
      tooltipValue: '18/05/2022',
      value: 539,
      label: 539,
      percentage: 0.95
    },
    {
      id: 80,
      name: '19/05/2022',
      valueToShow: 407,
      tooltipValue: '19/05/2022',
      value: 407,
      label: 407,
      percentage: 0.71
    },
    {
      id: 81,
      name: '',
      valueToShow: '',
      tooltipValue: '20/05/2022',
      value: 433,
      label: 433,
      percentage: 0.76
    },
    {
      id: 82,
      name: '21/05/2022',
      valueToShow: 308,
      tooltipValue: '21/05/2022',
      value: 308,
      label: 308,
      percentage: 0.54
    },
    {
      id: 83,
      name: '',
      valueToShow: '',
      tooltipValue: '22/05/2022',
      value: 302,
      label: 302,
      percentage: 0.53
    },
    {
      id: 84,
      name: '23/05/2022',
      valueToShow: 371,
      tooltipValue: '23/05/2022',
      value: 371,
      label: 371,
      percentage: 0.65
    },
    {
      id: 85,
      name: '',
      valueToShow: '',
      tooltipValue: '24/05/2022',
      value: 515,
      label: 515,
      percentage: 0.9
    },
    {
      id: 86,
      name: '25/05/2022',
      valueToShow: 558,
      tooltipValue: '25/05/2022',
      value: 558,
      label: 558,
      percentage: 0.98
    },
    {
      id: 87,
      name: '',
      valueToShow: '',
      tooltipValue: '26/05/2022',
      value: 62,
      label: 62,
      percentage: 0.11
    }
  ]
};

export const tableMockData = {
  rows: [
    {
      day: '14/03/2022',
      projectName: '14/03/2022',
      productName: '14/03/2022',
      cityId: '14/03/2022',
      regionId: '14/03/2022',
      spent: 76813.87,
      cpm: 13.29,
      impressions: 5778577,
      linkCTR: 0.27,
      linkClicks: 15818,
      cpc: 4.86,
      landingPageViews: 6414,
      landingPageViewsRate: 40.5,
      quaternaryReportingConversion: 277,
      quaternaryReportingConversionCr: 1.75,
      quaternaryReportingConversionCpa: 277.31,
      tertiaryReportingConversion: 190,
      tertiaryReportingConversionCr: 1.2,
      tertiaryReportingConversionCpa: 404.28,
      secondaryReportingConversion: 362,
      secondaryReportingConversionCr: 2.29,
      secondaryReportingConversionCpa: 212.19,
      primaryReportingConversion: 554,
      primaryReportingConversionCr: 3.5,
      primaryReportingConversionCpa: 138.65
    },
    {
      day: '15/03/2022',
      projectName: '15/03/2022',
      productName: '15/03/2022',
      cityId: '15/03/2022',
      regionId: '15/03/2022',
      spent: 88045.46,
      cpm: 13.97,
      impressions: 6301762,
      linkCTR: 0.31,
      linkClicks: 19798,
      cpc: 4.45,
      landingPageViews: 8591,
      landingPageViewsRate: 43.4,
      quaternaryReportingConversion: 357,
      quaternaryReportingConversionCr: 1.8,
      quaternaryReportingConversionCpa: 246.63,
      tertiaryReportingConversion: 263,
      tertiaryReportingConversionCr: 1.33,
      tertiaryReportingConversionCpa: 334.77,
      secondaryReportingConversion: 394,
      secondaryReportingConversionCr: 1.99,
      secondaryReportingConversionCpa: 223.47,
      primaryReportingConversion: 702,
      primaryReportingConversionCr: 3.55,
      primaryReportingConversionCpa: 125.42
    },
    {
      day: '16/03/2022',
      projectName: '16/03/2022',
      productName: '16/03/2022',
      cityId: '16/03/2022',
      regionId: '16/03/2022',
      spent: 88619.6,
      cpm: 14.83,
      impressions: 5976240,
      linkCTR: 0.33,
      linkClicks: 19839,
      cpc: 4.47,
      landingPageViews: 7948,
      landingPageViewsRate: 40.1,
      quaternaryReportingConversion: 268,
      quaternaryReportingConversionCr: 1.35,
      quaternaryReportingConversionCpa: 330.67,
      tertiaryReportingConversion: 200,
      tertiaryReportingConversionCr: 1.01,
      tertiaryReportingConversionCpa: 443.1,
      secondaryReportingConversion: 316,
      secondaryReportingConversionCr: 1.59,
      secondaryReportingConversionCpa: 280.44,
      primaryReportingConversion: 609,
      primaryReportingConversionCr: 3.07,
      primaryReportingConversionCpa: 145.52
    },
    {
      day: '17/03/2022',
      projectName: '17/03/2022',
      productName: '17/03/2022',
      cityId: '17/03/2022',
      regionId: '17/03/2022',
      spent: 85881.48,
      cpm: 14.45,
      impressions: 5942794,
      linkCTR: 0.27,
      linkClicks: 16007,
      cpc: 5.37,
      landingPageViews: 6579,
      landingPageViewsRate: 41.1,
      quaternaryReportingConversion: 279,
      quaternaryReportingConversionCr: 1.74,
      quaternaryReportingConversionCpa: 307.82,
      tertiaryReportingConversion: 241,
      tertiaryReportingConversionCr: 1.51,
      tertiaryReportingConversionCpa: 356.35,
      secondaryReportingConversion: 350,
      secondaryReportingConversionCr: 2.19,
      secondaryReportingConversionCpa: 245.38,
      primaryReportingConversion: 484,
      primaryReportingConversionCr: 3.02,
      primaryReportingConversionCpa: 177.44
    },
    {
      day: '18/03/2022',
      projectName: '18/03/2022',
      productName: '18/03/2022',
      cityId: '18/03/2022',
      regionId: '18/03/2022',
      spent: 80068.33,
      cpm: 16.48,
      impressions: 4857762,
      linkCTR: 0.3,
      linkClicks: 14553,
      cpc: 5.5,
      landingPageViews: 6016,
      landingPageViewsRate: 41.3,
      quaternaryReportingConversion: 169,
      quaternaryReportingConversionCr: 1.16,
      quaternaryReportingConversionCpa: 473.78,
      tertiaryReportingConversion: 178,
      tertiaryReportingConversionCr: 1.22,
      tertiaryReportingConversionCpa: 449.82,
      secondaryReportingConversion: 254,
      secondaryReportingConversionCr: 1.75,
      secondaryReportingConversionCpa: 315.23,
      primaryReportingConversion: 416,
      primaryReportingConversionCr: 2.86,
      primaryReportingConversionCpa: 192.47
    },
    {
      day: '19/03/2022',
      projectName: '19/03/2022',
      productName: '19/03/2022',
      cityId: '19/03/2022',
      regionId: '19/03/2022',
      spent: 70418.68,
      cpm: 15.88,
      impressions: 4435788,
      linkCTR: 0.3,
      linkClicks: 13495,
      cpc: 5.22,
      landingPageViews: 5630,
      landingPageViewsRate: 41.7,
      quaternaryReportingConversion: 199,
      quaternaryReportingConversionCr: 1.47,
      quaternaryReportingConversionCpa: 353.86,
      tertiaryReportingConversion: 179,
      tertiaryReportingConversionCr: 1.33,
      tertiaryReportingConversionCpa: 393.4,
      secondaryReportingConversion: 270,
      secondaryReportingConversionCr: 2,
      secondaryReportingConversionCpa: 260.81,
      primaryReportingConversion: 432,
      primaryReportingConversionCr: 3.2,
      primaryReportingConversionCpa: 163.01
    },
    {
      day: '20/03/2022',
      projectName: '20/03/2022',
      productName: '20/03/2022',
      cityId: '20/03/2022',
      regionId: '20/03/2022',
      spent: 61755.53,
      cpm: 13.9,
      impressions: 4443063,
      linkCTR: 0.26,
      linkClicks: 11603,
      cpc: 5.32,
      landingPageViews: 4310,
      landingPageViewsRate: 37.1,
      quaternaryReportingConversion: 180,
      quaternaryReportingConversionCr: 1.55,
      quaternaryReportingConversionCpa: 343.09,
      tertiaryReportingConversion: 168,
      tertiaryReportingConversionCr: 1.45,
      tertiaryReportingConversionCpa: 367.59,
      secondaryReportingConversion: 222,
      secondaryReportingConversionCr: 1.91,
      secondaryReportingConversionCpa: 278.18,
      primaryReportingConversion: 446,
      primaryReportingConversionCr: 3.84,
      primaryReportingConversionCpa: 138.47
    },
    {
      day: '21/03/2022',
      projectName: '21/03/2022',
      productName: '21/03/2022',
      cityId: '21/03/2022',
      regionId: '21/03/2022',
      spent: 65800.71,
      cpm: 14.31,
      impressions: 4598754,
      linkCTR: 0.26,
      linkClicks: 11950,
      cpc: 5.51,
      landingPageViews: 4403,
      landingPageViewsRate: 36.8,
      quaternaryReportingConversion: 210,
      quaternaryReportingConversionCr: 1.76,
      quaternaryReportingConversionCpa: 313.34,
      tertiaryReportingConversion: 192,
      tertiaryReportingConversionCr: 1.61,
      tertiaryReportingConversionCpa: 342.71,
      secondaryReportingConversion: 297,
      secondaryReportingConversionCr: 2.49,
      secondaryReportingConversionCpa: 221.55,
      primaryReportingConversion: 507,
      primaryReportingConversionCr: 4.24,
      primaryReportingConversionCpa: 129.78
    },
    {
      day: '22/03/2022',
      projectName: '22/03/2022',
      productName: '22/03/2022',
      cityId: '22/03/2022',
      regionId: '22/03/2022',
      spent: 71872.2,
      cpm: 15.14,
      impressions: 4748247,
      linkCTR: 0.31,
      linkClicks: 14694,
      cpc: 4.89,
      landingPageViews: 6117,
      landingPageViewsRate: 41.6,
      quaternaryReportingConversion: 310,
      quaternaryReportingConversionCr: 2.11,
      quaternaryReportingConversionCpa: 231.85,
      tertiaryReportingConversion: 220,
      tertiaryReportingConversionCr: 1.5,
      tertiaryReportingConversionCpa: 326.69,
      secondaryReportingConversion: 314,
      secondaryReportingConversionCr: 2.14,
      secondaryReportingConversionCpa: 228.89,
      primaryReportingConversion: 692,
      primaryReportingConversionCr: 4.71,
      primaryReportingConversionCpa: 103.86
    },
    {
      day: '23/03/2022',
      projectName: '23/03/2022',
      productName: '23/03/2022',
      cityId: '23/03/2022',
      regionId: '23/03/2022',
      spent: 76097.09,
      cpm: 15.44,
      impressions: 4929526,
      linkCTR: 0.3,
      linkClicks: 14668,
      cpc: 5.19,
      landingPageViews: 6127,
      landingPageViewsRate: 41.8,
      quaternaryReportingConversion: 324,
      quaternaryReportingConversionCr: 2.21,
      quaternaryReportingConversionCpa: 234.87,
      tertiaryReportingConversion: 244,
      tertiaryReportingConversionCr: 1.66,
      tertiaryReportingConversionCpa: 311.87,
      secondaryReportingConversion: 303,
      secondaryReportingConversionCr: 2.07,
      secondaryReportingConversionCpa: 251.15,
      primaryReportingConversion: 688,
      primaryReportingConversionCr: 4.69,
      primaryReportingConversionCpa: 110.61
    },
    {
      day: '24/03/2022',
      projectName: '24/03/2022',
      productName: '24/03/2022',
      cityId: '24/03/2022',
      regionId: '24/03/2022',
      spent: 76921.92,
      cpm: 16.24,
      impressions: 4737638,
      linkCTR: 0.29,
      linkClicks: 13836,
      cpc: 5.56,
      landingPageViews: 5552,
      landingPageViewsRate: 40.1,
      quaternaryReportingConversion: 258,
      quaternaryReportingConversionCr: 1.86,
      quaternaryReportingConversionCpa: 298.15,
      tertiaryReportingConversion: 231,
      tertiaryReportingConversionCr: 1.67,
      tertiaryReportingConversionCpa: 333,
      secondaryReportingConversion: 342,
      secondaryReportingConversionCr: 2.47,
      secondaryReportingConversionCpa: 224.92,
      primaryReportingConversion: 640,
      primaryReportingConversionCr: 4.63,
      primaryReportingConversionCpa: 120.19
    },
    {
      day: '25/03/2022',
      projectName: '25/03/2022',
      productName: '25/03/2022',
      cityId: '25/03/2022',
      regionId: '25/03/2022',
      spent: 77437.46,
      cpm: 17.23,
      impressions: 4495026,
      linkCTR: 0.3,
      linkClicks: 13618,
      cpc: 5.69,
      landingPageViews: 4980,
      landingPageViewsRate: 36.6,
      quaternaryReportingConversion: 226,
      quaternaryReportingConversionCr: 1.66,
      quaternaryReportingConversionCpa: 342.64,
      tertiaryReportingConversion: 198,
      tertiaryReportingConversionCr: 1.45,
      tertiaryReportingConversionCpa: 391.1,
      secondaryReportingConversion: 293,
      secondaryReportingConversionCr: 2.15,
      secondaryReportingConversionCpa: 264.29,
      primaryReportingConversion: 636,
      primaryReportingConversionCr: 4.67,
      primaryReportingConversionCpa: 121.76
    },
    {
      day: '26/03/2022',
      projectName: '26/03/2022',
      productName: '26/03/2022',
      cityId: '26/03/2022',
      regionId: '26/03/2022',
      spent: 77900.24,
      cpm: 17.28,
      impressions: 4507779,
      linkCTR: 0.3,
      linkClicks: 13764,
      cpc: 5.66,
      landingPageViews: 5014,
      landingPageViewsRate: 36.4,
      quaternaryReportingConversion: 214,
      quaternaryReportingConversionCr: 1.55,
      quaternaryReportingConversionCpa: 364.02,
      tertiaryReportingConversion: 182,
      tertiaryReportingConversionCr: 1.32,
      tertiaryReportingConversionCpa: 428.02,
      secondaryReportingConversion: 286,
      secondaryReportingConversionCr: 2.08,
      secondaryReportingConversionCpa: 272.38,
      primaryReportingConversion: 660,
      primaryReportingConversionCr: 4.8,
      primaryReportingConversionCpa: 118.03
    },
    {
      day: '27/03/2022',
      projectName: '27/03/2022',
      productName: '27/03/2022',
      cityId: '27/03/2022',
      regionId: '27/03/2022',
      spent: 78299.15,
      cpm: 17.23,
      impressions: 4543109,
      linkCTR: 0.29,
      linkClicks: 13342,
      cpc: 5.87,
      landingPageViews: 4909,
      landingPageViewsRate: 36.8,
      quaternaryReportingConversion: 286,
      quaternaryReportingConversionCr: 2.14,
      quaternaryReportingConversionCpa: 273.77,
      tertiaryReportingConversion: 206,
      tertiaryReportingConversionCr: 1.54,
      tertiaryReportingConversionCpa: 380.09,
      secondaryReportingConversion: 271,
      secondaryReportingConversionCr: 2.03,
      secondaryReportingConversionCpa: 288.93,
      primaryReportingConversion: 727,
      primaryReportingConversionCr: 5.45,
      primaryReportingConversionCpa: 107.7
    },
    {
      day: '28/03/2022',
      projectName: '28/03/2022',
      productName: '28/03/2022',
      cityId: '28/03/2022',
      regionId: '28/03/2022',
      spent: 76620.1,
      cpm: 16.95,
      impressions: 4521204,
      linkCTR: 0.32,
      linkClicks: 14580,
      cpc: 5.26,
      landingPageViews: 5746,
      landingPageViewsRate: 39.4,
      quaternaryReportingConversion: 309,
      quaternaryReportingConversionCr: 2.12,
      quaternaryReportingConversionCpa: 247.96,
      tertiaryReportingConversion: 216,
      tertiaryReportingConversionCr: 1.48,
      tertiaryReportingConversionCpa: 354.72,
      secondaryReportingConversion: 353,
      secondaryReportingConversionCr: 2.42,
      secondaryReportingConversionCpa: 217.05,
      primaryReportingConversion: 784,
      primaryReportingConversionCr: 5.38,
      primaryReportingConversionCpa: 97.73
    },
    {
      day: '29/03/2022',
      projectName: '29/03/2022',
      productName: '29/03/2022',
      cityId: '29/03/2022',
      regionId: '29/03/2022',
      spent: 80993.5,
      cpm: 18.82,
      impressions: 4303743,
      linkCTR: 0.34,
      linkClicks: 14484,
      cpc: 5.59,
      landingPageViews: 5742,
      landingPageViewsRate: 39.6,
      quaternaryReportingConversion: 315,
      quaternaryReportingConversionCr: 2.17,
      quaternaryReportingConversionCpa: 257.12,
      tertiaryReportingConversion: 262,
      tertiaryReportingConversionCr: 1.81,
      tertiaryReportingConversionCpa: 309.14,
      secondaryReportingConversion: 387,
      secondaryReportingConversionCr: 2.67,
      secondaryReportingConversionCpa: 209.29,
      primaryReportingConversion: 872,
      primaryReportingConversionCr: 6.02,
      primaryReportingConversionCpa: 92.88
    },
    {
      day: '30/03/2022',
      projectName: '30/03/2022',
      productName: '30/03/2022',
      cityId: '30/03/2022',
      regionId: '30/03/2022',
      spent: 93620.92,
      cpm: 22.48,
      impressions: 4164983,
      linkCTR: 0.36,
      linkClicks: 14865,
      cpc: 6.3,
      landingPageViews: 5499,
      landingPageViewsRate: 37,
      quaternaryReportingConversion: 315,
      quaternaryReportingConversionCr: 2.12,
      quaternaryReportingConversionCpa: 297.21,
      tertiaryReportingConversion: 252,
      tertiaryReportingConversionCr: 1.7,
      tertiaryReportingConversionCpa: 371.51,
      secondaryReportingConversion: 488,
      secondaryReportingConversionCr: 3.28,
      secondaryReportingConversionCpa: 191.85,
      primaryReportingConversion: 904,
      primaryReportingConversionCr: 6.08,
      primaryReportingConversionCpa: 103.56
    },
    {
      day: '31/03/2022',
      projectName: '31/03/2022',
      productName: '31/03/2022',
      cityId: '31/03/2022',
      regionId: '31/03/2022',
      spent: 70868.93,
      cpm: 22.28,
      impressions: 3180411,
      linkCTR: 0.32,
      linkClicks: 10255,
      cpc: 6.91,
      landingPageViews: 4096,
      landingPageViewsRate: 39.9,
      quaternaryReportingConversion: 251,
      quaternaryReportingConversionCr: 2.45,
      quaternaryReportingConversionCpa: 282.35,
      tertiaryReportingConversion: 213,
      tertiaryReportingConversionCr: 2.08,
      tertiaryReportingConversionCpa: 332.72,
      secondaryReportingConversion: 283,
      secondaryReportingConversionCr: 2.76,
      secondaryReportingConversionCpa: 250.42,
      primaryReportingConversion: 662,
      primaryReportingConversionCr: 6.46,
      primaryReportingConversionCpa: 107.05
    },
    {
      day: '01/04/2022',
      projectName: '01/04/2022',
      productName: '01/04/2022',
      cityId: '01/04/2022',
      regionId: '01/04/2022',
      spent: 80956.97,
      cpm: 20.63,
      impressions: 3924833,
      linkCTR: 0.31,
      linkClicks: 12255,
      cpc: 6.61,
      landingPageViews: 4781,
      landingPageViewsRate: 39,
      quaternaryReportingConversion: 237,
      quaternaryReportingConversionCr: 1.93,
      quaternaryReportingConversionCpa: 341.59,
      tertiaryReportingConversion: 189,
      tertiaryReportingConversionCr: 1.54,
      tertiaryReportingConversionCpa: 428.34,
      secondaryReportingConversion: 354,
      secondaryReportingConversionCr: 2.89,
      secondaryReportingConversionCpa: 228.69,
      primaryReportingConversion: 584,
      primaryReportingConversionCr: 4.77,
      primaryReportingConversionCpa: 138.62
    },
    {
      day: '02/04/2022',
      projectName: '02/04/2022',
      productName: '02/04/2022',
      cityId: '02/04/2022',
      regionId: '02/04/2022',
      spent: 72260.8,
      cpm: 21.34,
      impressions: 3386148,
      linkCTR: 0.3,
      linkClicks: 10094,
      cpc: 7.16,
      landingPageViews: 4001,
      landingPageViewsRate: 39.6,
      quaternaryReportingConversion: 202,
      quaternaryReportingConversionCr: 2,
      quaternaryReportingConversionCpa: 357.73,
      tertiaryReportingConversion: 178,
      tertiaryReportingConversionCr: 1.76,
      tertiaryReportingConversionCpa: 405.96,
      secondaryReportingConversion: 357,
      secondaryReportingConversionCr: 3.54,
      secondaryReportingConversionCpa: 202.41,
      primaryReportingConversion: 423,
      primaryReportingConversionCr: 4.19,
      primaryReportingConversionCpa: 170.83
    },
    {
      day: '03/04/2022',
      projectName: '03/04/2022',
      productName: '03/04/2022',
      cityId: '03/04/2022',
      regionId: '03/04/2022',
      spent: 90108.02,
      cpm: 22.35,
      impressions: 4031630,
      linkCTR: 0.3,
      linkClicks: 12101,
      cpc: 7.45,
      landingPageViews: 4786,
      landingPageViewsRate: 39.6,
      quaternaryReportingConversion: 267,
      quaternaryReportingConversionCr: 2.21,
      quaternaryReportingConversionCpa: 337.48,
      tertiaryReportingConversion: 237,
      tertiaryReportingConversionCr: 1.96,
      tertiaryReportingConversionCpa: 380.2,
      secondaryReportingConversion: 373,
      secondaryReportingConversionCr: 3.08,
      secondaryReportingConversionCpa: 241.58,
      primaryReportingConversion: 599,
      primaryReportingConversionCr: 4.95,
      primaryReportingConversionCpa: 150.43
    },
    {
      day: '04/04/2022',
      projectName: '04/04/2022',
      productName: '04/04/2022',
      cityId: '04/04/2022',
      regionId: '04/04/2022',
      spent: 90530.47,
      cpm: 18.8,
      impressions: 4816480,
      linkCTR: 0.31,
      linkClicks: 14756,
      cpc: 6.14,
      landingPageViews: 5303,
      landingPageViewsRate: 35.9,
      quaternaryReportingConversion: 272,
      quaternaryReportingConversionCr: 1.84,
      quaternaryReportingConversionCpa: 332.83,
      tertiaryReportingConversion: 212,
      tertiaryReportingConversionCr: 1.44,
      tertiaryReportingConversionCpa: 427.03,
      secondaryReportingConversion: 499,
      secondaryReportingConversionCr: 3.38,
      secondaryReportingConversionCpa: 181.42,
      primaryReportingConversion: 708,
      primaryReportingConversionCr: 4.8,
      primaryReportingConversionCpa: 127.87
    },
    {
      day: '05/04/2022',
      projectName: '05/04/2022',
      productName: '05/04/2022',
      cityId: '05/04/2022',
      regionId: '05/04/2022',
      spent: 84414.28,
      cpm: 16.69,
      impressions: 5057675,
      linkCTR: 0.29,
      linkClicks: 14723,
      cpc: 5.73,
      landingPageViews: 5242,
      landingPageViewsRate: 35.6,
      quaternaryReportingConversion: 376,
      quaternaryReportingConversionCr: 2.55,
      quaternaryReportingConversionCpa: 224.51,
      tertiaryReportingConversion: 331,
      tertiaryReportingConversionCr: 2.25,
      tertiaryReportingConversionCpa: 255.03,
      secondaryReportingConversion: 511,
      secondaryReportingConversionCr: 3.47,
      secondaryReportingConversionCpa: 165.19,
      primaryReportingConversion: 696,
      primaryReportingConversionCr: 4.73,
      primaryReportingConversionCpa: 121.28
    },
    {
      day: '06/04/2022',
      projectName: '06/04/2022',
      productName: '06/04/2022',
      cityId: '06/04/2022',
      regionId: '06/04/2022',
      spent: 80033.58,
      cpm: 16.59,
      impressions: 4824618,
      linkCTR: 0.28,
      linkClicks: 13719,
      cpc: 5.83,
      landingPageViews: 4857,
      landingPageViewsRate: 35.4,
      quaternaryReportingConversion: 228,
      quaternaryReportingConversionCr: 1.66,
      quaternaryReportingConversionCpa: 351.02,
      tertiaryReportingConversion: 220,
      tertiaryReportingConversionCr: 1.6,
      tertiaryReportingConversionCpa: 363.79,
      secondaryReportingConversion: 368,
      secondaryReportingConversionCr: 2.68,
      secondaryReportingConversionCpa: 217.48,
      primaryReportingConversion: 594,
      primaryReportingConversionCr: 4.33,
      primaryReportingConversionCpa: 134.74
    },
    {
      day: '07/04/2022',
      projectName: '07/04/2022',
      productName: '07/04/2022',
      cityId: '07/04/2022',
      regionId: '07/04/2022',
      spent: 85637.03,
      cpm: 16.57,
      impressions: 5168009,
      linkCTR: 0.32,
      linkClicks: 16330,
      cpc: 5.24,
      landingPageViews: 5253,
      landingPageViewsRate: 32.2,
      quaternaryReportingConversion: 295,
      quaternaryReportingConversionCr: 1.81,
      quaternaryReportingConversionCpa: 290.3,
      tertiaryReportingConversion: 244,
      tertiaryReportingConversionCr: 1.49,
      tertiaryReportingConversionCpa: 350.97,
      secondaryReportingConversion: 520,
      secondaryReportingConversionCr: 3.18,
      secondaryReportingConversionCpa: 164.69,
      primaryReportingConversion: 755,
      primaryReportingConversionCr: 4.62,
      primaryReportingConversionCpa: 113.43
    },
    {
      day: '08/04/2022',
      projectName: '08/04/2022',
      productName: '08/04/2022',
      cityId: '08/04/2022',
      regionId: '08/04/2022',
      spent: 92292.42,
      cpm: 17.13,
      impressions: 5387953,
      linkCTR: 0.31,
      linkClicks: 16604,
      cpc: 5.56,
      landingPageViews: 5642,
      landingPageViewsRate: 34,
      quaternaryReportingConversion: 245,
      quaternaryReportingConversionCr: 1.48,
      quaternaryReportingConversionCpa: 376.7,
      tertiaryReportingConversion: 200,
      tertiaryReportingConversionCr: 1.2,
      tertiaryReportingConversionCpa: 461.46,
      secondaryReportingConversion: 401,
      secondaryReportingConversionCr: 2.42,
      secondaryReportingConversionCpa: 230.16,
      primaryReportingConversion: 658,
      primaryReportingConversionCr: 3.96,
      primaryReportingConversionCpa: 140.26
    },
    {
      day: '09/04/2022',
      projectName: '09/04/2022',
      productName: '09/04/2022',
      cityId: '09/04/2022',
      regionId: '09/04/2022',
      spent: 92325.73,
      cpm: 17.49,
      impressions: 5280275,
      linkCTR: 0.32,
      linkClicks: 17008,
      cpc: 5.43,
      landingPageViews: 5278,
      landingPageViewsRate: 31,
      quaternaryReportingConversion: 185,
      quaternaryReportingConversionCr: 1.09,
      quaternaryReportingConversionCpa: 499.06,
      tertiaryReportingConversion: 172,
      tertiaryReportingConversionCr: 1.01,
      tertiaryReportingConversionCpa: 536.78,
      secondaryReportingConversion: 338,
      secondaryReportingConversionCr: 1.99,
      secondaryReportingConversionCpa: 273.15,
      primaryReportingConversion: 594,
      primaryReportingConversionCr: 3.49,
      primaryReportingConversionCpa: 155.43
    },
    {
      day: '10/04/2022',
      projectName: '10/04/2022',
      productName: '10/04/2022',
      cityId: '10/04/2022',
      regionId: '10/04/2022',
      spent: 92560.66,
      cpm: 17.4,
      impressions: 5319606,
      linkCTR: 0.31,
      linkClicks: 16672,
      cpc: 5.55,
      landingPageViews: 5072,
      landingPageViewsRate: 30.4,
      quaternaryReportingConversion: 170,
      quaternaryReportingConversionCr: 1.02,
      quaternaryReportingConversionCpa: 544.47,
      tertiaryReportingConversion: 142,
      tertiaryReportingConversionCr: 0.85,
      tertiaryReportingConversionCpa: 651.84,
      secondaryReportingConversion: 293,
      secondaryReportingConversionCr: 1.76,
      secondaryReportingConversionCpa: 315.91,
      primaryReportingConversion: 566,
      primaryReportingConversionCr: 3.39,
      primaryReportingConversionCpa: 163.53
    },
    {
      day: '11/04/2022',
      projectName: '11/04/2022',
      productName: '11/04/2022',
      cityId: '11/04/2022',
      regionId: '11/04/2022',
      spent: 86740.98,
      cpm: 16.05,
      impressions: 5405279,
      linkCTR: 0.32,
      linkClicks: 17507,
      cpc: 4.95,
      landingPageViews: 5486,
      landingPageViewsRate: 31.3,
      quaternaryReportingConversion: 199,
      quaternaryReportingConversionCr: 1.14,
      quaternaryReportingConversionCpa: 435.88,
      tertiaryReportingConversion: 150,
      tertiaryReportingConversionCr: 0.86,
      tertiaryReportingConversionCpa: 578.27,
      secondaryReportingConversion: 374,
      secondaryReportingConversionCr: 2.14,
      secondaryReportingConversionCpa: 231.93,
      primaryReportingConversion: 597,
      primaryReportingConversionCr: 3.41,
      primaryReportingConversionCpa: 145.29
    },
    {
      day: '12/04/2022',
      projectName: '12/04/2022',
      productName: '12/04/2022',
      cityId: '12/04/2022',
      regionId: '12/04/2022',
      spent: 87582.15,
      cpm: 16.66,
      impressions: 5255668,
      linkCTR: 0.32,
      linkClicks: 16581,
      cpc: 5.28,
      landingPageViews: 5825,
      landingPageViewsRate: 35.1,
      quaternaryReportingConversion: 268,
      quaternaryReportingConversionCr: 1.62,
      quaternaryReportingConversionCpa: 326.8,
      tertiaryReportingConversion: 196,
      tertiaryReportingConversionCr: 1.18,
      tertiaryReportingConversionCpa: 446.85,
      secondaryReportingConversion: 375,
      secondaryReportingConversionCr: 2.26,
      secondaryReportingConversionCpa: 233.55,
      primaryReportingConversion: 682,
      primaryReportingConversionCr: 4.11,
      primaryReportingConversionCpa: 128.42
    },
    {
      day: '13/04/2022',
      projectName: '13/04/2022',
      productName: '13/04/2022',
      cityId: '13/04/2022',
      regionId: '13/04/2022',
      spent: 90728.37,
      cpm: 16.63,
      impressions: 5455390,
      linkCTR: 0.33,
      linkClicks: 18078,
      cpc: 5.02,
      landingPageViews: 6108,
      landingPageViewsRate: 33.8,
      quaternaryReportingConversion: 271,
      quaternaryReportingConversionCr: 1.5,
      quaternaryReportingConversionCpa: 334.79,
      tertiaryReportingConversion: 241,
      tertiaryReportingConversionCr: 1.33,
      tertiaryReportingConversionCpa: 376.47,
      secondaryReportingConversion: 402,
      secondaryReportingConversionCr: 2.22,
      secondaryReportingConversionCpa: 225.69,
      primaryReportingConversion: 720,
      primaryReportingConversionCr: 3.98,
      primaryReportingConversionCpa: 126.01
    },
    {
      day: '14/04/2022',
      projectName: '14/04/2022',
      productName: '14/04/2022',
      cityId: '14/04/2022',
      regionId: '14/04/2022',
      spent: 91884.19,
      cpm: 16.63,
      impressions: 5524212,
      linkCTR: 0.3,
      linkClicks: 16462,
      cpc: 5.58,
      landingPageViews: 5672,
      landingPageViewsRate: 34.5,
      quaternaryReportingConversion: 252,
      quaternaryReportingConversionCr: 1.53,
      quaternaryReportingConversionCpa: 364.62,
      tertiaryReportingConversion: 190,
      tertiaryReportingConversionCr: 1.15,
      tertiaryReportingConversionCpa: 483.6,
      secondaryReportingConversion: 357,
      secondaryReportingConversionCr: 2.17,
      secondaryReportingConversionCpa: 257.38,
      primaryReportingConversion: 696,
      primaryReportingConversionCr: 4.23,
      primaryReportingConversionCpa: 132.02
    },
    {
      day: '15/04/2022',
      projectName: '15/04/2022',
      productName: '15/04/2022',
      cityId: '15/04/2022',
      regionId: '15/04/2022',
      spent: 81930.21,
      cpm: 16.01,
      impressions: 5118372,
      linkCTR: 0.27,
      linkClicks: 13626,
      cpc: 6.01,
      landingPageViews: 5219,
      landingPageViewsRate: 38.3,
      quaternaryReportingConversion: 205,
      quaternaryReportingConversionCr: 1.5,
      quaternaryReportingConversionCpa: 399.66,
      tertiaryReportingConversion: 170,
      tertiaryReportingConversionCr: 1.25,
      tertiaryReportingConversionCpa: 481.94,
      secondaryReportingConversion: 249,
      secondaryReportingConversionCr: 1.83,
      secondaryReportingConversionCpa: 329.04,
      primaryReportingConversion: 523,
      primaryReportingConversionCr: 3.84,
      primaryReportingConversionCpa: 156.65
    },
    {
      day: '16/04/2022',
      projectName: '16/04/2022',
      productName: '16/04/2022',
      cityId: '16/04/2022',
      regionId: '16/04/2022',
      spent: 81150.86,
      cpm: 15.95,
      impressions: 5086891,
      linkCTR: 0.26,
      linkClicks: 13049,
      cpc: 6.22,
      landingPageViews: 5413,
      landingPageViewsRate: 41.5,
      quaternaryReportingConversion: 223,
      quaternaryReportingConversionCr: 1.71,
      quaternaryReportingConversionCpa: 363.91,
      tertiaryReportingConversion: 181,
      tertiaryReportingConversionCr: 1.39,
      tertiaryReportingConversionCpa: 448.35,
      secondaryReportingConversion: 265,
      secondaryReportingConversionCr: 2.03,
      secondaryReportingConversionCpa: 306.23,
      primaryReportingConversion: 474,
      primaryReportingConversionCr: 3.63,
      primaryReportingConversionCpa: 171.2
    },
    {
      day: '17/04/2022',
      projectName: '17/04/2022',
      productName: '17/04/2022',
      cityId: '17/04/2022',
      regionId: '17/04/2022',
      spent: 75725.31,
      cpm: 13.47,
      impressions: 5621722,
      linkCTR: 0.23,
      linkClicks: 12792,
      cpc: 5.92,
      landingPageViews: 5089,
      landingPageViewsRate: 39.8,
      quaternaryReportingConversion: 252,
      quaternaryReportingConversionCr: 1.97,
      quaternaryReportingConversionCpa: 300.5,
      tertiaryReportingConversion: 171,
      tertiaryReportingConversionCr: 1.34,
      tertiaryReportingConversionCpa: 442.84,
      secondaryReportingConversion: 187,
      secondaryReportingConversionCr: 1.46,
      secondaryReportingConversionCpa: 404.95,
      primaryReportingConversion: 480,
      primaryReportingConversionCr: 3.75,
      primaryReportingConversionCpa: 157.76
    },
    {
      day: '18/04/2022',
      projectName: '18/04/2022',
      productName: '18/04/2022',
      cityId: '18/04/2022',
      regionId: '18/04/2022',
      spent: 83970.77,
      cpm: 13.5,
      impressions: 6221096,
      linkCTR: 0.25,
      linkClicks: 15376,
      cpc: 5.46,
      landingPageViews: 6571,
      landingPageViewsRate: 42.7,
      quaternaryReportingConversion: 403,
      quaternaryReportingConversionCr: 2.62,
      quaternaryReportingConversionCpa: 208.36,
      tertiaryReportingConversion: 273,
      tertiaryReportingConversionCr: 1.78,
      tertiaryReportingConversionCpa: 307.59,
      secondaryReportingConversion: 341,
      secondaryReportingConversionCr: 2.22,
      secondaryReportingConversionCpa: 246.25,
      primaryReportingConversion: 718,
      primaryReportingConversionCr: 4.67,
      primaryReportingConversionCpa: 116.95
    },
    {
      day: '19/04/2022',
      projectName: '19/04/2022',
      productName: '19/04/2022',
      cityId: '19/04/2022',
      regionId: '19/04/2022',
      spent: 92887.06,
      cpm: 15.37,
      impressions: 6043152,
      linkCTR: 0.25,
      linkClicks: 14861,
      cpc: 6.25,
      landingPageViews: 6789,
      landingPageViewsRate: 45.7,
      quaternaryReportingConversion: 588,
      quaternaryReportingConversionCr: 3.96,
      quaternaryReportingConversionCpa: 157.97,
      tertiaryReportingConversion: 405,
      tertiaryReportingConversionCr: 2.73,
      tertiaryReportingConversionCpa: 229.35,
      secondaryReportingConversion: 420,
      secondaryReportingConversionCr: 2.83,
      secondaryReportingConversionCpa: 221.16,
      primaryReportingConversion: 855,
      primaryReportingConversionCr: 5.75,
      primaryReportingConversionCpa: 108.64
    },
    {
      day: '20/04/2022',
      projectName: '20/04/2022',
      productName: '20/04/2022',
      cityId: '20/04/2022',
      regionId: '20/04/2022',
      spent: 94146.9,
      cpm: 15.75,
      impressions: 5977055,
      linkCTR: 0.26,
      linkClicks: 15763,
      cpc: 5.97,
      landingPageViews: 6797,
      landingPageViewsRate: 43.1,
      quaternaryReportingConversion: 457,
      quaternaryReportingConversionCr: 2.9,
      quaternaryReportingConversionCpa: 206.01,
      tertiaryReportingConversion: 352,
      tertiaryReportingConversionCr: 2.23,
      tertiaryReportingConversionCpa: 267.46,
      secondaryReportingConversion: 333,
      secondaryReportingConversionCr: 2.11,
      secondaryReportingConversionCpa: 282.72,
      primaryReportingConversion: 768,
      primaryReportingConversionCr: 4.87,
      primaryReportingConversionCpa: 122.59
    },
    {
      day: '21/04/2022',
      projectName: '21/04/2022',
      productName: '21/04/2022',
      cityId: '21/04/2022',
      regionId: '21/04/2022',
      spent: 94173.26,
      cpm: 15.07,
      impressions: 6247473,
      linkCTR: 0.26,
      linkClicks: 16106,
      cpc: 5.85,
      landingPageViews: 6936,
      landingPageViewsRate: 43.1,
      quaternaryReportingConversion: 358,
      quaternaryReportingConversionCr: 2.22,
      quaternaryReportingConversionCpa: 263.05,
      tertiaryReportingConversion: 310,
      tertiaryReportingConversionCr: 1.92,
      tertiaryReportingConversionCpa: 303.78,
      secondaryReportingConversion: 361,
      secondaryReportingConversionCr: 2.24,
      secondaryReportingConversionCpa: 260.87,
      primaryReportingConversion: 699,
      primaryReportingConversionCr: 4.34,
      primaryReportingConversionCpa: 134.73
    },
    {
      day: '22/04/2022',
      projectName: '22/04/2022',
      productName: '22/04/2022',
      cityId: '22/04/2022',
      regionId: '22/04/2022',
      spent: 96070.9,
      cpm: 15.81,
      impressions: 6077357,
      linkCTR: 0.25,
      linkClicks: 15224,
      cpc: 6.31,
      landingPageViews: 6311,
      landingPageViewsRate: 41.5,
      quaternaryReportingConversion: 315,
      quaternaryReportingConversionCr: 2.07,
      quaternaryReportingConversionCpa: 304.99,
      tertiaryReportingConversion: 269,
      tertiaryReportingConversionCr: 1.77,
      tertiaryReportingConversionCpa: 357.14,
      secondaryReportingConversion: 317,
      secondaryReportingConversionCr: 2.08,
      secondaryReportingConversionCpa: 303.06,
      primaryReportingConversion: 631,
      primaryReportingConversionCr: 4.14,
      primaryReportingConversionCpa: 152.25
    },
    {
      day: '23/04/2022',
      projectName: '23/04/2022',
      productName: '23/04/2022',
      cityId: '23/04/2022',
      regionId: '23/04/2022',
      spent: 107128.62,
      cpm: 18.25,
      impressions: 5868729,
      linkCTR: 0.27,
      linkClicks: 15597,
      cpc: 6.87,
      landingPageViews: 6824,
      landingPageViewsRate: 43.8,
      quaternaryReportingConversion: 447,
      quaternaryReportingConversionCr: 2.87,
      quaternaryReportingConversionCpa: 239.66,
      tertiaryReportingConversion: 282,
      tertiaryReportingConversionCr: 1.81,
      tertiaryReportingConversionCpa: 379.89,
      secondaryReportingConversion: 350,
      secondaryReportingConversionCr: 2.24,
      secondaryReportingConversionCpa: 306.08,
      primaryReportingConversion: 728,
      primaryReportingConversionCr: 4.67,
      primaryReportingConversionCpa: 147.15
    },
    {
      day: '24/04/2022',
      projectName: '24/04/2022',
      productName: '24/04/2022',
      cityId: '24/04/2022',
      regionId: '24/04/2022',
      spent: 110517.72,
      cpm: 17.52,
      impressions: 6307930,
      linkCTR: 0.26,
      linkClicks: 16441,
      cpc: 6.72,
      landingPageViews: 7156,
      landingPageViewsRate: 43.5,
      quaternaryReportingConversion: 458,
      quaternaryReportingConversionCr: 2.79,
      quaternaryReportingConversionCpa: 241.31,
      tertiaryReportingConversion: 276,
      tertiaryReportingConversionCr: 1.68,
      tertiaryReportingConversionCpa: 400.43,
      secondaryReportingConversion: 336,
      secondaryReportingConversionCr: 2.04,
      secondaryReportingConversionCpa: 328.92,
      primaryReportingConversion: 732,
      primaryReportingConversionCr: 4.45,
      primaryReportingConversionCpa: 150.98
    },
    {
      day: '25/04/2022',
      projectName: '25/04/2022',
      productName: '25/04/2022',
      cityId: '25/04/2022',
      regionId: '25/04/2022',
      spent: 112487.03,
      cpm: 16.06,
      impressions: 7002540,
      linkCTR: 0.27,
      linkClicks: 19051,
      cpc: 5.9,
      landingPageViews: 8755,
      landingPageViewsRate: 46,
      quaternaryReportingConversion: 457,
      quaternaryReportingConversionCr: 2.4,
      quaternaryReportingConversionCpa: 246.14,
      tertiaryReportingConversion: 287,
      tertiaryReportingConversionCr: 1.51,
      tertiaryReportingConversionCpa: 391.94,
      secondaryReportingConversion: 443,
      secondaryReportingConversionCr: 2.33,
      secondaryReportingConversionCpa: 253.92,
      primaryReportingConversion: 755,
      primaryReportingConversionCr: 3.96,
      primaryReportingConversionCpa: 148.99
    },
    {
      day: '26/04/2022',
      projectName: '26/04/2022',
      productName: '26/04/2022',
      cityId: '26/04/2022',
      regionId: '26/04/2022',
      spent: 118663.44,
      cpm: 16.86,
      impressions: 7038787,
      linkCTR: 0.3,
      linkClicks: 21339,
      cpc: 5.56,
      landingPageViews: 9701,
      landingPageViewsRate: 45.5,
      quaternaryReportingConversion: 433,
      quaternaryReportingConversionCr: 2.03,
      quaternaryReportingConversionCpa: 274.05,
      tertiaryReportingConversion: 337,
      tertiaryReportingConversionCr: 1.58,
      tertiaryReportingConversionCpa: 352.12,
      secondaryReportingConversion: 528,
      secondaryReportingConversionCr: 2.47,
      secondaryReportingConversionCpa: 224.74,
      primaryReportingConversion: 864,
      primaryReportingConversionCr: 4.05,
      primaryReportingConversionCpa: 137.34
    },
    {
      day: '27/04/2022',
      projectName: '27/04/2022',
      productName: '27/04/2022',
      cityId: '27/04/2022',
      regionId: '27/04/2022',
      spent: 116231.45,
      cpm: 17.84,
      impressions: 6516940,
      linkCTR: 0.3,
      linkClicks: 19867,
      cpc: 5.85,
      landingPageViews: 9060,
      landingPageViewsRate: 45.6,
      quaternaryReportingConversion: 408,
      quaternaryReportingConversionCr: 2.05,
      quaternaryReportingConversionCpa: 284.88,
      tertiaryReportingConversion: 298,
      tertiaryReportingConversionCr: 1.5,
      tertiaryReportingConversionCpa: 390.04,
      secondaryReportingConversion: 458,
      secondaryReportingConversionCr: 2.31,
      secondaryReportingConversionCpa: 253.78,
      primaryReportingConversion: 817,
      primaryReportingConversionCr: 4.11,
      primaryReportingConversionCpa: 142.27
    },
    {
      day: '28/04/2022',
      projectName: '28/04/2022',
      productName: '28/04/2022',
      cityId: '28/04/2022',
      regionId: '28/04/2022',
      spent: 111532.91,
      cpm: 16.1,
      impressions: 6929251,
      linkCTR: 0.28,
      linkClicks: 19692,
      cpc: 5.66,
      landingPageViews: 9181,
      landingPageViewsRate: 46.6,
      quaternaryReportingConversion: 392,
      quaternaryReportingConversionCr: 1.99,
      quaternaryReportingConversionCpa: 284.52,
      tertiaryReportingConversion: 254,
      tertiaryReportingConversionCr: 1.29,
      tertiaryReportingConversionCpa: 439.11,
      secondaryReportingConversion: 464,
      secondaryReportingConversionCr: 2.36,
      secondaryReportingConversionCpa: 240.37,
      primaryReportingConversion: 859,
      primaryReportingConversionCr: 4.36,
      primaryReportingConversionCpa: 129.84
    },
    {
      day: '29/04/2022',
      projectName: '29/04/2022',
      productName: '29/04/2022',
      cityId: '29/04/2022',
      regionId: '29/04/2022',
      spent: 105034.74,
      cpm: 16.27,
      impressions: 6457173,
      linkCTR: 0.29,
      linkClicks: 18727,
      cpc: 5.61,
      landingPageViews: 8625,
      landingPageViewsRate: 46.1,
      quaternaryReportingConversion: 317,
      quaternaryReportingConversionCr: 1.69,
      quaternaryReportingConversionCpa: 331.34,
      tertiaryReportingConversion: 245,
      tertiaryReportingConversionCr: 1.31,
      tertiaryReportingConversionCpa: 428.71,
      secondaryReportingConversion: 472,
      secondaryReportingConversionCr: 2.52,
      secondaryReportingConversionCpa: 222.53,
      primaryReportingConversion: 669,
      primaryReportingConversionCr: 3.57,
      primaryReportingConversionCpa: 157
    },
    {
      day: '30/04/2022',
      projectName: '30/04/2022',
      productName: '30/04/2022',
      cityId: '30/04/2022',
      regionId: '30/04/2022',
      spent: 98906.28,
      cpm: 17.06,
      impressions: 5797332,
      linkCTR: 0.28,
      linkClicks: 16446,
      cpc: 6.01,
      landingPageViews: 7453,
      landingPageViewsRate: 45.3,
      quaternaryReportingConversion: 328,
      quaternaryReportingConversionCr: 1.99,
      quaternaryReportingConversionCpa: 301.54,
      tertiaryReportingConversion: 200,
      tertiaryReportingConversionCr: 1.22,
      tertiaryReportingConversionCpa: 494.53,
      secondaryReportingConversion: 597,
      secondaryReportingConversionCr: 3.63,
      secondaryReportingConversionCpa: 165.67,
      primaryReportingConversion: 719,
      primaryReportingConversionCr: 4.37,
      primaryReportingConversionCpa: 137.56
    },
    {
      day: '01/05/2022',
      projectName: '01/05/2022',
      productName: '01/05/2022',
      cityId: '01/05/2022',
      regionId: '01/05/2022',
      spent: 98742.25,
      cpm: 15.04,
      impressions: 6563699,
      linkCTR: 0.26,
      linkClicks: 16714,
      cpc: 5.91,
      landingPageViews: 7139,
      landingPageViewsRate: 42.7,
      quaternaryReportingConversion: 350,
      quaternaryReportingConversionCr: 2.09,
      quaternaryReportingConversionCpa: 282.12,
      tertiaryReportingConversion: 163,
      tertiaryReportingConversionCr: 0.98,
      tertiaryReportingConversionCpa: 605.78,
      secondaryReportingConversion: 371,
      secondaryReportingConversionCr: 2.22,
      secondaryReportingConversionCpa: 266.15,
      primaryReportingConversion: 704,
      primaryReportingConversionCr: 4.21,
      primaryReportingConversionCpa: 140.26
    },
    {
      day: '02/05/2022',
      projectName: '02/05/2022',
      productName: '02/05/2022',
      cityId: '02/05/2022',
      regionId: '02/05/2022',
      spent: 105302.74,
      cpm: 14.53,
      impressions: 7247439,
      linkCTR: 0.27,
      linkClicks: 19530,
      cpc: 5.39,
      landingPageViews: 8614,
      landingPageViewsRate: 44.1,
      quaternaryReportingConversion: 435,
      quaternaryReportingConversionCr: 2.23,
      quaternaryReportingConversionCpa: 242.08,
      tertiaryReportingConversion: 248,
      tertiaryReportingConversionCr: 1.27,
      tertiaryReportingConversionCpa: 424.61,
      secondaryReportingConversion: 395,
      secondaryReportingConversionCr: 2.02,
      secondaryReportingConversionCpa: 266.59,
      primaryReportingConversion: 891,
      primaryReportingConversionCr: 4.56,
      primaryReportingConversionCpa: 118.18
    },
    {
      day: '03/05/2022',
      projectName: '03/05/2022',
      productName: '03/05/2022',
      cityId: '03/05/2022',
      regionId: '03/05/2022',
      spent: 110941.48,
      cpm: 15.68,
      impressions: 7077105,
      linkCTR: 0.29,
      linkClicks: 20231,
      cpc: 5.48,
      landingPageViews: 8701,
      landingPageViewsRate: 43,
      quaternaryReportingConversion: 446,
      quaternaryReportingConversionCr: 2.2,
      quaternaryReportingConversionCpa: 248.75,
      tertiaryReportingConversion: 269,
      tertiaryReportingConversionCr: 1.33,
      tertiaryReportingConversionCpa: 412.42,
      secondaryReportingConversion: 418,
      secondaryReportingConversionCr: 2.07,
      secondaryReportingConversionCpa: 265.41,
      primaryReportingConversion: 997,
      primaryReportingConversionCr: 4.93,
      primaryReportingConversionCpa: 111.28
    },
    {
      day: '04/05/2022',
      projectName: '04/05/2022',
      productName: '04/05/2022',
      cityId: '04/05/2022',
      regionId: '04/05/2022',
      spent: 118890.67,
      cpm: 16.09,
      impressions: 7387397,
      linkCTR: 0.28,
      linkClicks: 20294,
      cpc: 5.86,
      landingPageViews: 8697,
      landingPageViewsRate: 42.9,
      quaternaryReportingConversion: 498,
      quaternaryReportingConversionCr: 2.45,
      quaternaryReportingConversionCpa: 238.74,
      tertiaryReportingConversion: 326,
      tertiaryReportingConversionCr: 1.61,
      tertiaryReportingConversionCpa: 364.7,
      secondaryReportingConversion: 496,
      secondaryReportingConversionCr: 2.44,
      secondaryReportingConversionCpa: 239.7,
      primaryReportingConversion: 1127,
      primaryReportingConversionCr: 5.55,
      primaryReportingConversionCpa: 105.49
    },
    {
      day: '05/05/2022',
      projectName: '05/05/2022',
      productName: '05/05/2022',
      cityId: '05/05/2022',
      regionId: '05/05/2022',
      spent: 115930.64,
      cpm: 18.07,
      impressions: 6417036,
      linkCTR: 0.29,
      linkClicks: 18746,
      cpc: 6.18,
      landingPageViews: 7599,
      landingPageViewsRate: 40.5,
      quaternaryReportingConversion: 336,
      quaternaryReportingConversionCr: 1.79,
      quaternaryReportingConversionCpa: 345.03,
      tertiaryReportingConversion: 271,
      tertiaryReportingConversionCr: 1.45,
      tertiaryReportingConversionCpa: 427.79,
      secondaryReportingConversion: 411,
      secondaryReportingConversionCr: 2.19,
      secondaryReportingConversionCpa: 282.07,
      primaryReportingConversion: 927,
      primaryReportingConversionCr: 4.95,
      primaryReportingConversionCpa: 125.06
    },
    {
      day: '06/05/2022',
      projectName: '06/05/2022',
      productName: '06/05/2022',
      cityId: '06/05/2022',
      regionId: '06/05/2022',
      spent: 109466.35,
      cpm: 17.12,
      impressions: 6395104,
      linkCTR: 0.28,
      linkClicks: 17837,
      cpc: 6.14,
      landingPageViews: 7280,
      landingPageViewsRate: 40.8,
      quaternaryReportingConversion: 325,
      quaternaryReportingConversionCr: 1.82,
      quaternaryReportingConversionCpa: 336.82,
      tertiaryReportingConversion: 259,
      tertiaryReportingConversionCr: 1.45,
      tertiaryReportingConversionCpa: 422.65,
      secondaryReportingConversion: 357,
      secondaryReportingConversionCr: 2,
      secondaryReportingConversionCpa: 306.63,
      primaryReportingConversion: 756,
      primaryReportingConversionCr: 4.24,
      primaryReportingConversionCpa: 144.8
    },
    {
      day: '07/05/2022',
      projectName: '07/05/2022',
      productName: '07/05/2022',
      cityId: '07/05/2022',
      regionId: '07/05/2022',
      spent: 109754.89,
      cpm: 17.68,
      impressions: 6208483,
      linkCTR: 0.3,
      linkClicks: 18766,
      cpc: 5.85,
      landingPageViews: 7522,
      landingPageViewsRate: 40.1,
      quaternaryReportingConversion: 271,
      quaternaryReportingConversionCr: 1.44,
      quaternaryReportingConversionCpa: 405,
      tertiaryReportingConversion: 163,
      tertiaryReportingConversionCr: 0.87,
      tertiaryReportingConversionCpa: 673.34,
      secondaryReportingConversion: 289,
      secondaryReportingConversionCr: 1.54,
      secondaryReportingConversionCpa: 379.77,
      primaryReportingConversion: 731,
      primaryReportingConversionCr: 3.9,
      primaryReportingConversionCpa: 150.14
    },
    {
      day: '08/05/2022',
      projectName: '08/05/2022',
      productName: '08/05/2022',
      cityId: '08/05/2022',
      regionId: '08/05/2022',
      spent: 108188.95,
      cpm: 16.1,
      impressions: 6720922,
      linkCTR: 0.28,
      linkClicks: 18761,
      cpc: 5.77,
      landingPageViews: 6994,
      landingPageViewsRate: 37.3,
      quaternaryReportingConversion: 218,
      quaternaryReportingConversionCr: 1.16,
      quaternaryReportingConversionCpa: 496.28,
      tertiaryReportingConversion: 140,
      tertiaryReportingConversionCr: 0.75,
      tertiaryReportingConversionCpa: 772.78,
      secondaryReportingConversion: 253,
      secondaryReportingConversionCr: 1.35,
      secondaryReportingConversionCpa: 427.62,
      primaryReportingConversion: 492,
      primaryReportingConversionCr: 2.62,
      primaryReportingConversionCpa: 219.9
    },
    {
      day: '09/05/2022',
      projectName: '09/05/2022',
      productName: '09/05/2022',
      cityId: '09/05/2022',
      regionId: '09/05/2022',
      spent: 108896.98,
      cpm: 16.25,
      impressions: 6702425,
      linkCTR: 0.28,
      linkClicks: 18846,
      cpc: 5.78,
      landingPageViews: 7189,
      landingPageViewsRate: 38.1,
      quaternaryReportingConversion: 227,
      quaternaryReportingConversionCr: 1.2,
      quaternaryReportingConversionCpa: 479.72,
      tertiaryReportingConversion: 154,
      tertiaryReportingConversionCr: 0.82,
      tertiaryReportingConversionCpa: 707.12,
      secondaryReportingConversion: 283,
      secondaryReportingConversionCr: 1.5,
      secondaryReportingConversionCpa: 384.79,
      primaryReportingConversion: 590,
      primaryReportingConversionCr: 3.13,
      primaryReportingConversionCpa: 184.57
    },
    {
      day: '10/05/2022',
      projectName: '10/05/2022',
      productName: '10/05/2022',
      cityId: '10/05/2022',
      regionId: '10/05/2022',
      spent: 105952.36,
      cpm: 15.93,
      impressions: 6652274,
      linkCTR: 0.29,
      linkClicks: 19073,
      cpc: 5.56,
      landingPageViews: 7496,
      landingPageViewsRate: 39.3,
      quaternaryReportingConversion: 288,
      quaternaryReportingConversionCr: 1.51,
      quaternaryReportingConversionCpa: 367.89,
      tertiaryReportingConversion: 219,
      tertiaryReportingConversionCr: 1.15,
      tertiaryReportingConversionCpa: 483.8,
      secondaryReportingConversion: 347,
      secondaryReportingConversionCr: 1.82,
      secondaryReportingConversionCpa: 305.34,
      primaryReportingConversion: 1020,
      primaryReportingConversionCr: 5.35,
      primaryReportingConversionCpa: 103.87
    },
    {
      day: '11/05/2022',
      projectName: '11/05/2022',
      productName: '11/05/2022',
      cityId: '11/05/2022',
      regionId: '11/05/2022',
      spent: 119395.49,
      cpm: 17.04,
      impressions: 7004884,
      linkCTR: 0.3,
      linkClicks: 20965,
      cpc: 5.69,
      landingPageViews: 7960,
      landingPageViewsRate: 38,
      quaternaryReportingConversion: 280,
      quaternaryReportingConversionCr: 1.34,
      quaternaryReportingConversionCpa: 426.41,
      tertiaryReportingConversion: 200,
      tertiaryReportingConversionCr: 0.95,
      tertiaryReportingConversionCpa: 596.98,
      secondaryReportingConversion: 360,
      secondaryReportingConversionCr: 1.72,
      secondaryReportingConversionCpa: 331.65,
      primaryReportingConversion: 1013,
      primaryReportingConversionCr: 4.83,
      primaryReportingConversionCpa: 117.86
    },
    {
      day: '12/05/2022',
      projectName: '12/05/2022',
      productName: '12/05/2022',
      cityId: '12/05/2022',
      regionId: '12/05/2022',
      spent: 104830.39,
      cpm: 15.98,
      impressions: 6559750,
      linkCTR: 0.29,
      linkClicks: 19239,
      cpc: 5.45,
      landingPageViews: 7446,
      landingPageViewsRate: 38.7,
      quaternaryReportingConversion: 230,
      quaternaryReportingConversionCr: 1.2,
      quaternaryReportingConversionCpa: 455.78,
      tertiaryReportingConversion: 181,
      tertiaryReportingConversionCr: 0.94,
      tertiaryReportingConversionCpa: 579.17,
      secondaryReportingConversion: 308,
      secondaryReportingConversionCr: 1.6,
      secondaryReportingConversionCpa: 340.36,
      primaryReportingConversion: 709,
      primaryReportingConversionCr: 3.69,
      primaryReportingConversionCpa: 147.86
    },
    {
      day: '13/05/2022',
      projectName: '13/05/2022',
      productName: '13/05/2022',
      cityId: '13/05/2022',
      regionId: '13/05/2022',
      spent: 93642.7,
      cpm: 15.31,
      impressions: 6116016,
      linkCTR: 0.27,
      linkClicks: 16184,
      cpc: 5.79,
      landingPageViews: 6426,
      landingPageViewsRate: 39.7,
      quaternaryReportingConversion: 223,
      quaternaryReportingConversionCr: 1.38,
      quaternaryReportingConversionCpa: 419.92,
      tertiaryReportingConversion: 167,
      tertiaryReportingConversionCr: 1.03,
      tertiaryReportingConversionCpa: 560.73,
      secondaryReportingConversion: 250,
      secondaryReportingConversionCr: 1.54,
      secondaryReportingConversionCpa: 374.57,
      primaryReportingConversion: 712,
      primaryReportingConversionCr: 4.4,
      primaryReportingConversionCpa: 131.52
    },
    {
      day: '14/05/2022',
      projectName: '14/05/2022',
      productName: '14/05/2022',
      cityId: '14/05/2022',
      regionId: '14/05/2022',
      spent: 79035.16,
      cpm: 13.22,
      impressions: 5978272,
      linkCTR: 0.26,
      linkClicks: 15576,
      cpc: 5.07,
      landingPageViews: 6357,
      landingPageViewsRate: 40.8,
      quaternaryReportingConversion: 211,
      quaternaryReportingConversionCr: 1.35,
      quaternaryReportingConversionCpa: 374.57,
      tertiaryReportingConversion: 151,
      tertiaryReportingConversionCr: 0.97,
      tertiaryReportingConversionCpa: 523.41,
      secondaryReportingConversion: 238,
      secondaryReportingConversionCr: 1.53,
      secondaryReportingConversionCpa: 332.08,
      primaryReportingConversion: 497,
      primaryReportingConversionCr: 3.19,
      primaryReportingConversionCpa: 159.02
    },
    {
      day: '15/05/2022',
      projectName: '15/05/2022',
      productName: '15/05/2022',
      cityId: '15/05/2022',
      regionId: '15/05/2022',
      spent: 88076.08,
      cpm: 14.62,
      impressions: 6024664,
      linkCTR: 0.27,
      linkClicks: 15978,
      cpc: 5.51,
      landingPageViews: 6978,
      landingPageViewsRate: 43.7,
      quaternaryReportingConversion: 226,
      quaternaryReportingConversionCr: 1.41,
      quaternaryReportingConversionCpa: 389.72,
      tertiaryReportingConversion: 145,
      tertiaryReportingConversionCr: 0.91,
      tertiaryReportingConversionCpa: 607.42,
      secondaryReportingConversion: 289,
      secondaryReportingConversionCr: 1.81,
      secondaryReportingConversionCpa: 304.76,
      primaryReportingConversion: 560,
      primaryReportingConversionCr: 3.5,
      primaryReportingConversionCpa: 157.28
    },
    {
      day: '16/05/2022',
      projectName: '16/05/2022',
      productName: '16/05/2022',
      cityId: '16/05/2022',
      regionId: '16/05/2022',
      spent: 106619.26,
      cpm: 17.31,
      impressions: 6158684,
      linkCTR: 0.28,
      linkClicks: 17131,
      cpc: 6.22,
      landingPageViews: 7422,
      landingPageViewsRate: 43.3,
      quaternaryReportingConversion: 226,
      quaternaryReportingConversionCr: 1.32,
      quaternaryReportingConversionCpa: 471.77,
      tertiaryReportingConversion: 159,
      tertiaryReportingConversionCr: 0.93,
      tertiaryReportingConversionCpa: 670.56,
      secondaryReportingConversion: 253,
      secondaryReportingConversionCr: 1.48,
      secondaryReportingConversionCpa: 421.42,
      primaryReportingConversion: 626,
      primaryReportingConversionCr: 3.65,
      primaryReportingConversionCpa: 170.32
    },
    {
      day: '17/05/2022',
      projectName: '17/05/2022',
      productName: '17/05/2022',
      cityId: '17/05/2022',
      regionId: '17/05/2022',
      spent: 105440.39,
      cpm: 17.49,
      impressions: 6027552,
      linkCTR: 0.29,
      linkClicks: 17805,
      cpc: 5.92,
      landingPageViews: 7903,
      landingPageViewsRate: 44.4,
      quaternaryReportingConversion: 276,
      quaternaryReportingConversionCr: 1.55,
      quaternaryReportingConversionCpa: 382.03,
      tertiaryReportingConversion: 192,
      tertiaryReportingConversionCr: 1.08,
      tertiaryReportingConversionCpa: 549.17,
      secondaryReportingConversion: 271,
      secondaryReportingConversionCr: 1.52,
      secondaryReportingConversionCpa: 389.08,
      primaryReportingConversion: 570,
      primaryReportingConversionCr: 3.2,
      primaryReportingConversionCpa: 184.98
    },
    {
      day: '18/05/2022',
      projectName: '18/05/2022',
      productName: '18/05/2022',
      cityId: '18/05/2022',
      regionId: '18/05/2022',
      spent: 94086.82,
      cpm: 17.37,
      impressions: 5416818,
      linkCTR: 0.29,
      linkClicks: 15531,
      cpc: 6.06,
      landingPageViews: 7147,
      landingPageViewsRate: 46,
      quaternaryReportingConversion: 289,
      quaternaryReportingConversionCr: 1.86,
      quaternaryReportingConversionCpa: 325.56,
      tertiaryReportingConversion: 259,
      tertiaryReportingConversionCr: 1.67,
      tertiaryReportingConversionCpa: 363.27,
      secondaryReportingConversion: 226,
      secondaryReportingConversionCr: 1.46,
      secondaryReportingConversionCpa: 416.31,
      primaryReportingConversion: 539,
      primaryReportingConversionCr: 3.47,
      primaryReportingConversionCpa: 174.56
    },
    {
      day: '19/05/2022',
      projectName: '19/05/2022',
      productName: '19/05/2022',
      cityId: '19/05/2022',
      regionId: '19/05/2022',
      spent: 86695.14,
      cpm: 17.5,
      impressions: 4953392,
      linkCTR: 0.29,
      linkClicks: 14305,
      cpc: 6.06,
      landingPageViews: 6221,
      landingPageViewsRate: 43.5,
      quaternaryReportingConversion: 205,
      quaternaryReportingConversionCr: 1.43,
      quaternaryReportingConversionCpa: 422.9,
      tertiaryReportingConversion: 154,
      tertiaryReportingConversionCr: 1.08,
      tertiaryReportingConversionCpa: 562.96,
      secondaryReportingConversion: 113,
      secondaryReportingConversionCr: 0.79,
      secondaryReportingConversionCpa: 767.21,
      primaryReportingConversion: 407,
      primaryReportingConversionCr: 2.85,
      primaryReportingConversionCpa: 213.01
    },
    {
      day: '20/05/2022',
      projectName: '20/05/2022',
      productName: '20/05/2022',
      cityId: '20/05/2022',
      regionId: '20/05/2022',
      spent: 84063.31,
      cpm: 18.17,
      impressions: 4627124,
      linkCTR: 0.3,
      linkClicks: 13916,
      cpc: 6.04,
      landingPageViews: 5908,
      landingPageViewsRate: 42.5,
      quaternaryReportingConversion: 222,
      quaternaryReportingConversionCr: 1.6,
      quaternaryReportingConversionCpa: 378.66,
      tertiaryReportingConversion: 175,
      tertiaryReportingConversionCr: 1.26,
      tertiaryReportingConversionCpa: 480.36,
      secondaryReportingConversion: 186,
      secondaryReportingConversionCr: 1.34,
      secondaryReportingConversionCpa: 451.95,
      primaryReportingConversion: 433,
      primaryReportingConversionCr: 3.11,
      primaryReportingConversionCpa: 194.14
    },
    {
      day: '21/05/2022',
      projectName: '21/05/2022',
      productName: '21/05/2022',
      cityId: '21/05/2022',
      regionId: '21/05/2022',
      spent: 73904.18,
      cpm: 16.86,
      impressions: 4382890,
      linkCTR: 0.31,
      linkClicks: 13761,
      cpc: 5.37,
      landingPageViews: 6059,
      landingPageViewsRate: 44,
      quaternaryReportingConversion: 118,
      quaternaryReportingConversionCr: 0.86,
      quaternaryReportingConversionCpa: 626.31,
      tertiaryReportingConversion: 90,
      tertiaryReportingConversionCr: 0.65,
      tertiaryReportingConversionCpa: 821.16,
      secondaryReportingConversion: 143,
      secondaryReportingConversionCr: 1.04,
      secondaryReportingConversionCpa: 516.81,
      primaryReportingConversion: 308,
      primaryReportingConversionCr: 2.24,
      primaryReportingConversionCpa: 239.95
    },
    {
      day: '22/05/2022',
      projectName: '22/05/2022',
      productName: '22/05/2022',
      cityId: '22/05/2022',
      regionId: '22/05/2022',
      spent: 78908.44,
      cpm: 17.18,
      impressions: 4592098,
      linkCTR: 0.29,
      linkClicks: 13518,
      cpc: 5.84,
      landingPageViews: 5928,
      landingPageViewsRate: 43.9,
      quaternaryReportingConversion: 138,
      quaternaryReportingConversionCr: 1.02,
      quaternaryReportingConversionCpa: 571.8,
      tertiaryReportingConversion: 118,
      tertiaryReportingConversionCr: 0.87,
      tertiaryReportingConversionCpa: 668.72,
      secondaryReportingConversion: 148,
      secondaryReportingConversionCr: 1.09,
      secondaryReportingConversionCpa: 533.17,
      primaryReportingConversion: 302,
      primaryReportingConversionCr: 2.23,
      primaryReportingConversionCpa: 261.29
    },
    {
      day: '23/05/2022',
      projectName: '23/05/2022',
      productName: '23/05/2022',
      cityId: '23/05/2022',
      regionId: '23/05/2022',
      spent: 76179.66,
      cpm: 15.46,
      impressions: 4928801,
      linkCTR: 0.28,
      linkClicks: 13957,
      cpc: 5.46,
      landingPageViews: 5938,
      landingPageViewsRate: 42.5,
      quaternaryReportingConversion: 159,
      quaternaryReportingConversionCr: 1.14,
      quaternaryReportingConversionCpa: 479.12,
      tertiaryReportingConversion: 134,
      tertiaryReportingConversionCr: 0.96,
      tertiaryReportingConversionCpa: 568.5,
      secondaryReportingConversion: 135,
      secondaryReportingConversionCr: 0.97,
      secondaryReportingConversionCpa: 564.29,
      primaryReportingConversion: 371,
      primaryReportingConversionCr: 2.66,
      primaryReportingConversionCpa: 205.34
    },
    {
      day: '24/05/2022',
      projectName: '24/05/2022',
      productName: '24/05/2022',
      cityId: '24/05/2022',
      regionId: '24/05/2022',
      spent: 78184.65,
      cpm: 16.31,
      impressions: 4794993,
      linkCTR: 0.28,
      linkClicks: 13319,
      cpc: 5.87,
      landingPageViews: 5732,
      landingPageViewsRate: 43,
      quaternaryReportingConversion: 244,
      quaternaryReportingConversionCr: 1.83,
      quaternaryReportingConversionCpa: 320.43,
      tertiaryReportingConversion: 247,
      tertiaryReportingConversionCr: 1.85,
      tertiaryReportingConversionCpa: 316.54,
      secondaryReportingConversion: 209,
      secondaryReportingConversionCr: 1.57,
      secondaryReportingConversionCpa: 374.09,
      primaryReportingConversion: 515,
      primaryReportingConversionCr: 3.87,
      primaryReportingConversionCpa: 151.81
    },
    {
      day: '25/05/2022',
      projectName: '25/05/2022',
      productName: '25/05/2022',
      cityId: '25/05/2022',
      regionId: '25/05/2022',
      spent: 82140.27,
      cpm: 15.67,
      impressions: 5240510,
      linkCTR: 0.26,
      linkClicks: 13784,
      cpc: 5.96,
      landingPageViews: 5972,
      landingPageViewsRate: 43.3,
      quaternaryReportingConversion: 228,
      quaternaryReportingConversionCr: 1.65,
      quaternaryReportingConversionCpa: 360.26,
      tertiaryReportingConversion: 177,
      tertiaryReportingConversionCr: 1.28,
      tertiaryReportingConversionCpa: 464.07,
      secondaryReportingConversion: 176,
      secondaryReportingConversionCr: 1.28,
      secondaryReportingConversionCpa: 466.71,
      primaryReportingConversion: 558,
      primaryReportingConversionCr: 4.05,
      primaryReportingConversionCpa: 147.2
    },
    {
      day: '26/05/2022',
      projectName: '26/05/2022',
      productName: '26/05/2022',
      cityId: '26/05/2022',
      regionId: '26/05/2022',
      spent: 81698.89,
      cpm: 16.89,
      impressions: 4836443,
      linkCTR: 0.32,
      linkClicks: 15400,
      cpc: 5.31,
      landingPageViews: 6595,
      landingPageViewsRate: 42.8,
      quaternaryReportingConversion: 292,
      quaternaryReportingConversionCr: 1.9,
      quaternaryReportingConversionCpa: 279.79,
      tertiaryReportingConversion: 226,
      tertiaryReportingConversionCr: 1.47,
      tertiaryReportingConversionCpa: 361.5,
      secondaryReportingConversion: 261,
      secondaryReportingConversionCr: 1.69,
      secondaryReportingConversionCpa: 313.02,
      primaryReportingConversion: 590,
      primaryReportingConversionCr: 3.83,
      primaryReportingConversionCpa: 138.47
    },
    {
      day: '27/05/2022',
      projectName: '27/05/2022',
      productName: '27/05/2022',
      cityId: '27/05/2022',
      regionId: '27/05/2022',
      spent: 81397.42,
      cpm: 15.96,
      impressions: 5100635,
      linkCTR: 0.28,
      linkClicks: 14006,
      cpc: 5.81,
      landingPageViews: 6117,
      landingPageViewsRate: 43.7,
      quaternaryReportingConversion: 219,
      quaternaryReportingConversionCr: 1.56,
      quaternaryReportingConversionCpa: 371.68,
      tertiaryReportingConversion: 227,
      tertiaryReportingConversionCr: 1.62,
      tertiaryReportingConversionCpa: 358.58,
      secondaryReportingConversion: 259,
      secondaryReportingConversionCr: 1.85,
      secondaryReportingConversionCpa: 314.28,
      primaryReportingConversion: 533,
      primaryReportingConversionCr: 3.81,
      primaryReportingConversionCpa: 152.72
    },
    {
      day: '28/05/2022',
      projectName: '28/05/2022',
      productName: '28/05/2022',
      cityId: '28/05/2022',
      regionId: '28/05/2022',
      spent: 76234.88,
      cpm: 15.83,
      impressions: 4814516,
      linkCTR: 0.29,
      linkClicks: 13875,
      cpc: 5.49,
      landingPageViews: 5760,
      landingPageViewsRate: 41.5,
      quaternaryReportingConversion: 208,
      quaternaryReportingConversionCr: 1.5,
      quaternaryReportingConversionCpa: 366.51,
      tertiaryReportingConversion: 185,
      tertiaryReportingConversionCr: 1.33,
      tertiaryReportingConversionCpa: 412.08,
      secondaryReportingConversion: 212,
      secondaryReportingConversionCr: 1.53,
      secondaryReportingConversionCpa: 359.6,
      primaryReportingConversion: 481,
      primaryReportingConversionCr: 3.47,
      primaryReportingConversionCpa: 158.49
    },
    {
      day: '29/05/2022',
      projectName: '29/05/2022',
      productName: '29/05/2022',
      cityId: '29/05/2022',
      regionId: '29/05/2022',
      spent: 86931.12,
      cpm: 16.07,
      impressions: 5408358,
      linkCTR: 0.26,
      linkClicks: 14189,
      cpc: 6.13,
      landingPageViews: 5962,
      landingPageViewsRate: 42,
      quaternaryReportingConversion: 160,
      quaternaryReportingConversionCr: 1.13,
      quaternaryReportingConversionCpa: 543.32,
      tertiaryReportingConversion: 160,
      tertiaryReportingConversionCr: 1.13,
      tertiaryReportingConversionCpa: 543.32,
      secondaryReportingConversion: 199,
      secondaryReportingConversionCr: 1.4,
      secondaryReportingConversionCpa: 436.84,
      primaryReportingConversion: 331,
      primaryReportingConversionCr: 2.33,
      primaryReportingConversionCpa: 262.63
    },
    {
      day: '30/05/2022',
      projectName: '30/05/2022',
      productName: '30/05/2022',
      cityId: '30/05/2022',
      regionId: '30/05/2022',
      spent: 89665.73,
      cpm: 15.91,
      impressions: 5637379,
      linkCTR: 0.27,
      linkClicks: 15056,
      cpc: 5.96,
      landingPageViews: 6465,
      landingPageViewsRate: 42.9,
      quaternaryReportingConversion: 231,
      quaternaryReportingConversionCr: 1.53,
      quaternaryReportingConversionCpa: 388.16,
      tertiaryReportingConversion: 200,
      tertiaryReportingConversionCr: 1.33,
      tertiaryReportingConversionCpa: 448.33,
      secondaryReportingConversion: 218,
      secondaryReportingConversionCr: 1.45,
      secondaryReportingConversionCpa: 411.31,
      primaryReportingConversion: 485,
      primaryReportingConversionCr: 3.22,
      primaryReportingConversionCpa: 184.88
    },
    {
      day: '31/05/2022',
      projectName: '31/05/2022',
      productName: '31/05/2022',
      cityId: '31/05/2022',
      regionId: '31/05/2022',
      spent: 95741.59,
      cpm: 15.61,
      impressions: 6134839,
      linkCTR: 0.26,
      linkClicks: 15712,
      cpc: 6.09,
      landingPageViews: 6816,
      landingPageViewsRate: 43.4,
      quaternaryReportingConversion: 209,
      quaternaryReportingConversionCr: 1.33,
      quaternaryReportingConversionCpa: 458.09,
      tertiaryReportingConversion: 201,
      tertiaryReportingConversionCr: 1.28,
      tertiaryReportingConversionCpa: 476.33,
      secondaryReportingConversion: 175,
      secondaryReportingConversionCr: 1.11,
      secondaryReportingConversionCpa: 547.09,
      primaryReportingConversion: 435,
      primaryReportingConversionCr: 2.77,
      primaryReportingConversionCpa: 220.1
    },
    {
      day: '01/06/2022',
      projectName: '01/06/2022',
      productName: '01/06/2022',
      cityId: '01/06/2022',
      regionId: '01/06/2022',
      spent: 97141.26,
      cpm: 18.09,
      impressions: 5371336,
      linkCTR: 0.27,
      linkClicks: 14683,
      cpc: 6.62,
      landingPageViews: 6109,
      landingPageViewsRate: 41.6,
      quaternaryReportingConversion: 270,
      quaternaryReportingConversionCr: 1.84,
      quaternaryReportingConversionCpa: 359.78,
      tertiaryReportingConversion: 226,
      tertiaryReportingConversionCr: 1.54,
      tertiaryReportingConversionCpa: 429.83,
      secondaryReportingConversion: 315,
      secondaryReportingConversionCr: 2.15,
      secondaryReportingConversionCpa: 308.38,
      primaryReportingConversion: 605,
      primaryReportingConversionCr: 4.12,
      primaryReportingConversionCpa: 160.56
    },
    {
      day: '02/06/2022',
      projectName: '02/06/2022',
      productName: '02/06/2022',
      cityId: '02/06/2022',
      regionId: '02/06/2022',
      spent: 90784.35,
      cpm: 18.44,
      impressions: 4924140,
      linkCTR: 0.28,
      linkClicks: 13767,
      cpc: 6.59,
      landingPageViews: 5561,
      landingPageViewsRate: 40.4,
      quaternaryReportingConversion: 224,
      quaternaryReportingConversionCr: 1.63,
      quaternaryReportingConversionCpa: 405.29,
      tertiaryReportingConversion: 196,
      tertiaryReportingConversionCr: 1.42,
      tertiaryReportingConversionCpa: 463.19,
      secondaryReportingConversion: 282,
      secondaryReportingConversionCr: 2.05,
      secondaryReportingConversionCpa: 321.93,
      primaryReportingConversion: 577,
      primaryReportingConversionCr: 4.19,
      primaryReportingConversionCpa: 157.34
    },
    {
      day: '03/06/2022',
      projectName: '03/06/2022',
      productName: '03/06/2022',
      cityId: '03/06/2022',
      regionId: '03/06/2022',
      spent: 85685.83,
      cpm: 18.72,
      impressions: 4577535,
      linkCTR: 0.27,
      linkClicks: 12272,
      cpc: 6.98,
      landingPageViews: 5065,
      landingPageViewsRate: 41.3,
      quaternaryReportingConversion: 228,
      quaternaryReportingConversionCr: 1.86,
      quaternaryReportingConversionCpa: 375.82,
      tertiaryReportingConversion: 192,
      tertiaryReportingConversionCr: 1.56,
      tertiaryReportingConversionCpa: 446.28,
      secondaryReportingConversion: 249,
      secondaryReportingConversionCr: 2.03,
      secondaryReportingConversionCpa: 344.12,
      primaryReportingConversion: 567,
      primaryReportingConversionCr: 4.62,
      primaryReportingConversionCpa: 151.12
    },
    {
      day: '04/06/2022',
      projectName: '04/06/2022',
      productName: '04/06/2022',
      cityId: '04/06/2022',
      regionId: '04/06/2022',
      spent: 69397.23,
      cpm: 17.97,
      impressions: 3861665,
      linkCTR: 0.28,
      linkClicks: 10809,
      cpc: 6.42,
      landingPageViews: 4510,
      landingPageViewsRate: 41.7,
      quaternaryReportingConversion: 151,
      quaternaryReportingConversionCr: 1.4,
      quaternaryReportingConversionCpa: 459.58,
      tertiaryReportingConversion: 111,
      tertiaryReportingConversionCr: 1.03,
      tertiaryReportingConversionCpa: 625.2,
      secondaryReportingConversion: 187,
      secondaryReportingConversionCr: 1.73,
      secondaryReportingConversionCpa: 371.11,
      primaryReportingConversion: 421,
      primaryReportingConversionCr: 3.89,
      primaryReportingConversionCpa: 164.84
    },
    {
      day: '05/06/2022',
      projectName: '05/06/2022',
      productName: '05/06/2022',
      cityId: '05/06/2022',
      regionId: '05/06/2022',
      spent: 74055.15,
      cpm: 19.35,
      impressions: 3826833,
      linkCTR: 0.28,
      linkClicks: 10544,
      cpc: 7.02,
      landingPageViews: 4463,
      landingPageViewsRate: 42.3,
      quaternaryReportingConversion: 161,
      quaternaryReportingConversionCr: 1.53,
      quaternaryReportingConversionCpa: 459.97,
      tertiaryReportingConversion: 146,
      tertiaryReportingConversionCr: 1.38,
      tertiaryReportingConversionCpa: 507.23,
      secondaryReportingConversion: 248,
      secondaryReportingConversionCr: 2.35,
      secondaryReportingConversionCpa: 298.61,
      primaryReportingConversion: 434,
      primaryReportingConversionCr: 4.12,
      primaryReportingConversionCpa: 170.63
    },
    {
      day: '06/06/2022',
      projectName: '06/06/2022',
      productName: '06/06/2022',
      cityId: '06/06/2022',
      regionId: '06/06/2022',
      spent: 73086.44,
      cpm: 18.6,
      impressions: 3928342,
      linkCTR: 0.29,
      linkClicks: 11357,
      cpc: 6.44,
      landingPageViews: 4579,
      landingPageViewsRate: 40.3,
      quaternaryReportingConversion: 185,
      quaternaryReportingConversionCr: 1.63,
      quaternaryReportingConversionCpa: 395.06,
      tertiaryReportingConversion: 176,
      tertiaryReportingConversionCr: 1.55,
      tertiaryReportingConversionCpa: 415.26,
      secondaryReportingConversion: 139,
      secondaryReportingConversionCr: 1.22,
      secondaryReportingConversionCpa: 525.8,
      primaryReportingConversion: 423,
      primaryReportingConversionCr: 3.72,
      primaryReportingConversionCpa: 172.78
    },
    {
      day: '07/06/2022',
      projectName: '07/06/2022',
      productName: '07/06/2022',
      cityId: '07/06/2022',
      regionId: '07/06/2022',
      spent: 80347.51,
      cpm: 19.44,
      impressions: 4133674,
      linkCTR: 0.28,
      linkClicks: 11772,
      cpc: 6.83,
      landingPageViews: 4826,
      landingPageViewsRate: 41,
      quaternaryReportingConversion: 249,
      quaternaryReportingConversionCr: 2.12,
      quaternaryReportingConversionCpa: 322.68,
      tertiaryReportingConversion: 226,
      tertiaryReportingConversionCr: 1.92,
      tertiaryReportingConversionCpa: 355.52,
      secondaryReportingConversion: 178,
      secondaryReportingConversionCr: 1.51,
      secondaryReportingConversionCpa: 451.39,
      primaryReportingConversion: 630,
      primaryReportingConversionCr: 5.35,
      primaryReportingConversionCpa: 127.54
    },
    {
      day: '08/06/2022',
      projectName: '08/06/2022',
      productName: '08/06/2022',
      cityId: '08/06/2022',
      regionId: '08/06/2022',
      spent: 15792.12,
      cpm: 19.21,
      impressions: 822092,
      linkCTR: 0.32,
      linkClicks: 2615,
      cpc: 6.04,
      landingPageViews: 1081,
      landingPageViewsRate: 41.3,
      quaternaryReportingConversion: 37,
      quaternaryReportingConversionCr: 1.41,
      quaternaryReportingConversionCpa: 426.81,
      tertiaryReportingConversion: 30,
      tertiaryReportingConversionCr: 1.15,
      tertiaryReportingConversionCpa: 526.4,
      secondaryReportingConversion: 25,
      secondaryReportingConversionCr: 0.96,
      secondaryReportingConversionCpa: 631.68,
      primaryReportingConversion: 110,
      primaryReportingConversionCr: 4.21,
      primaryReportingConversionCpa: 143.56
    }
  ],
  grandTotal: {
    day: 'Grand Total',
    projectName: 'Grand Total',
    productName: 'Grand Total',
    cityId: 'Grand Total',
    regionId: 'Grand Total',
    mediaName: 'Grand Total',
    spent: 7737877.03,
    cpm: 16.56,
    impressions: 467172101,
    linkCTR: 0.29,
    linkClicks: 1337840,
    cpc: 5.78,
    landingPageViews: 543396,
    landingPageViewsRate: 40.6,
    quaternaryReportingConversion: 23748,
    quaternaryReportingConversionCr: 1.78,
    quaternaryReportingConversionCpa: 325.83,
    tertiaryReportingConversion: 18310,
    tertiaryReportingConversionCr: 1.37,
    tertiaryReportingConversionCpa: 422.6,
    secondaryReportingConversion: 27300,
    secondaryReportingConversionCr: 2.04,
    secondaryReportingConversionCpa: 283.44,
    primaryReportingConversion: 54555,
    primaryReportingConversionCr: 4.08,
    primaryReportingConversionCpa: 141.84,
    city: '',
    region: '',
    audienceSize: ''
  },
  columns: [
    {
      label: 'DAILY',
      dataKey: 'day',
      isFixed: true
    },
    {
      label: 'Spent',
      dataKey: 'spent'
    },
    {
      label: 'CPM',
      dataKey: 'cpm',
      withColorIndicator: 'min'
    },
    {
      label: 'Impressions',
      dataKey: 'impressions'
    },
    {
      label: 'Link CTR %',
      dataKey: 'linkCTR',
      hasPercentage: true
    },
    {
      label: 'Link Clicks',
      dataKey: 'linkClicks'
    },
    {
      label: 'CPC',
      dataKey: 'cpc',
      withColorIndicator: 'min'
    },
    {
      label: 'LP views',
      dataKey: 'landingPageViews'
    },
    {
      label: 'LPV Rate%',
      dataKey: 'landingPageViewsRate',
      hasPercentage: true
    },
    {
      label: 'Hero Metric 1',
      dataKey: 'primaryReportingConversion'
    },
    {
      label: 'Hero Metric 1 CPQ',
      dataKey: 'primaryReportingConversionCpa',
      withColorIndicator: 'min'
    },
    {
      label: 'Hero Metric 1 CVR%',
      dataKey: 'primaryReportingConversionCr',
      hasPercentage: true
    },
    {
      label: 'Hero Metric 2',
      dataKey: 'secondaryReportingConversion'
    },
    {
      label: 'Hero Metric 2 CPQ',
      dataKey: 'secondaryReportingConversionCpa',
      withColorIndicator: 'min'
    },
    {
      label: 'Hero Metric 2 CVR%',
      dataKey: 'secondaryReportingConversionCr',
      hasPercentage: true
    },
    {
      label: 'Hero Metric 3',
      dataKey: 'tertiaryReportingConversion'
    },
    {
      label: 'Hero Metric 3 CPQ',
      dataKey: 'tertiaryReportingConversionCpa',
      withColorIndicator: 'min'
    },
    {
      label: 'Hero Metric 3 CVR%',
      dataKey: 'tertiaryReportingConversionCr',
      hasPercentage: true
    },
    {
      label: 'Hero Metric 4',
      dataKey: 'quaternaryReportingConversion'
    },
    {
      label: 'Hero Metric 4 CPQ',
      dataKey: 'quaternaryReportingConversionCpa',
      withColorIndicator: 'min'
    },
    {
      label: 'Hero Metric 4 CVR%',
      dataKey: 'quaternaryReportingConversionCr',
      hasPercentage: true
    }
  ],
  exportLink: '/graphs/time-series-data-table/csv',
  filters: {
    heroMetrics: {
      type: '',
      label: 'Hero Metric 1',
      metric: 'primary'
    },
    activeLabel: 'DAILY',
    clientIds: [
      348, 227, 237, 263, 246, 330, 302, 328, 408, 54, 103, 128, 335, 332, 388, 314, 264, 280, 414,
      333, 272, 172, 203, 142, 174, 140, 175, 338, 141, 176, 143, 177, 144, 402, 311, 289, 285, 313,
      293, 358, 372, 300, 218, 273, 217, 415, 392, 283, 271, 149, 180, 287, 131, 178, 87, 181, 105,
      182, 15, 173, 73, 416, 284, 56, 270, 262, 202, 132, 183, 88, 184, 339, 104, 185, 17, 186, 74,
      423, 169, 187, 170, 188, 171, 189, 424, 148, 269, 274, 275, 315, 276, 277, 366, 159, 221, 295,
      251, 282, 161, 215, 279, 219, 214, 222, 319, 208, 210, 390, 387, 385, 139, 329, 331, 394, 296,
      409, 386, 243, 324, 34, 201, 410, 428, 418, 129, 250, 252, 152, 191, 158, 192, 340, 153, 193,
      305, 290, 376, 384, 344, 426, 383, 378, 430, 136, 138, 137, 434, 431, 205, 419, 393, 336, 320,
      309, 310, 80, 326, 249, 278, 146, 194, 154, 145, 195, 341, 147, 196, 299, 420, 337, 321, 292,
      286, 267, 106, 256, 253, 157, 197, 155, 198, 342, 156, 199, 303, 343, 421, 429, 365, 357, 347,
      216, 228, 62, 377, 97, 301, 281, 211, 245, 255, 350, 355, 225, 346, 351, 373, 231, 354, 288,
      232, 417, 381, 382, 307, 306, 312, 352, 356, 395, 400, 399, 407, 367, 364, 362, 375, 359, 360,
      371, 361, 363, 370, 369, 368, 379, 374, 297, 397, 220, 260, 37, 130, 401, 406, 405, 353, 345,
      327, 209, 422, 391, 89, 93, 90, 380, 160, 294, 323, 298, 304, 291, 268, 151, 91, 63, 65, 92,
      162, 163, 168, 396, 308
    ],
    netGross: 'net',
    last13Weeks: true,
    clientGroupReportingIds: [
      55, 15, 58, 57, 85, 64, 35, 52, 16, 27, 28, 48, 17, 29, 30, 94, 95, 63, 18, 80, 19, 20, 39,
      40, 88, 62, 60, 3, 51, 38, 44, 66, 87, 32, 49, 22, 50, 54, 37, 33, 34, 93, 73, 68, 23, 67, 92,
      69, 24, 75, 81, 47, 74, 82, 90, 86, 79, 76, 78, 46, 83, 43, 65, 84, 59, 42, 56, 26, 45, 25,
      41, 71, 61, 91, 53
    ],
    clientGroupIds: [
      355, 211, 224, 256, 236, 337, 302, 335, 418, 1, 61, 127, 342, 339, 395, 315, 257, 277, 424,
      340, 267, 148, 188, 110, 150, 108, 156, 345, 109, 157, 111, 158, 112, 409, 312, 289, 285, 314,
      293, 365, 379, 300, 202, 268, 201, 425, 399, 281, 266, 117, 160, 287, 70, 159, 48, 161, 63,
      162, 2, 149, 30, 426, 282, 5, 265, 255, 186, 69, 163, 49, 164, 346, 62, 165, 4, 166, 31, 433,
      138, 168, 139, 169, 140, 170, 434, 116, 264, 270, 271, 316, 272, 273, 373, 128, 205, 295, 243,
      280, 130, 199, 276, 203, 198, 206, 326, 192, 194, 397, 394, 392, 107, 336, 338, 401, 296, 419,
      393, 231, 331, 26, 185, 420, 438, 428, 67, 240, 244, 120, 174, 123, 183, 347, 121, 176, 305,
      290, 383, 391, 351, 436, 390, 385, 440, 104, 106, 105, 444, 441, 189, 429, 400, 343, 327, 310,
      311, 41, 333, 239, 275, 114, 177, 122, 113, 178, 348, 115, 179, 299, 430, 344, 328, 292, 286,
      262, 64, 248, 245, 126, 180, 124, 181, 349, 125, 182, 303, 350, 431, 439, 372, 364, 354, 200,
      212, 15, 384, 56, 301, 279, 195, 232, 247, 357, 362, 209, 353, 358, 380, 214, 361, 288, 216,
      427, 388, 389, 308, 306, 313, 359, 363, 402, 407, 406, 417, 374, 371, 369, 382, 366, 367, 378,
      368, 370, 377, 376, 375, 386, 381, 297, 404, 204, 252, 19, 68, 408, 416, 415, 360, 352, 334,
      193, 432, 398, 43, 52, 51, 387, 129, 294, 330, 298, 304, 291, 263, 119, 50, 20, 27, 44, 131,
      132, 137, 403, 309
    ],
    dateRange: {
      to: '2022-06-12T12:00:00.000+01:00',
      from: '2022-03-14'
    }
  }
};

export const biaxialChartData = {
  title: 'Hero Metric 1 vs Hero Metric 1 CPQ',
  barName: 'Hero Metric 1',
  lineName: 'Hero Metric 1 CPQ',
  data: [
    {
      name: '14/03/2022',
      barValue: 554,
      barValueToShow: 554,
      barTooltipValue: '14/03/2022',
      lineValue: 138.65320316328925,
      lineValueToShow: 138.65320316328925,
      lineTooltipValue: '14/03/2022'
    },
    {
      name: '15/03/2022',
      barValue: 702,
      barValueToShow: '',
      barTooltipValue: '15/03/2022',
      lineValue: 125.42088209709253,
      lineValueToShow: '',
      lineTooltipValue: '15/03/2022'
    },
    {
      name: '16/03/2022',
      barValue: 609,
      barValueToShow: 609,
      barTooltipValue: '16/03/2022',
      lineValue: 145.51658648780696,
      lineValueToShow: 145.51658648780696,
      lineTooltipValue: '16/03/2022'
    },
    {
      name: '17/03/2022',
      barValue: 484,
      barValueToShow: '',
      barTooltipValue: '17/03/2022',
      lineValue: 177.44107151739607,
      lineValueToShow: '',
      lineTooltipValue: '17/03/2022'
    },
    {
      name: '18/03/2022',
      barValue: 416,
      barValueToShow: 416,
      barTooltipValue: '18/03/2022',
      lineValue: 192.471935455759,
      lineValueToShow: 192.471935455759,
      lineTooltipValue: '18/03/2022'
    },
    {
      name: '19/03/2022',
      barValue: 432,
      barValueToShow: '',
      barTooltipValue: '19/03/2022',
      lineValue: 163.0062085436226,
      lineValueToShow: '',
      lineTooltipValue: '19/03/2022'
    },
    {
      name: '20/03/2022',
      barValue: 446,
      barValueToShow: 446,
      barTooltipValue: '20/03/2022',
      lineValue: 138.4653201862969,
      lineValueToShow: 138.4653201862969,
      lineTooltipValue: '20/03/2022'
    },
    {
      name: '21/03/2022',
      barValue: 507,
      barValueToShow: '',
      barTooltipValue: '21/03/2022',
      lineValue: 129.78444424856463,
      lineValueToShow: '',
      lineTooltipValue: '21/03/2022'
    },
    {
      name: '22/03/2022',
      barValue: 692,
      barValueToShow: 692,
      barTooltipValue: '22/03/2022',
      lineValue: 103.86155676485357,
      lineValueToShow: 103.86155676485357,
      lineTooltipValue: '22/03/2022'
    },
    {
      name: '23/03/2022',
      barValue: 688,
      barValueToShow: '',
      barTooltipValue: '23/03/2022',
      lineValue: 110.60623471070687,
      lineValueToShow: '',
      lineTooltipValue: '23/03/2022'
    },
    {
      name: '24/03/2022',
      barValue: 640,
      barValueToShow: 640,
      barTooltipValue: '24/03/2022',
      lineValue: 120.19049799850617,
      lineValueToShow: 120.19049799850617,
      lineTooltipValue: '24/03/2022'
    },
    {
      name: '25/03/2022',
      barValue: 636,
      barValueToShow: '',
      barTooltipValue: '25/03/2022',
      lineValue: 121.75700765912005,
      lineValueToShow: '',
      lineTooltipValue: '25/03/2022'
    },
    {
      name: '26/03/2022',
      barValue: 660,
      barValueToShow: 660,
      barTooltipValue: '26/03/2022',
      lineValue: 118.03066336219622,
      lineValueToShow: 118.03066336219622,
      lineTooltipValue: '26/03/2022'
    },
    {
      name: '27/03/2022',
      barValue: 727,
      barValueToShow: '',
      barTooltipValue: '27/03/2022',
      lineValue: 107.7017125291327,
      lineValueToShow: '',
      lineTooltipValue: '27/03/2022'
    },
    {
      name: '28/03/2022',
      barValue: 784,
      barValueToShow: 784,
      barTooltipValue: '28/03/2022',
      lineValue: 97.7297141524982,
      lineValueToShow: 97.7297141524982,
      lineTooltipValue: '28/03/2022'
    },
    {
      name: '29/03/2022',
      barValue: 872,
      barValueToShow: '',
      barTooltipValue: '29/03/2022',
      lineValue: 92.88245177769248,
      lineValueToShow: '',
      lineTooltipValue: '29/03/2022'
    },
    {
      name: '30/03/2022',
      barValue: 904,
      barValueToShow: 904,
      barTooltipValue: '30/03/2022',
      lineValue: 103.5629623040631,
      lineValueToShow: 103.5629623040631,
      lineTooltipValue: '30/03/2022'
    },
    {
      name: '31/03/2022',
      barValue: 662,
      barValueToShow: '',
      barTooltipValue: '31/03/2022',
      lineValue: 107.05276166052865,
      lineValueToShow: '',
      lineTooltipValue: '31/03/2022'
    },
    {
      name: '01/04/2022',
      barValue: 584,
      barValueToShow: 584,
      barTooltipValue: '01/04/2022',
      lineValue: 138.6249436586553,
      lineValueToShow: 138.6249436586553,
      lineTooltipValue: '01/04/2022'
    },
    {
      name: '02/04/2022',
      barValue: 423,
      barValueToShow: '',
      barTooltipValue: '02/04/2022',
      lineValue: 170.82930686530034,
      lineValueToShow: '',
      lineTooltipValue: '02/04/2022'
    },
    {
      name: '03/04/2022',
      barValue: 599,
      barValueToShow: 599,
      barTooltipValue: '03/04/2022',
      lineValue: 150.43074696132905,
      lineValueToShow: 150.43074696132905,
      lineTooltipValue: '03/04/2022'
    },
    {
      name: '04/04/2022',
      barValue: 708,
      barValueToShow: '',
      barTooltipValue: '04/04/2022',
      lineValue: 127.8678890243278,
      lineValueToShow: '',
      lineTooltipValue: '04/04/2022'
    },
    {
      name: '05/04/2022',
      barValue: 696,
      barValueToShow: 696,
      barTooltipValue: '05/04/2022',
      lineValue: 121.28488193636481,
      lineValueToShow: 121.28488193636481,
      lineTooltipValue: '05/04/2022'
    },
    {
      name: '06/04/2022',
      barValue: 594,
      barValueToShow: '',
      barTooltipValue: '06/04/2022',
      lineValue: 134.73666393543635,
      lineValueToShow: '',
      lineTooltipValue: '06/04/2022'
    },
    {
      name: '07/04/2022',
      barValue: 755,
      barValueToShow: 755,
      barTooltipValue: '07/04/2022',
      lineValue: 113.4265240214913,
      lineValueToShow: 113.4265240214913,
      lineTooltipValue: '07/04/2022'
    },
    {
      name: '08/04/2022',
      barValue: 658,
      barValueToShow: '',
      barTooltipValue: '08/04/2022',
      lineValue: 140.2620424656141,
      lineValueToShow: '',
      lineTooltipValue: '08/04/2022'
    },
    {
      name: '09/04/2022',
      barValue: 594,
      barValueToShow: 594,
      barTooltipValue: '09/04/2022',
      lineValue: 155.43051620882306,
      lineValueToShow: 155.43051620882306,
      lineTooltipValue: '09/04/2022'
    },
    {
      name: '10/04/2022',
      barValue: 566,
      barValueToShow: '',
      barTooltipValue: '10/04/2022',
      lineValue: 163.53474294435063,
      lineValueToShow: '',
      lineTooltipValue: '10/04/2022'
    },
    {
      name: '11/04/2022',
      barValue: 597,
      barValueToShow: 597,
      barTooltipValue: '11/04/2022',
      lineValue: 145.2947789722651,
      lineValueToShow: 145.2947789722651,
      lineTooltipValue: '11/04/2022'
    },
    {
      name: '12/04/2022',
      barValue: 682,
      barValueToShow: '',
      barTooltipValue: '12/04/2022',
      lineValue: 128.419577403939,
      lineValueToShow: '',
      lineTooltipValue: '12/04/2022'
    },
    {
      name: '13/04/2022',
      barValue: 720,
      barValueToShow: 720,
      barTooltipValue: '13/04/2022',
      lineValue: 126.0116184637054,
      lineValueToShow: 126.0116184637054,
      lineTooltipValue: '13/04/2022'
    },
    {
      name: '14/04/2022',
      barValue: 696,
      barValueToShow: '',
      barTooltipValue: '14/04/2022',
      lineValue: 132.01750718845034,
      lineValueToShow: '',
      lineTooltipValue: '14/04/2022'
    },
    {
      name: '15/04/2022',
      barValue: 523,
      barValueToShow: 523,
      barTooltipValue: '15/04/2022',
      lineValue: 156.65432159571301,
      lineValueToShow: 156.65432159571301,
      lineTooltipValue: '15/04/2022'
    },
    {
      name: '16/04/2022',
      barValue: 474,
      barValueToShow: '',
      barTooltipValue: '16/04/2022',
      lineValue: 171.2043436792078,
      lineValueToShow: '',
      lineTooltipValue: '16/04/2022'
    },
    {
      name: '17/04/2022',
      barValue: 480,
      barValueToShow: 480,
      barTooltipValue: '17/04/2022',
      lineValue: 157.7610536357388,
      lineValueToShow: 157.7610536357388,
      lineTooltipValue: '17/04/2022'
    },
    {
      name: '18/04/2022',
      barValue: 718,
      barValueToShow: '',
      barTooltipValue: '18/04/2022',
      lineValue: 116.95092999046892,
      lineValueToShow: '',
      lineTooltipValue: '18/04/2022'
    },
    {
      name: '19/04/2022',
      barValue: 855,
      barValueToShow: 855,
      barTooltipValue: '19/04/2022',
      lineValue: 108.63983143498326,
      lineValueToShow: 108.63983143498326,
      lineTooltipValue: '19/04/2022'
    },
    {
      name: '20/04/2022',
      barValue: 768,
      barValueToShow: '',
      barTooltipValue: '20/04/2022',
      lineValue: 122.58710761166488,
      lineValueToShow: '',
      lineTooltipValue: '20/04/2022'
    },
    {
      name: '21/04/2022',
      barValue: 699,
      barValueToShow: 699,
      barTooltipValue: '21/04/2022',
      lineValue: 134.72569828757346,
      lineValueToShow: 134.72569828757346,
      lineTooltipValue: '21/04/2022'
    },
    {
      name: '22/04/2022',
      barValue: 631,
      barValueToShow: '',
      barTooltipValue: '22/04/2022',
      lineValue: 152.2518156390702,
      lineValueToShow: '',
      lineTooltipValue: '22/04/2022'
    },
    {
      name: '23/04/2022',
      barValue: 728,
      barValueToShow: 728,
      barTooltipValue: '23/04/2022',
      lineValue: 147.15470175229905,
      lineValueToShow: 147.15470175229905,
      lineTooltipValue: '23/04/2022'
    },
    {
      name: '24/04/2022',
      barValue: 732,
      barValueToShow: '',
      barTooltipValue: '24/04/2022',
      lineValue: 150.98049004085343,
      lineValueToShow: '',
      lineTooltipValue: '24/04/2022'
    },
    {
      name: '25/04/2022',
      barValue: 755,
      barValueToShow: 755,
      barTooltipValue: '25/04/2022',
      lineValue: 148.98944238788442,
      lineValueToShow: 148.98944238788442,
      lineTooltipValue: '25/04/2022'
    },
    {
      name: '26/04/2022',
      barValue: 864,
      barValueToShow: '',
      barTooltipValue: '26/04/2022',
      lineValue: 137.34194883216966,
      lineValueToShow: '',
      lineTooltipValue: '26/04/2022'
    },
    {
      name: '27/04/2022',
      barValue: 817,
      barValueToShow: 817,
      barTooltipValue: '27/04/2022',
      lineValue: 142.26616031637772,
      lineValueToShow: 142.26616031637772,
      lineTooltipValue: '27/04/2022'
    },
    {
      name: '28/04/2022',
      barValue: 859,
      barValueToShow: '',
      barTooltipValue: '28/04/2022',
      lineValue: 129.8404071346116,
      lineValueToShow: '',
      lineTooltipValue: '28/04/2022'
    },
    {
      name: '29/04/2022',
      barValue: 669,
      barValueToShow: 669,
      barTooltipValue: '29/04/2022',
      lineValue: 157.00260370239192,
      lineValueToShow: 157.00260370239192,
      lineTooltipValue: '29/04/2022'
    },
    {
      name: '30/04/2022',
      barValue: 719,
      barValueToShow: '',
      barTooltipValue: '30/04/2022',
      lineValue: 137.56088952465768,
      lineValueToShow: '',
      lineTooltipValue: '30/04/2022'
    },
    {
      name: '01/05/2022',
      barValue: 704,
      barValueToShow: 704,
      barTooltipValue: '01/05/2022',
      lineValue: 140.25888296812826,
      lineValueToShow: 140.25888296812826,
      lineTooltipValue: '01/05/2022'
    },
    {
      name: '02/05/2022',
      barValue: 891,
      barValueToShow: '',
      barTooltipValue: '02/05/2022',
      lineValue: 118.18489873128307,
      lineValueToShow: '',
      lineTooltipValue: '02/05/2022'
    },
    {
      name: '03/05/2022',
      barValue: 997,
      barValueToShow: 997,
      barTooltipValue: '03/05/2022',
      lineValue: 111.27530390684798,
      lineValueToShow: 111.27530390684798,
      lineTooltipValue: '03/05/2022'
    },
    {
      name: '04/05/2022',
      barValue: 1127,
      barValueToShow: '',
      barTooltipValue: '04/05/2022',
      lineValue: 105.49304955344425,
      lineValueToShow: '',
      lineTooltipValue: '04/05/2022'
    },
    {
      name: '05/05/2022',
      barValue: 927,
      barValueToShow: 927,
      barTooltipValue: '05/05/2022',
      lineValue: 125.06002614050782,
      lineValueToShow: 125.06002614050782,
      lineTooltipValue: '05/05/2022'
    },
    {
      name: '06/05/2022',
      barValue: 756,
      barValueToShow: '',
      barTooltipValue: '06/05/2022',
      lineValue: 144.79676278067566,
      lineValueToShow: '',
      lineTooltipValue: '06/05/2022'
    },
    {
      name: '07/05/2022',
      barValue: 731,
      barValueToShow: 731,
      barTooltipValue: '07/05/2022',
      lineValue: 150.14348818360995,
      lineValueToShow: 150.14348818360995,
      lineTooltipValue: '07/05/2022'
    },
    {
      name: '08/05/2022',
      barValue: 492,
      barValueToShow: '',
      barTooltipValue: '08/05/2022',
      lineValue: 219.89624455052513,
      lineValueToShow: '',
      lineTooltipValue: '08/05/2022'
    },
    {
      name: '09/05/2022',
      barValue: 590,
      barValueToShow: 590,
      barTooltipValue: '09/05/2022',
      lineValue: 184.5711473690851,
      lineValueToShow: 184.5711473690851,
      lineTooltipValue: '09/05/2022'
    },
    {
      name: '10/05/2022',
      barValue: 1020,
      barValueToShow: '',
      barTooltipValue: '10/05/2022',
      lineValue: 103.87486758180985,
      lineValueToShow: '',
      lineTooltipValue: '10/05/2022'
    },
    {
      name: '11/05/2022',
      barValue: 1013,
      barValueToShow: 1013,
      barTooltipValue: '11/05/2022',
      lineValue: 117.86326303517653,
      lineValueToShow: 117.86326303517653,
      lineTooltipValue: '11/05/2022'
    },
    {
      name: '12/05/2022',
      barValue: 709,
      barValueToShow: '',
      barTooltipValue: '12/05/2022',
      lineValue: 147.85668774228674,
      lineValueToShow: '',
      lineTooltipValue: '12/05/2022'
    },
    {
      name: '13/05/2022',
      barValue: 712,
      barValueToShow: 712,
      barTooltipValue: '13/05/2022',
      lineValue: 131.52064767902655,
      lineValueToShow: 131.52064767902655,
      lineTooltipValue: '13/05/2022'
    },
    {
      name: '14/05/2022',
      barValue: 497,
      barValueToShow: '',
      barTooltipValue: '14/05/2022',
      lineValue: 159.0244755151586,
      lineValueToShow: '',
      lineTooltipValue: '14/05/2022'
    },
    {
      name: '15/05/2022',
      barValue: 560,
      barValueToShow: 560,
      barTooltipValue: '15/05/2022',
      lineValue: 157.278709633675,
      lineValueToShow: 157.278709633675,
      lineTooltipValue: '15/05/2022'
    },
    {
      name: '16/05/2022',
      barValue: 626,
      barValueToShow: '',
      barTooltipValue: '16/05/2022',
      lineValue: 170.31829998204216,
      lineValueToShow: '',
      lineTooltipValue: '16/05/2022'
    },
    {
      name: '17/05/2022',
      barValue: 570,
      barValueToShow: 570,
      barTooltipValue: '17/05/2022',
      lineValue: 184.98313688228004,
      lineValueToShow: 184.98313688228004,
      lineTooltipValue: '17/05/2022'
    },
    {
      name: '18/05/2022',
      barValue: 539,
      barValueToShow: '',
      barTooltipValue: '18/05/2022',
      lineValue: 174.55811057180333,
      lineValueToShow: '',
      lineTooltipValue: '18/05/2022'
    },
    {
      name: '19/05/2022',
      barValue: 407,
      barValueToShow: 407,
      barTooltipValue: '19/05/2022',
      lineValue: 213.01017757265367,
      lineValueToShow: 213.01017757265367,
      lineTooltipValue: '19/05/2022'
    },
    {
      name: '20/05/2022',
      barValue: 433,
      barValueToShow: '',
      barTooltipValue: '20/05/2022',
      lineValue: 194.14160328696528,
      lineValueToShow: '',
      lineTooltipValue: '20/05/2022'
    },
    {
      name: '21/05/2022',
      barValue: 308,
      barValueToShow: 308,
      barTooltipValue: '21/05/2022',
      lineValue: 239.94863637807694,
      lineValueToShow: 239.94863637807694,
      lineTooltipValue: '21/05/2022'
    },
    {
      name: '22/05/2022',
      barValue: 302,
      barValueToShow: '',
      barTooltipValue: '22/05/2022',
      lineValue: 261.2862371152131,
      lineValueToShow: '',
      lineTooltipValue: '22/05/2022'
    },
    {
      name: '23/05/2022',
      barValue: 371,
      barValueToShow: 371,
      barTooltipValue: '23/05/2022',
      lineValue: 205.3360238550005,
      lineValueToShow: 205.3360238550005,
      lineTooltipValue: '23/05/2022'
    },
    {
      name: '24/05/2022',
      barValue: 515,
      barValueToShow: '',
      barTooltipValue: '24/05/2022',
      lineValue: 151.8148569355276,
      lineValueToShow: '',
      lineTooltipValue: '24/05/2022'
    },
    {
      name: '25/05/2022',
      barValue: 558,
      barValueToShow: 558,
      barTooltipValue: '25/05/2022',
      lineValue: 147.2047792679253,
      lineValueToShow: 147.2047792679253,
      lineTooltipValue: '25/05/2022'
    },
    {
      name: '26/05/2022',
      barValue: 590,
      barValueToShow: '',
      barTooltipValue: '26/05/2022',
      lineValue: 138.47269055062935,
      lineValueToShow: '',
      lineTooltipValue: '26/05/2022'
    },
    {
      name: '27/05/2022',
      barValue: 533,
      barValueToShow: 533,
      barTooltipValue: '27/05/2022',
      lineValue: 152.71560993217855,
      lineValueToShow: 152.71560993217855,
      lineTooltipValue: '27/05/2022'
    },
    {
      name: '28/05/2022',
      barValue: 481,
      barValueToShow: '',
      barTooltipValue: '28/05/2022',
      lineValue: 158.49247245999004,
      lineValueToShow: '',
      lineTooltipValue: '28/05/2022'
    },
    {
      name: '29/05/2022',
      barValue: 331,
      barValueToShow: 331,
      barTooltipValue: '29/05/2022',
      lineValue: 262.63178272801946,
      lineValueToShow: 262.63178272801946,
      lineTooltipValue: '29/05/2022'
    },
    {
      name: '30/05/2022',
      barValue: 485,
      barValueToShow: '',
      barTooltipValue: '30/05/2022',
      lineValue: 184.87779622717002,
      lineValueToShow: '',
      lineTooltipValue: '30/05/2022'
    },
    {
      name: '31/05/2022',
      barValue: 435,
      barValueToShow: 435,
      barTooltipValue: '31/05/2022',
      lineValue: 220.09559837117277,
      lineValueToShow: 220.09559837117277,
      lineTooltipValue: '31/05/2022'
    },
    {
      name: '01/06/2022',
      barValue: 605,
      barValueToShow: '',
      barTooltipValue: '01/06/2022',
      lineValue: 160.56406446464422,
      lineValueToShow: '',
      lineTooltipValue: '01/06/2022'
    },
    {
      name: '02/06/2022',
      barValue: 577,
      barValueToShow: 577,
      barTooltipValue: '02/06/2022',
      lineValue: 157.33855300192985,
      lineValueToShow: 157.33855300192985,
      lineTooltipValue: '02/06/2022'
    },
    {
      name: '03/06/2022',
      barValue: 567,
      barValueToShow: '',
      barTooltipValue: '03/06/2022',
      lineValue: 151.12140181430038,
      lineValueToShow: '',
      lineTooltipValue: '03/06/2022'
    },
    {
      name: '04/06/2022',
      barValue: 421,
      barValueToShow: 421,
      barTooltipValue: '04/06/2022',
      lineValue: 164.8390258549936,
      lineValueToShow: 164.8390258549936,
      lineTooltipValue: '04/06/2022'
    },
    {
      name: '05/06/2022',
      barValue: 434,
      barValueToShow: '',
      barTooltipValue: '05/06/2022',
      lineValue: 170.63397679515697,
      lineValueToShow: '',
      lineTooltipValue: '05/06/2022'
    },
    {
      name: '06/06/2022',
      barValue: 423,
      barValueToShow: 423,
      barTooltipValue: '06/06/2022',
      lineValue: 172.78119366762203,
      lineValueToShow: 172.78119366762203,
      lineTooltipValue: '06/06/2022'
    },
    {
      name: '07/06/2022',
      barValue: 630,
      barValueToShow: '',
      barTooltipValue: '07/06/2022',
      lineValue: 127.85010334779582,
      lineValueToShow: '',
      lineTooltipValue: '07/06/2022'
    },
    {
      name: '08/06/2022',
      barValue: 465,
      barValueToShow: 465,
      barTooltipValue: '08/06/2022',
      lineValue: 157.4393556924677,
      lineValueToShow: 157.4393556924677,
      lineTooltipValue: '08/06/2022'
    },
    {
      name: '09/06/2022',
      barValue: 57,
      barValueToShow: '',
      barTooltipValue: '09/06/2022',
      lineValue: 33.62903515811552,
      lineValueToShow: '',
      lineTooltipValue: '09/06/2022'
    }
  ]
};
