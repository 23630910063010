import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from 'components/Icon';

export default function PositionedMenu({ items }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (onClick) => {
    setAnchorEl(null);
    if (onClick && typeof onClick === 'function') onClick();
  };

  return (
    <>
      <Button
        id="positioned-button"
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ width: '30px', height: '30px', minWidth: '20px', float: 'right' }}
      >
        <Icon type="download" style={{ width: 'fit-content' }} />
      </Button>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {items.map((item) => (
          <MenuItem key={item?.name} onClick={() => handleClose(item.onClick)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
