import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import UITable from 'components/Table';
import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import { useDashboard } from 'utils/hooks/useDashboard';
import { selectTimeOfDayData } from 'redux/slices/dashboards';
import { getTimeOfDayData } from 'redux/thunk/dashboards';
import TimeOfDayBiaxialComposedChartsGroup from 'components/_dashboard/timeOfDayBiaxialComposedChartsGroup';
import { detectEmptyData } from 'utils/utility';

// ----------------------------------------------------------------------

TimeOfDay.propTypes = {
  isModal: PropTypes.bool
};

export default function TimeOfDay({ isModal }) {
  const { dashboardData, isLoading } = useDashboard(getTimeOfDayData, selectTimeOfDayData, isModal);

  return (
    <DashboardLayout
      hideFilters={isModal}
      hideTimeSelector
      isLoading={isLoading}
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12} mt={5} mb={5}>
        <TimeOfDayBiaxialComposedChartsGroup
          timeOfDayChartData={dashboardData.composedCharts}
          isModal={isModal}
        />
      </Grid>
      {dashboardData && dashboardData.table && (
        <Grid item xs={12} sx={{ marginRight: isModal ? 2 : 0 }}>
          <UITable
            grandTotal={dashboardData.table?.grandTotal}
            list={dashboardData.table?.rows}
            columns={dashboardData.table?.columns}
            title="Dashboard"
            filters={dashboardData.table?.filters}
            exportLink={dashboardData.table?.exportLink}
          />
        </Grid>
      )}
    </DashboardLayout>
  );
}
