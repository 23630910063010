import { styled } from '@mui/material/styles';
import RockerterBiaxialComposedChart from 'components/charts/BiaxialComposedChart';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomTooltip from 'components/charts/CustomTooltip';
import VolumeVsEfficiencyTooltip from 'components/charts/BiaxialComposedChart/VolumeVsEfficiencyTooltip';
// ----------------------------------------------------------------------

const ChartTotalTitle = styled('div')(({ theme }) => ({
  fontSize: 14,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  marginBottom: 40,
  textAlign: 'center'
}));

BiaxialComposedChartsGroup.propTypes = {
  volumeEfficiencyChartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default function BiaxialComposedChartsGroup({ volumeEfficiencyChartData, isModal }) {
  const _renderChartTitle = (data, index) => <ChartTotalTitle>{data.title}</ChartTotalTitle>;

  return (
    <Grid container>
      {volumeEfficiencyChartData?.length &&
        volumeEfficiencyChartData.map((data, index) => (
          <Grid item xs={12} key={index} sx={{ marginBottom: 10 }}>
            {_renderChartTitle(data, index)}
            <CustomTooltip zoom={-0.05}>
              <RockerterBiaxialComposedChart
                width={isModal ? '97%' : '100%'}
                chartData={data}
                CustomTooltip={VolumeVsEfficiencyTooltip}
              />
            </CustomTooltip>
          </Grid>
        ))}
    </Grid>
  );
}
