import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import palette from 'theme/palette';

function renderCustomizedLabel(props) {
  return <text {...props} fontSize={11} />;
}

function renderActiveShape(props) {
  const { cx, cy, midAngle, outerRadius, value, payload } = props;
  if (value < 2) return <></>;
  const RADIAN = Math.PI / 180;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 3}
        y={ey}
        textAnchor={textAnchor}
        fill={payload.fill}
        fontSize={11}
      >
        {value}%
      </text>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={payload.fill} fill="none" />
    </g>
  );
}

export default function RocketerPieChart({ chartData, width, height, outerRadius }) {
  const onMouseOver = (data, x, e) => {
    e.target.style.opacity = 0.8;
  };

  const onMouseOut = (data, x, e) => {
    e.target.style.opacity = 1;
  };

  return (
    <PieChart width={width || 400} height={height || 300}>
      <Pie
        data={chartData}
        dataKey="percentage"
        cx="50%"
        cy="50%"
        outerRadius={outerRadius || 80}
        isAnimationActive={false}
        label={renderCustomizedLabel}
        labelLine={renderActiveShape}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={palette.pieChart[index]} />
        ))}
      </Pie>
      <Legend wrapperStyle={{ fontSize: 12 }} />
    </PieChart>
  );
}
