import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import RocketerBarChart from 'components/charts/BarChart';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { selectIsSidebarOpen } from 'redux/slices/app';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import CustomTooltip from 'components/charts/CustomTooltip';

// ----------------------------------------------------------------------

const ChartTotalData = styled('div')(() => ({
  marginLeft: 20,
  marginBottom: 30,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  fontWeight: 'bold',
  opacity: 0.7
}));

ProductAnalysisHorizontalChartsGroup.propTypes = {
  barChartData: PropTypes.array,
  isModal: PropTypes.bool
};

export default function ProductAnalysisHorizontalChartsGroup({ barChartData, isModal }) {
  const [width] = useWindowSize();
  const theme = useTheme();
  const defaultHeight = 250;
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const _width = isModal
    ? (width + width * 0.2) / 4
    : (width + width * 0.2 - (isDashboardSidebarFiltersOpen ? 480 : 0)) / 4;
  //   using the ones from region tab, because are the same
  const { regionBarColors, regionHoverColors } = theme.palette.chart;
  const titleAddOnBy = 'by';

  const _renderChartTitle = (data, index) => (
    <ChartTotalData index={index}>
      <ChartTitle>{`${titleAddOnBy} ${data.title}`}</ChartTitle>
    </ChartTotalData>
  );

  return (
    <Grid container justifyContent="space-between" alignItems="stretch" sx={{ zoom: '80%' }}>
      {barChartData &&
        barChartData.map((data, index) => (
          <Grid
            item
            xs={Math.round(12 / barChartData.length)}
            sx={{ mb: 7 }}
            key={data.title}
            style={{ height: defaultHeight + 50 }}
          >
            {_renderChartTitle(data, index)}
            <CustomTooltip zoomLeft={0.3} zoomRight={0.2}>
              <RocketerBarChart
                xAxisDomain={[0, 'auto']}
                hoverBarColor={regionHoverColors[index]}
                barColor={regionBarColors[index]}
                showCartesianGrid
                showTooltip
                showLegend
                chartData={data}
                width={_width}
                height={defaultHeight + 50}
                roundNumber={index % 2 === 0}
                hideXAxisLegend
                hideYTickLine
                yAxisWidth={100}
                title={data.title}
                margin={{
                  left: 30,
                  right: 20
                }}
              />
            </CustomTooltip>
          </Grid>
        ))}
    </Grid>
  );
}
