/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveLabel,
  selectFiltersLoading,
  selectCheckedFilters,
  composeFilterObj,
  selectFilterObjForApiCall,
  selectTrackableFilterObjForApiCall,
  setHeroMetricsDropdownSelection,
  setHeroMetricsFilter,
  selectHeroMetricFilter
  // setHeroMetricsDropdownSelection
} from 'redux/slices/filters';
import { selectDashboard, selectDashboardLoading } from 'redux/slices/dashboards';
import _, { isEmpty } from 'lodash';
import { getHeroMetricOptions } from 'redux/thunk/filters';

export function useDashboard(fetchFunction, selector, isModal) {
  const dispatch = useDispatch();
  const activeTimeFrame = useSelector(selectActiveLabel('time'));
  const activeHeroMetric = useSelector(selectHeroMetricFilter);
  const dashboardData = useSelector(selector);
  const filtersLoading = useSelector(selectFiltersLoading);
  const dashboardLoading = useSelector(selectDashboardLoading);
  const overallLoading = filtersLoading || dashboardLoading;
  const checkedFilters = useSelector(selectCheckedFilters);
  const filterObjForApiCall = useSelector(selectFilterObjForApiCall);
  const trackableFilterObjForApiCall = useSelector(selectTrackableFilterObjForApiCall);
  const [dataFetched, setDataFetched] = useState(false);
  const currentDashboard = useSelector(selectDashboard);
  const isTimeSeriesDataDashboard = currentDashboard?.name === 'Time Series Data';
  const [trackableObjForTimeSeries, setTrackableObjForTimeSeries] = useState({});

  const [initialFetch, setInitialFetch] = useState(true);

  useEffect(() => {
    setInitialFetch(true);
  }, []);

  // ------ fetch handling for all dashboards, except Time series data -----
  useEffect(() => {
    if (!isEmpty(checkedFilters) && !isModal && !isTimeSeriesDataDashboard) {
      dispatch(composeFilterObj());
      setDataFetched(false);
    }
  }, [checkedFilters, activeTimeFrame, activeHeroMetric]);

  useEffect(() => {
    if (
      !isEmpty(trackableFilterObjForApiCall) &&
      !dataFetched &&
      !isModal &&
      !isTimeSeriesDataDashboard
    ) {
      dispatch(fetchFunction(filterObjForApiCall, initialFetch));
      setInitialFetch(false);
      setDataFetched(true);
    }
  }, [trackableFilterObjForApiCall, activeHeroMetric]);

  // ------ fetch handling for Time series data -------
  useEffect(() => {
    if (!isEmpty(checkedFilters) && !isModal && isTimeSeriesDataDashboard) {
      dispatch(composeFilterObj());
      setDataFetched(false);
    }
  }, [checkedFilters, activeTimeFrame]);

  useEffect(() => {
    if (!isEmpty(filterObjForApiCall) && isTimeSeriesDataDashboard) {
      setTrackableObjForTimeSeries(
        _.omit(filterObjForApiCall, ['clientGroupReportingIds', 'clientGroupIds', 'clientIds'])
      );
    }
  }, [filterObjForApiCall]);

  // when on Time series data, when companies change, get new herometrics and set extraFilters.herometrics
  useEffect(() => {
    if (isTimeSeriesDataDashboard && !isEmpty(filterObjForApiCall)) {
      dispatch(
        getHeroMetricOptions(filterObjForApiCall?.clientGroupReportingIds, (options) => {
          // set first herometric and first herometric's option after options are successfully fetched
          dispatch(setHeroMetricsDropdownSelection(0));
          dispatch(
            setHeroMetricsFilter({
              type: options[0].metrics[0].type,
              label: options[0].metrics[0].label,
              metric: options[0].metrics[0].metric
            })
          );
        })
      );
    }
  }, [filterObjForApiCall.clientGroupReportingIds]);

  useEffect(() => {
    if (!isEmpty(trackableFilterObjForApiCall) && !isModal && isTimeSeriesDataDashboard) {
      dispatch(fetchFunction(filterObjForApiCall));
    }
  }, [trackableObjForTimeSeries]);

  useEffect(() => {
    if (!isEmpty(trackableFilterObjForApiCall) && !isModal && isTimeSeriesDataDashboard) {
      dispatch(fetchFunction(filterObjForApiCall));
    }
  }, [activeHeroMetric]);

  // left this in case issues while testing
  // useEffect(() => {
  //   if (currentDashboardFilters.length) {
  //     dispatch(fetchFunction(filterObjForApiCall));
  //   }
  // }, [currentDashboardFilters]);

  return { dashboardData, isLoading: overallLoading };
}
