import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDashboardFilters,
  selectCompaniesFromFilter,
  setCheckedFilters,
  selectCheckedFilters,
  selectProjectsFromFilter
} from 'redux/slices/filters';
import AutocompleteDropdown from 'components/dropdown/AutocompleteDropdown';
import {
  setDataForFilter,
  setCorrespondingProjectsToCompany,
  setAllCorrespondingProjectsToCompany
} from 'utils/utility';
import { setDataLength } from 'redux/slices/dashboards';

export default function CompanyDropdown() {
  const { t } = useTranslation();
  const companies = useSelector(selectCompaniesFromFilter);
  const companyProjFilter = useSelector(selectDashboardFilters);
  const dispatch = useDispatch();
  const { company, filteredCompanies } = companyProjFilter;
  const checkedFilters = useSelector(selectCheckedFilters);
  const projects = useSelector(selectProjectsFromFilter);

  const handleProjectsSelection = (selectedCompanies, checked) => {
    if (!selectedCompanies.length) {
      dispatch(
        setCheckedFilters({
          filterName: 'Project Name',
          data: []
        })
      );
    } else {
      selectedCompanies.map((company) =>
        dispatch(
          setCheckedFilters({
            filterName: 'Project Name',
            data: checked
              ? setCorrespondingProjectsToCompany(company.id, projects, checked, checkedFilters)
              : setAllCorrespondingProjectsToCompany(company.id, projects, checkedFilters)
          })
        )
      );
    }
    dispatch(setDataLength(''));
  };

  const handleChange = (event, arrayOfValues, reason) => {
    if (reason === 'removeOption' || reason === 'clear') {
      handleProjectsSelection(arrayOfValues, false);
      if (checkedFilters['Company Name']?.data.length === 1 || !arrayOfValues.length) {
        dispatch(
          setCheckedFilters({
            filterName: 'Company Name',
            data: setDataForFilter('all', 'Company Name', false, companies, checkedFilters)
          })
        );
      } else {
        const removedOption = checkedFilters['Company Name'].data.find(
          (o) => !arrayOfValues.map((value) => value.name).includes(o)
        );
        dispatch(
          setCheckedFilters({
            filterName: 'Company Name',
            data: setDataForFilter(removedOption, 'Company Name', false, companies, checkedFilters)
          })
        );
      }
    } else {
      dispatch(
        setCheckedFilters({
          filterName: 'Company Name',
          data: setDataForFilter(
            event.currentTarget.textContent || arrayOfValues.pop(),
            'Company Name',
            !!arrayOfValues.find(
              (value) => value.name === event.currentTarget.textContent || arrayOfValues[0].name
            ),
            companies,
            checkedFilters
          )
        })
      );
      handleProjectsSelection(arrayOfValues, true);
      dispatch(setDataLength(''));
    }
  };

  return (
    <AutocompleteDropdown
      options={filteredCompanies.length ? filteredCompanies : companies}
      placeholder={company.length ? '' : t('companyName')}
      handleChange={handleChange}
      filterType="company"
      disabled={checkedFilters['Company Name']?.disabled || false}
      selectedOptions={companies.filter(
        (company) =>
          checkedFilters['Company Name']?.data?.find(
            (checkedFilter) => checkedFilter === company.name
          ) || false
      )}
    />
  );
}
