import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  },
  isSidebarOpen: true
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    }
  }
});

export const { setLanguage, setNotification, setIsSidebarOpen } = appSlice.actions;

export const selectLanguage = (state) => state.app.language;
export const selectNotification = (state) => state.app.notification;
export const selectIsSidebarOpen = (state) => state.app.isSidebarOpen;

export default appSlice.reducer;
