import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid
} from 'recharts';
import palette from 'theme/palette';
import typography from 'theme/typography';
import { useTheme } from '@mui/material/styles';
import { fShortenNumber } from 'utils/formatNumber';
import { fDate } from 'utils/formatTime';
import { useTooltipContext } from 'components/charts/CustomTooltip';
import { dateFormatter } from 'utils/utility';
// ----------------------------------------------------------------------

const xAxisStyle = {
  fontSize: 12
};
const yAxisStyle = {
  fontSize: 12,
  marginRight: '15px'
};

const numberFormatter = (tick) => fShortenNumber(tick).toUpperCase();

// ----------------------------------------------------------------------

RockerterBiaxialComposedChart.propTypes = {
  chartData: PropTypes.object,
  height: PropTypes.number,
  leftRightPadding: PropTypes.number,
  CustomTooltip: PropTypes.func
};

const tooltipContent = (props, color, title) => {
  const { payload } = props;
  const { name, dataKey } = props.tooltipPayload[0];
  if (!name || !dataKey || !payload) return null;
  return (
    <div>
      <div style={{ color: palette.common.black }}>{`${name}`}</div>
      <div style={{ color, fontWeight: 'bold' }}>{`${fShortenNumber(
        props.value
      ).toUpperCase()}`}</div>
      <hr style={{ marginTop: 10, marginBottom: 10 }} />
      <span style={{ color: palette.common.black }}>{dateFormatter(payload.name)}</span>
    </div>
  );
};
const tooltipStyle = {
  backgroundColor: palette.common.white,
  borderColor: palette.grey[500],
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  fontFamily: typography.fontFamily,
  fontSize: '13px',
  padding: '.5%',
  marginTop: '-200px'
};

export default function RockerterBiaxialComposedChart({ chartData, height, CustomTooltip, width }) {
  const theme = useTheme();
  const color = theme.palette.primary.dark;
  const colorHover = theme.palette.primary.light;
  const labelColor = theme.palette.error.light;
  const { openTooltip, closeTooltip } = useTooltipContext();

  const onMouseOver = (data, x, e) => {
    e.target.style.fill = colorHover;
  };

  const onMouseOut = (data, x, e) => {
    e.target.style.fill = color;
  };

  return (
    <ResponsiveContainer width={width || '100%'} height={height || 400}>
      <ComposedChart data={chartData?.data}>
        <XAxis
          dataKey="name"
          allowDataOverflow
          minTickGap={1}
          tickSize={10}
          tickFormatter={(tick) => dateFormatter(tick)}
          style={xAxisStyle}
        />
        <YAxis
          yAxisId="left"
          tickSize={10}
          allowDataOverflow
          style={yAxisStyle}
          label={{
            value: `${chartData?.barName}`,
            angle: -90,
            position: 'insideLeft',
            fontSize: 12
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickSize={10}
          allowDataOverflow
          style={yAxisStyle}
          tickFormatter={(tick) => numberFormatter(tick)}
          label={{
            value: `${chartData?.lineName}`,
            angle: 90,
            position: 'insideRight',
            fontSize: 12
          }}
        />
        <CartesianGrid horizontal={false} />
        <Legend wrapperStyle={{ position: 'relative', marginTop: -5, fontSize: 12 }} />
        <Bar
          yAxisId="left"
          name={chartData?.barName}
          dataKey="barValue"
          maxBarSize={80}
          fill={color}
          isAnimationActive={false}
          legendType="square"
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onMouseEnter={(e) =>
            openTooltip({
              content: tooltipContent(e, color, chartData?.barName),
              style: tooltipStyle
            })
          }
          onMouseLeave={() => closeTooltip()}
        />
        <Line
          yAxisId="right"
          name={chartData?.lineName}
          dataKey="lineValue"
          type="linear"
          stroke={labelColor}
          strokeWidth={2}
          legendType="line"
          isAnimationActive={false}
          dot={CustomTooltip ? <CustomTooltip color={labelColor} /> : <div />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
