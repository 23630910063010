import { styled } from '@mui/material/styles';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Icon from 'components/Icon';
import {
  setIsDashboardSidebarFiltersOpen,
  selectIsDashboardSidebarFiltersOpen,
  selectCurrentDashboardFilters
} from 'redux/slices/filters';
import FilterCard from 'components/sidebar-filters/FilterCard';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../components/Scrollbar';

// ----------------------------------------------------------------------

export const FILTERS_DRAWER_WIDTH = 220;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    width: FILTERS_DRAWER_WIDTH
  }
}));

const FiltersWrapperStyle = styled('div')(() => ({
  position: 'fixed',
  right: '0px',
  top: '57%'
}));

// ----------------------------------------------------------------------

export default function DashboardFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const currentDashboardFilters = useSelector(selectCurrentDashboardFilters);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ pt: 3, pb: 1, top: '50%', position: 'absolute' }}>
        <IconButton
          onClick={() => dispatch(setIsDashboardSidebarFiltersOpen(false))}
          sx={{
            mr: 0,
            color: 'text.primary',
            transform: 'rotate(180deg) scale(0.5)',
            width: '30px',
            padding: 0,
            right: '8px'
          }}
        >
          <Icon type="arrowLeft" />
        </IconButton>
      </Box>
      <Typography sx={{ fontSize: 12, fontWeight: 700, marginTop: '13px', marginLeft: '13px' }}>
        {t('filters')}
      </Typography>
      <Box xs={{ marginBottom: '50px' }}>
        {currentDashboardFilters &&
          currentDashboardFilters.map((filterData) => (
            <FilterCard key={filterData.id} data={filterData} />
          ))}
      </Box>
    </Scrollbar>
  );
  if (isDashboardSidebarFiltersOpen) {
    return (
      <RootStyle>
        <Drawer
          open={isDashboardSidebarFiltersOpen}
          onClose={() => dispatch(setIsDashboardSidebarFiltersOpen(false))}
          variant="persistent"
          anchor="right"
          PaperProps={{
            sx: { width: FILTERS_DRAWER_WIDTH, height: 'calc(100% - 70px)', marginTop: '71px' },
            bgcolor: 'background.default'
          }}
        >
          {renderContent}
        </Drawer>
      </RootStyle>
    );
  }
  return (
    <FiltersWrapperStyle>
      <IconButton
        onClick={() => dispatch(setIsDashboardSidebarFiltersOpen(true))}
        sx={{
          mr: 1,
          color: 'text.primary',
          transform: 'scale(0.4)',
          float: 'right',
          right: 0,
          display: 'contents'
        }}
      >
        <Icon type="arrowLeft" />
      </IconButton>
    </FiltersWrapperStyle>
  );
}
