import React, { useState } from 'react';
import { Box, Popper, Fade, Typography, IconButton, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import palette from 'theme/palette';
import DateList from 'components/DateList';
import Calendar from 'components/Calendar';
import ActiveDataRangeLabels from 'components/sidebar-filters/ActiveDataRangeLabels';
import {
  setCalendarFromAndTo,
  setCheckedFilters,
  selectCalendarFromAndTo
} from 'redux/slices/filters';
import { useDispatch, useSelector } from 'react-redux';
import { fDate } from 'utils/formatTime';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { setDataLength } from 'redux/slices/dashboards';

const StyledBox = styled(Box)(({ theme, calendar }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '5px',
  height: '400px',
  width: calendar ? '800px' : '450px',
  p: 1,
  background: theme.palette.common.white,
  boxShadow: theme.customShadows.z24,
  padding: '12px',
  marginRight: '190px'
}));

const ButtonStyle = styled(IconButton)(() => ({
  height: '20px',
  width: '20px',
  transform: 'scale(0.7)',
  position: 'absolute',
  right: 0,
  padding: 0
}));

const OKButtonStyle = styled(IconButton)(({ theme }) => ({
  height: '40px',
  width: '60px',
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: '5px',
  background: theme.palette.primary.dark,
  transform: 'scale(0.7)',
  position: 'absolute',
  right: 0,
  padding: 0,
  marginRight: '-10px',
  color: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.primary.light
  }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '30px',
  padding: '7px',
  alignItemss: 'left',
  fontSize: 12,
  fontWeight: 400,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  display: 'block',
  textAlign: 'left',
  justifyContent: 'left',
  alignItems: 'left !important'
}));

export default function FilterModal({
  id,
  setModalToOpen,
  open,
  anchorEl,
  setAnchorEl,
  modalType
}) {
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState(0);

  const calendarFromAndTo = useSelector(selectCalendarFromAndTo);
  const [calendarValue, setCalendarValue] = useState([null, null]);

  const [selectedDates, setSelectedDates] = useState([]);

  const [selectedCalendarFrame, setCalendarFrame] = useState('01-01-2022');
  const [selectedTimeframe, setSelectedTimeframe] = useState('Years');

  const dispatch = useDispatch();
  const _renderTitle = () => (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        borderBottom: `1px solid ${palette.grey[400]}`,
        paddingBottom: '6px'
      }}
    >
      <Typography sx={{ fontSize: 17, fontWeight: 700, lineHeight: '20px' }}>
        {t('editFilter')}
      </Typography>
      <ButtonStyle
        onClick={() => {
          setModalToOpen(null);
          setAnchorEl(null);
        }}
      >
        <Icon type="close" />
      </ButtonStyle>
    </Box>
  );

  TabPanel.propTypes = {
    children: PropTypes.object,
    value: PropTypes.number,
    index: PropTypes.number
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ px: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const getCalendarToAndFrom = () => {
    // set only to value
    if (!calendarFromAndTo[0] && calendarFromAndTo[1] && tabSelected !== 0) {
      return [
        {
          id: 'calendar-selection-to',
          name: `${fDate(calendarFromAndTo[1])}`
        }
      ];
    }

    // set only from value
    if (calendarFromAndTo[0] && !calendarFromAndTo[1] && tabSelected !== 0) {
      return [
        {
          id: 'calendar-selection-from',
          name: `${fDate(calendarFromAndTo[0])}`
        }
      ];
    }

    // set both from and to values
    if (calendarFromAndTo[0] && calendarFromAndTo[1] && tabSelected !== 0) {
      return [
        {
          id: 'calendar-selection',
          name: `From ${fDate(calendarFromAndTo[0])} - To ${fDate(calendarFromAndTo[1])}`
        }
      ];
    }

    return [];
  };

  const _renderFooter = () => (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        borderTop: `1px solid ${palette.grey[400]}`,
        paddingBottom: '6px'
      }}
    >
      <ActiveDataRangeLabels
        selectedTimeframe={selectedTimeframe}
        selectedDates={selectedDates}
        activeTab={tabSelected}
      />
      <Box sx={{ maxWidth: '150px' }} />
      <OKButtonStyle
        onClick={() => {
          const toAndFrom = getCalendarToAndFrom();
          setModalToOpen(null);
          setAnchorEl(null);
          if (selectedDates.length || toAndFrom.length) {
            dispatch(
              setCheckedFilters({
                filterName: 'Date Range',
                data: tabSelected && toAndFrom.length ? toAndFrom : selectedDates
              })
            );
            dispatch(setDataLength(''));
          }
        }}
      >
        <Typography sx={{ fontSize: 17, fontWeight: 700, lineHeight: '20px' }}>OK</Typography>
      </OKButtonStyle>
    </Box>
  );

  const _renderDateRangeContent = () => (
    <Box sx={{ position: 'relative', display: 'flex' }}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '315px' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabSelected}
          onChange={(e, value) => {
            setTabSelected(value);
          }}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <StyledTab label={t('list')} />
          <StyledTab label={t('calendar')} />
        </Tabs>
        <TabPanel value={tabSelected} index={0}>
          <DateList
            selectedDates={selectedDates}
            setSelectedDates={(dates) => setSelectedDates(dates)}
            selectedTimeframe={selectedTimeframe}
            setSelectedTimeframe={setSelectedTimeframe}
          />
        </TabPanel>
        <TabPanel value={tabSelected} index={1}>
          <Calendar
            value={calendarValue}
            setValue={(v) => setCalendarValue(v)}
            selectedCalendarFrame={selectedCalendarFrame}
            setCalendarFrame={(frame) => {
              setCalendarFrame(frame);
              dispatch(setCalendarFromAndTo([null, null]));
            }}
          />
        </TabPanel>
      </Box>
    </Box>
  );

  const _renderFilterModalContent = () => {
    let content = null;
    switch (modalType) {
      case 'dateRange':
        content = _renderDateRangeContent();
        break;
      default:
        content = <></>;
    }
    return content;
  };

  return (
    <Popper id={id} open={open} anchorEl={anchorEl} transition placement="left-start">
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <StyledBox calendar={tabSelected === 1}>
            {_renderTitle()}
            {_renderFilterModalContent()}
            {_renderFooter()}
          </StyledBox>
        </Fade>
      )}
    </Popper>
  );
}

FilterModal.propTypes = {
  id: PropTypes.string,
  setModalToOpen: PropTypes.func,
  open: PropTypes.bool,
  anchorEl: PropTypes.element,
  setAnchorEl: PropTypes.func,
  modalType: PropTypes.string
};
