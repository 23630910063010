import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, IconButton } from '@mui/material';
// components
import Search from 'components/Search';
import {
  selectDashboards,
  filterDashboards,
  selectFilteredDashboards,
  selectDashboardNotFoundTrigger,
  setDashboardNotFoundTrigger
} from 'redux/slices/dashboards';
import { useSelector, useDispatch } from 'react-redux';
import Icon from 'components/Icon';
import { setNotification, selectIsSidebarOpen, setIsSidebarOpen } from 'redux/slices/app';
import { DRAWER_WIDTH } from 'uiConstants';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import breakpoints from 'theme/breakpoints';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import configSidebar from './SidebarConfig';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

export default function DashboardSidebar() {
  const { t } = useTranslation();
  const dashboards = useSelector(selectDashboards);
  const filteredDashboards = useSelector(selectFilteredDashboards);
  const dashboardNotFoundTrigger = useSelector(selectDashboardNotFoundTrigger);
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const [width] = useWindowSize();

  useEffect(() => {
    if (dashboardNotFoundTrigger) {
      dispatch(setNotification({ type: 'error', messageId: 'dashboardNotFound' }));
      dispatch(setDashboardNotFoundTrigger(false));
    }
  }, [dashboardNotFoundTrigger, dispatch]);

  useEffect(() => {
    if (width && width < breakpoints.values.lg && isSidebarOpen) {
      dispatch(setIsSidebarOpen(false));
    }
  }, [width]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ pl: 2.5, pt: 3, pb: 1 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo width={100} />
        </Box>
        <IconButton
          onClick={() => dispatch(setIsSidebarOpen(false))}
          sx={{
            mr: 1,
            color: 'text.primary',
            transform: 'scale(0.5)',
            float: 'right'
          }}
        >
          <Icon type="arrowLeft" />
        </IconButton>
      </Box>

      <Box sx={{ mb: 0.5, mx: 2.5 }}>
        <Search
          placeholderId="dashboards"
          onChange={(word) => dispatch(filterDashboards(word))}
          withIcon
        />
      </Box>

      <NavSection
        navConfig={configSidebar(filteredDashboards.length ? filteredDashboards : dashboards, t)}
      />
    </Scrollbar>
  );
  if (isSidebarOpen) {
    return (
      <RootStyle>
        <Drawer
          open={isSidebarOpen}
          onClose={() => dispatch(setIsSidebarOpen(false))}
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
            bgcolor: 'background.default'
          }}
        >
          {renderContent}
        </Drawer>
      </RootStyle>
    );
  }
  return <> </>;
}
