import axios from 'axios';
import {
  clearData,
  clearToken,
  clearUser,
  getAccessToken,
  getToken,
  getRefreshToken,
  setLocalStorageAccessToken,
  setLocalStorageRefreshToken,
  validateQueryParams,
  setLocalStorageToken,
  setLocalStorageEmail,
  getEmail
} from 'utils/utility';

const http = (config) => {
  const apiConfig = {
    baseURL: process.env.REACT_APP_API_BASE_URL_POINTER || 'http://localhost:5000',
    timeout: 300000
  };

  const handleSuccess = (response) => {
    if (response.data && response.data.refreshToken && response.data.idToken) {
      clearToken();
      setLocalStorageRefreshToken(response.data.refreshToken);
      setLocalStorageToken(response.data.idToken);
      setLocalStorageAccessToken(response.data.accessToken);
    }
    if (response.data.email) {
      setLocalStorageEmail(response.data.email);
    }
    return response.data;
  };

  const handleError = async (error) => {
    if (error.response) {
      // If id token expired, we need to refresh it
      if (error.response.status === 401 && error.response.data.access === 'TOKEN_EXPIRED') {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          try {
            const { config } = error;
            // Make a request to refresh-token endpoint to get new ones
            const { accessToken, idToken, refreshToken } = await axios
              .post(
                `${config.baseURL}users/refresh-token`,
                {
                  email: getEmail()
                },
                {
                  headers: {
                    Authorization: getToken(),
                    RefreshToken: getRefreshToken()
                  }
                }
              )
              .then((r) => r.data);

            setLocalStorageRefreshToken(refreshToken);
            setLocalStorageToken(idToken);
            setLocalStorageAccessToken(accessToken);

            // Repeat the failed request
            const response = await axios.request({
              ...config,
              headers: {
                ...config.headers,
                baseURL: undefined,
                Authorization: `${idToken}`
              }
            });
            return response;
          } catch (e) {
            if (window.location.pathname !== '/login') {
              clearToken();
              window.location.href = '/login';
            }
          }
        }
      }
      // In case we receive 401, logout the user
      if (error.response.status === 401 && window.location.pathname !== '/login') {
        clearToken();
        clearUser();
        clearData();
        window.location.href = '/login';
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        errorMessage: error.response.data.message || error.response.statusText || '',
        errorStatus: error.response.status
      });
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ errorMessage: error });
  };

  const apiClient = axios.create(config || apiConfig);
  apiClient.interceptors.response.use(handleSuccess, (error) => handleError(error));
  apiClient.interceptors.request.use((config) => {
    const idToken = getToken();
    const refreshToken = getRefreshToken();
    const accessToken = getAccessToken();
    if (idToken != null) {
      if (!config.headers.Authorization) {
        config.headers.Authorization = idToken;
      }
    }
    if (refreshToken != null) {
      if (!config.headers.RefreshToken) {
        config.headers.RefreshToken = refreshToken;
      }
    }
    if (accessToken != null) {
      if (!config.headers.AccessToken) {
        config.headers.AccessToken = accessToken;
      }
    }

    return {
      ...config,
      url: validateQueryParams(config.url)
    };
  });
  return apiClient;
};

export default http();
