import palette from 'theme/palette';
import typography from 'theme/typography';
import { useTooltipContext } from 'components/charts/CustomTooltip';
import React from 'react';
import { fShortenNumber } from 'utils/formatNumber';

const tooltipContent = (props) => (
  <div>
    <div style={{ color: palette.common.black }}>{`${props.name}`}</div>
    <div style={{ color: props.color, fontWeight: 'bold' }}>{`${fShortenNumber(
      props.value
    ).toUpperCase()}`}</div>
    <hr style={{ marginTop: 10, marginBottom: 10 }} />
    <span style={{ color: palette.common.black }}>{props.payload.tooltipValue}</span>
  </div>
);

const tooltipStyle = {
  backgroundColor: palette.common.white,
  borderColor: palette.grey[500],
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  fontFamily: typography.fontFamily,
  fontSize: '20px',
  padding: '.5%'
};

export default (props) => {
  const { dataKey, ...rest } = props;
  const { openTooltip, closeTooltip } = useTooltipContext();
  if (props.value === null) return <div />;
  return (
    <svg
      t="100"
      width="160%"
      height="160%"
      onMouseEnter={(e) =>
        openTooltip({
          content: tooltipContent(rest),
          style: tooltipStyle
        })
      }
      onMouseLeave={() => closeTooltip()}
    >
      <circle {...rest} r={7} strokeWidth={3} />
    </svg>
  );
};
