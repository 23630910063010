import palette from 'theme/palette';
import typography from 'theme/typography';
import { useTooltipContext } from 'components/charts/CustomTooltip';
import React from 'react';
import { fShortenNumber } from 'utils/formatNumber';
import { dateFormatter } from 'utils/utility';

const tooltipContent = (props) => (
  <div>
    <div style={{ color: palette.common.black }}>{`${props.name}`}</div>
    <div style={{ color: props.color, fontWeight: 'bold' }}>{`${fShortenNumber(
      props.value
    ).toUpperCase()}`}</div>
    <hr style={{ marginTop: 10, marginBottom: 10 }} />
    <span style={{ color: palette.common.black }}>{dateFormatter(props.payload.name)}</span>
  </div>
);

const tooltipStyle = {
  backgroundColor: palette.common.white,
  borderColor: palette.grey[500],
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  fontFamily: typography.fontFamily,
  fontSize: '13px',
  padding: '.5%',
  marginTop: '-250px'
};

export default (props) => {
  const { openTooltip, closeTooltip } = useTooltipContext();
  if (props.value === null) return <div />;
  return (
    <svg
      t="100"
      width="100%"
      height="100%"
      onMouseEnter={(e) =>
        openTooltip({
          content: tooltipContent(props),
          style: tooltipStyle
        })
      }
      onMouseLeave={() => closeTooltip()}
    >
      <circle {...props} r={4} fill={props.color} />
    </svg>
  );
};
