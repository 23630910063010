import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { formatTick, invisibleTick } from 'utils/utility';
import palette from 'theme/palette';
import typography from 'theme/typography';
import { fShortenNumber, fNumber2Decimals } from 'utils/formatNumber';
import { useTooltipContext } from 'components/charts/CustomTooltip';

// ----------------------------------------------------------------------

const xAxisStyle = {
  fontSize: 12,
  color: 'palette.grey[800]'
};
const yAxisStyle = {
  fontSize: 14,
  color: palette.grey[800],
  marginRight: '15px',
  whiteSpace: 'nowrap'
};

const yAxisCustomizedTickStyle = {
  fontSize: 10.5,
  textAlign: 'left',
  lineHeight: '8'
};

// ----------------------------------------------------------------------

RocketerBarChart.propTypes = {
  chartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  xAxisDomain: PropTypes.array,
  hideYAxisLegend: PropTypes.bool,
  hideXAxisLegend: PropTypes.bool,
  showCartesianGrid: PropTypes.bool,
  hideVerticalCartesianGrid: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.object,
  barColor: PropTypes.string,
  hoverBarColor: PropTypes.string,
  barLabelKey: PropTypes.string,
  title: PropTypes.string,
  yDataKey: PropTypes.string,
  yPadding: PropTypes.object,
  showTooltip: PropTypes.bool,
  showLegend: PropTypes.bool,
  showLabelList: PropTypes.bool,
  roundValue: PropTypes.bool,
  hideYTickLine: PropTypes.bool,
  yAxisWidth: PropTypes.number,
  tickInterval: PropTypes.number,
  hideZeroYAxisLabels: PropTypes.bool,
  customLabelSize: PropTypes.number
};

const tooltipContent = (props, barColor, title) => (
  <div>
    <div style={{ color: palette.common.black }}>{`${title}`}</div>
    <div style={{ color: barColor, fontWeight: 'bold' }}>{`${fShortenNumber(
      props.value
    ).toUpperCase()}`}</div>
    <hr style={{ marginTop: 10, marginBottom: 10 }} />
    <span style={{ color: palette.common.black }}>
      {props.payload?.tooltipValue || props.payload.name}
    </span>
  </div>
);

const tooltipStyle = {
  backgroundColor: palette.common.white,
  borderColor: palette.grey[500],
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  fontFamily: typography.fontFamily,
  fontSize: '14px',
  padding: '.5%'
};

function RocketerBarChart({
  chartData,
  width,
  height,
  hideYAxisLegend,
  hideXAxisLegend,
  barColor,
  showCartesianGrid,
  hideVerticalCartesianGrid,
  hoverBarColor,
  margin,
  yPadding,
  xAxisDomain,
  barLabelKey,
  roundValue,
  showTooltip,
  showLegend,
  showLabelList,
  hideYTickLine,
  yAxisWidth,
  title,
  yDataKey,
  tickInterval,
  hideZeroYAxisLabels,
  customizedTick,
  customLabelSize
}) {
  const { openTooltip, closeTooltip } = useTooltipContext() || {};
  const theme = useTheme();
  const maxValue = chartData?.data?.length ? Math.max(...chartData?.data?.map((d) => d.value)) : 0;
  const color = barColor || theme.palette.secondary.light;
  const colorHover = hoverBarColor || theme.palette.secondary.main;
  const colorText = theme.palette.common.black;

  const onMouseOver = (data, x, e) => {
    e.target.style.fill = colorHover;
  };

  const onMouseOut = (data, x, e) => {
    e.target.style.fill = color;
  };

  const formatLabel = (value) => {
    if (value === '') return null;
    if (chartData?.hasPercentage) {
      return `${fNumber2Decimals(value)}%`;
    }
    return `${value}`.toUpperCase();
  };

  return (
    <BarChart
      width={width}
      height={height}
      data={chartData.data}
      layout="vertical"
      margin={margin}
      barCategoryGap="15%"
    >
      {showCartesianGrid && (
        <CartesianGrid strokeDasharray="3 3" vertical={hideVerticalCartesianGrid} />
      )}
      <XAxis
        data={chartData.data}
        dataKey="value"
        type="number"
        allowDataOverflow
        minTickGap={1}
        tickSize={15}
        interval={0}
        tickFormatter={(tick) => (hideXAxisLegend ? invisibleTick() : formatTick(tick))}
        style={xAxisStyle}
        tickLine={false}
        domain={xAxisDomain || [0, 'dataMax']}
      />
      <YAxis
        data={chartData.data}
        dataKey={yDataKey || 'name'}
        tickSize={10}
        interval={tickInterval || 0}
        type="category"
        allowDataOverflow
        style={customizedTick ? yAxisCustomizedTickStyle : yAxisStyle}
        tickLine={!hideYTickLine}
        tickFormatter={(tick) => (hideYAxisLegend ? invisibleTick() : formatTick(tick))}
        padding={yPadding}
        width={yAxisWidth}
        tick={customizedTick && { width: 160, textAlign: 'left' }}
      />
      {showLegend && <Legend height={40} />}
      <Bar
        dataKey="value"
        name={chartData?.legendValue ? chartData.legendValue : chartData.title}
        fill={color}
        maxBarSize={80}
        isAnimationActive={false}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onMouseEnter={(e) =>
          showTooltip &&
          openTooltip({
            content: tooltipContent(e, barColor, title),
            style: tooltipStyle
          })
        }
        onMouseLeave={() => showTooltip && closeTooltip()}
      >
        {showLabelList && (
          <LabelList
            dataKey={barLabelKey || 'value'}
            position="insideRight"
            style={{ fontSize: customLabelSize || 14, fill: colorText, fontWeight: 500 }}
            formatter={(tick) => formatLabel(formatTick(tick, maxValue, roundValue))}
          />
        )}
        {showLabelList && (
          <LabelList
            dataKey={barLabelKey || 'value'}
            position="right"
            style={{ fontSize: customLabelSize || 14, fill: colorText }}
            formatter={(tick) =>
              formatLabel(formatTick(tick, maxValue, roundValue, true, hideZeroYAxisLabels))
            }
          />
        )}
      </Bar>
    </BarChart>
  );
}

export default RocketerBarChart;
