import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import RocketerRadarChart from 'components/charts/RadarChart';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CustomTooltip from 'components/charts/CustomTooltip';
import { RADAR_CHARTS_TITLES } from 'uiConstants';
import { roundToNearestTen, getMaxOfArray } from 'utils/utility';
// ----------------------------------------------------------------------

const ChartDataWrapper = styled('div')(() => ({
  marginLeft: 20,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  fontWeight: 'bold',
  opacity: 0.7,
  marginLeft: 9,
  textAlign: 'center'
}));

RadarChartsGroup.propTypes = {
  radarChartData: PropTypes.array,
  isModal: PropTypes.bool
};

export default function RadarChartsGroup({ radarChartData, isModal }) {
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const _renderChartTitle = (data, index) => (
    <ChartDataWrapper index={index}>
      <ChartTitle>{data}</ChartTitle>
    </ChartDataWrapper>
  );

  const getCustomPaddingForRightSideChart = (index) => (!isModal && index / 2 !== 0 ? 5 : 0);

  return (
    <Grid
      container
      justifyContent="space-evenly"
      sx={{
        mr: `${isDashboardSidebarFiltersOpen ? '50px' : '30px'}`
      }}
    >
      {radarChartData &&
        radarChartData.map((data, index) => {
          const maxValue = getMaxOfArray('value', data.data);
          return (
            <Grid
              item
              xs={6}
              key={`${data.title}${index}`}
              sx={{ pl: getCustomPaddingForRightSideChart(index) }}
            >
              {_renderChartTitle(RADAR_CHARTS_TITLES[index])}
              <CustomTooltip zoom={0.3}>
                <RocketerRadarChart
                  chartData={data}
                  maxDomain={roundToNearestTen(maxValue + 0.05 * maxValue)}
                />
              </CustomTooltip>
            </Grid>
          );
        })}
    </Grid>
  );
}
