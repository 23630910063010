import { selectPerformanceOverviewChartData } from 'redux/slices/dashboards';
import HorizontalChartsGroup from 'components/_dashboard/horizontalChartsGroup';
import { getPerformanceOverviewData } from 'redux/thunk/dashboards';
import { useDashboard } from 'utils/hooks/useDashboard';
import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import PropTypes from 'prop-types';
import { detectEmptyData } from 'utils/utility';

// ----------------------------------------------------------------------

PerformanceOverview.propTypes = {
  isModal: PropTypes.bool
};

export default function PerformanceOverview({ isModal }) {
  const { dashboardData, isLoading } = useDashboard(
    getPerformanceOverviewData,
    selectPerformanceOverviewChartData,
    isModal
  );
  return (
    <DashboardLayout
      hideFilters={isModal}
      isLoading={isLoading}
      emptyData={detectEmptyData(dashboardData)}
    >
      <HorizontalChartsGroup performanceOverviewChartData={dashboardData} isModal={isModal} />
    </DashboardLayout>
  );
}
