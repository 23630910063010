import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import RocketerBarChart from 'components/charts/BarChart';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import palette from 'theme/palette';
import CustomTooltip from 'components/charts/CustomTooltip';

// ----------------------------------------------------------------------

const ChartTotalData = styled('div')(() => ({
  marginLeft: 80,
  marginBottom: 30,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTotalTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

HorizontalChartsGroupTimeSeriesData.propTypes = {
  barChartData: PropTypes.array,
  isModal: PropTypes.bool
};

export default function HorizontalChartsGroupTimeSeriesData({ barChartData, isModal }) {
  const [width] = useWindowSize();
  const defaultHeight = 250;
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const isSidebarOpen = useSelector(selectIsDashboardSidebarFiltersOpen);

  const _width = isModal
    ? (width + width * 0.2) / 4
    : (width + width * 0.2 - (isDashboardSidebarFiltersOpen ? 480 : 200)) / 4;

  const _renderChartTitle = (data, index) => (
    <ChartTotalData index={index}>
      <ChartTotalTitle>{data.title}</ChartTotalTitle>
    </ChartTotalData>
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        MozTransformOrigin: 'top left',
        MozTransform: 'scale(0.8)',
        transform: 'scale(0.8)',
        transformOrigin: 'top left',
        width: '125%'
      }}
    >
      {barChartData &&
        barChartData.map((data, index) => (
          <Grid
            item
            xs={Math.round(12 / barChartData.length)}
            sx={{ mb: 7 }}
            key={data.title}
            style={{ height: defaultHeight + 50 }}
          >
            {_renderChartTitle(data, index)}
            <CustomTooltip>
              <RocketerBarChart
                xAxisDomain={[0, 'auto']}
                barColor={palette.chart.barChartColor[2]}
                hoverBarColor={palette.chart.barChartColor[3]}
                showCartesianGrid
                hideVerticalCartesianGrid={false}
                showLabelList
                chartData={data}
                width={_width}
                height={defaultHeight}
                roundNumber={index % 2 === 0}
                margin={{
                  left: 40,
                  right: 20
                }}
                interval={data.length > 8 ? 3 : 0}
              />
            </CustomTooltip>
          </Grid>
        ))}
    </Grid>
  );
}
