import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import RocketerBarChart from 'components/charts/BarChart';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { selectIsSidebarOpen } from 'redux/slices/app';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import CustomTooltip from 'components/charts/CustomTooltip';
import { calculateChartHeight } from 'utils/utility';

// ----------------------------------------------------------------------

const ChartTotalData = styled('div')(() => ({
  marginLeft: 20,
  marginBottom: 30,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  fontWeight: 'bold',
  opacity: 0.7,
  textAlign: 'center'
}));

TopCitiesHorizontalChartsGroup.propTypes = {
  barChartData: PropTypes.array,
  isModal: PropTypes.bool
};

export default function TopCitiesHorizontalChartsGroup({ barChartData, isModal }) {
  const [width] = useWindowSize();
  const theme = useTheme();

  const defaultHeight = 250;
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const { regionBarColors, regionHoverColors } = theme.palette.chart;

  const _width = isModal
    ? (width + width * 0.2) / 4
    : (width + width * 0.2 - (isDashboardSidebarFiltersOpen ? 320 : 300)) / 4;

  const _renderChartTitle = (data, index) => (
    <ChartTotalData index={index}>
      <ChartTitle>{data.title}</ChartTitle>
    </ChartTotalData>
  );
  const customHeight = barChartData && calculateChartHeight(barChartData[0]?.data?.length);
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        zoom: '80%',
        width: '100%',
        ml: '-10px',
        padding: '0 20px',
        height: `${customHeight + 40}px`
      }}
    >
      {barChartData &&
        barChartData.map((data, index) => (
          <Grid
            item
            xs={Math.round(12 / barChartData.length)}
            sx={{ mb: 7 }}
            key={data.title}
            style={{ height: defaultHeight + 50 }}
          >
            {_renderChartTitle(data, index)}
            <CustomTooltip zoom={0.3}>
              <RocketerBarChart
                hideXAxisLegend
                showLegend
                showTooltip
                showLabelList
                hoverBarColor={regionHoverColors[index]}
                barColor={regionBarColors[index]}
                showCartesianGrid
                hideVerticalCartesianGrid={false}
                chartData={data}
                width={_width}
                height={customHeight}
                roundNumber={index % 2 === 0}
                margin={{ left: 60, right: isModal ? 30 : 55 }}
                title={data.title}
                customizedTick
                customLabelSize={9}
              />
            </CustomTooltip>
          </Grid>
        ))}
    </Grid>
  );
}
