import {
  loginRequest,
  loginError,
  loginSuccess,
  getUserError,
  getUserSuccess,
  getUserRequest,
  forgotPasswordSuccess,
  forgotPasswordRequest,
  forgotPasswordError,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError
} from 'redux/slices/auth';
import { setNotification } from 'redux/slices/app';
import { API_ERROR_MESSAGES } from 'uiConstants';

export const login = (email, password, callback) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(loginRequest());

  try {
    const response = await apiService.login(email, password);
    dispatch(loginSuccess(response));
  } catch (error) {
    dispatch(loginError(error.errorMessage));
    dispatch(
      setNotification({
        type: 'error',
        messageId:
          error.errorMessage === '' ? API_ERROR_MESSAGES.invalidEmailOrPassword : error.errorMessage
      })
    );
    return;
  }
  callback();
};

export const changePassword =
  (code, email, password, confirmPassword, callback) =>
  async (dispatch, getState, extraArgument) => {
    const { apiService } = extraArgument;
    dispatch(changePasswordRequest());

    try {
      const response = await apiService.changePassword(code, email, password, confirmPassword);
      dispatch(changePasswordSuccess(response.user));
      callback();
    } catch (error) {
      dispatch(changePasswordError(error.errorMessage));
      dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
    }
  };

export const getUser = () => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(getUserRequest());
  try {
    const response = await apiService.getUser();
    dispatch(getUserSuccess(response));
  } catch (error) {
    dispatch(getUserError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};

export const forgotPassword = (email) => async (dispatch, getState, extraArgument) => {
  const { apiService } = extraArgument;
  dispatch(forgotPasswordRequest());
  try {
    const response = await apiService.forgotPassword(email);
    dispatch(forgotPasswordSuccess(response));
  } catch (error) {
    dispatch(forgotPasswordError(error.errorMessage));
    dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
  }
};
