/* eslint-disable no-nested-ternary */
import { fDate, subRangeToTime, getLastNumberOfOptions, fDateFullYear } from 'utils/formatTime';
import { fShortenNumber } from 'utils/formatNumber';
import { uniqueId } from 'lodash';
import { CHART_DEFAULT_MEASUREMENTS } from 'uiConstants';
import { v4 as uuidv4 } from 'uuid';
import { differenceInDays } from 'date-fns';

export const clearToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('email');
};

export const clearUser = () => {
  localStorage.removeItem('user');
};

export const clearData = () => {
  localStorage.removeItem('data');
};

export const getLocalStorageUser = () => JSON.parse(localStorage.getItem('user'));

export const getAccessToken = () => localStorage.getItem('access_token');

export const getToken = () => localStorage.getItem('token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const getEmail = () => localStorage.getItem('email');

export const getUserId = () => {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user).id : null;
};

export const setLocalStorageAccessToken = (token) => localStorage.setItem('access_token', token);

export const setLocalStorageToken = (token) => localStorage.setItem('token', token);

export const setLocalStorageRefreshToken = (token) => localStorage.setItem('refresh_token', token);

export const setLocalStorageEmail = (email) => localStorage.setItem('email', email);

export const validateQueryParams = (url) => {
  const urlBreak = url.split('?');
  if (
    urlBreak[1] &&
    (urlBreak[1].indexOf('undefined') !== -1 || urlBreak[1].indexOf('null') !== -1)
  ) {
    return urlBreak[0];
  }
  return url;
};

export const filterListByName = (list, name) =>
  list.filter((item) => item.name.toLowerCase().includes(name.toLowerCase()));

export const roundToNearestTen = (value) => {
  const stringValue = String(value);
  const nrOfDigits = stringValue.split('.')[0].length; // length for integers and floats
  const tenPower = 10 ** (nrOfDigits - 1);
  return Math.ceil(value / tenPower) * tenPower;
};

export const getMaxOfArray = (key, array) =>
  Math.max(...(array?.map((object) => object[key]) || []), 0);

export const getMaxOfArray2Keys = (key1, key2, array) =>
  Math.max(...(array?.map((object) => object[key1][key2]) || []), 0);

// simulate time waiting for api call to finish to emulate loading process
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const invisibleTick = () => '';

export const formatTick = (tick, maxValue, roundValue, rightLabel, hideZeroYAxisLabels) => {
  if (roundValue) tick = Math.ceil(tick);
  if (hideZeroYAxisLabels && rightLabel && tick === 0) return '';
  if (!tick && !rightLabel && tick !== 0) return '';
  if (tick < maxValue / 1.6 && !rightLabel) return '';
  if (tick > maxValue / 1.6 && rightLabel) return '';
  // format only if it's in Zulu time
  if (tick && typeof tick === 'string' && tick.includes('Z') && Date.parse(tick)) {
    return fDate(tick);
  }
  if (typeof tick === 'number') {
    return fShortenNumber(tick).toUpperCase();
  }
  const trimmedTick = tick.trimEnd();
  // if (trimmedTick.length > 10) {
  //   return `${trimmedTick.substring(0, 10)}...`;
  // }
  return trimmedTick;
};

export const dateFormatter = (tick) => {
  if (tick && typeof tick === 'string' && tick.includes('Z') && Date.parse(tick)) {
    return fDate(tick);
  }
  return tick;
};

export const generateXOptions = (last, timeUnit, format, withChecked, to) => {
  const options = [...Array(last)].map((_, i) => {
    const date = to || new Date();
    let item = {
      id: uniqueId(),
      name: subRangeToTime(date, timeUnit, i, format)
    };
    if (withChecked) {
      item = { ...item, checked: true };
    }
    return item;
  });
  return options;
};

export const getCompleteListOfDateOptions = (selectedOption, lastYears) => {
  let options = [];
  switch (selectedOption) {
    case 'Years':
      options = generateXOptions(lastYears, 'years', 'yyyy', true);
      break;
    case 'Quarters':
      options = generateXOptions(
        getLastNumberOfOptions('Quarters', lastYears),
        'quarters',
        'QQQ yyyy',
        true
      );
      break;
    case 'Months':
      options = generateXOptions(
        getLastNumberOfOptions('Months', lastYears),
        'months',
        'MM/yyyy',
        true
      );
      break;
    case 'Days':
      options = generateXOptions(
        getLastNumberOfOptions('Days', lastYears),
        'days',
        'dd/MM/yyyy',
        true
      );
      break;
    default:
      options = [];
  }
  return options;
};

export const buildQuarterValue = (name) => {
  let day = '01';
  if (name.includes('Q2')) {
    day = '04';
  }
  if (name.includes('Q3')) {
    day = '07';
  }
  if (name.includes('Q4')) {
    day = '10';
  }
  return `${day}-01-${name.substr(name.length - 4)}`;
};

export const setDataForFilter = (optionName, name, value, options, checkedFilters) => {
  const setAllOptions = () => (value ? options.map((o) => o.name) : []);

  const setDateRangeAllOptions = () =>
    checkedFilters[name].data.map((option) => {
      const newOption = { ...option, checked: value };
      return newOption;
    });

  const setFilteredOptions = () =>
    value
      ? checkedFilters[name] && checkedFilters[name].data
        ? [...checkedFilters[name].data, optionName]
        : [optionName]
      : checkedFilters[name].data.filter((option) => option !== optionName);

  const setDateRangeFilteredOptions = () =>
    checkedFilters[name] && checkedFilters[name].data
      ? checkedFilters[name].data.map((option) => {
          let newOption = { ...option };
          if (option.name === optionName) {
            newOption = {
              ...option,
              checked: value
            };
          }
          return newOption;
        })
      : [optionName];

  return optionName === 'all'
    ? name === 'Date Range'
      ? setDateRangeAllOptions()
      : setAllOptions()
    : name === 'Date Range'
    ? setDateRangeFilteredOptions()
    : setFilteredOptions();
};

// format dayofweek label, as it comes uppercase from API
export const formatDayOfWeekLabel = (label) => {
  const formattedLabel = 'DayOfWeek';
  if (label === 'DAYOFWEEK') {
    label = formattedLabel;
  }
  return label;
};

// set corresponding projects based on the array of selected companies
export const setAllCorrespondingProjectsToCompany = (companyId, projects) => {
  const correspondingProjects = projects.filter((project) => project.companyId === companyId);
  return correspondingProjects.map((p) => p.name);
};

// set corresponding projects based on checked value and all existing projects
export const setCorrespondingProjectsToCompany = (companyId, projects, checked, checkedFilters) => {
  const correspondingProjects = checked
    ? projects.filter((project) => project.companyId === companyId)
    : projects.filter((project) => project.companyId !== companyId);
  return checked
    ? checkedFilters['Project Name'] && checkedFilters['Project Name'].data.length
      ? [...checkedFilters['Project Name'].data, ...correspondingProjects.map((p) => p.name)]
      : correspondingProjects.map((project) => project.name)
    : checkedFilters['Project Name'].data.filter(
        (option) => !!correspondingProjects.find((project) => project.name === option)
      );
};

export const formatWithDash = (name) => {
  let formattedName = name;
  if (name.includes(' ')) {
    formattedName = name.replace(' ', '/');
  }
  if (name.includes('-')) {
    formattedName = name.replace('-', '/');
  }
  return formattedName;
};

export const generateArrayOfDates = (from, to) => {
  let arrayOfDates = [];
  if (from === to || (from && !to) || (to && !from)) {
    arrayOfDates = [
      {
        id: uuidv4(),
        name: fDateFullYear(from || to),
        checked: true
      }
    ];
  } else {
    arrayOfDates = generateXOptions(
      Math.abs(differenceInDays(new Date(from), new Date(to))) + 1,
      'days',
      'dd/MM/yyyy',
      true,
      new Date(to)
    );
  }
  return arrayOfDates;
};

export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
};

export const calculateChartHeight = (dataLength) => {
  // ensure enough space for less data
  if (dataLength && dataLength < 30) {
    return (dataLength + 20) * CHART_DEFAULT_MEASUREMENTS.HORIZONTAL_CHART_BAR_HEIGHT;
  }
  if (dataLength && dataLength > 70) {
    return dataLength * CHART_DEFAULT_MEASUREMENTS.LARGE_DATA_HORIZONTAL_CHART_BAR_HEIGHT;
  }
  return dataLength * CHART_DEFAULT_MEASUREMENTS.HORIZONTAL_CHART_BAR_HEIGHT;
};

// sort chart data by tabel data order
export const sortChartDataByTabelData = (
  dataType,
  listNameRefference,
  list,
  dashboardData,
  setNewData
) => {
  const newDashboardData = dashboardData[dataType].map((elem) => {
    const entriesLimit = 30;
    const largeDataEntriesLimit = 100;
    const newElement = { ...elem };
    const sortedElements = list.map((listItem) =>
      elem.data.find(
        (elemItem) =>
          elemItem.tooltipValue === listItem[listNameRefference] ||
          elemItem.name === listItem[listNameRefference]
      )
    );
    const labelDisplayHandledSortedData = sortedElements.map((element, index) => {
      const newElement = { ...element };
      if (sortedElements.length > entriesLimit) {
        if (
          index % (sortedElements.length > largeDataEntriesLimit ? 4 : 2) === 0 &&
          newElement.tooltipValue
        ) {
          newElement.name = newElement.tooltipValue;
        } else {
          newElement.name = '';
        }
      } else if (newElement.tooltipValue) {
        newElement.name = newElement.tooltipValue;
      }
      return newElement;
    });

    newElement.data = labelDisplayHandledSortedData;
    return newElement;
  });
  setNewData(newDashboardData);
};

export const detectEmptyData = (dashboardData) => {
  const tables = Object.entries(dashboardData).filter((pair) =>
    pair[0].toLowerCase().includes('table')
  );
  const charts = Object.entries(dashboardData).filter((pair) =>
    pair[0].toLowerCase().includes('chart')
  );

  const allTableDataEmpty = tables.length
    ? !!tables.filter((table) => !table[1].rows.length).length
    : false;
  const allChartDataEmpty = charts.length
    ? !!charts.filter((chart) =>
        Array.isArray(chart[1])
          ? !chart[1].filter((chartData) => chartData.data?.length).length
          : !chart[1].data.length
      ).length
    : false;
  return allTableDataEmpty || allChartDataEmpty;
};

export const getAllChartDataLabeled = (data) =>
  data.map((d) => {
    const newData = { ...d, name: d.tooltipValue };
    return newData;
  });

export const replaceDataInChartWithFirst25 = (chart, allowFilterLabelPopulation) => {
  const newChart = {
    ...chart,
    data: allowFilterLabelPopulation
      ? getAllChartDataLabeled(chart.data).slice(0, 25)
      : chart.data.slice(0, 25)
  };
  return newChart;
};

export const getFirstXDataChartValues = (chartType, data, dataLength) => {
  if (dataLength === '') {
    return data[chartType];
  }
  const defaultLimitForLabeling = 75;
  const newDashboardData = Array.isArray(data[chartType])
    ? data[chartType]?.map((c) => {
        let newChartData = { ...c };
        newChartData = {
          ...c,
          data:
            dataLength < defaultLimitForLabeling
              ? getAllChartDataLabeled(c.data).slice(0, dataLength)
              : c.data.slice(0, dataLength)
        };
        return newChartData;
      })
    : {
        ...data[chartType],
        data: data[chartType].data.slice(0, dataLength)
      };

  return newDashboardData;
};
