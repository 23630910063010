import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import RocketerPieChart from 'components/charts/PieChart';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowSize } from 'utils/hooks/useWindowSize';

// ----------------------------------------------------------------------
const defaultWidth = 300;
const defaultOuterRadius = 50;

const ChartDataWrapper = styled('div')(() => ({
  marginLeft: 20,
  marginBottom: 30,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  fontWeight: 'bold',
  opacity: 0.7,
  marginLeft: 9
}));

PieChartsGroup.propTypes = {
  pieChartsData: PropTypes.array
};

export default function PieChartsGroup({ pieChartsData, isModal }) {
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const [width] = useWindowSize();

  const _width = isModal
    ? (width + width * 0.2) / 4
    : (width + width * 0.2 - (isDashboardSidebarFiltersOpen ? 900 : 700)) / 4;
  const _renderChartTitle = (data, index) => (
    <ChartDataWrapper index={index}>
      <ChartTitle>{data.title}</ChartTitle>
    </ChartDataWrapper>
  );
  return (
    <Grid container justifyContent="space-evenly">
      {pieChartsData &&
        pieChartsData.map((data, index) => (
          <Grid item xs={3} key={data.title}>
            {_renderChartTitle(data, index)}
            <RocketerPieChart
              chartData={data.data}
              width={_width}
              outerRadius={defaultOuterRadius}
            />
          </Grid>
        ))}
    </Grid>
  );
}
