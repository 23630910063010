import UITable from 'components/Table';
import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TopCitiesHorizontalChartsGroup from 'components/_dashboard/topCitiesHorizontalChartsGroup';
import { sortChartDataByTabelData, detectEmptyData } from 'utils/utility';
import { useDashboard } from 'utils/hooks/useDashboard';
import { selectTopCitiesData, setCurrentDashboardData } from 'redux/slices/dashboards';
import { getTopCitiesData } from 'redux/thunk/dashboards';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

TopCities.propTypes = {
  isModal: PropTypes.bool
};

export default function TopCities({ isModal }) {
  const maxWidth = 875;

  const { dashboardData, isLoading } = useDashboard(getTopCitiesData, selectTopCitiesData, isModal);
  const dispatch = useDispatch();

  return (
    <DashboardLayout
      hideFilters={isModal}
      isLoading={isLoading}
      hideTimeSelector
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12} mt={2}>
        <TopCitiesHorizontalChartsGroup isModal={isModal} barChartData={dashboardData.barCharts} />
      </Grid>
      <Grid item xs={12} mt={5} sx={{ maxWidth, marginRight: isModal ? 2 : 0 }}>
        <UITable
          grandTotal={dashboardData.tableOne?.grandTotal}
          sortBy="primaryReportingConversion"
          sortDirection="DESC"
          list={dashboardData.tableOne?.rows}
          columns={dashboardData.tableOne?.columns}
          exportLink={dashboardData.tableOne?.exportLink}
          filters={dashboardData.tableOne?.filters}
          globalSort={(list) =>
            sortChartDataByTabelData('barCharts', 'city', list, dashboardData, (newDashboardData) =>
              dispatch(
                setCurrentDashboardData({
                  dataType: 'barCharts',
                  dashboardData: newDashboardData
                })
              )
            )
          }
        />
      </Grid>
      <Grid item xs={12} mt={5} sx={{ maxWidth, marginRight: isModal ? 2 : 0 }}>
        <UITable
          grandTotal={dashboardData.tableTwo?.grandTotal}
          sortBy="secondaryReportingConversion"
          sortDirection="DESC"
          list={dashboardData.tableTwo?.rows}
          columns={dashboardData.tableTwo?.columns}
          exportLink={dashboardData.tableTwo?.exportLink}
          filters={dashboardData.tableTwo?.filters}
          globalSort={(list) =>
            sortChartDataByTabelData('barCharts', 'city', list, dashboardData, (newDashboardData) =>
              dispatch(
                setCurrentDashboardData({
                  dataType: 'barCharts',
                  dashboardData: newDashboardData
                })
              )
            )
          }
        />
      </Grid>
    </DashboardLayout>
  );
}
