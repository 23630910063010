import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import UITable from 'components/Table';
import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import { useDashboard } from 'utils/hooks/useDashboard';
import { selectProjectsChartData, setCurrentDashboardData } from 'redux/slices/dashboards';
import { getProjectsChartData } from 'redux/thunk/dashboards';
import HorizontalChartsGroupProjects from 'components/_dashboard/horizontalChartsGroupProjects';
import { useDispatch } from 'react-redux';
import { sortChartDataByTabelData, detectEmptyData } from 'utils/utility';

// ----------------------------------------------------------------------

Projects.propTypes = {
  isModal: PropTypes.bool
};

export default function Projects({ isModal }) {
  const { dashboardData, isLoading } = useDashboard(
    getProjectsChartData,
    selectProjectsChartData,
    isModal
  );
  const dispatch = useDispatch();

  return (
    <DashboardLayout
      hideFilters={isModal}
      hideTimeSelector
      isLoading={isLoading}
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12} mt={2}>
        <HorizontalChartsGroupProjects barChartData={dashboardData.barCharts} isModal={isModal} />
      </Grid>
      {dashboardData && dashboardData.table && (
        <Grid item xs={12} mt={2} sx={{ marginRight: isModal ? 2 : 0 }}>
          <UITable
            grandTotal={dashboardData.table?.grandTotal}
            sortBy="primaryReportingConversionCpa"
            sortDirection="ASC"
            list={dashboardData.table?.rows}
            columns={dashboardData.table?.columns}
            title="Projects metrics"
            filters={dashboardData.table?.filters}
            exportLink={dashboardData.table?.exportLink}
            colWidth={120}
            globalSort={(list) =>
              sortChartDataByTabelData(
                'barCharts',
                'projectName',
                list,
                dashboardData,
                (newDashboardData) =>
                  dispatch(
                    setCurrentDashboardData({
                      dataType: 'barCharts',
                      dashboardData: newDashboardData
                    })
                  )
              )
            }
          />
        </Grid>
      )}
    </DashboardLayout>
  );
}
