const sample = [
  ['Yoghurt', 159, 6.0, 24, 4.0],
  ['Ice cream', 237, 9.0, 37, 4.3],
  ['Eclair', 262, 16.0, 24, 6.0],
  ['Cupcake', -305, 3.7, 67, 4.3],
  ['Gingerbread', 356, 16.0, 49, 3.9]
];

const _rows = [];

function createData(id, dessert, calories, fat, carbs, protein) {
  return { id, dessert, calories, fat, carbs, protein };
}

for (let i = 0; i < 100; i += 1) {
  const randomSelection = sample[Math.floor(Math.random() * sample.length)];
  _rows.push(createData(i, ...randomSelection));
}

export const rows = _rows;

export const columns = [
  {
    width: 70,
    label: 'ID',
    dataKey: 'id',
    isFixed: true
  },
  {
    width: 160,
    label: 'Dessert',
    dataKey: 'dessert',
    isFixed: true
  },
  {
    width: 130,
    label: 'Calories\u00A0(g)',
    dataKey: 'calories'
  },
  {
    width: 130,
    label: 'Fat\u00A0(g)',
    dataKey: 'fat',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Carbs\u00A0(g)',
    dataKey: 'carbs',
    withColorIndicator: 'max'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  },
  {
    width: 130,
    label: 'Protein\u00A0(g)',
    dataKey: 'protein'
  }
];

export const topCitiesColumns = [
  {
    label: 'ID',
    dataKey: 'ID',
    isFixed: true
  },
  {
    label: 'Region',
    dataKey: 'region'
  },
  {
    label: 'City',
    dataKey: 'city'
  },
  {
    label: 'Audience Size',
    dataKey: 'audSize'
  },
  {
    label: 'Unq Quotes 1D Click',
    dataKey: 'unqQuotes'
  },
  {
    label: 'Unq CPL 1D Click',
    dataKey: 'unqCpl'
  },
  {
    label: 'Unq Quote CR% 1D Click',
    dataKey: 'unqCr'
  }
];

export const topCitiesRows = [
  {
    id: 1,
    ID: '2463277',
    region: 'Maryland',
    city: 'Silver Spring',
    audSize: '70,000',
    unqQuotes: '14',
    unqCpl: '62.36',
    unqCr: '10.45%'
  },
  {
    id: 2,
    ID: '2430536',
    region: 'Georgia',
    city: 'Atlanta',
    audSize: '2,000,000',
    unqQuotes: '13',
    unqCpl: '37.5',
    unqCr: '14.44%'
  },
  {
    id: 3,
    ID: '2468569',
    region: 'Michigan',
    city: 'New Baltimore',
    audSize: '10,000',
    unqQuotes: '3',
    unqCpl: '76.09',
    unqCr: '9.38%'
  },
  {
    id: 4,
    ID: '2435464',
    region: 'Georgia',
    city: 'Waycross',
    audSize: '25,000',
    unqQuotes: '2',
    unqCpl: '40.13',
    unqCr: '6.67%'
  },
  {
    id: 5,
    ID: '2433172',
    region: 'Georgia',
    city: 'Linwood',
    audSize: '1,000',
    unqQuotes: '1',
    unqCpl: '30.74',
    unqCr: '20.00%'
  },
  {
    id: 6,
    ID: '2469417',
    region: 'Michigan',
    city: 'Waterford Township',
    audSize: '52,000',
    unqQuotes: '1',
    unqCpl: '325.91',
    unqCr: '1.89%'
  },
  {
    id: 7,
    ID: '2431063',
    region: 'Georgia',
    city: 'Cairo',
    audSize: '14,000',
    unqQuotes: '0',
    unqCpl: '',
    unqCr: '0.00%'
  },
  {
    id: 8,
    ID: '2431343',
    region: 'Georgia',
    city: 'Clem',
    audSize: '1,000',
    unqQuotes: '0',
    unqCpl: '',
    unqCr: '0.00%'
  },
  {
    id: 9,
    ID: '2467406',
    region: 'Michigan',
    city: 'Clem',
    audSize: '61,000',
    unqQuotes: '0',
    unqCpl: '',
    unqCr: ''
  }
];

// Impression Devices mock data
export const impressionDevicesColumns = [
  {
    width: 70,
    label: 'impression_device',
    dataKey: 'impressionDevice',
    isFixed: true
  },
  {
    width: 160,
    label: 'Spend',
    dataKey: 'spend'
  },
  {
    width: 130,
    label: 'CPM',
    dataKey: 'cpm'
  },
  {
    width: 130,
    label: 'Impressions',
    dataKey: 'impressions'
  },
  {
    width: 130,
    label: 'Link CTR %',
    dataKey: 'ctr'
  },
  {
    width: 130,
    label: 'Link Clicks',
    dataKey: 'clicks'
  },
  {
    width: 130,
    label: 'CPC',
    dataKey: 'cpc'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click',
    dataKey: 'unq1D'
  },
  {
    width: 130,
    label: 'Unq CPL 1D Click',
    dataKey: 'unqcpl'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 1D Click',
    dataKey: 'unqquote'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click %Split',
    dataKey: 'unq1Dsplit'
  },
  {
    width: 130,
    label: 'Apps 1D Click',
    dataKey: 'apps1D'
  },
  {
    width: 130,
    label: 'CPA 1D Click',
    dataKey: 'cpa1D'
  },
  {
    width: 130,
    label: 'App CR% 1D Click',
    dataKey: 'appsCR1D'
  },
  {
    width: 130,
    label: 'Apps 1D Click %Split',
    dataKey: 'apps1Dclicks'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click',
    dataKey: 'unqleads'
  },
  {
    width: 130,
    label: 'Unq CPL 28D Click',
    dataKey: 'unqcpl28'
  },
  {
    width: 130,
    label: 'CvR%',
    dataKey: 'cvr'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click %Split',
    dataKey: 'unqleads28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click',
    dataKey: 'unqquotecr'
  },
  {
    width: 130,
    label: 'Apps 28D Click',
    dataKey: 'apps28'
  },
  {
    width: 130,
    label: 'CPA 28D Click',
    dataKey: 'cpa28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click %Split',
    dataKey: 'unqquotecrsplit'
  }
];

export const impressionDevicesRows = [
  {
    id: 1,
    impressionDevice: 'ipod',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000%',
    clicks: '0',
    cpc: '',
    unq1D: 0,
    unqcpl: '',
    unqquote: '',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00%',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 2,
    impressionDevice: 'android_tablet',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938%',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    impressionDevice: 'android_smartphone',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740%',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    impressionDevice: 'other',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668%',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: '',
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    impressionDevice: 'ipad',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595%',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    impressionDevice: 'iphone',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420%',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    impressionDevice: 'desktop',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240%',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  }
];

// Age mock data - used impressionDevicesColumns as it has the same columns, but the first
const ageArray = ['45-54', '55-64', '65+', 'Unknown'];
export const ageColumns = impressionDevicesColumns.map((obj) => {
  if (obj.dataKey === 'impressionDevice') {
    return { ...obj, label: 'age', dataKey: 'age' };
  }

  return obj;
});

const ageRowsWithUndefined = impressionDevicesRows.map(({ impressionDevice: age, ...rest }, i) => ({
  age: ageArray[i],
  ...rest
}));
export const ageRows = ageRowsWithUndefined.filter((row) => row.age !== undefined);

// Gender mock data - used impressionDevicesColumns as it has the same columns, but the first
const genderArray = ['female', 'male', 'Unknown'];
export const genderColumns = impressionDevicesColumns.map((obj) => {
  if (obj.dataKey === 'impressionDevice') {
    return { ...obj, label: 'gender', dataKey: 'gender' };
  }

  return obj;
});

const genderRowsWithUndefined = impressionDevicesRows.map(
  ({ impressionDevice: gender, ...rest }, i) => ({
    gender: genderArray[i],
    ...rest
  })
);
export const genderRows = genderRowsWithUndefined.filter((row) => row.gender !== undefined);

// Grand Total mock data
export const demographicsGrandTotal = {
  impressionDevice: 'Grand Total',
  spend: '110,708.93',
  cpm: 58.8,
  impressions: '1,882,914',
  ctr: '0.494%',
  clicks: '9,298',
  cpc: 11.91,
  unq1D: 122,
  unqcpl: '907.45',
  unqquote: '1.31%',
  unq1Dsplit: '100.00%',
  apps1D: 1,
  cpa1D: '110,708.93',
  appsCR1D: '0.01%',
  apps1Dclicks: '100.00%',
  unqleads: '123',
  unqcpl28: '900.07',
  cvr: '1.32%',
  unqleads28: '100.00%',
  unqquotecr: 1,
  apps28: '110,708.93',
  cpa28: '0.01%',
  unqquotecrsplit: '100.00%',
  age: 'Grand Total',
  gender: 'Grand Total'
};

export const mediaFileColumns = [
  {
    width: 160,
    label: 'Media Name',
    dataKey: 'media',
    isFixed: true
  },
  {
    width: 160,
    label: 'Spend',
    dataKey: 'spend'
  },
  {
    width: 130,
    label: 'CPM',
    dataKey: 'cpm'
  },
  {
    width: 130,
    label: 'Impressions',
    dataKey: 'impressions'
  },
  {
    width: 130,
    label: 'Link CTR %',
    dataKey: 'ctr'
  },
  {
    width: 130,
    label: 'Link Clicks',
    dataKey: 'clicks'
  },
  {
    width: 130,
    label: 'CPC',
    dataKey: 'cpc'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click',
    dataKey: 'unq1D'
  },
  {
    width: 130,
    label: 'Unq CPL 1D Click',
    dataKey: 'unqcpl'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 1D Click',
    dataKey: 'unqquote'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click %Split',
    dataKey: 'unq1Dsplit'
  },
  {
    width: 130,
    label: 'Apps 1D Click',
    dataKey: 'apps1D'
  },
  {
    width: 130,
    label: 'CPA 1D Click',
    dataKey: 'cpa1D'
  },
  {
    width: 130,
    label: 'App CR% 1D Click',
    dataKey: 'appsCR1D'
  },
  {
    width: 130,
    label: 'Apps 1D Click %Split',
    dataKey: 'apps1Dclicks'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click',
    dataKey: 'unqleads'
  },
  {
    width: 130,
    label: 'Unq CPL 28D Click',
    dataKey: 'unqcpl28'
  },
  {
    width: 130,
    label: 'CvR%',
    dataKey: 'cvr'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click %Split',
    dataKey: 'unqleads28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click',
    dataKey: 'unqquotecr'
  },
  {
    width: 130,
    label: 'Apps 28D Click',
    dataKey: 'apps28'
  },
  {
    width: 130,
    label: 'CPA 28D Click',
    dataKey: 'cpa28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click %Split',
    dataKey: 'unqquotecrsplit'
  }
];

export const mediaFileRows = [
  {
    id: 1,
    media: '10878:SBLI-10878',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: '',
    unq1D: 0,
    unqcpl: '',
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    media: '9564:SBLI-9564',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    media: '9845:SBLI-9845',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: '',
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    media: '1087:SBLI-1087',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    media: '1078:SBLI-1078',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    media: '10978:SBLI-10978',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  }
];

export const copyImageMediaGrandTotal = {
  media: 'Grand Total',
  spend: '110,708.93',
  cpm: 58.8,
  impressions: '1,882,914',
  ctr: '0.494',
  clicks: '9,298',
  cpc: 11.91,
  unq1D: 122,
  unqcpl: '907.45',
  unqquote: '1.31%',
  unq1Dsplit: '100.00%',
  apps1D: 1,
  cpa1D: '110,708.93',
  appsCR1D: '0.01%',
  apps1Dclicks: '100.00%',
  unqleads: '123',
  unqcpl28: '900.07',
  cvr: '1.32%',
  unqleads28: '100.00%',
  unqquotecr: 1,
  apps28: '110,708.93',
  cpa28: '0.01%',
  unqquotecrsplit: '100.00%',
  age: 'Grand Total',
  gender: 'Grand Total'
};

export const copyFileColumns = [
  {
    width: 160,
    label: 'Copy Name',
    dataKey: 'media',
    isFixed: true
  },
  {
    width: 160,
    label: 'Spend',
    dataKey: 'spend'
  },
  {
    width: 130,
    label: 'CPM',
    dataKey: 'cpm'
  },
  {
    width: 130,
    label: 'Impressions',
    dataKey: 'impressions'
  },
  {
    width: 130,
    label: 'Link CTR %',
    dataKey: 'ctr'
  },
  {
    width: 130,
    label: 'Link Clicks',
    dataKey: 'clicks'
  },
  {
    width: 130,
    label: 'CPC',
    dataKey: 'cpc'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click',
    dataKey: 'unq1D'
  },
  {
    width: 130,
    label: 'Unq CPL 1D Click',
    dataKey: 'unqcpl'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 1D Click',
    dataKey: 'unqquote'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click %Split',
    dataKey: 'unq1Dsplit'
  },
  {
    width: 130,
    label: 'Apps 1D Click',
    dataKey: 'apps1D'
  },
  {
    width: 130,
    label: 'CPA 1D Click',
    dataKey: 'cpa1D'
  },
  {
    width: 130,
    label: 'App CR% 1D Click',
    dataKey: 'appsCR1D'
  },
  {
    width: 130,
    label: 'Apps 1D Click %Split',
    dataKey: 'apps1Dclicks'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click',
    dataKey: 'unqleads'
  },
  {
    width: 130,
    label: 'Unq CPL 28D Click',
    dataKey: 'unqcpl28'
  },
  {
    width: 130,
    label: 'CvR%',
    dataKey: 'cvr'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click %Split',
    dataKey: 'unqleads28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click',
    dataKey: 'unqquotecr'
  },
  {
    width: 130,
    label: 'Apps 28D Click',
    dataKey: 'apps28'
  },
  {
    width: 130,
    label: 'CPA 28D Click',
    dataKey: 'cpa28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click %Split',
    dataKey: 'unqquotecrsplit'
  }
];

export const copyFileRows = [
  {
    id: 1,
    media: '10878:SBLI-10878',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: '',
    unq1D: 0,
    unqcpl: '',
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    media: '9564:SBLI-9564',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    media: '9845:SBLI-9845',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    media: '1087:SBLI-1087',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: '',
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    media: '1078:SBLI-1078',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    media: '10978:SBLI-10978',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  }
];

export const copyImageCopyGrandTotal = {
  media: 'Grand Total',
  spend: '110,708.93',
  cpm: 58.8,
  impressions: '1,882,914',
  ctr: '0.494',
  clicks: '9,298',
  cpc: 11.91,
  unq1D: 122,
  unqcpl: '907.45',
  unqquote: '1.31%',
  unq1Dsplit: '100.00%',
  apps1D: 1,
  cpa1D: '110,708.93',
  appsCR1D: '0.01%',
  apps1Dclicks: '100.00%',
  unqleads: '123',
  unqcpl28: '900.07',
  cvr: '1.32%',
  unqleads28: '100.00%',
  unqquotecr: 1,
  apps28: '110,708.93',
  cpa28: '0.01%',
  unqquotecrsplit: '100.00%',
  age: 'Grand Total',
  gender: 'Grand Total'
};

export const copyAndMediaColumns = [
  {
    width: 160,
    label: 'Media Name',
    dataKey: 'media',
    isFixed: true
  },
  {
    width: 160,
    label: 'Copy Name',
    dataKey: 'copy',
    isFixed: true
  },
  {
    width: 160,
    label: 'Spend',
    dataKey: 'spend'
  },
  {
    width: 130,
    label: 'CPM',
    dataKey: 'cpm'
  },
  {
    width: 130,
    label: 'Impressions',
    dataKey: 'impressions'
  },
  {
    width: 130,
    label: 'Link CTR %',
    dataKey: 'ctr'
  },
  {
    width: 130,
    label: 'Link Clicks',
    dataKey: 'clicks'
  },
  {
    width: 130,
    label: 'CPC',
    dataKey: 'cpc'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click',
    dataKey: 'unq1D'
  },
  {
    width: 130,
    label: 'Unq CPL 1D Click',
    dataKey: 'unqcpl'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 1D Click',
    dataKey: 'unqquote'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click %Split',
    dataKey: 'unq1Dsplit'
  },
  {
    width: 130,
    label: 'Apps 1D Click',
    dataKey: 'apps1D'
  },
  {
    width: 130,
    label: 'CPA 1D Click',
    dataKey: 'cpa1D'
  },
  {
    width: 130,
    label: 'App CR% 1D Click',
    dataKey: 'appsCR1D'
  },
  {
    width: 130,
    label: 'Apps 1D Click %Split',
    dataKey: 'apps1Dclicks'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click',
    dataKey: 'unqleads'
  },
  {
    width: 130,
    label: 'Unq CPL 28D Click',
    dataKey: 'unqcpl28'
  },
  {
    width: 130,
    label: 'CvR%',
    dataKey: 'cvr'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click %Split',
    dataKey: 'unqleads28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click',
    dataKey: 'unqquotecr'
  },
  {
    width: 130,
    label: 'Apps 28D Click',
    dataKey: 'apps28'
  },
  {
    width: 130,
    label: 'CPA 28D Click',
    dataKey: 'cpa28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click %Split',
    dataKey: 'unqquotecrsplit'
  }
];

export const copyAndMediaRows = [
  {
    id: 1,
    media: '10878:SBLI-10878',
    copy: '1088:SBLI-1878',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: '',
    unq1D: 0,
    unqcpl: '',
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    media: '10878:SBLI-10878',
    copy: '2878:SBLI-2878',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    media: '10872:SBLI-10872',
    copy: '1087:SBLI-1087',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    media: '10788:SBLI-10788',
    copy: '1078:SBLI-1078',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: '',
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    media: '10788:SBLI-10788',
    copy: '1078:SBLI-1078',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    media: '10878:SBLI-10878',
    copy: '1078:SBLI-1078',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    media: '10878:SBLI-10878',
    copy: '1078:SBLI-1078',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  }
];

export const copyAndMediaGrandTotal = {
  media: 'Grand Total',
  copy: ' ',
  spend: '110,708.93',
  cpm: 58.8,
  impressions: '1,882,914',
  ctr: '0.494',
  clicks: '9,298',
  cpc: 11.91,
  unq1D: 122,
  unqcpl: '907.45',
  unqquote: '1.31%',
  unq1Dsplit: '100.00%',
  apps1D: 1,
  cpa1D: '110,708.93',
  appsCR1D: '0.01%',
  apps1Dclicks: '100.00%',
  unqleads: '123',
  unqcpl28: '900.07',
  cvr: '1.32%',
  unqleads28: '100.00%',
  unqquotecr: 1,
  apps28: '110,708.93',
  cpa28: '0.01%',
  unqquotecrsplit: '100.00%',
  age: 'Grand Total',
  gender: 'Grand Total'
};

export const regionTableColumns = [
  {
    width: 160,
    label: 'Region Id',
    dataKey: 'id',
    isFixed: true
  },
  {
    width: 160,
    label: 'Region',
    dataKey: 'region',
    isFixed: true
  },
  {
    width: 160,
    label: 'Spend',
    dataKey: 'spend'
  },
  {
    width: 130,
    label: 'CPM',
    dataKey: 'cpm',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Impressions',
    dataKey: 'impressions'
  },
  {
    width: 130,
    label: 'Link CTR %',
    dataKey: 'ctr'
  },
  {
    width: 130,
    label: 'Link Clicks',
    dataKey: 'clicks'
  },
  {
    width: 130,
    label: 'CPC',
    dataKey: 'cpc',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click',
    dataKey: 'unq1D'
  },
  {
    width: 130,
    label: 'Unq CPL 1D Click',
    dataKey: 'unqcpl',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 1D Click',
    dataKey: 'unqquote'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click %Split',
    dataKey: 'unq1Dsplit',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Apps 1D Click',
    dataKey: 'apps1D'
  },
  {
    width: 130,
    label: 'CPA 1D Click',
    dataKey: 'cpa1D'
  },
  {
    width: 130,
    label: 'App CR% 1D Click',
    dataKey: 'appsCR1D',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Apps 1D Click %Split',
    dataKey: 'apps1Dclicks'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click',
    dataKey: 'unqleads',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Unq CPL 28D Click',
    dataKey: 'unqcpl28'
  },
  {
    width: 130,
    label: 'CvR%',
    dataKey: 'cvr'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click %Split',
    dataKey: 'unqleads28',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click',
    dataKey: 'unqquotecr'
  },
  {
    width: 130,
    label: 'Apps 28D Click',
    dataKey: 'apps28'
  },
  {
    width: 130,
    label: 'CPA 28D Click',
    dataKey: 'cpa28',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click %Split',
    dataKey: 'unqquotecrsplit'
  }
];

export const regionTableRows = [
  {
    id: 1,
    region: 'Montana',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: 20.25,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    region: 'Connecticut',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    region: 'New York',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    region: 'West Virginia',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    region: 'Maine',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    region: 'Kansas',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    region: 'Minnesota',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 1,
    region: 'New York',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: 20.25,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    region: 'West Virginia',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    region: 'Maine',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    region: 'Kansas',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    region: 'Maine',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    region: 'Mississippi',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    region: 'New Hampshire',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 7,
    region: 'New Hampshire',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 7,
    region: 'Minnesota',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 1,
    region: 'Georgia',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: 20.25,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    region: 'Indiana',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    region: 'Alaska',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    region: 'Nebraska',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    region: 'Kentucky',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    region: 'Rhode Island',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    region: 'Wyoming',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 7,
    region: 'Delaware',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 1,
    region: 'New York',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: 20.25,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    region: 'West Virginia',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    region: 'Maine',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    region: 'Kansas',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    region: 'Maine',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    region: 'Mississippi',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    region: 'New Hampshire',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 7,
    region: 'New Hampshire',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 7,
    region: 'Minnesota',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 1,
    region: 'Georgia',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: 20.25,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    region: 'Indiana',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 3,
    region: 'Alaska',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 4,
    region: 'Nebraska',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    region: 'Kentucky',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    region: 'Rhode Island',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    region: 'Wyoming',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 7,
    region: 'Delaware',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  }
];

export const regionTableGrandTotal = {
  id: 'Grand Total',
  region: null,
  spend: '110,708.93',
  cpm: 58.8,
  impressions: '1,882,914',
  ctr: '0.494',
  clicks: '9,298',
  cpc: 11.91,
  unq1D: 122,
  unqcpl: '907.45',
  unqquote: '1.31%',
  unq1Dsplit: '100.00%',
  apps1D: 1,
  cpa1D: '110,708.93',
  appsCR1D: '0.01%',
  apps1Dclicks: '100.00%',
  unqleads: '123',
  unqcpl28: '900.07',
  cvr: '1.32%',
  unqleads28: '100.00%',
  unqquotecr: 1,
  apps28: '110,708.93',
  cpa28: '0.01%',
  unqquotecrsplit: '100.00%',
  age: 'Grand Total',
  gender: 'Grand Total'
};

export const cityDataTableColumns = [
  {
    width: 160,
    label: 'City Id',
    dataKey: 'id',
    isFixed: true
  },
  {
    width: 160,
    label: 'City',
    dataKey: 'city',
    isFixed: true
  },
  {
    width: 160,
    label: 'Region',
    dataKey: 'region',
    isFixed: true
  },
  {
    width: 160,
    label: 'Latitude',
    dataKey: 'impressions',
    isFixed: true
  },
  {
    width: 160,
    label: 'Longitude',
    dataKey: 'impressions',
    isFixed: true
  },
  {
    width: 160,
    label: 'Audience_Size',
    dataKey: 'impressions',
    isFixed: true
  },
  {
    width: 160,
    label: 'Spend',
    dataKey: 'spend'
  },
  {
    width: 130,
    label: 'CPM',
    dataKey: 'cpm'
  },
  {
    width: 130,
    label: 'Impressions',
    dataKey: 'impressions'
  },
  {
    width: 130,
    label: 'Link CTR %',
    dataKey: 'ctr'
  },
  {
    width: 130,
    label: 'Link Clicks',
    dataKey: 'clicks'
  },
  {
    width: 130,
    label: 'CPC',
    dataKey: 'cpc'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click',
    dataKey: 'unq1D'
  },
  {
    width: 130,
    label: 'Unq CPL 1D Click',
    dataKey: 'unqcpl'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 1D Click',
    dataKey: 'unqquote'
  },
  {
    width: 130,
    label: 'Unq Quotes 1D Click %Split',
    dataKey: 'unq1Dsplit'
  },
  {
    width: 130,
    label: 'Apps 1D Click',
    dataKey: 'apps1D'
  },
  {
    width: 130,
    label: 'CPA 1D Click',
    dataKey: 'cpa1D'
  },
  {
    width: 130,
    label: 'App CR% 1D Click',
    dataKey: 'appsCR1D'
  },
  {
    width: 130,
    label: 'Apps 1D Click %Split',
    dataKey: 'apps1Dclicks'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click',
    dataKey: 'unqleads'
  },
  {
    width: 130,
    label: 'Unq CPL 28D Click',
    dataKey: 'unqcpl28'
  },
  {
    width: 130,
    label: 'CvR%',
    dataKey: 'cvr'
  },
  {
    width: 130,
    label: 'Unq Leads 28D Click %Split',
    dataKey: 'unqleads28'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click',
    dataKey: 'unqquotecr'
  },
  {
    width: 130,
    label: 'Apps 28D Click',
    dataKey: 'apps28'
  },
  {
    width: 130,
    label: 'CPA 28D Click',
    dataKey: 'cpa28',
    withColorIndicator: 'min'
  },
  {
    width: 130,
    label: 'Unq Quote CR% 28D Click %Split',
    dataKey: 'unqquotecrsplit'
  }
];

export const cityDataTableRows = [
  {
    id: 1,
    city: 'Atlanta',
    region: 'Montana',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: 20.25,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    city: 'Detroit',
    region: 'Connecticut',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    city: 'Boston ',
    region: 'New York',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    city: 'Baltimore',
    region: 'West Virginia',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 9,
    city: 'Silver Spring',
    region: 'Maine',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    city: 'Muscogee County',
    region: 'Kansas',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    region: 'Minnesota',
    city: 'Seattle',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  },
  {
    id: 1,
    city: 'Atlanta',
    region: 'Montana',
    spend: '4.61',
    cpm: 39.4,
    impressions: '117',
    ctr: '0.000',
    clicks: '0',
    cpc: 20.25,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '',
    unq1Dsplit: '0.00',
    apps1D: 0.0,
    cpa1D: '',
    appsCR1D: '',
    apps1Dclicks: '0.00',
    unqleads: '25',
    unqcpl28: '498.34',
    cvr: '',
    unqleads28: '0.00',
    unqquotecr: 0,
    apps28: '',
    cpa28: '',
    unqquotecrsplit: '0.00'
  },
  {
    id: 2,
    city: 'Detroit',
    region: 'Connecticut',
    spend: '2,491.69',
    cpm: 77.41,
    impressions: '32,190',
    ctr: '0.938',
    clicks: '302',
    cpc: 8.25,
    unq1D: 5,
    unqcpl: '498.34',
    unqquote: '1.66%',
    unq1Dsplit: '4.10%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '1,148.19',
    cvr: '1.66%',
    unqleads28: '4.07%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    city: 'Boston ',
    region: 'New York',
    spend: '42,483.02',
    cpm: 70.9,
    impressions: '599,231',
    ctr: '0.740',
    clicks: '4,436',
    cpc: 9.58,
    unq1D: 37,
    unqcpl: '1,148.19',
    unqquote: '0.83%',
    unq1Dsplit: '30.33%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 5,
    unqcpl28: '',
    cvr: '0.83%',
    unqleads28: '30.08%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 5,
    city: 'Baltimore',
    region: 'West Virginia',
    spend: '879.92',
    cpm: 67.55,
    impressions: '13,027',
    ctr: '0.668',
    clicks: '87',
    cpc: 10.11,
    unq1D: 0,
    unqcpl: 630.25,
    unqquote: '0.00%',
    unq1Dsplit: '0.00%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 37,
    unqcpl28: '595.05',
    cvr: '0.00%',
    unqleads28: '0.00%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 9,
    city: 'Silver Spring',
    region: 'Maine',
    spend: '7,140.56',
    cpm: 65.02,
    impressions: '109,814',
    ctr: '0.595',
    clicks: '653',
    cpc: 10.94,
    unq1D: 12,
    unqcpl: '595.05',
    unqquote: '1.84%',
    unq1Dsplit: '9.84%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 0,
    unqcpl28: '822.01',
    cvr: '1.84%',
    unqleads28: '9.76%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 6,
    city: 'Muscogee County',
    region: 'Kansas',
    spend: '36,168.64',
    cpm: 58.65,
    impressions: '616,702',
    ctr: '0.420',
    clicks: '2,593',
    cpc: 13.95,
    unq1D: 44,
    unqcpl: '822.01',
    unqquote: '1.70%',
    unq1Dsplit: '36.07%',
    apps1D: 0,
    cpa1D: '',
    appsCR1D: '0.00%',
    apps1Dclicks: '0.00%',
    unqleads: 12,
    unqcpl28: '861.62',
    cvr: '1.70%',
    unqleads28: '35.77%',
    unqquotecr: 0,
    apps28: '',
    cpa28: '0.00%',
    unqquotecrsplit: '0.00%'
  },
  {
    id: 7,
    region: 'Minnesota',
    city: 'Seattle',
    spend: '21,540.49',
    cpm: 42.08,
    impressions: '511,833',
    ctr: '0.240',
    clicks: '1,227',
    cpc: 17.56,
    unq1D: 24,
    unqcpl: '897.52',
    unqquote: '1.96%',
    unq1Dsplit: '19.67%',
    apps1D: 1,
    cpa1D: '21,540.49',
    appsCR1D: '0.08%',
    apps1Dclicks: '100.00%',
    unqleads: 44,
    unqcpl28: 'unqcpl28',
    cvr: '2.04%',
    unqleads28: '20.33%',
    unqquotecr: 1,
    apps28: '21,540.49',
    cpa28: '0.08%',
    unqquotecrsplit: '100.00%'
  }
];

export const cityDataTableGrandTotal = {
  id: 'Grand Total',
  region: null,
  city: null,
  spend: '110,708.93',
  cpm: 58.8,
  impressions: null,
  ctr: '0.494',
  clicks: '9,298',
  cpc: 11.91,
  unq1D: 122,
  unqcpl: '907.45',
  unqquote: '1.31%',
  unq1Dsplit: '100.00%',
  apps1D: 1,
  cpa1D: '110,708.93',
  appsCR1D: '0.01%',
  apps1Dclicks: '100.00%',
  unqleads: '123',
  unqcpl28: '900.07',
  cvr: '1.32%',
  unqleads28: '100.00%',
  unqquotecr: 1,
  apps28: '110,708.93',
  cpa28: '0.01%',
  unqquotecrsplit: '100.00%',
  age: 'Grand Total',
  gender: 'Grand Total'
};
