import React from 'react';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const RocketerSelect = ({ onChange, value, options, width }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth size="small">
      <Select
        id="demo-simple-select"
        value={value}
        onChange={onChange}
        style={{ fontSize: 13, width: width || 200 }}
      >
        {options.map((o) => (
          <MenuItem key={o} style={{ fontSize: 14 }} value={o.value}>
            {t(o.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

RocketerSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string
};

export default RocketerSelect;
