import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import RocketerBarChart from 'components/charts/BarChart';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { selectIsSidebarOpen } from 'redux/slices/app';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import CustomTooltip from 'components/charts/CustomTooltip';
import { calculateChartHeight } from 'utils/utility';

// ----------------------------------------------------------------------

const ChartTotalData = styled('div')(() => ({
  marginLeft: 20,
  marginBottom: 25,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  fontWeight: 'bold',
  opacity: 0.7
}));

CopyImageChartsGroup.propTypes = {
  barChartData: PropTypes.array,
  isModal: PropTypes.bool,
  titles: PropTypes.array
};

export default function CopyImageChartsGroup({ barChartData, isModal, titles }) {
  const [width] = useWindowSize();
  const theme = useTheme();
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const _width =
    isModal || (!isDashboardSidebarFiltersOpen && !isSidebarOpen)
      ? (width - 200) / 2
      : (width - 60 - (isDashboardSidebarFiltersOpen ? 450 : 300)) / 2;
  const { regionBarColors, regionHoverColors } = theme.palette.chart;
  const customHeight = barChartData && calculateChartHeight(barChartData[0]?.data?.length);

  const _renderChartTitle = (data, index) => (
    <ChartTotalData index={index}>
      <ChartTitle>{`${titles[index]} ${data.title}`}</ChartTitle>
    </ChartTotalData>
  );

  return (
    <Grid container justifyContent="space-between">
      {barChartData &&
        barChartData.map((data, index) => (
          <Grid
            item
            sx={{ mb: 8 }}
            key={data.title}
            style={{
              height: customHeight + 40,
              zoom: '80%',
              marginLeft: index % 2 === 0 ? '20px' : '-20px'
            }}
          >
            {_renderChartTitle(data, index)}
            <CustomTooltip zoom={0.3}>
              <RocketerBarChart
                hoverBarColor={regionHoverColors[index]}
                barColor={regionBarColors[index]}
                showCartesianGrid={false}
                showLegend
                showTooltip
                showLabelList
                chartData={data}
                width={_width + 100}
                height={customHeight}
                roundNumber={index % 2 === 0}
                hideXAxisLegend
                hideYTickLine
                yAxisWidth={140}
                title={data.title}
                margin={100}
                customizedTick
                customLabelSize={12}
              />
            </CustomTooltip>
          </Grid>
        ))}
    </Grid>
  );
}
