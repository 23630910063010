import { createSlice, current } from '@reduxjs/toolkit';
import { filterListByName, replaceDataInChartWithFirst25 } from 'utils/utility';

const initialState = {
  dashboardLoading: false,
  dashboards: [],
  filteredDashboards: [],
  dashboardNotFoundTrigger: false,
  currentDashboard: {},
  currentDashboardData: [],
  fullCurrentDashboardData: [],
  dataLength: ''
};

const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    getDashboardsRequest: (state) => {
      state.dashboardLoading = true;
    },
    getDashboardsError: (state) => {
      state.dashboardLoading = false;
    },
    getDashboardsSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.dashboards = action.payload;
    },
    getPerformanceOverviewDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getPerformanceOverviewDataError: (state) => {
      state.dashboardLoading = false;
    },
    getPerformanceOverviewDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getTimeSeriesChartDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getTimeSeriesChartDataError: (state) => {
      state.dashboardLoading = false;
    },
    getTimeSeriesChartDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getProjectsChartDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getProjectsChartDataSuccess: (state, action) => {
      state.dashboardLoading = false;

      // hide name for every even element within barchart's adjustedHerometric1CVR%
      // this was done on the BE side for the rest of charts, but this was missed
      if (action.payload.barCharts[2].data.length > 30) {
        const adjustedHerometric1CVR = action.payload.barCharts[2].data.map((item) => {
          const adjustedItem = { ...item };
          if (item.id % 2 !== 0) {
            adjustedItem.name = '';
          }
          return adjustedItem;
        });

        action.payload.barCharts.splice(2, 1, {
          ...action.payload.barCharts[2],
          data: adjustedHerometric1CVR
        });
      }

      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getProjectsChartDataError: (state) => {
      state.dashboardLoading = false;
    },
    getRegionDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getRegionDataError: (state) => {
      state.dashboardLoading = false;
    },
    getRegionDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getVolumeEfficiencyChartDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getVolumeEfficiencyDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getVolumeEfficiencyDataError: (state) => {
      state.dashboardLoading = false;
    },
    getTopCitiesDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getTopCitiesDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      const allowFilterLabelPopulation = true;
      if (action.payload.initialFetch) {
        const first25EntriesForEachChart = {
          barCharts: action.payload.data.barCharts.map((chart) =>
            replaceDataInChartWithFirst25(chart, allowFilterLabelPopulation)
          ),
          tableOne: action.payload.data.tableOne,
          tableTwo: action.payload.data.tableTwo
        };
        state.currentDashboardData = first25EntriesForEachChart;
        state.fullCurrentDashboardData = action.payload.data;
      } else {
        state.currentDashboardData = action.payload.data;
        state.fullCurrentDashboardData = action.payload.data;
      }
    },
    getTopCitiesDataError: (state) => {
      state.dashboardLoading = false;
    },
    getCopyImageChartDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getCopyImageChartDataError: (state) => {
      state.dashboardLoading = false;
    },
    getTimeOfDayDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getTimeOfDayDataError: (state) => {
      state.dashboardLoading = false;
    },
    getTimeOfDayDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getProductDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getProductDataError: (state) => {
      state.dashboardLoading = false;
    },
    getProductDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getProductAnalysisDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getProductAnalysisDataError: (state) => {
      state.dashboardLoading = false;
    },
    getProductAnalysisDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getCityDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getCityDataError: (state) => {
      state.dashboardLoading = false;
    },
    getCityDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getDemographicsDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getDemographicsDataError: (state) => {
      state.dashboardLoading = false;
    },
    getDemographicsDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      state.currentDashboardData = action.payload;
      state.fullCurrentDashboardData = action.payload;
    },
    getCopyImageDataRequest: (state) => {
      state.dashboardLoading = true;
    },
    getCopyImageDataError: (state) => {
      state.dashboardLoading = false;
    },
    getCopyImageDataSuccess: (state, action) => {
      state.dashboardLoading = false;
      const allowFilterLabelPopulation = true;

      if (action.payload.initialFetch) {
        const first25EntriesForEachChart = {
          copyBarCharts: action.payload.data.copyBarCharts.map((chart) =>
            replaceDataInChartWithFirst25(chart, allowFilterLabelPopulation)
          ),
          copyTemplateTable: action.payload.data.copyTemplateTable,
          mediaBarCharts: action.payload.data.mediaBarCharts.map((chart) =>
            replaceDataInChartWithFirst25(chart, allowFilterLabelPopulation)
          ),
          mediaCopyTable: action.payload.data.copyTemplateTable,
          mediaFileTable: action.payload.data.mediaFileTable,
          radarChart: action.payload.data.radarChart.map((chart) =>
            replaceDataInChartWithFirst25(chart, allowFilterLabelPopulation)
          )
        };

        state.currentDashboardData = first25EntriesForEachChart;
        state.fullCurrentDashboardData = action.payload.data;
      } else {
        state.currentDashboardData = action.payload.data;
        state.fullCurrentDashboardData = action.payload.data;
      }
    },
    filterDashboards: (state, action) => {
      state.filteredDashboards = filterListByName(
        current(state).filteredDashboards.length && action.payload !== ''
          ? current(state).filteredDashboards
          : current(state).dashboards,
        action.payload
      );
      if (!current(state).filteredDashboards.length && action.payload !== '') {
        state.dashboardNotFoundTrigger = true;
      }
    },
    setDashboardNotFoundTrigger: (state, action) => {
      state.dashboardNotFoundTrigger = action.payload;
    },
    setCurrentDashboard: (state, action) => {
      state.currentDashboard = current(state).dashboards.find(
        (dashboard) => dashboard.id === action.payload
      );
    },
    setCurrentDashboardData: (state, action) => {
      if (action.payload.dataType === '') {
        state.currentDashboardData = action.payload.dashboardData;
      } else {
        state.currentDashboardData[action.payload.dataType] = action.payload.dashboardData;
      }
    },
    setDataLength: (state, action) => {
      state.dataLength = action.payload;
    },
    clearDashboards: (state) => {
      state.dashboards = [];
      state.filteredDashboards = [];
      state.currentDashboard = {};
      state.currentDashboardData = [];
      state.fullCurrentDashboardData = [];
    }
  }
});

export const {
  getDashboardsRequest,
  getDashboardsError,
  getDashboardsSuccess,
  getPerformanceOverviewDataRequest,
  getPerformanceOverviewDataError,
  getPerformanceOverviewDataSuccess,
  getTimeSeriesChartDataRequest,
  getTimeSeriesChartDataError,
  getTimeSeriesChartDataSuccess,
  getProjectsChartDataRequest,
  getProjectsChartDataSuccess,
  getProjectsChartDataError,
  getRegionDataRequest,
  getRegionDataSuccess,
  getRegionDataError,
  getVolumeEfficiencyChartDataRequest,
  getVolumeEfficiencyDataSuccess,
  getVolumeEfficiencyChartDataError,
  getTopCitiesDataRequest,
  getTopCitiesDataSuccess,
  getTopCitiesDataError,
  getCopyImageDataRequest,
  getCopyImageDataSuccess,
  getCopyImageDataError,
  getTimeOfDayDataRequest,
  getTimeOfDayDataSuccess,
  getTimeOfDayDataError,
  getProductDataRequest,
  getProductDataSuccess,
  getProductDataError,
  getDemographicsDataRequest,
  getDemographicsDataSuccess,
  getDemographicsDataError,
  getProductAnalysisDataRequest,
  getProductAnalysisDataError,
  getProductAnalysisDataSuccess,
  getCityDataRequest,
  getCityDataError,
  getCityDataSuccess,
  filterDashboards,
  setDashboardNotFoundTrigger,
  setCurrentDashboard,
  clearDashboards,
  setCurrentDashboardData,
  setDataLength
} = dashboardsSlice.actions;

export const selectDashboards = (state) => state.dashboards.dashboards;
export const selectFilteredDashboards = (state) => state.dashboards.filteredDashboards;
export const selectDashboardNotFoundTrigger = (state) => state.dashboards.dashboardNotFoundTrigger;
export const selectDashboard = (state) => state.dashboards.currentDashboard;
export const selectDashboardLoading = (state) => state.dashboards.dashboardLoading;
export const selectPerformanceOverviewChartData = (state) => state.dashboards.currentDashboardData;
export const selectTimeSeriesChartData = (state) => state.dashboards.currentDashboardData;
export const selectProjectsChartData = (state) => state.dashboards.currentDashboardData;
export const selectRegionData = (state) => state.dashboards.currentDashboardData;
export const selectVolumeEfficiencyChartData = (state) => state.dashboards.currentDashboardData;
export const selectTopCitiesData = (state) => state.dashboards.currentDashboardData;
export const selectCopyImageChartData = (state) => state.dashboards.currentDashboardData;
export const selectTimeOfDayData = (state) => state.dashboards.currentDashboardData;
export const selectDemographicsData = (state) => state.dashboards.currentDashboardData;
export const selectProductDataData = (state) => state.dashboards.currentDashboardData;
export const selectProductAnalysisData = (state) => state.dashboards.currentDashboardData;
export const selectCityData = (state) => state.dashboards.currentDashboardData;
export const selectCurrentDashboardData = (state) => state.dashboards.currentDashboardData;
export const selectFullCurrentDashboardData = (state) => state.dashboards.fullCurrentDashboardData;
export const selectDataLength = (state) => state.dashboards.dataLength;

export default dashboardsSlice.reducer;
