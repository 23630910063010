import { DATE_LIST_OPTIONS_LENGTH, UNIT_OF_TIME } from 'uiConstants';
import {
  sub,
  format,
  formatDistanceToNow,
  differenceInMonths,
  differenceInDays,
  differenceInQuarters,
  subQuarters,
  compareAsc,
  compareDesc,
  max,
  min,
  isBefore,
  isAfter,
  endOfYear,
  startOfYear,
  parseJSON,
  parseISO
} from 'date-fns';
import _ from 'lodash';
// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd/MM/yy');
}

export function fDateFullYear(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateYearFirst(date) {
  return format(new Date(date), 'yyyy/MM/dd');
}

export function fDateDay(date) {
  return format(new Date(date), 'dd');
}

export function fDateMonth(date) {
  return format(new Date(date), 'MM');
}

export function fDateYear(date) {
  return format(new Date(date), 'yy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

const splitDate = (date) => date.split('/');
const months = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11
};
const compareByMonthYear = (value1, value2) => {
  const date1 = new Date();
  const date2 = new Date();
  const [month1, year1] = value1.day.split('-');
  const [month2, year2] = value2.day.split('-');

  date1.setFullYear(year1);
  date1.setMonth(months[month1]);
  date2.setFullYear(year2);
  date2.setMonth(months[month2]);
  return compareDesc(date2, date1);
};

export function dateComparator(value1, value2) {
  if (value1.day.split('-').length === 2) {
    return compareByMonthYear(value1, value2);
  }
  const [day1, month1, year1] = splitDate(value2.day);
  const [day2, month2, year2] = splitDate(value1.day);
  return compareDesc(new Date(year1, month1 - 1, day1), new Date(year2, month2 - 1, day2));
}

export function subRangeToTime(date, interval, duration, dateFormat) {
  // duration can be years, months, quarters, weeks, days, hours, minutes, seconds
  if (interval === 'quarters') {
    return format(subQuarters(new Date(date), duration), dateFormat);
  }
  return format(sub(new Date(date), { [interval]: duration }), dateFormat);
}

export function orderDatesCronologically(dates) {
  return dates.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  });
}

export function getLastNumberOfOptions(typeOfOption, years) {
  const endDay = sub(new Date(format(new Date(), 'y') - years + 1, 0, 1), new Date());
  switch (typeOfOption) {
    case 'Years':
      return differenceInDays(new Date(), endDay) + 1;
    case 'Quarters':
      return differenceInQuarters(new Date(), endDay) + 1;
    case 'Months':
      return differenceInMonths(new Date(), endDay) + 1;
    case 'Days':
      return differenceInDays(new Date(), endDay) + 1;
    default:
      return differenceInDays(new Date(), endDay) + 1;
  }
}

export function fDateToYear(date) {
  return format(new Date(date), 'Y');
}

export function findEarliestDateFromArray(selectedDates, originalFormat) {
  const formattedDates = selectedDates.map((date) => new Date(date));
  return format(min(formattedDates), originalFormat);
}

export function findLatestDateFromArray(selectedDates, originalFormat) {
  const formattedDates = selectedDates.map((date) => new Date(date));
  return format(max(formattedDates), originalFormat);
}

export function findExcludedDatesBetweenEarliestAndLatest(earliest, latest, excludedDates) {
  const earliestFormatted = new Date(earliest);
  const latestFormatted = new Date(latest);
  const formattedDates = excludedDates.map((date) => new Date(date));
  const betweenEarliestAndLatest = formattedDates.filter(
    (date) => isBefore(date, latestFormatted) && isAfter(date, earliestFormatted)
  );

  return betweenEarliestAndLatest;
}

export function getBegginingOfYear(year) {
  return startOfYear(new Date(year));
}

export function getEndOfYear(year) {
  return endOfYear(new Date(year));
}

export function isYear(year) {
  return year.length === 4;
}

export function findOriginalTimeFormat(date) {
  if (date.length === 4) {
    return 'yyyy';
  }
  if (parseInt(date.substr(date.length - 4), 10)) {
    return 'dd/MM/yyyy';
  }
  return 'yyyy/MM/dd';
}

export function convertToDefaultTimeFormat(date, originalFormat) {
  // conversion to yyyy/MM/dd format as new Date() cannot process other type of formats
  if (originalFormat === 'dd/MM/yyyy') {
    return `${date.substr(6, 4)}-${date.substr(3, 2)}-${date.substr(0, 2)}`;
  }
  return date;
}

export function findDateType(date) {
  if (date.length === 4) return UNIT_OF_TIME.YEARS;
  if (date.length === 7 && date.includes('Q')) return UNIT_OF_TIME.QUARTERS;
  if (date.length === 7) return UNIT_OF_TIME.MONTHS;
  return UNIT_OF_TIME.DAYS;
}
