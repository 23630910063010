import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import UITable from 'components/Table';
import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import { useDashboard } from 'utils/hooks/useDashboard';
import { selectRegionData, setCurrentDashboardData } from 'redux/slices/dashboards';
import { getRegionData } from 'redux/thunk/dashboards';
import RegionHorizontalChartsGroup from 'components/_dashboard/regionHorizontalChartsGroup';
import { sortChartDataByTabelData, detectEmptyData } from 'utils/utility';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

Region.propTypes = {
  isModal: PropTypes.bool
};

export default function Region({ isModal }) {
  const { dashboardData, isLoading } = useDashboard(getRegionData, selectRegionData, isModal);
  const dispatch = useDispatch();
  return (
    <DashboardLayout
      hideFilters={isModal}
      hideTimeSelector
      isLoading={isLoading}
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12}>
        <RegionHorizontalChartsGroup barChartData={dashboardData.barGraphs} isModal={isModal} />
      </Grid>
      <Grid item xs={12} sx={{ marginRight: isModal ? 2 : 0, marginBottom: isModal ? 3 : 0 }}>
        <UITable
          grandTotal={dashboardData.table?.grandTotal}
          list={dashboardData.table?.rows}
          columns={dashboardData.table?.columns}
          title="Regional metrics"
          sortBy=""
          sortDirection="ASC"
          filters={dashboardData.table?.filters}
          exportLink={dashboardData.table?.exportLink}
          globalSort={(list) =>
            sortChartDataByTabelData(
              'barGraphs',
              'region',
              list,
              dashboardData,
              (newDashboardData) =>
                dispatch(
                  setCurrentDashboardData({
                    dataType: 'barGraphs',
                    dashboardData: newDashboardData
                  })
                )
            )
          }
        />
      </Grid>
    </DashboardLayout>
  );
}
