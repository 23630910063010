import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

export default function SingleSelectCardContent({ options, setFilter, defaultValue }) {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        value={defaultValue || options[1]?.name}
        onChange={(e) => setFilter(e.target.value)}
        name="radio-buttons-group"
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.name}
            control={
              <Radio
                size="small"
                sx={{
                  padding: '2px',
                  margin: '3px',
                  '& .MuiSvgIcon-root': {
                    fontSize: 15
                  }
                }}
              />
            }
            label={<Typography sx={{ fontSize: 12 }}>{option.name}</Typography>}
            sx={{ fontSize: 10 }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

SingleSelectCardContent.propTypes = {
  options: PropTypes.array,
  setFilter: PropTypes.func,
  defaultValue: PropTypes.string
};
