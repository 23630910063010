import React, { useContext } from "react";

import useMousePosition from "utils/hooks/useMousePosition";

const TooltipContext = React.createContext();

// Function for all those little tooltip context consumers out there
export function useTooltipContext() {
  const tooltipContext = useContext(TooltipContext);
  return tooltipContext;
}

// Tooltip provider - place somewhere in your app with access to full window
// width. You can use the same thing for all of your tooltips, and the callback
// functions openTooltip and closeTooltip will modify it's state
export default function CustomTooltip({ children, zoom= 0, zoomLeft=0, zoomTop=0}) {
  const emptyTooltip = {
    open: false,
    content: null,
    style: null
  };
  const [tooltip, setTooltip] = React.useState(emptyTooltip);

  const openTooltip = ({ content, style }) => {
    setTooltip({
      open: true,
      content,
      style
    });
  };

  const closeTooltip = () => {
    setTooltip(emptyTooltip);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Tooltip tooltip={tooltip} zoom={zoom} zoomLeft={zoomLeft} zoomTop={zoomTop}/>
      <TooltipProvider openTooltip={openTooltip} closeTooltip={closeTooltip}>
        {children}
      </TooltipProvider>
    </div>
  );
}

const Tooltip = ({ tooltip, zoom= 0, zoomLeft=0, zoomTop=0 }) => {
  const position = useMousePosition();
  // This part is essential to the tooltip functioning properly, but
  // I haven't thought of a way to stop listening to useMousePosition
  // when the tooltip is closed
  const left = tooltip.open ? position.x : -9999;
  const top = tooltip.open ? position.y : -9999;

  return (
    <div
      style={{
        position: "fixed",
        left: zoomLeft ? (left + left * zoomLeft) : (left + left * zoom),
        top: zoomTop ? (top + top * zoomTop) : (top + (top * zoom)),
        zIndex: 9999,
        ...tooltip.style
      }}
    >
      {tooltip.content}
    </div>
  );
};

// Used in the CustomTooltip wrapper above
function TooltipProvider({ children, openTooltip, closeTooltip }) {
  const tooltipContext = React.useMemo(() => ({
      openTooltip,
      closeTooltip
    }), [openTooltip, closeTooltip]);

  return (
    <TooltipContext.Provider value={tooltipContext}>
      {children}
    </TooltipContext.Provider>
  );
}
