import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useWindowSize } from 'utils/hooks/useWindowSize';

AutocompleteDropdown.propTypes = {
  options: PropTypes.array,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  filterType: PropTypes.string,
  selectedOptions: PropTypes.array,
  disabled: PropTypes.bool
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteStyle = styled(Autocomplete, {
  shouldForwardProp: (props) => props !== 'maxChipWidth' && props !== 'fullWidth'
})(({ maxChipWidth }) => ({
  '& .MuiChip-label': {
    maxWidth: maxChipWidth
  },
  '& .MuiAutocomplete-input': {
    minWidth: '0px !important'
  }
}));

export default function AutocompleteDropdown({
  options,
  handleChange,
  placeholder,
  filterType,
  selectedOptions,
  disabled
}) {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [width, height] = useWindowSize();
  const xlWidth = 1530;
  const lgWidth = 2000;

  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  const getLimitTags = () => {
    if (width > xlWidth) {
      return 2;
    }
    return 3;
  };

  const getMaxWidth = () => {
    if (selectedOptions.length < 2 || (selectedOptions.length === 2 && width > lgWidth)) {
      return '';
    }
    if (width > xlWidth && width < lgWidth) {
      return '62px';
    }
    return '';
  };

  const renderTags = (value, getTagProps) =>
    selectedOptions.map((option, index) => {
      if (!focused)
        return (
          <Chip
            variant="filled"
            key={option}
            size="small"
            label={option.name}
            {...getTagProps({ index })}
          />
        );
      return null;
    });

  return (
    <AutocompleteStyle
      id="autocomplete-dropdown"
      size="small"
      maxChipWidth={getMaxWidth()}
      multiple
      disableCloseOnSelect
      defaultValue={selectedOptions}
      value={selectedOptions}
      limitTags={getLimitTags()}
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={!focused ? placeholder : t(`${filterType}Name`)}
          inputProps={{ ...params.inputProps, style: { fontSize: 13 } }}
        />
      )}
      disabled={disabled}
      renderTags={renderTags}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ fontSize: 14 }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
    />
  );
}
