import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  companies: []
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    getCompaniesRequest: (state) => {
      state.loading = true;
    },
    getCompaniesError: (state) => {
      state.loading = false;
    },
    getCompaniesSuccess: (state, action) => {
      state.loading = false;
      state.companies = action.payload;
    },
    clearCompanies: (state) => {
      state.loading = false;
      state.companies = [];
    }
  }
});

export const { getCompaniesRequest, getCompaniesError, getCompaniesSuccess, clearCompanies } =
  companiesSlice.actions;

export const selectCompanies = (state) => state.companies.companies;
export default companiesSlice.reducer;
