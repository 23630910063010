/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, IconButton, Box } from '@mui/material';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Page from 'components/Page';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDashboard,
  setCurrentDashboard,
  selectDashboards,
  setCurrentDashboardData,
  selectFullCurrentDashboardData,
  selectDataLength,
  setDataLength
} from 'redux/slices/dashboards';
import { getCompanies } from 'redux/thunk/companies';
import Icon from 'components/Icon';
import { styled } from '@mui/material/styles';
import PerformanceOverview from 'layouts/dashboard/dashboards/PerformanceOverview';
import { selectIsSidebarOpen } from 'redux/slices/app';
import TimeSeriesData from 'layouts/dashboard/dashboards/TimeSeriesData';
import UIDialog from 'components/Dialog';
import DashboardFilters, { FILTERS_DRAWER_WIDTH } from 'layouts/dashboard/DashboardFilters';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import ProductData from 'layouts/dashboard/dashboards/ProductData';
import Projects from 'layouts/dashboard/dashboards/Projects';
import CityData from 'layouts/dashboard/dashboards/CityData';
import Demographics from 'layouts/dashboard/dashboards/Demographics';
import Region from 'layouts/dashboard/dashboards/Region';
import VolumeEfficiency from 'layouts/dashboard/dashboards/VolumeEfficiency';
import TopCities from 'layouts/dashboard/dashboards/TopCities';
import TimeOfDay from 'layouts/dashboard/dashboards/TimeOfDay';
import { useTranslation } from 'react-i18next';
import ProductAnalysis from 'layouts/dashboard/dashboards/ProductAnalysis';
import CopyAndImage from 'layouts/dashboard/dashboards/CopyAndImage';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getFirstXDataChartValues } from 'utils/utility';
import {
  DASHBOARDS_FREE_OF_CHART_LENGTH_SELECTOR,
  DASHBOARDS_WITH_LARGE_DATA,
  DASHBOARDS_WITH_SINGLE_TYPE_OF_DATA,
  CHART_LENGTH_SELECTOR_OPTIONS
} from 'uiConstants';

// ----------------------------------------------------------------------

const ButtonStyle = styled(IconButton, {
  shouldForwardProp: (props) =>
    props !== 'isSidebarOpen' && props !== 'isDashboardSidebarFiltersOpen'
})(({ theme, isSidebarOpen, isDashboardSidebarFiltersOpen }) => ({
  marginTop: 2,
  height: 50,
  left: `calc(100% - ${
    isDashboardSidebarFiltersOpen
      ? isSidebarOpen
        ? '505px'
        : '285px'
      : isSidebarOpen
      ? '285px'
      : '65px'
  })`,
  position: 'absolute',
  '&:hover': {
    backgroundColor: theme.palette.common.white
  }
}));

const ContainerStyled = styled(Container)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 'unset'
  }
}));

const DashboardTitleStyled = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  position: 'fixed',
  zIndex: 1000,
  height: 50,
  background: theme.palette.common.white,
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  margin: '0px !important',
  top: 70,
  [theme.breakpoints.up('xs')]: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: '100%'
  }
}));

export default function Dashboard() {
  const { t } = useTranslation();
  const currentDashboard = useSelector(selectDashboard);
  const dashboards = useSelector(selectDashboards);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const fullCurrentDashboardData = useSelector(selectFullCurrentDashboardData);

  const dataLength = useSelector(selectDataLength);
  const [isOpen, setIsOpen] = useState(false);

  const currentDashboardName = dashboards.find(
    (dashboard) => dashboard.id === params.dashboardId
  )?.name;

  useEffect(() => {
    if (dashboards.length) {
      if (!dashboards.find((dashboard) => dashboard.id === params.dashboardId)) {
        navigate(`/dashboard/${dashboards[0].id}`, { replace: true });
      } else {
        dispatch(setCurrentDashboard(params.dashboardId));
        dispatch(getCompanies());
        if (DASHBOARDS_WITH_LARGE_DATA.includes(currentDashboardName)) {
          dispatch(setDataLength(25));
        } else {
          dispatch(setDataLength(''));
        }
      }
    }
  }, [pathname, dashboards]);

  const showDataLengthSelector = DASHBOARDS_FREE_OF_CHART_LENGTH_SELECTOR.includes(
    currentDashboardName
  )
    ? false
    : DASHBOARDS_WITH_SINGLE_TYPE_OF_DATA.includes(currentDashboardName)
    ? true
    : !!Object.entries(fullCurrentDashboardData).filter((pair) =>
        pair[0].toLowerCase().includes('chart')
      )?.length;

  const handleChange = (e) => {
    const charts = Object.keys(fullCurrentDashboardData).filter((keyName) =>
      keyName.toLowerCase().includes('chart')
    );

    if (DASHBOARDS_WITH_SINGLE_TYPE_OF_DATA.includes(currentDashboardName)) {
      dispatch(
        setCurrentDashboardData({
          dataType: '',
          dashboardData: getFirstXDataChartValues(
            'barCharts',
            { barCharts: fullCurrentDashboardData },
            e.target.value
          )
        })
      );
    }

    if (charts.length) {
      // eslint-disable-next-line array-callback-return
      charts.map((chart) => {
        dispatch(
          setCurrentDashboardData({
            dataType: chart,
            dashboardData: getFirstXDataChartValues(chart, fullCurrentDashboardData, e.target.value)
          })
        );
      });
    }

    dispatch(setDataLength(e.target.value));
  };
  const _renderDashboard = (dashboard, hideFilters) => {
    switch (dashboard) {
      case 'performanceOverview':
        return <PerformanceOverview isModal={hideFilters} />;
      case 'timeSeriesData':
        return <TimeSeriesData isModal={hideFilters} />;
      case 'productData':
        return <ProductData isModal={hideFilters} />;
      case 'projects':
        return <Projects isModal={hideFilters} />;
      case 'region':
        return <Region isModal={hideFilters} />;
      case 'cityData':
        return <CityData isModal={hideFilters} />;
      case 'volumeVsEfficiency':
        return <VolumeEfficiency isModal={hideFilters} />;
      case 'productAnalysis':
        return <ProductAnalysis isModal={hideFilters} />;
      case 'demographics':
        return <Demographics isModal={hideFilters} />;
      case 'topCities':
        return <TopCities isModal={hideFilters} />;
      case 'timeOfDay':
        return <TimeOfDay isModal={hideFilters} />;
      case 'copyAndImage':
        return <CopyAndImage isModal={hideFilters} />;
      default:
        return null;
    }
  };

  const _renderChartLengthControl = () => (
    <FormControl
      sx={{
        m: 2,
        width: 75,
        left: `calc(100% - ${
          isDashboardSidebarFiltersOpen
            ? isSidebarOpen
              ? '605px'
              : '385px'
            : isSidebarOpen
            ? '385px'
            : '165px'
        })`,
        position: 'absolute'
      }}
    >
      <Select
        value={dataLength}
        onChange={handleChange}
        displayEmpty
        style={{ height: 25, fontSize: 13 }}
      >
        {CHART_LENGTH_SELECTOR_OPTIONS.map((option) => (
          <MenuItem
            key={option.id}
            style={{ fontSize: 13, justifyContent: 'center' }}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Page title={`${t(currentDashboard.label)} - Rocketer Reporting`}>
      <ContainerStyled>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            padding: 0,
            width: `${
              isDashboardSidebarFiltersOpen ? `calc(100% - ${FILTERS_DRAWER_WIDTH}px)` : '100%'
            }`
          }}
        >
          <DashboardTitleStyled>
            <div
              style={{
                display: 'flex',
                height: 50,
                width: `calc(100% - ${isSidebarOpen ? '300px' : '0px'})`
              }}
            >
              <div style={{ float: 'left' }}>
                <Typography variant="subtitle1" sx={{ lineHeight: '50px' }}>
                  {currentDashboard?.name}
                </Typography>
              </div>
              {showDataLengthSelector && _renderChartLengthControl()}
              <ButtonStyle
                isSidebarOpen={isSidebarOpen}
                isDashboardSidebarFiltersOpen={isDashboardSidebarFiltersOpen}
                onClick={() => setIsOpen(true)}
              >
                <Icon type="extend" />
              </ButtonStyle>
            </div>
          </DashboardTitleStyled>
          {_renderDashboard(currentDashboard?.label)}
          <DashboardFilters />
        </Grid>
      </ContainerStyled>
      <UIDialog
        fullScreen
        isOpen={isOpen}
        title={currentDashboard?.name}
        handleClose={() => setIsOpen(false)}
      >
        <Box ml={4}>{_renderDashboard(currentDashboard?.label, true)}</Box>
      </UIDialog>
    </Page>
  );
}
