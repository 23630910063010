import DashboardLayout from 'layouts/dashboard/dashboards/DashboardLayout';
import UITable from 'components/Table';
import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import {
  impressionDevicesColumns,
  impressionDevicesRows,
  ageColumns,
  ageRows,
  genderRows,
  genderColumns,
  demographicsGrandTotal
} from '_mocks_/table';
import { useDashboard } from 'utils/hooks/useDashboard';
import { selectDemographicsData } from 'redux/slices/dashboards';
import { getDemographicsData } from 'redux/thunk/dashboards';
import { detectEmptyData } from 'utils/utility';

Demographics.propTypes = {
  isModal: PropTypes.bool
};

export default function Demographics({ isModal }) {
  const { dashboardData, isLoading } = useDashboard(
    getDemographicsData,
    selectDemographicsData,
    isModal
  );

  return (
    <DashboardLayout
      hideFilters={isModal}
      hideTimeSelector
      isLoading={isLoading}
      emptyData={detectEmptyData(dashboardData)}
    >
      <Grid item xs={12} sx={{ marginRight: isModal ? 5 : 0 }}>
        <UITable
          list={dashboardData.impressionDevicesTable?.rows}
          columns={dashboardData.impressionDevicesTable?.columns}
          title="Impression Devices"
          grandTotal={dashboardData.impressionDevicesTable?.grandTotal}
          colWidth={130}
          sortBy="primaryReportingConversionCpa"
          sortDirection="ASC"
          filters={dashboardData.impressionDevicesTable?.filters}
          exportLink={dashboardData.impressionDevicesTable?.exportLink}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginRight: isModal ? 5 : 0 }}>
        <UITable
          list={dashboardData.ageTable?.rows}
          columns={dashboardData.ageTable?.columns}
          title="Age"
          grandTotal={dashboardData.ageTable?.grandTotal}
          colWidth={130}
          sortBy="primaryReportingConversionCpa"
          sortDirection="ASC"
          filters={dashboardData.ageTable?.filters}
          exportLink={dashboardData.ageTable?.exportLink}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginRight: isModal ? 5 : 0, marginBottom: isModal ? 3 : 0 }}>
        <UITable
          list={dashboardData.genderTable?.rows}
          columns={dashboardData.genderTable?.columns}
          title="Gender"
          grandTotal={dashboardData.genderTable?.grandTotal}
          colWidth={130}
          sortBy="primaryReportingConversionCpa"
          sortDirection="ASC"
          filters={dashboardData.genderTable?.filters}
          exportLink={dashboardData.genderTable?.exportLink}
        />
      </Grid>
    </DashboardLayout>
  );
}
