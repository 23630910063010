import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import RocketerBarChart from 'components/charts/BarChart';
import { selectIsDashboardSidebarFiltersOpen } from 'redux/slices/filters';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import CustomTooltip from 'components/charts/CustomTooltip';
import { calculateChartHeight } from 'utils/utility';

// ----------------------------------------------------------------------

const ChartTotalData = styled('div')(() => ({
  marginLeft: 20,
  marginBottom: 30,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const ChartTitle = styled('div')(({ theme }) => ({
  fontSize: 16,
  cursor: 'default',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.common.black,
  fontWeight: 'bold',
  opacity: 0.7,
  textAlign: 'center'
}));

RegionHorizontalChartsGroup.propTypes = {
  barChartData: PropTypes.array,
  isModal: PropTypes.bool
};

const getLargestData = (barChartData) => {
  const sortedChartDataLengths = barChartData?.map((elem) => elem.data.length).sort();
  return sortedChartDataLengths ? Math.max(...sortedChartDataLengths) : 0;
};

export default function RegionHorizontalChartsGroup({ barChartData, isModal, index }) {
  const [width] = useWindowSize();
  const theme = useTheme();
  const defaultHeight = 250;
  const isDashboardSidebarFiltersOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const customHeight = barChartData && calculateChartHeight(getLargestData(barChartData));
  const _width = isModal
    ? (width + width * 0.1) / 4
    : (width - (isDashboardSidebarFiltersOpen ? 320 : 300)) / 4;
  const { regionBarColors, regionHoverColors } = theme.palette.chart;

  const _renderChartTitle = (data, index) => (
    <ChartTotalData index={index}>
      <ChartTitle>{data.title}</ChartTitle>
    </ChartTotalData>
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        zoom: '80%',
        width: '102%',
        ml: '-10px',
        padding: '0 20px',
        height: `${customHeight + 40}px`
      }}
    >
      {barChartData &&
        barChartData.map((data, index) => (
          <Grid
            item
            xs={Math.round(12 / barChartData.length)}
            sx={{ mb: 7 }}
            key={data.title}
            style={{ height: defaultHeight + 50 }}
          >
            {_renderChartTitle(data, index)}
            <CustomTooltip zoom={0.3}>
              <RocketerBarChart
                hideXAxisLegend
                showLegend
                showTooltip
                showLabelList
                hoverBarColor={regionHoverColors[index]}
                barColor={regionBarColors[index]}
                showCartesianGrid
                hideVerticalCartesianGrid={false}
                chartData={data}
                width={_width}
                height={customHeight}
                roundNumber={index % 2 === 0}
                margin={{ left: 0, right: isModal ? 30 : 55 }}
                title={data.title}
                customizedTick
              />
            </CustomTooltip>
          </Grid>
        ))}
    </Grid>
  );
}
