import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { InputBase } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

const SearchIconWrapper = styled('div')(() => ({
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase, {
  shouldForwardProp: (props) => props !== 'withIcon' && props !== 'fullWidth'
})(({ theme, withIcon }) => ({
  color: 'inherit',
  fontSize: 12,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `${withIcon ? `calc(1em + ${theme.spacing(2)})` : 0}`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto'
  }
}));

export default function Search({ placeholderId, onChange, withIcon }) {
  const defaultPlaceholderId = 'search';
  const { t } = useTranslation();

  return (
    <SearchWrapper>
      {withIcon ? (
        <SearchIconWrapper>
          <Icon type="search" />
        </SearchIconWrapper>
      ) : (
        <></>
      )}

      <StyledInputBase
        placeholder={t(placeholderId || defaultPlaceholderId)}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => onChange(e.target.value)}
        withIcon={withIcon}
      />
    </SearchWrapper>
  );
}

Search.propTypes = {
  placeholderId: PropTypes.string,
  onChange: PropTypes.func,
  withIcon: PropTypes.bool
};
