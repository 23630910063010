/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import CustomSwitch from 'components/Switch';
import palette from 'theme/palette';
import SingleSelectCardContent from 'components/sidebar-filters/SingleSelectCardContent';
import MultipleSelectCardContent from 'components/sidebar-filters/MultipleSelectCardContent';
import {
  setCheckedFilters,
  selectCheckedFilters,
  setCheckedFiltersToggle,
  selectProjectsFromFilter
} from 'redux/slices/filters';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import FilterModal from 'components/sidebar-filters/FilterModal';
import { setDataForFilter, setCorrespondingProjectsToCompany } from 'utils/utility';
import { setDataLength } from 'redux/slices/dashboards';

const FilterCardWrapper = styled('div', {
  shouldForwardProp: (props) => props !== 'isEnabled' && props !== 'fullWidth'
})(({ theme, isEnabled }) => ({
  backgroundColor: theme.palette.grey[200],
  margin: '13px',
  border: `1px solid ${theme.palette.grey[300]}`,
  opacity: isEnabled ? 1 : 0.3
}));

const ButtonStyle = styled(IconButton)(() => ({
  height: '15px',
  width: '15px',
  padding: 0,
  marginRight: '10px',
  transform: 'scale(0.6)',
  opacity: 0,
  float: 'right',
  '&:hover': {
    opacity: 1
  }
}));

export default function FilterCard({ data }) {
  const { id, name, type, options, extraType } = data;
  const checkedFilters = useSelector(selectCheckedFilters);
  const [isEnabled, setIsEnabled] = useState(!checkedFilters[name]?.disabled || false);
  const [modalToOpen, setModalToOpen] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const projects = useSelector(selectProjectsFromFilter);

  const handleSetCheckedOptions = (optionName, value) => {
    dispatch(
      setCheckedFilters({
        filterName: name,
        data: setDataForFilter(optionName, name, value, options, checkedFilters)
      })
    );

    // if the filter tocuhed is Company Name,
    // handle Project Name behavior depending on the company selected

    if (name === 'Company Name') {
      // deselect all projects
      if (optionName === 'all' && !value) {
        dispatch(
          setCheckedFilters({
            filterName: 'Project Name',
            data: []
          })
        );
        // select all projects
      } else if (optionName === 'all' && value) {
        dispatch(
          setCheckedFilters({
            filterName: 'Project Name',
            data: projects.map((option) => option.name)
          })
        );
        // select only the projects that have companyId equal to the company selected
      } else {
        dispatch(
          setCheckedFilters({
            filterName: 'Project Name',
            data: setCorrespondingProjectsToCompany(
              options.find((option) => option.name === optionName).id,
              projects,
              value,
              checkedFilters
            )
          })
        );
      }
    }
    dispatch(setDataLength(''));
  };

  const _renderCardContentBasedOnType = () => {
    let cardContentComponent = <></>;
    switch (type) {
      case 'singleSelect':
        cardContentComponent = (
          <SingleSelectCardContent
            defaultValue={checkedFilters[name]?.data || options[1]?.name}
            options={options}
            setFilter={(filterData) => {
              dispatch(setCheckedFilters({ filterName: name, data: filterData }));
              dispatch(setDataLength(''));
            }}
          />
        );
        break;
      case 'multipleSelect':
        cardContentComponent = (
          <MultipleSelectCardContent
            options={
              name === 'Date Range' && checkedFilters[name]?.data?.length
                ? checkedFilters[name]?.data
                : options
            }
            checkedValues={
              name === 'Date Range' && checkedFilters[name]?.data.length
                ? checkedFilters[name]?.data.filter((date) => date.checked).map((date) => date.name)
                : checkedFilters[name]?.data && checkedFilters[name]?.data[0]
                ? checkedFilters[name]?.data
                : []
            }
            withSelectAll={name !== 'Date Range'}
            withSearch={name !== 'Date Range'}
            setFilter={(optionName, value) => {
              handleSetCheckedOptions(optionName, value);
            }}
          />
        );
        break;
      default:
        cardContentComponent = <></>;
    }
    return (
      <Box
        sx={{
          minHeight: '30px',
          display: 'block',
          paddingLeft: '15px',
          pointerEvents: isEnabled ? 'auto' : 'none',
          maxHeight: '310px',
          overflowY:
            name === 'Date Range' && checkedFilters[name]?.data.length > 14 ? 'auto' : 'hidden',
          overflowX: 'hidden'
        }}
      >
        {cardContentComponent}
      </Box>
    );
  };

  const _renderEnableDisableSwitch = () => (
    <CustomSwitch
      size="small"
      isEnabled={!checkedFilters[name]?.disabled || false}
      onChange={(value) => {
        setIsEnabled(value);
        dispatch(setCheckedFiltersToggle({ filterName: name, data: value }));
        dispatch(setDataLength(''));
      }}
      extraCustomisation={{ float: 'right', margin: '4px' }}
    />
  );

  const _renderOpenModalButton = () => (
    <Tooltip title={t('editFilter')} placement="top">
      <ButtonStyle
        onClick={(e) => {
          setModalToOpen('dateRange');
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
        aria-describedby={id}
      >
        <Icon type="edit" />
      </ButtonStyle>
    </Tooltip>
  );

  return (
    <>
      {modalToOpen && (
        <FilterModal
          id={id}
          open={!!modalToOpen}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          modalType={modalToOpen}
          setModalToOpen={setModalToOpen}
        />
      )}
      <FilterCardWrapper isEnabled={!checkedFilters[name]?.disabled || false}>
        <Box>
          <Typography
            sx={{
              fontSize: 12,
              padding: '7px 0px 7px 7px',
              borderBottom: type !== 'checkbox' ? `1px solid ${palette.grey[300]}` : 'none'
            }}
          >
            {name}
            {type === 'checkbox' ? (
              _renderEnableDisableSwitch()
            ) : extraType ? (
              _renderOpenModalButton()
            ) : (
              <></>
            )}
          </Typography>
          {type !== 'checkbox' && (
            <>
              {_renderCardContentBasedOnType()}
              <Box sx={{ borderTop: `1px solid ${palette.grey[300]}`, height: '20px' }}>
                {_renderEnableDisableSwitch()}
              </Box>
            </>
          )}
        </Box>
      </FilterCardWrapper>
    </>
  );
}

FilterCard.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  data: PropTypes.object,
  extraType: PropTypes.string
};
