import {
  getCurrentDashboardFiltersRequest,
  getCurrentDashboardFiltersError,
  getCurrentDashboardFiltersSuccess,
  getHeroMetricsRequest,
  getHeroMetricsSuccess,
  getHeroMetricsError,
  setHeroMetricsFilter,
  getHeroMetricOptionsRequest,
  getHeroMetricOptionsError,
  getHeroMetricOptionsSuccess
} from 'redux/slices/filters';
import { setNotification } from 'redux/slices/app';

export const getCurrentDashboardFilters =
  (id, setDefault) => async (dispatch, getState, extraArgument) => {
    const { apiService } = extraArgument;
    dispatch(getCurrentDashboardFiltersRequest());
    try {
      const response = await apiService.getCurrentDashboardFilters(id);
      dispatch(getCurrentDashboardFiltersSuccess(response));
      setDefault(response);
    } catch (error) {
      dispatch(getCurrentDashboardFiltersError(error.errorMessage));
      dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
    }
  };

export const getHeroMetrics =
  (heroMetric, clientGroupReportingIds) => async (dispatch, getState, extraArgument) => {
    const { apiService } = extraArgument;
    dispatch(getHeroMetricsRequest());
    try {
      const heroMetrics = await apiService.getHeroMetricsFilters(
        heroMetric,
        clientGroupReportingIds && clientGroupReportingIds.length > 1 ? [] : clientGroupReportingIds
      );
      dispatch(getHeroMetricsSuccess(heroMetrics));
      // dispatch(setHeroMetricsFilter(heroMetrics[0]));
    } catch (error) {
      dispatch(getHeroMetricsError(error.errorMessage));
      dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
    }
  };

export const getHeroMetricOptions =
  (clientGroupReportingIds, callback) => async (dispatch, getState, extraArgument) => {
    const { apiService } = extraArgument;
    dispatch(getHeroMetricOptionsRequest());
    try {
      const options = await apiService.getHeroMetricsOptions(
        clientGroupReportingIds && clientGroupReportingIds.length > 1 ? [] : clientGroupReportingIds
      );
      dispatch(getHeroMetricOptionsSuccess(options));
      callback(options);
    } catch (error) {
      dispatch(getHeroMetricOptionsError(error.errorMessage));
      dispatch(setNotification({ type: 'error', messageId: error.errorMessage }));
    }
  };
