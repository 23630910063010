import { Grid } from '@mui/material';
import RocketerLineChart from 'components/charts/LineChart/index';
import {
  selectActiveLabel,
  selectHeroMetricFilter,
  selectIsDashboardSidebarFiltersOpen
} from 'redux/slices/filters';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DRAWER_WIDTH } from 'uiConstants';
import { roundToNearestTen, getMaxOfArray } from 'utils/utility';
import { useState, useEffect } from 'react';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { selectIsSidebarOpen } from 'redux/slices/app';
import palette from 'theme/palette';
import CustomTooltip from 'components/charts/CustomTooltip';
import TimeSeriesDataTooltip from 'components/charts/LineChart/TimeSeriesDataTooltip';
// ----------------------------------------------------------------------

TimeSeriesLineChart.propTypes = {
  lineChartData: PropTypes.object,
  isModal: PropTypes.bool
};

const getInterval = (totalEntries) => {
  if (totalEntries < 60) return 0;
  if (totalEntries >= 60 && totalEntries < 100) return 3;
  if (totalEntries >= 100 && totalEntries < 200) return 4;
  if (totalEntries >= 200 && totalEntries < 250) return 6;
  if (totalEntries >= 2000 && totalEntries < 3000) return 60;
  return 10;
};

const colorHeroMetric = {
  '': 0,
  cr: 1,
  cpa: 2,
  cpc: 3,
  cpm: 0,
  lpvr: 1,
  lctr: 2
};

export default function TimeSeriesLineChart({ lineChartData, isModal }) {
  const activeHeroMetricLabel = useSelector(selectActiveLabel('heroMetrics'));
  const activeHeroMetricFilter = useSelector(selectHeroMetricFilter);
  const [YAxisTicks, setYAxisTicks] = useState([]);
  const [width] = useWindowSize();
  const [leftRightPadding, setLeftRightPadding] = useState(0);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const isFilterSidebarOpen = useSelector(selectIsDashboardSidebarFiltersOpen);
  const maxLengthData = 90;

  useEffect(() => {
    const maxValue = getMaxOfArray('value', lineChartData?.data);
    const tickMax = roundToNearestTen(maxValue);
    setYAxisTicks([
      0,
      Math.trunc(tickMax / 4),
      Math.trunc(tickMax / 2),
      Math.trunc((3 * tickMax) / 4),
      tickMax
    ]);
    let aproxChartWidth = isSidebarOpen ? width - DRAWER_WIDTH : width;
    aproxChartWidth = isFilterSidebarOpen ? aproxChartWidth - DRAWER_WIDTH : aproxChartWidth;
    setLeftRightPadding(aproxChartWidth / (lineChartData?.data.length + 2));
  }, [lineChartData, width, isSidebarOpen, isFilterSidebarOpen]);

  return (
    <Grid
      container
      sx={{
        MozTransform: 'scale(0.6)',
        MozTransformOrigin: 'top left',
        width: '165%',
        transform: 'scale(0.6)',
        transformOrigin: 'top left'
      }}
    >
      <div style={{ marginBottom: 30, marginLeft: 20, fontSize: 20 }}>
        {lineChartData?.title} Trend
      </div>
      <CustomTooltip zoomLeft={0.1} zoomTop={-0.3}>
        <div
          style={{
            overflowX:
              width <= 1440 && lineChartData?.data.length < maxLengthData ? 'hidden' : 'auto',
            overflowY: 'hidden',
            height: '515px'
          }}
        >
          <RocketerLineChart
            lineColor={palette.chart.lineChartColor[colorHeroMetric[activeHeroMetricFilter.type]]}
            chartData={lineChartData}
            axisTicks={YAxisTicks}
            leftRightPadding={leftRightPadding}
            angle={lineChartData?.data.length > 10 ? -40 : 0}
            tickInterval={getInterval(lineChartData?.data.length)}
            CustomTooltip={TimeSeriesDataTooltip}
          />
        </div>
      </CustomTooltip>
    </Grid>
  );
}
