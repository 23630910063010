/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Typography, TextField, IconButton } from '@mui/material';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  setCalendarFromAndTo,
  selectCalendarFromAndTo,
  selectDateListNoOfYears,
  selectDateListEarliest,
  selectDateListLatest
} from 'redux/slices/filters';
import { useDispatch, useSelector } from 'react-redux';
import { isBefore } from 'date-fns';
import { fDateDay, fDateMonth, fDateYear, getLastNumberOfOptions } from 'utils/formatTime';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import { generateXOptions, buildQuarterValue } from 'utils/utility';
import { LicenseInfo } from '@mui/x-license-pro';
import apiConfig from 'config/config';

const StyledCalendar = styled(Box)(() => ({
  width: '950px',
  transform: 'scale(0.7)',
  transformOrigin: 'top left',
  position: 'absolute',
  height: '400px'
}));

const DividerStyled = styled(Divider)(() => ({
  display: 'inline-block',
  width: '1px',
  height: '10px',
  marginTop: '10px'
}));

const TimeButtonStyle = styled(IconButton)(({ theme }) => ({
  background: 'none',
  display: 'inline-block',
  color: theme.palette.primary.darker,

  '&:hover': {
    background: 'none'
  },
  '& p': {
    fontSize: '12px',
    fontWeight: 500
  },
  '& p:hover': {
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.primary.light
  }
}));

const StyledInput = styled(Box)(({ theme }) => ({
  top: '240px',
  position: 'relative',
  width: '100%',
  background: theme.palette.common.white
}));

const StyledBox = styled(Box)(() => ({
  width: '100%'
}));

function Calendar({ setCalendarFrame, selectedCalendarFrame }) {
  LicenseInfo.setLicenseKey(apiConfig.calendarKey);

  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();
  const [triggered, setTriggered] = useState(false);
  const [focused, setIsFocused] = useState(null);
  const calendarFromAndTo = useSelector(selectCalendarFromAndTo);
  const dateListNoOfYears = useSelector(selectDateListNoOfYears);
  const dateListEarliest = useSelector(selectDateListEarliest);
  const dateListLatest = useSelector(selectDateListLatest);
  const [buttonTriggered, setButtonTriggered] = useState(false);
  const calendarDropdownQuarterOptions = generateXOptions(
    getLastNumberOfOptions('Quarters', dateListNoOfYears),
    'quarters',
    'QQQ yyyy',
    true
  );
  useEffect(() => {
    if (triggered) {
      if (value.length === 1) {
        dispatch(setCalendarFromAndTo(value));
      } else {
        dispatch(
          setCalendarFromAndTo(
            calendarFromAndTo[0] &&
              !calendarFromAndTo[1] &&
              isBefore(new Date(value), new Date(calendarFromAndTo[0]))
              ? [value, calendarFromAndTo[0]]
              : calendarFromAndTo[0] && calendarFromAndTo[1]
              ? [value, null]
              : calendarFromAndTo[0]
              ? [calendarFromAndTo[0], value]
              : [value, calendarFromAndTo[0]]
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  const _renderTodayAndMinMaxButtons = (secondTimeframe) => (
    <Box
      sx={{
        display: 'block',
        position: 'absolute',
        width: '100%',
        top: '286px',
        left: secondTimeframe === 'to' ? '480px' : '270px'
      }}
    >
      <TimeButtonStyle
        onClick={(e) => {
          setValue(new Date());
          setTriggered(true);
        }}
      >
        <Typography sx={{ fontSize: 17, fontWeight: 700, lineHeight: '20px' }} title="today">
          Today
        </Typography>
      </TimeButtonStyle>
      <DividerStyled orientation="vertical" color="grey" />

      <TimeButtonStyle
        onClick={() => {
          setValue(secondTimeframe === 'to' ? dateListEarliest : dateListLatest);
          setTriggered(true);
        }}
      >
        <Typography sx={{ fontSize: 17, fontWeight: 700, lineHeight: '20px' }}>
          {secondTimeframe === 'to' ? 'Latest Date' : 'Earliest Date'}
        </Typography>
      </TimeButtonStyle>
    </Box>
  );

  return (
    <StyledBox>
      <FormControl sx={{ width: '130%' }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={selectedCalendarFrame}
          onChange={(e) => {
            setCalendarFromAndTo([null, null]);
            setCalendarFrame(e.target.value);
          }}
          variant="standard"
          sx={{ width: '150px', fontSize: '12px', margin: 'auto' }}
        >
          {calendarDropdownQuarterOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={buildQuarterValue(option.name)}
              sx={{ fontSize: '12px' }}
            >
              {option.name}
            </MenuItem>
          ))}
          <MenuItem
            value={`${fDateMonth(new Date())}-${fDateDay(new Date())}-${fDateYear(new Date())}`}
            sx={{ fontSize: '12px' }}
          >
            {t('today')}
          </MenuItem>
          <MenuItem
            value={`${fDateMonth(new Date(dateListEarliest))}-${fDateDay(
              new Date(dateListEarliest)
            )}-${fDateYear(new Date(dateListEarliest))}`}
            sx={{ fontSize: '12px' }}
          >
            {t('beginningOfData')}
          </MenuItem>
          <MenuItem
            value={`${fDateMonth(new Date(dateListLatest))}-${fDateDay(
              new Date(dateListLatest)
            )}-${fDateYear(new Date(dateListLatest))}`}
            sx={{ fontSize: '12px' }}
          >
            {t('endOfData')}
          </MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledCalendar>
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            value={calendarFromAndTo}
            calendars={3}
            onChange={(newValue) => {
              setValue(newValue[0]);
              setTriggered(true);
            }}
            defaultCalendarMonth={new Date(selectedCalendarFrame)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </StyledCalendar>
        <StyledInput>
          <DateRangePicker
            startText="From"
            endText="To"
            value={calendarFromAndTo}
            onChange={(newValue) => {
              setValue(newValue[0]);
              setTriggered(true);
            }}
            open={false}
            renderInput={(startProps, endProps) => (
              <>
                <Box sx={{ marginLeft: '30px' }}>
                  <Typography
                    sx={{ fontSize: 12, height: 12, display: 'inline-block', marginLeft: '100px' }}
                  >
                    {t('from')}
                  </Typography>
                  <TextField
                    {...startProps}
                    variant="standard"
                    sx={{
                      fontSize: '12px',
                      marginLeft: '10px',
                      marginRight: '50px',
                      marginTop: '4px'
                    }}
                    size="small"
                    InputProps={{
                      style: { fontSize: '12px' }
                    }}
                    onFocus={() => setIsFocused('from')}
                    placeholder="mm/dd/yyyy"
                    label=""
                  />
                  <Typography sx={{ fontSize: 12, display: 'inline-block' }}>{t('to')}</Typography>
                  <TextField
                    {...endProps}
                    variant="standard"
                    size="small"
                    placeholder="mm/dd/yyyy"
                    sx={{ marginLeft: '10px', marginTop: '4px' }}
                    label=""
                    onFocus={() => setIsFocused('to')}
                    InputProps={{
                      style: { fontSize: '12px' }
                    }}
                  />
                </Box>
              </>
            )}
          />
        </StyledInput>
      </LocalizationProvider>
      {focused && _renderTodayAndMinMaxButtons(focused)}
    </StyledBox>
  );
}

export default Calendar;

Calendar.propTypes = {
  setCalendarFrame: PropTypes.func,
  selectedCalendarFrame: PropTypes.string
};
