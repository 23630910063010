import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#748ebf',
  light: '#395da5',
  main: '#03318c',
  dark: '#033179',
  darker: '#022A54',
  contrastText: '#fff'
};
const SECONDARY = {
  lighter: '#fea285',
  light: '#fe602f',
  main: '#fe3c00',
  dark: '#b72c00',
  darker: '#972400',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
  barChartColor: [
    '#00ADC1','#19c6da', // performance dashboard
    '#fbb755', '#FFD06EFF' // time series data
  ],
  lineChartColor: [
    '#6EDA55',
    '#b2b2f7',
    '#fc7570',
    '#00cee6'
  ],
  lineChartHoverColor: [
    '#56c03b',
    '#8585d2',
    '#da5d58',
    '#04adc0'
  ],
  stackedBarChartColor: {
    colors: [
      '#595959',
      '#cccccc',
      '#EF6537',
      '#ff8a86', 
      '#7030A0', 
      '#3B93A7', 
      '#093157'
    ],
    hoverColors: [
      '#464444',
      '#9d9d9d',
      '#d25224',
      '#e06f6b', 
      '#682798', 
      '#2b7e8d', 
      '#052546'
    ]
  },
  multiLineChartColors: [
    '#595959',
    '#cccccc',
    '#EF6537',
    '#ff8a86', 
    '#7030A0', 
    '#3B93A7', 
    '#093157'
  ],
  regionBarColors: ['#6eda55', '#9b9bd7', '#fc7570', '#00cee6'],
  regionHoverColors: ['#87f36e', '#b4b4f0', '#ff8e89', '#19e7ff'],
  timeOfDayBarColors: ['#6eda55', '#00cee6'],
  timeOfDayBarHoverColors: ['#87f36e', '#19e7ff'],
  timeOfDayLineColors: ['#fc7570', '#9b9bd7']
};

const TABLE_COLORS = {
  cellBackground: ['#a8da70', '#b6e076', '#d9ef90', '#fed585', '#fdc978', '#fba15d', '#f16e43'],
  cellBorderColor: '#aaa',
  cellBorder: `1px solid #aaa`
};

const PIE_CHART_COLORS = [
  '#093157',
  '#3B93A7',
  '#595959',
  '#cccccc',
  '#EF6537',
  '#7030A0',
  '#ff8a86'
];

const RADAR_CHART_COLORS = ['#6eda55', '#87f36e'];

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  table: TABLE_COLORS,
  pieChart: PIE_CHART_COLORS,
  radarChart: RADAR_CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
