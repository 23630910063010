import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, FormikProvider } from 'formik';
import { Link, TextField, Stack, Container, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
import { setNotification } from 'redux/slices/app';
import { useDispatch } from 'react-redux';
import Logo from 'components/Logo';
import Page from 'components/Page';
import { ForgotPasswordFormik } from 'utils/formik';
import { forgotPassword } from 'redux/thunk/auth';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const LogoStyle = styled('div')(() => ({
  margin: '0px auto 50px'
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = ForgotPasswordFormik();
  const { errors, touched, isSubmitting, getFieldProps, values } = formik;

  const onSubmit = () => {
    dispatch(forgotPassword(values.email));
    dispatch(setNotification({ type: 'success', messageId: 'ifYourAreAnAuthorizedUser' }));
    navigate('/login', { replace: true });
  };

  return (
    <RootStyle title={t('forgotPassword')}>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <LogoStyle>
              <Logo />
            </LogoStyle>
            <Typography variant="h4" gutterBottom>
              {t('recoverLostPassword')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{t('enterYourEmail')}</Typography>
          </Stack>
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={onSubmit}>
              <Stack spacing={3} sx={{ mb: 4 }}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label={t('email')}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
              <Stack spacing={3} sx={{ mb: 4, display: 'table', width: '100%' }} direction="row">
                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  to="/login"
                  sx={{ ml: 0.5, verticalAlign: 'middle', display: 'table-cell' }}
                >
                  {t('cancel')}
                </Link>

                <LoadingButton
                  size="large"
                  type="submit"
                  variant={isEmpty(errors) ? 'contained' : 'disabled'}
                  loading={isSubmitting}
                  sx={{ float: 'right' }}
                >
                  {t('send')}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
